import { TTableHeader } from "../../Packages/Table/Types";

export const EntityTableHeader: TTableHeader[] = [
    {
      column_id: "name",
      label: "Name",
      isSortable: "true",
      visible: true,
    },
    {
      column_id: "updated",
      label: "Updated",
      isSortable: "true",
      visible: true,
    },
    {
      column_id: "quick_look",
      label: "Quick Look",
      isSortable: "false",
      visible: true,
    },
    {
      column_id: "labels",
      label: "Labels",
      isSortable: "true",
      visible: true,
    },
    {
      column_id: "view",
      label: "View",
      isSortable: "",
      visible: false,
    },
    {
      column_id: "moreOptions",
      label: "",
      isSortable: "",
      visible: false,
    },
  ];