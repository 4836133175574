import { useEffect, useState } from "react";
import { getAllProjects } from "../Services/getAllProjects";
import { getAllBranches } from "../Services/getAllBranches";
import { useToast } from "./useToast";
import { AxiosError } from "axios";
import { ToastType } from "../Context/Providers/ToastProvider";
import { Branch } from "../Client/typescript-axios-client-generated";
export const useVersionControl = () => {
  const [currentBranch, setCurrentBranch] = useState<string>('');
  const [currentProject, setCurrentProject] = useState<string>('');
  const [currentBranchName, setCurrentBranchName] = useState<string>('')
  const[currentProjectName, setCurrentProjectName] = useState<string>('');
  const [branchesList, setBranchesList] = useState<Branch[]>([]);
  const [projectsList, setProjectsList] = useState<Branch[]>([]);

  const toast = useToast()

  const updateBranch = (id: string) => {
    setCurrentBranch(id);
    const currBranch = branchesList?.find(branch => branch.id === id)
    if(currBranch !== undefined){
      setCurrentBranchName(currBranch.name)
    }
  };
  const updateProject = (id: string) => {
    setCurrentProject(id);
    const currProject = projectsList?.find(project => project.id === id)
    if(currProject !== undefined){
      setCurrentProjectName(currProject.name)
    }
  };

  useEffect(() => {
    //fetch projects from backend and set here
    const projects = getAllProjects();
    projects
      .then((data: any) => {
        setProjectsList(data);
        if(data.length > 0){
          setCurrentProject(data[0].id)
          setCurrentProjectName(data[0].name)
        }
      })
      .catch((err: AxiosError) => {
        console.log(err);
        toast.open('Error occured while fetching projects', ToastType.ERROR)
      });
  }, []);

  useEffect(() => {
    if(currentProject === '') return
    const branches = getAllBranches(currentProject);
    branches.then((data) => {
      setBranchesList(data);
      if(data.length > 0){
        setCurrentBranch(data[0].id)
        setCurrentBranchName(data[0].name)
      }
    }).catch((err)=>{
      toast.open('Error occured while fetching branches', ToastType.ERROR)
    });
  }, [currentProject]);

  return {
    currentBranch,
    currentProject,
    currentBranchName,
    currentProjectName,
    projectsList,
    branchesList,
    updateBranch,
    updateProject,
  };
};
