import { FeatureSet } from "../../Client/typescript-axios-client-generated";
import { TRowProps } from "../../Packages/Table/Types";
import { BlueButton, GreyButton } from "../../Styles/Button";

export const convertToFeatureSetTableData = (
  featureSets: FeatureSet[],
  editClickHandler: (e: string) => void,
  duplicateClickHandler: (e: string) => void,
  deleteClickHandler: (e: string) => void,
  quickViewHandler: (e: string) => void,
): TRowProps[] => {
  return featureSets.map((featureSet) => {
    return {
      row_id: featureSet.id,
      data: {
        name: {
          column_id: "name",
          text: featureSet.name,
          link: "/data-layer/feature-sets/" + featureSet.id,
        },
        entity: {
          column_id: "entity",
          text: "household_id",
          link: "data-layer/entities/" + featureSet.entity_id,
        },
        labels: [],
        updated: {
          column_id: "updated",
          text: "2 month ago",
          toolTip: "Apr 24, 2024 12:57 PM (IST)",
        },
        quickLook: {
          column_id: "quickLook",
          text: "Open",
          buttonElement: GreyButton,
          onClickHandler: quickViewHandler
        },
        view: {
          column_id: "view",
          text: "View",
          buttonElement: BlueButton,
          onClickHandler: () => {},
        },
        moreOptions: [
          {
            column_id: "moreOptions",
            text: "Edit",
            onClickHandler: editClickHandler,
          },
          {
            column_id: "moreOptions",
            text: "Duplicate",
            onClickHandler: duplicateClickHandler,
          },
          {
            column_id: "moreOptions",
            text: "Delete",
            onClickHandler: deleteClickHandler,
          },
        ],
      },
    };
  });
};
