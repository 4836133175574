import { Box, Typography } from "@mui/material";
import { FlexColumnBox } from "../../../Styles";
import { Colors } from "../../../Constants";
import { LabelTypography } from "../../../Styles/CustomTypography";

type SingleSelectInputProps = {
  label: string;
  data: SingleSelectInputData[];
  onSelect: (id: string) => void;
  selectedOptionId: string;
  showAsGrid?: boolean;
  columnCount?: number;
  minWidth?: string;
};

export type SingleSelectInputData = {
  optionId: string;
  title: string;
  description: string;
};

export const SingleSelectInput = ({
  label,
  data,
  selectedOptionId,
  onSelect,
  showAsGrid,
  columnCount = 1,
  minWidth
}: SingleSelectInputProps) => {
  return (
    <FlexColumnBox>
      <LabelTypography style={{ fontWeight: "bold" }}>{label}</LabelTypography>
      <Box
        sx={{
          display: showAsGrid ? "grid" : "flex",
          flexDirection: showAsGrid ? "unset" : "column",
          gap: '1rem',
          gridTemplateColumns: showAsGrid ? `repeat(${columnCount}, minmax(${minWidth ?? '354px'}, 1fr))` : "unset",
        }}
      >
        {data.map((option) => {
          return (
            <Box
              key={option.optionId}
              onClick={() => {
                onSelect(option.optionId);
              }}
              sx={{
                padding: "16px",
                borderRadius: "0.5rem",
                boxShadow: '0 1px 2px #0003 !important',
                border: selectedOptionId === option.optionId ? `1px solid #234afa80` : '',
                backgroundColor:
                  selectedOptionId === option.optionId ? Colors.APP_MEDIUM_BLUE_COLOR : "",
                cursor: "pointer",
                '&:hover': {
                  backgroundColor: selectedOptionId === option.optionId ? Colors.APP_MEDIUM_BLUE_COLOR : Colors.APP_VERY_LIGHT_BLUE_COLOR,
                },
              }}
            >
              <Typography variant="h6" fontWeight={"bold"}>
                {option.title}
              </Typography>
              <Typography>{option.description}</Typography>
            </Box>
          );
        })}
      </Box>
    </FlexColumnBox>
  );
};
