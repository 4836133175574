import { Typography } from "@mui/material";
import { useSelectedRow } from "../../../../../Hooks/useSelectedRows";
import { useVersionControlContext } from "../../../../../Hooks/useVersionControlContext";
import { GenericTable } from "../../../../../Packages/Table";
import { TTableHeader } from "../../../../../Packages/Table/Types";
import {
  DownWardAnimatedBox,
  FlexColumnBox,
  FlexRowBox,
} from "../../../../../Styles";
import {
  BlueButton,
  GreenButton,
  RedButton,
} from "../../../../../Styles/Button";
import { convertProjectToTRowPropsArray } from "../../../../../Utils/Mapper/ProjectMapper";
import { Colors } from "../../../../../Constants";
import { useNavigate } from "react-router-dom";

export const ProjectsTable = () => {
  const versionControlContext = useVersionControlContext();
  const projectsInTableFormat = convertProjectToTRowPropsArray(
    versionControlContext?.projectsList ?? []
  );

  const { selectAllRows, selectedRows, updateSelectedRow, totalRows } =
    useSelectedRow(projectsInTableFormat);
  const header: TTableHeader[] = [
    { column_id: "name", label: "Name", isSortable: "asc", visible: true },
    {
      column_id: "description",
      label: "Description",
      isSortable: "desc",
      visible: true,
    },
  ];
  const navigate = useNavigate();

  return (
    <FlexColumnBox sx={{ padding: "1rem", flexGrow: 1 }}>
      <FlexRowBox sx={{ justifyContent: "space-between" }}>
        <DownWardAnimatedBox flag={selectedRows.length > 0}>
          <FlexColumnBox>
            <FlexRowBox sx={{ gap: "5px" }}>
              <GreenButton>Add to project</GreenButton>
              <RedButton>Delete</RedButton>
            </FlexRowBox>
            <Typography sx={{ color: Colors.LINK_TEXT_COLOR }}>
              {selectedRows.length} row selected
            </Typography>
          </FlexColumnBox>
        </DownWardAnimatedBox>
        <FlexColumnBox>
          <BlueButton
            onClick={() => {
              navigate("/governance/projects/create");
            }}
          >
            Create New Project
          </BlueButton>
          <Typography></Typography>
        </FlexColumnBox>
      </FlexRowBox>
      <GenericTable
        rows={projectsInTableFormat}
        tableHeader={header}
        selectAllRows={selectAllRows}
        updateSelectedRow={updateSelectedRow}
        totalRows={totalRows}
        selectedRows={selectedRows}
      />
    </FlexColumnBox>
  );
};
