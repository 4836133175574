import styled from "@emotion/styled";
import CheckBox from "@mui/material/Checkbox";
import { Colors } from "../Constants";

export const StyledThinBorderCheckBox = styled(CheckBox)({
  '&.MuiCheckbox-root': {
    color: Colors.LINK_TEXT_COLOR, // Custom color for the checkbox
    padding: '4px', // Adjust padding to make it more compact
  },
  '&.Mui-checked': {
    color: Colors.LINK_TEXT_COLOR, // Custom color for the checked state
  },
  '& .MuiSvgIcon-root': {
    fontSize: '1.2rem', // Adjust the size of the checkbox icon
  },
  '& .MuiSvgIcon-root path': {
    stroke: Colors.APP_LIGHT_GREY, // Adjust the stroke color
    strokeWidth: '0.001px', // Adjust the stroke width for thinner border
  },
  '&:hover .MuiSvgIcon-root path': {
    stroke: Colors.APP_LIGHT_GREY, // Change border color on hover
  },
});
