import { useReducer } from "react";
import { initialState } from "../Constants/DefaultStateValues/CreateFeatureFormDefaultState";
import { createFeatureReducer } from "../Reducers/CreateFeatureReducer";

export const useCreateFeatureForm = () => {
  const [createFeatureState, dispatch] = useReducer(
    createFeatureReducer,
    initialState
  );

  return {
    createFeatureState,
    dispatch,
  };
};
