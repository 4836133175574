import { useMemo } from "react";
import { GenericFilter } from "../../../../Components/GenericFilter/GenericFilter";
import {
  DownWardAnimatedBox,
  FlexColumnBox,
  FlexRowBox,
} from "../../../../Styles";
import { exportedData } from "../../../../TestingData/DataLayer";
import { useToast } from "../../../../Hooks/useToast";
import {
  mapToEntityMappingTableData,
} from "../../../../Utils/Mapper/EntityMappingMapper";
import { GenericTable } from "../../../../Packages/Table";
import { Box, Typography } from "@mui/material";
import { useSelectedRow } from "../../../../Hooks/useSelectedRows";
import { BlueButton, GreenButton, RedButton } from "../../../../Styles/Button";
import { Colors } from "../../../../Constants";
import { useNavigate } from "react-router-dom";
import { useGetEntityMappings } from "../../../../Hooks/DataFetcher/EntityMapping/useGetEntityMappings";
import { EntityMappingTableHeader } from "../../../../Constants/TableHeaders/EntityMappingTableHeader";

export const EntityMapping = () => {
  const { entityMappings, updateEntityMappings } = useGetEntityMappings();

  const toast = useToast();

  const navigate = useNavigate();

  const tableData = useMemo(() => {
    return mapToEntityMappingTableData(entityMappings);
  }, [entityMappings]);

  const { selectAllRows, selectedRows, totalRows, updateSelectedRow } =
    useSelectedRow(tableData);

  return (
    <FlexRowBox flexGrow={1} >
      <GenericFilter
        globalSearchEnabled={true}
        data={exportedData.filterData.data}
      />
      <FlexColumnBox sx={{ padding: "2rem", backgroundColor: Colors.APP_VERY_LIGHT_BLUE_COLOR, flexGrow: 1 }}>
        <FlexRowBox sx={{ justifyContent: "space-between" }}>
          <DownWardAnimatedBox flag={selectedRows.length > 0}>
            <FlexColumnBox>
              <FlexRowBox sx={{ gap: "5px" }}>
                {selectedRows.length <= 1 && (
                  <GreenButton>Duplicate</GreenButton>
                )}
                <RedButton>Delete</RedButton>
              </FlexRowBox>
              <Typography sx={{ color: Colors.LINK_TEXT_COLOR }}>
                {selectedRows.length} row selected
              </Typography>
            </FlexColumnBox>
          </DownWardAnimatedBox>
          <FlexColumnBox>
            <BlueButton
              onClick={() => {
                navigate("/data-layer/entity-mappings/create");
              }}
            >
              Create Entity Mapping
            </BlueButton>
            <Typography></Typography>
          </FlexColumnBox>
        </FlexRowBox>
        <GenericTable
          rows={tableData}
          tableHeader={EntityMappingTableHeader}
          selectAllRows={selectAllRows}
          updateSelectedRow={updateSelectedRow}
          totalRows={totalRows}
          selectedRows={selectedRows}
        />
      </FlexColumnBox>
    </FlexRowBox>
  );
};
