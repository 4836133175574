import { ApiClient } from "../Utils/ApiClient";

export const deleteEntityMapping = async (
  projectName: string,
  branchName: string,
  entity_mapping_id: string
) => {
  return ApiClient.entityMappingApiClient
    .deleteEntityMappingEntityMappingEntityMappingIdDelete(
      projectName,
      branchName,
      entity_mapping_id
    )
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};
