export const aggregationFunctions = [
    {
      "label": "Last",
      "itemId": "LAST",
      "description": "Retrieve the last value in a series of data points, often used to get the most recent observation."
    },
    {
      "label": "Sum",
      "itemId": "SUM",
      "description": "Calculate the total sum of all the values in a dataset, providing a cumulative total."
    },
    {
      "label": "Count",
      "itemId": "COUNT",
      "description": "Count the number of values in a dataset, useful for determining the size or quantity of data points."
    },
    {
      "label": "Count Distinct",
      "itemId": "COUNT_DISTINCT",
      "description": "Count the number of unique values in a dataset, helping to identify the diversity of data points."
    },
    {
      "label": "Avg",
      "itemId": "AVG",
      "description": "Calculate the average (mean) of the values in a dataset, providing an indication of the central tendency."
    },
    {
      "label": "Min",
      "itemId": "MIN",
      "description": "Find the minimum value in a dataset, identifying the smallest data point."
    },
    {
      "label": "Max",
      "itemId": "MAX",
      "description": "Find the maximum value in a dataset, identifying the largest data point."
    },
    {
      "label": "Min By",
      "itemId": "MIN_BY",
      "description": "Find the minimum value in a dataset based on a specified field or criteria, useful for conditional minimum calculations."
    },
    {
      "label": "Max By",
      "itemId": "MAX_BY",
      "description": "Find the maximum value in a dataset based on a specified field or criteria, useful for conditional maximum calculations."
    },
    {
      "label": "Std",
      "itemId": "STD",
      "description": "Calculate the standard deviation of values in a dataset, measuring the amount of variation or dispersion from the average."
    },
    {
      "label": "Basket Sum",
      "itemId": "BASKET_SUM",
      "description": "Calculate the total sum of values within a defined basket or group, providing a cumulative total for that specific subset."
    },
    {
      "label": "Basket Last",
      "itemId": "BASKET_LAST",
      "description": "Retrieve the last value within a defined basket or group, often used to get the most recent observation within that subset."
    },
    {
      "label": "Basket Max",
      "itemId": "BASKET_MAX",
      "description": "Find the maximum value within a defined basket or group, identifying the largest data point in that subset."
    },
    {
      "label": "Basket Min",
      "itemId": "BASKET_MIN",
      "description": "Find the minimum value within a defined basket or group, identifying the smallest data point in that subset."
    },
    {
      "label": "Collect List",
      "itemId": "COLLECT_LIST",
      "description": "Collect all values into a list, preserving the order of data points and allowing for sequential access."
    },
    {
      "label": "Collect Set",
      "itemId": "COLLECT_SET",
      "description": "Collect all unique values into a set, removing duplicates and providing a collection of distinct data points."
    },
    {
      "label": "First",
      "itemId": "FIRST",
      "description": "Retrieve the first value in a series of data points, often used to get the earliest observation."
    },
    {
      "label": "Percentage Change",
      "itemId": "PERCENTAGE_CHANGE",
      "description": "Calculate the percentage change between values, useful for understanding relative growth or decline over time."
    },
    {
      "label": "Absolute Change",
      "itemId": "ABSOLUTE_CHANGE",
      "description": "Calculate the absolute change between values, providing the actual difference between data points."
    },
    {
      "label": "Standard Score",
      "itemId": "STANDARD_SCORE",
      "description": "Calculate the standard score (z-score) of values, indicating how many standard deviations a data point is from the mean."
    }
  ]
  