import React from "react";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useNavigate } from "react-router-dom";
const PermissionDenied = () => {
    const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#f9f9f9",
        padding: "20px",
        textAlign: "center",
        flexGrow: 1
      }}
    >
      <WarningAmberIcon sx={{ color: "red", height: 48, width: 48 }} />
      <h2 style={{ marginTop: "20px", color: "#333" }}>
        You do not have permission to access the requested page
      </h2>
      <p style={{ marginTop: "10px", color: "#666" }}>
        Either go back to the previous page or log in as a different user.
      </p>
      <div style={{ marginTop: "20px" }}>
        <button
          onClick={() => {
            // Handle login as different user
          }}
          style={{
            padding: "10px 20px",
            backgroundColor: "#007bff",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            marginRight: "10px",
          }}
        >
          Log in as different user
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
          style={{
            padding: "10px 20px",
            backgroundColor: "transparent",
            color: "#007bff",
            border: "1px solid #007bff",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Go back
        </button>
      </div>
    </div>
  );
};

export default PermissionDenied;
