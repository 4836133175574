import { useEffect, useReducer } from "react";

import { reducer } from "../../../../../../Reducers/createScheduleReducer";
import { initialState } from "../../../../../../Constants/DefaultStateValues/createScheduleFormDeafultState";
import ClustersOptions from "../../../../../../Constants/ClustersList";
import { getAllDomains } from "../../../../../../Services/getDomains";
import { getAllTeams } from "../../../../../../Services/getTeams";
import { ETarget } from "../../Type";
import { useVersionControlContext } from "../../../../../../Hooks/useVersionControlContext";
import { getAllFeatureSetsByBranchId } from "../../../../../../Services/getAllFeatureSetsByBranchId";

export const useCreateSchedule = () => {
  const [createScheduleState, dispatch] = useReducer(reducer, initialState);
  const versionControlContext = useVersionControlContext();

  useEffect(() => {
    dispatch({ type: "SET_CLUSTERS_DATA", payload: ClustersOptions });

    const domainDataPromise = getAllDomains();
    const teamDataPromise = getAllTeams();

    Promise.all([domainDataPromise, teamDataPromise])
      .then((results) => {
        dispatch({ type: "SET_DOMAINS_DATA", payload: results[0] });
        dispatch({ type: "SET_TEAMS_DATA", payload: results[1] });
      })
      .catch((err) => {
        //TODO Handle error if any of promise failed
      });

    dispatch({ type: "SET_TEAMS_DATA", payload: ClustersOptions });
  }, []);

  useEffect(() => {
    if (!versionControlContext) return;
    let branch = versionControlContext.branchesList.find((branch) => branch.id == createScheduleState.branch[0]);
    let branchName = branch ? branch.name : undefined;

    if (createScheduleState.target === ETarget.OFFICIAL) {
      branchName = "main";
    }
    if(!branchName ) return;

    getAllFeatureSetsByBranchId(
      versionControlContext.currentProjectName,
      branchName
    )
      .then((data) => {
        dispatch({
          type: "SET_FEATURE_SET_DATA",
          payload: data,
        });
      })
      .catch((err) => {});
  }, [
    createScheduleState.target,
    versionControlContext?.currentProject,
    createScheduleState.branch,
  ]);

  return {
    createScheduleState,
    dispatch,
  };
};
