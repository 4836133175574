import { GenericFilterProps } from "../Components/GenericFilter/GenericFilter";
import { TGenericTableProps, TRow, TRowProps, TTableHeader } from "../Packages/Table/Types";
import { Data, FilterType } from "../Types";

const filterData: GenericFilterProps = {
    data: [
        {
            id: "Entity",
            type: FilterType.CHECKBOX,
            data: [
                { id: "account_id", label: "account_id" },
                { id: "service_id", label: "service_id" },
                { id: "household_id", label: "household_id" },
                { id: "individual_id", label: "individual_id" },
                
            ],
        },
        {
            id: "Table",
            type: FilterType.CHECKBOX,
            data: [
                { id: "account_id", label: "bvp_dataprod_sv..." },
                { id: "service_id", label: "service_id" },
                { id: "household_id", label: "household_id" },
                { id: "individual_id", label: "individual_id" },
                
            ],
        },
        {
            id: "dropdown-filter",
            type: FilterType.DROPDOWN,
            data: [
                { id: "item1", label: "Item 1" },
                { id: "item2", label: "Item 2" },
                { id: "item3", label: "Item 3" },
            ],
        }
    ],
    globalSearchEnabled: false
};    
const tableHeader: TTableHeader[] = [
  { column_id: "name", label: "Name", isSortable: "asc", visible: true },
  { column_id: "tableType", label: "Table Type", isSortable: "desc", visible: true },
  { column_id: "source", label: "Sources", isSortable: "asc", visible: true },
  { column_id: "labels", label: "Labels", isSortable: "asc", visible: true },
  { column_id: "updated", label: "Updated", isSortable: "asc", visible: true },
  { column_id: "quickLook", label: "Quick Look", isSortable: "asc", visible: true },
  { column_id: "view", label: "View", isSortable: "asc", visible: true },
  { column_id: "moreOptions", label: "More Options", isSortable: "asc", visible: true },
];

const rows: TRowProps[] = [];

for (let i = 1; i <= 20; i++) {
  const row_id = `row_${i}`; // Generate unique row_id for each row
  const row: TRow = {
    name: [{ column_id: "name", text: `this_is_long_name`, link: `/profile/${i}` },{ column_id: "name", text: `this_is_name_long_long`, link: `/profile/${i}` },{ column_id: "name", text: `Name ${i}`, link: `/profile/${i}` }],
    tableType: { column_id: "tableType", text: `Table Type ${i}` },
    sources: { column_id: "sources", text: `Sources ${i}` },
    labels: [
      { column_id: "labels", text: `Label` },
      { column_id: "labels", text: `Label ${i * 2}` },
      { column_id: "labels", text: `Label ${i * 2 - 1}` },
      { column_id: "labels", text: `Label ${i * 2 - 1}` }
    ],
    updated: { column_id: "updated", text: `${i} months ago`, toolTip: '24/04/2020 - 3:20' },
    quickLook: { column_id: "quickLook", text: `Quick Look ${i}` },
    view: { column_id: "view", text: `View ${i}` },
    moreOptions: [
      { column_id: "moreOptions", text: `Option 1`, onClickHandler: () => alert(`Clicked on Option 1 for row ${i}`) },
      { column_id: "moreOptions", text: `Option 2`, onClickHandler: () => alert(`Clicked on Option 2 for row ${i}`) },
      { column_id: "moreOptions", text: `Option 3`, onClickHandler: () => alert(`Clicked on Option 3 for row ${i}`) },
      { column_id: "moreOptions", text: `Option 4`, onClickHandler: () => alert(`Clicked on Option 4 for row ${i}`) },
    ],
  };
  rows.push({
    row_id: row_id,
    data: row,
  });
}




const dummyData: TGenericTableProps = {
  rows: rows,
  tableHeader: tableHeader,
  selectAllRows: function (): void {
    throw new Error("Function not implemented.");
  },
  updateSelectedRow: function (rowId: string): void {
    throw new Error("Function not implemented.");
  },
  totalRows: 0,
  selectedRows: []
};

export const exportedData = {dummyData: dummyData, filterData: filterData};

