/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AddReviewer
 */
export interface AddReviewer {
    /**
     * List of user_ids you want to include as reviewer
     * @type {Array<string>}
     * @memberof AddReviewer
     */
    'users': Array<string>;
}
/**
 * 
 * @export
 * @interface AdditionalSparkPropertyCreate
 */
export interface AdditionalSparkPropertyCreate {
    /**
     * 
     * @type {string}
     * @memberof AdditionalSparkPropertyCreate
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalSparkPropertyCreate
     */
    'value': string | null;
}
/**
 * 
 * @export
 * @interface AttributeDataInsertSchema
 */
export interface AttributeDataInsertSchema {
    /**
     * 
     * @type {string}
     * @memberof AttributeDataInsertSchema
     */
    'attribute_id': string;
    /**
     * 
     * @type {any}
     * @memberof AttributeDataInsertSchema
     */
    'value': any;
}
/**
 * 
 * @export
 * @interface BaseEntity
 */
export interface BaseEntity {
    /**
     * This is the Name of entity
     * @type {string}
     * @memberof BaseEntity
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BaseEntity
     */
    'description': string;
    /**
     * 
     * @type {EntityType}
     * @memberof BaseEntity
     */
    'entity_type': EntityType;
    /**
     * 
     * @type {string}
     * @memberof BaseEntity
     */
    'output_column': string | null;
    /**
     * 
     * @type {DataType}
     * @memberof BaseEntity
     */
    'data_type'?: DataType | null;
    /**
     * 
     * @type {string}
     * @memberof BaseEntity
     */
    'id': string;
    /**
     * 
     * @type {Array<BaseEntity>}
     * @memberof BaseEntity
     */
    'composite_mappings': Array<BaseEntity>;
    /**
     * 
     * @type {Array<AttributeDataInsertSchema>}
     * @memberof BaseEntity
     */
    'attribute_data': Array<AttributeDataInsertSchema>;
}


/**
 * 
 * @export
 * @interface BaseEntityBase
 */
export interface BaseEntityBase {
    /**
     * This is the Name of entity
     * @type {string}
     * @memberof BaseEntityBase
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BaseEntityBase
     */
    'description': string;
    /**
     * 
     * @type {EntityType}
     * @memberof BaseEntityBase
     */
    'entity_type': EntityType;
    /**
     * 
     * @type {string}
     * @memberof BaseEntityBase
     */
    'output_column': string | null;
    /**
     * 
     * @type {DataType}
     * @memberof BaseEntityBase
     */
    'data_type'?: DataType | null;
}


/**
 * 
 * @export
 * @interface BaseEntityCreate
 */
export interface BaseEntityCreate {
    /**
     * This is the Name of entity
     * @type {string}
     * @memberof BaseEntityCreate
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BaseEntityCreate
     */
    'description': string;
    /**
     * 
     * @type {EntityType}
     * @memberof BaseEntityCreate
     */
    'entity_type': EntityType;
    /**
     * 
     * @type {string}
     * @memberof BaseEntityCreate
     */
    'output_column': string | null;
    /**
     * 
     * @type {DataType}
     * @memberof BaseEntityCreate
     */
    'data_type'?: DataType | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof BaseEntityCreate
     */
    'composite_mappings': Array<string>;
    /**
     * 
     * @type {Array<AttributeDataInsertSchema>}
     * @memberof BaseEntityCreate
     */
    'attribute_data': Array<AttributeDataInsertSchema>;
}


/**
 * 
 * @export
 * @interface BaseFeature
 */
export interface BaseFeature {
    /**
     * 
     * @type {string}
     * @memberof BaseFeature
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BaseFeature
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BaseFeature
     */
    'description'?: string | null;
    /**
     * 
     * @type {TransformationType}
     * @memberof BaseFeature
     */
    'transformation_type': TransformationType;
    /**
     * 
     * @type {string}
     * @memberof BaseFeature
     */
    'event_table'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseFeature
     */
    'event_aggregation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseFeature
     */
    'aggregation_expression'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseFeature
     */
    'event_additional_filter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseFeature
     */
    'event_post_aggregation_filter'?: string | null;
    /**
     * 
     * @type {EventFeatureWindowType}
     * @memberof BaseFeature
     */
    'event_window_type'?: EventFeatureWindowType | null;
    /**
     * 
     * @type {number}
     * @memberof BaseFeature
     */
    'event_window_size'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BaseFeature
     */
    'composite_entity_id'?: string | null;
    /**
     * 
     * @type {BaseEntityBase}
     * @memberof BaseFeature
     */
    'composite_entity': BaseEntityBase | null;
    /**
     * 
     * @type {Array<BaseEntityBase>}
     * @memberof BaseFeature
     */
    'entity_restrictions': Array<BaseEntityBase> | null;
    /**
     * 
     * @type {Array<FeatureBaseSchema>}
     * @memberof BaseFeature
     */
    'feature_dependencies': Array<FeatureBaseSchema> | null;
    /**
     * 
     * @type {Array<AttributeDataInsertSchema>}
     * @memberof BaseFeature
     */
    'attribute_data': Array<AttributeDataInsertSchema>;
    /**
     * 
     * @type {Array<FeatureSetInDBBase>}
     * @memberof BaseFeature
     */
    'feature_sets'?: Array<FeatureSetInDBBase> | null;
}


/**
 * 
 * @export
 * @interface BaseFeatureCreate
 */
export interface BaseFeatureCreate {
    /**
     * 
     * @type {string}
     * @memberof BaseFeatureCreate
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseFeatureCreate
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BaseFeatureCreate
     */
    'description'?: string | null;
    /**
     * 
     * @type {TransformationType}
     * @memberof BaseFeatureCreate
     */
    'transformation_type': TransformationType;
    /**
     * 
     * @type {string}
     * @memberof BaseFeatureCreate
     */
    'event_table'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseFeatureCreate
     */
    'event_aggregation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseFeatureCreate
     */
    'aggregation_expression'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseFeatureCreate
     */
    'event_additional_filter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseFeatureCreate
     */
    'event_post_aggregation_filter'?: string | null;
    /**
     * 
     * @type {EventFeatureWindowType}
     * @memberof BaseFeatureCreate
     */
    'event_window_type'?: EventFeatureWindowType | null;
    /**
     * 
     * @type {number}
     * @memberof BaseFeatureCreate
     */
    'event_window_size'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BaseFeatureCreate
     */
    'composite_entity_id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof BaseFeatureCreate
     */
    'entity_restrictions': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BaseFeatureCreate
     */
    'dependencies_mapping': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BaseFeatureCreate
     */
    'sources_mapping': Array<string>;
    /**
     * 
     * @type {Array<AttributeDataInsertSchema>}
     * @memberof BaseFeatureCreate
     */
    'attributes_data': Array<AttributeDataInsertSchema>;
}


/**
 * 
 * @export
 * @interface BaseTable
 */
export interface BaseTable {
    /**
     * 
     * @type {string}
     * @memberof BaseTable
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BaseTable
     */
    'description'?: string | null;
    /**
     * 
     * @type {TableType}
     * @memberof BaseTable
     */
    'table_type'?: TableType | null;
}


/**
 * 
 * @export
 * @interface Branch
 */
export interface Branch {
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    'project_id': string;
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    'from_branch_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    'created_by': string | null;
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    'updated_by': string | null;
    /**
     * 
     * @type {BranchInDBBase}
     * @memberof Branch
     */
    'from_branch'?: BranchInDBBase | null;
    /**
     * 
     * @type {number}
     * @memberof Branch
     */
    'commits_behind'?: number;
    /**
     * 
     * @type {number}
     * @memberof Branch
     */
    'commits_ahead'?: number;
}
/**
 * 
 * @export
 * @interface BranchCreate
 */
export interface BranchCreate {
    /**
     * 
     * @type {string}
     * @memberof BranchCreate
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BranchCreate
     */
    'project_id': string;
    /**
     * 
     * @type {string}
     * @memberof BranchCreate
     */
    'from_branch_id'?: string | null;
}
/**
 * 
 * @export
 * @interface BranchInDBBase
 */
export interface BranchInDBBase {
    /**
     * 
     * @type {string}
     * @memberof BranchInDBBase
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BranchInDBBase
     */
    'project_id': string;
    /**
     * 
     * @type {string}
     * @memberof BranchInDBBase
     */
    'from_branch_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BranchInDBBase
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BranchInDBBase
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof BranchInDBBase
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof BranchInDBBase
     */
    'created_by': string | null;
    /**
     * 
     * @type {string}
     * @memberof BranchInDBBase
     */
    'updated_by': string | null;
}
/**
 * 
 * @export
 * @interface ChangeEntrySchema
 */
export interface ChangeEntrySchema {
    /**
     * 
     * @type {string}
     * @memberof ChangeEntrySchema
     */
    'field_name': string;
    /**
     * 
     * @type {FromValue}
     * @memberof ChangeEntrySchema
     */
    'from_value': FromValue | null;
    /**
     * 
     * @type {ToValue}
     * @memberof ChangeEntrySchema
     */
    'to_value': ToValue | null;
    /**
     * 
     * @type {boolean}
     * @memberof ChangeEntrySchema
     */
    'is_changed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChangeEntrySchema
     */
    'show_in_code_format': boolean;
}
/**
 * 
 * @export
 * @interface ChangeRequestCreate
 */
export interface ChangeRequestCreate {
    /**
     * Name of the change request
     * @type {string}
     * @memberof ChangeRequestCreate
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestCreate
     */
    'description'?: string | null;
    /**
     * ID of the source branch
     * @type {string}
     * @memberof ChangeRequestCreate
     */
    'source_branch_id': string;
    /**
     * ID of the target branch
     * @type {string}
     * @memberof ChangeRequestCreate
     */
    'target_branch_id': string;
    /**
     * ID of the project
     * @type {string}
     * @memberof ChangeRequestCreate
     */
    'project_id': string;
}
/**
 * 
 * @export
 * @interface ChangeRequestGetSchema
 */
export interface ChangeRequestGetSchema {
    /**
     * Name of the change request
     * @type {string}
     * @memberof ChangeRequestGetSchema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestGetSchema
     */
    'description'?: string | null;
    /**
     * ID of the source branch
     * @type {string}
     * @memberof ChangeRequestGetSchema
     */
    'source_branch_id': string;
    /**
     * ID of the target branch
     * @type {string}
     * @memberof ChangeRequestGetSchema
     */
    'target_branch_id': string;
    /**
     * ID of the project
     * @type {string}
     * @memberof ChangeRequestGetSchema
     */
    'project_id': string;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestGetSchema
     */
    'id': string;
    /**
     * 
     * @type {ProjectInDBBase}
     * @memberof ChangeRequestGetSchema
     */
    'project': ProjectInDBBase;
    /**
     * 
     * @type {BranchInDBBase}
     * @memberof ChangeRequestGetSchema
     */
    'source_branch': BranchInDBBase;
    /**
     * 
     * @type {BranchInDBBase}
     * @memberof ChangeRequestGetSchema
     */
    'target_branch': BranchInDBBase;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestGetSchema
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestGetSchema
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestGetSchema
     */
    'merged_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestGetSchema
     */
    'merged_by': string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestGetSchema
     */
    'created_by': string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestGetSchema
     */
    'updated_by': string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestGetSchema
     */
    'closed_at': string | null;
}
/**
 * 
 * @export
 * @interface ChangeRequestGetSchemaWithCommits
 */
export interface ChangeRequestGetSchemaWithCommits {
    /**
     * Name of the change request
     * @type {string}
     * @memberof ChangeRequestGetSchemaWithCommits
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestGetSchemaWithCommits
     */
    'description'?: string | null;
    /**
     * ID of the source branch
     * @type {string}
     * @memberof ChangeRequestGetSchemaWithCommits
     */
    'source_branch_id': string;
    /**
     * ID of the target branch
     * @type {string}
     * @memberof ChangeRequestGetSchemaWithCommits
     */
    'target_branch_id': string;
    /**
     * ID of the project
     * @type {string}
     * @memberof ChangeRequestGetSchemaWithCommits
     */
    'project_id': string;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestGetSchemaWithCommits
     */
    'id': string;
    /**
     * 
     * @type {ProjectInDBBase}
     * @memberof ChangeRequestGetSchemaWithCommits
     */
    'project': ProjectInDBBase;
    /**
     * 
     * @type {BranchInDBBase}
     * @memberof ChangeRequestGetSchemaWithCommits
     */
    'source_branch': BranchInDBBase;
    /**
     * 
     * @type {BranchInDBBase}
     * @memberof ChangeRequestGetSchemaWithCommits
     */
    'target_branch': BranchInDBBase;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestGetSchemaWithCommits
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestGetSchemaWithCommits
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestGetSchemaWithCommits
     */
    'merged_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestGetSchemaWithCommits
     */
    'merged_by': string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestGetSchemaWithCommits
     */
    'created_by': string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestGetSchemaWithCommits
     */
    'updated_by': string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestGetSchemaWithCommits
     */
    'closed_at': string | null;
    /**
     * 
     * @type {Array<CommitGetSchema>}
     * @memberof ChangeRequestGetSchemaWithCommits
     */
    'commits': Array<CommitGetSchema>;
    /**
     * 
     * @type {Array<ChangeSchema>}
     * @memberof ChangeRequestGetSchemaWithCommits
     */
    'changes': Array<ChangeSchema>;
}
/**
 * 
 * @export
 * @interface ChangeSchema
 */
export interface ChangeSchema {
    /**
     * 
     * @type {string}
     * @memberof ChangeSchema
     */
    'id': string;
    /**
     * 
     * @type {Array<ChangeEntrySchema>}
     * @memberof ChangeSchema
     */
    'change': Array<ChangeEntrySchema>;
    /**
     * 
     * @type {string}
     * @memberof ChangeSchema
     */
    'operation_type': string;
    /**
     * 
     * @type {string}
     * @memberof ChangeSchema
     */
    'item_type': string;
    /**
     * 
     * @type {string}
     * @memberof ChangeSchema
     */
    'item_name': string;
}
/**
 * 
 * @export
 * @interface Cluster
 */
export interface Cluster {
    /**
     * 
     * @type {string}
     * @memberof Cluster
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Cluster
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface CommitGetSchema
 */
export interface CommitGetSchema {
    /**
     * 
     * @type {string}
     * @memberof CommitGetSchema
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof CommitGetSchema
     */
    'created_by': string | null;
    /**
     * 
     * @type {string}
     * @memberof CommitGetSchema
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof CommitGetSchema
     */
    'updated_by': string | null;
    /**
     * 
     * @type {string}
     * @memberof CommitGetSchema
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof CommitGetSchema
     */
    'dolt_commit_id': string;
    /**
     * 
     * @type {string}
     * @memberof CommitGetSchema
     */
    'id': string;
    /**
     * 
     * @type {}
     * @memberof CommitGetSchema
     */
    'changes'?:  | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CreationMode = {
    Standard: 'STANDARD',
    Streaming: 'STREAMING'
} as const;

export type CreationMode = typeof CreationMode[keyof typeof CreationMode];


/**
 * 
 * @export
 * @interface CustomScheduleAttributesCreate
 */
export interface CustomScheduleAttributesCreate {
    /**
     * 
     * @type {string}
     * @memberof CustomScheduleAttributesCreate
     */
    'cron': string;
}
/**
 * 
 * @export
 * @interface DailyScheduleAttributesCreate
 */
export interface DailyScheduleAttributesCreate {
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleAttributesCreate
     */
    'run_time': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DataType = {
    Long: 'Long',
    String: 'String',
    Integer: 'Integer',
    Binary: 'Binary'
} as const;

export type DataType = typeof DataType[keyof typeof DataType];


/**
 * 
 * @export
 * @interface DeleteEntityMappingSchema
 */
export interface DeleteEntityMappingSchema {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteEntityMappingSchema
     */
    'result': boolean;
}
/**
 * 
 * @export
 * @interface Doamin
 */
export interface Doamin {
    /**
     * 
     * @type {string}
     * @memberof Doamin
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Doamin
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface EntityMappingCreateSchema
 */
export interface EntityMappingCreateSchema {
    /**
     * UUID of the source entity
     * @type {string}
     * @memberof EntityMappingCreateSchema
     */
    'from_entity_id': string;
    /**
     * UUID of the destination entity
     * @type {string}
     * @memberof EntityMappingCreateSchema
     */
    'to_entity_id': string;
    /**
     * UUID of the feature associated with this mapping
     * @type {string}
     * @memberof EntityMappingCreateSchema
     */
    'mapping_feature_id': string;
    /**
     * Boolean flag indicating if multiple entities are involved
     * @type {boolean}
     * @memberof EntityMappingCreateSchema
     */
    'project_multiple_entities': boolean;
}
/**
 * 
 * @export
 * @interface EntityMappingGetSchema
 */
export interface EntityMappingGetSchema {
    /**
     * UUID of the source entity
     * @type {string}
     * @memberof EntityMappingGetSchema
     */
    'from_entity_id': string;
    /**
     * UUID of the destination entity
     * @type {string}
     * @memberof EntityMappingGetSchema
     */
    'to_entity_id': string;
    /**
     * UUID of the feature associated with this mapping
     * @type {string}
     * @memberof EntityMappingGetSchema
     */
    'mapping_feature_id': string;
    /**
     * Boolean flag indicating if multiple entities are involved
     * @type {boolean}
     * @memberof EntityMappingGetSchema
     */
    'project_multiple_entities': boolean;
    /**
     * UUID of the entity mapping
     * @type {string}
     * @memberof EntityMappingGetSchema
     */
    'id': string;
    /**
     * 
     * @type {BaseEntityBase}
     * @memberof EntityMappingGetSchema
     */
    'from_entity': BaseEntityBase;
    /**
     * 
     * @type {BaseEntityBase}
     * @memberof EntityMappingGetSchema
     */
    'to_entity': BaseEntityBase;
    /**
     * 
     * @type {FeatureBaseSchema}
     * @memberof EntityMappingGetSchema
     */
    'mapping_feature': FeatureBaseSchema;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const EntityType = {
    BaseEntity: 'BASE_ENTITY',
    CompositeEntity: 'COMPOSITE_ENTITY'
} as const;

export type EntityType = typeof EntityType[keyof typeof EntityType];


/**
 * 
 * @export
 * @enum {string}
 */

export const EventFeatureWindowType = {
    OpenWindow: 'OPEN_WINDOW',
    RowWindow: 'ROW_WINDOW',
    HourWindow: 'HOUR_WINDOW',
    DayWindow: 'DAY_WINDOW',
    MonthWindow: 'MONTH_WINDOW'
} as const;

export type EventFeatureWindowType = typeof EventFeatureWindowType[keyof typeof EventFeatureWindowType];


/**
 * 
 * @export
 * @interface EventOrFactTable
 */
export interface EventOrFactTable {
    /**
     * 
     * @type {string}
     * @memberof EventOrFactTable
     */
    'timestamp_column'?: string | null;
}
/**
 * 
 * @export
 * @interface EventStore
 */
export interface EventStore {
    /**
     * 
     * @type {string}
     * @memberof EventStore
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EventStore
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EventStore
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface ExternalTableCreateSchema
 */
export interface ExternalTableCreateSchema {
    /**
     * 
     * @type {string}
     * @memberof ExternalTableCreateSchema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ExternalTableCreateSchema
     */
    'description'?: string | null;
    /**
     * 
     * @type {TableType}
     * @memberof ExternalTableCreateSchema
     */
    'table_type'?: TableType | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalTableCreateSchema
     */
    'source': string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalTableCreateSchema
     */
    'source_table': string | null;
    /**
     * 
     * @type {Array<TableEntityMapping>}
     * @memberof ExternalTableCreateSchema
     */
    'table_entity_mapping': Array<TableEntityMapping> | null;
    /**
     * 
     * @type {TableTimeSemantics}
     * @memberof ExternalTableCreateSchema
     */
    'table_time_semantics'?: TableTimeSemantics | null;
    /**
     * 
     * @type {Array<AttributeDataInsertSchema>}
     * @memberof ExternalTableCreateSchema
     */
    'attributes_data': Array<AttributeDataInsertSchema>;
}


/**
 * 
 * @export
 * @interface FeatureBaseSchema
 */
export interface FeatureBaseSchema {
    /**
     * 
     * @type {string}
     * @memberof FeatureBaseSchema
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeatureBaseSchema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureBaseSchema
     */
    'description'?: string | null;
    /**
     * 
     * @type {TransformationType}
     * @memberof FeatureBaseSchema
     */
    'transformation_type': TransformationType;
    /**
     * 
     * @type {string}
     * @memberof FeatureBaseSchema
     */
    'event_table'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeatureBaseSchema
     */
    'event_aggregation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeatureBaseSchema
     */
    'aggregation_expression'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeatureBaseSchema
     */
    'event_additional_filter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeatureBaseSchema
     */
    'event_post_aggregation_filter'?: string | null;
    /**
     * 
     * @type {EventFeatureWindowType}
     * @memberof FeatureBaseSchema
     */
    'event_window_type'?: EventFeatureWindowType | null;
    /**
     * 
     * @type {number}
     * @memberof FeatureBaseSchema
     */
    'event_window_size'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FeatureBaseSchema
     */
    'composite_entity_id'?: string | null;
}


/**
 * 
 * @export
 * @interface FeatureGenerationCreateResponse
 */
export interface FeatureGenerationCreateResponse {
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationCreateResponse
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface FeatureGenerationDetailedGetSchema
 */
export interface FeatureGenerationDetailedGetSchema {
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationDetailedGetSchema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationDetailedGetSchema
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationDetailedGetSchema
     */
    'cluster_id': string;
    /**
     * 
     * @type {ScheduleType}
     * @memberof FeatureGenerationDetailedGetSchema
     */
    'schedule_type': ScheduleType;
    /**
     * 
     * @type {any}
     * @memberof FeatureGenerationDetailedGetSchema
     */
    'created_at': any;
    /**
     * 
     * @type {any}
     * @memberof FeatureGenerationDetailedGetSchema
     */
    'updated_at': any;
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationDetailedGetSchema
     */
    'created_by'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationDetailedGetSchema
     */
    'updated_by'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationDetailedGetSchema
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureGenerationDetailedGetSchema
     */
    'contains_pii'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureGenerationDetailedGetSchema
     */
    'contains_token'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureGenerationDetailedGetSchema
     */
    'contains_usagedata'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureGenerationDetailedGetSchema
     */
    'contains_web_token'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationDetailedGetSchema
     */
    'creator': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationDetailedGetSchema
     */
    'domain_id': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationDetailedGetSchema
     */
    'owner_id': string;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureGenerationDetailedGetSchema
     */
    'merge_files_csv'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationDetailedGetSchema
     */
    'model_dependencies': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationDetailedGetSchema
     */
    'post_process_workflow': string;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureGenerationDetailedGetSchema
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {CreationMode}
     * @memberof FeatureGenerationDetailedGetSchema
     */
    'creation_mode': CreationMode;
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationDetailedGetSchema
     */
    'branch': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationDetailedGetSchema
     */
    'feature_set': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FeatureGenerationDetailedGetSchema
     */
    'labels': Array<string>;
    /**
     * 
     * @type {RetryCreate}
     * @memberof FeatureGenerationDetailedGetSchema
     */
    'retry'?: RetryCreate;
    /**
     * 
     * @type {DailyScheduleAttributesCreate}
     * @memberof FeatureGenerationDetailedGetSchema
     */
    'daily_schedule_attributes'?: DailyScheduleAttributesCreate | null;
    /**
     * 
     * @type {CustomScheduleAttributesCreate}
     * @memberof FeatureGenerationDetailedGetSchema
     */
    'custom_schedule_attributes'?: CustomScheduleAttributesCreate | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FeatureGenerationDetailedGetSchema
     */
    'dependency_schedules_attributes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FeatureGenerationDetailedGetSchema
     */
    'schedule_failure_email_list'?: Array<string> | null;
    /**
     * 
     * @type {Array<AdditionalSparkPropertyCreate>}
     * @memberof FeatureGenerationDetailedGetSchema
     */
    'additional_spark_properties'?: Array<AdditionalSparkPropertyCreate> | null;
}


/**
 * 
 * @export
 * @interface FeatureGenerationScheduleCreate
 */
export interface FeatureGenerationScheduleCreate {
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationScheduleCreate
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationScheduleCreate
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationScheduleCreate
     */
    'cluster_id': string;
    /**
     * 
     * @type {ScheduleType}
     * @memberof FeatureGenerationScheduleCreate
     */
    'schedule_type': ScheduleType;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureGenerationScheduleCreate
     */
    'contains_pii'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureGenerationScheduleCreate
     */
    'contains_token'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureGenerationScheduleCreate
     */
    'contains_usagedata'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureGenerationScheduleCreate
     */
    'contains_web_token'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationScheduleCreate
     */
    'creator': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationScheduleCreate
     */
    'domain_id': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationScheduleCreate
     */
    'owner_id': string;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureGenerationScheduleCreate
     */
    'merge_files_csv'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationScheduleCreate
     */
    'model_dependencies': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationScheduleCreate
     */
    'post_process_workflow': string;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureGenerationScheduleCreate
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {CreationMode}
     * @memberof FeatureGenerationScheduleCreate
     */
    'creation_mode': CreationMode;
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationScheduleCreate
     */
    'branch_id': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationScheduleCreate
     */
    'feature_set_id': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FeatureGenerationScheduleCreate
     */
    'labels': Array<string>;
    /**
     * 
     * @type {RetryCreate}
     * @memberof FeatureGenerationScheduleCreate
     */
    'retry'?: RetryCreate | null;
    /**
     * 
     * @type {DailyScheduleAttributesCreate}
     * @memberof FeatureGenerationScheduleCreate
     */
    'daily_schedule_attributes'?: DailyScheduleAttributesCreate | null;
    /**
     * 
     * @type {CustomScheduleAttributesCreate}
     * @memberof FeatureGenerationScheduleCreate
     */
    'custom_schedule_attributes'?: CustomScheduleAttributesCreate | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FeatureGenerationScheduleCreate
     */
    'dependency_schedules_attributes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FeatureGenerationScheduleCreate
     */
    'schedule_failure_email_list'?: Array<string> | null;
    /**
     * 
     * @type {Array<AdditionalSparkPropertyCreate>}
     * @memberof FeatureGenerationScheduleCreate
     */
    'additional_spark_properties'?: Array<AdditionalSparkPropertyCreate> | null;
}


/**
 * 
 * @export
 * @interface FeatureGenerationScheduleGetSchema
 */
export interface FeatureGenerationScheduleGetSchema {
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationScheduleGetSchema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationScheduleGetSchema
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationScheduleGetSchema
     */
    'cluster_id': string;
    /**
     * 
     * @type {ScheduleType}
     * @memberof FeatureGenerationScheduleGetSchema
     */
    'schedule_type': ScheduleType;
    /**
     * 
     * @type {any}
     * @memberof FeatureGenerationScheduleGetSchema
     */
    'created_at': any;
    /**
     * 
     * @type {any}
     * @memberof FeatureGenerationScheduleGetSchema
     */
    'updated_at': any;
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationScheduleGetSchema
     */
    'created_by'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationScheduleGetSchema
     */
    'updated_by'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationScheduleGetSchema
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureGenerationScheduleGetSchema
     */
    'contains_pii'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureGenerationScheduleGetSchema
     */
    'contains_token'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureGenerationScheduleGetSchema
     */
    'contains_usagedata'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureGenerationScheduleGetSchema
     */
    'contains_web_token'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationScheduleGetSchema
     */
    'creator': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationScheduleGetSchema
     */
    'domain_id': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationScheduleGetSchema
     */
    'owner_id': string;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureGenerationScheduleGetSchema
     */
    'merge_files_csv'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationScheduleGetSchema
     */
    'model_dependencies': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationScheduleGetSchema
     */
    'post_process_workflow': string;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureGenerationScheduleGetSchema
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {CreationMode}
     * @memberof FeatureGenerationScheduleGetSchema
     */
    'creation_mode': CreationMode;
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationScheduleGetSchema
     */
    'branch': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureGenerationScheduleGetSchema
     */
    'feature_set': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FeatureGenerationScheduleGetSchema
     */
    'labels': Array<string>;
}


/**
 * 
 * @export
 * @interface FeatureSet
 */
export interface FeatureSet {
    /**
     * 
     * @type {string}
     * @memberof FeatureSet
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureSet
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeatureSet
     */
    'entity_id': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureSet
     */
    'id': string;
    /**
     * 
     * @type {Array<FeatureBaseSchema>}
     * @memberof FeatureSet
     */
    'features': Array<FeatureBaseSchema>;
    /**
     * 
     * @type {BaseEntityBase}
     * @memberof FeatureSet
     */
    'entity': BaseEntityBase;
    /**
     * 
     * @type {Array<AttributeDataInsertSchema>}
     * @memberof FeatureSet
     */
    'attribute_data': Array<AttributeDataInsertSchema>;
}
/**
 * 
 * @export
 * @interface FeatureSetCreate
 */
export interface FeatureSetCreate {
    /**
     * 
     * @type {string}
     * @memberof FeatureSetCreate
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureSetCreate
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeatureSetCreate
     */
    'entity_id': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FeatureSetCreate
     */
    'features': Array<string>;
    /**
     * 
     * @type {Array<AttributeDataInsertSchema>}
     * @memberof FeatureSetCreate
     */
    'attributes_data': Array<AttributeDataInsertSchema>;
}
/**
 * 
 * @export
 * @interface FeatureSetInDBBase
 */
export interface FeatureSetInDBBase {
    /**
     * 
     * @type {string}
     * @memberof FeatureSetInDBBase
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureSetInDBBase
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureSetInDBBase
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeatureSetInDBBase
     */
    'entity_id': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FilterType = {
    Entity: 'Entity',
    Feature: 'Feature',
    FeatureSet: 'Feature Set',
    EntityMapping: 'Entity Mapping',
    Table: 'Table',
    Schedule: 'Schedule'
} as const;

export type FilterType = typeof FilterType[keyof typeof FilterType];


/**
 * 
 * @export
 * @interface FromValue
 */
export interface FromValue {
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const JobType = {
    FeatureGenerationSchedule: 'FEATURE_GENERATION_SCHEDULE',
    MaterialisationSchedule: 'MATERIALISATION_SCHEDULE'
} as const;

export type JobType = typeof JobType[keyof typeof JobType];


/**
 * 
 * @export
 * @interface PointInTime
 */
export interface PointInTime {
    /**
     * 
     * @type {string}
     * @memberof PointInTime
     */
    'primary_entity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PointInTime
     */
    'validity_column'?: string | null;
}
/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'id': string;
    /**
     * 
     * @type {Array<BranchInDBBase>}
     * @memberof Project
     */
    'branches'?: Array<BranchInDBBase>;
}
/**
 * 
 * @export
 * @interface ProjectCreate
 */
export interface ProjectCreate {
    /**
     * 
     * @type {string}
     * @memberof ProjectCreate
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreate
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface ProjectInDBBase
 */
export interface ProjectInDBBase {
    /**
     * 
     * @type {string}
     * @memberof ProjectInDBBase
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectInDBBase
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectInDBBase
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface RetryCreate
 */
export interface RetryCreate {
    /**
     * 
     * @type {number}
     * @memberof RetryCreate
     */
    'waiting_time': number;
    /**
     * 
     * @type {number}
     * @memberof RetryCreate
     */
    'max_attempts': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ScheduleType = {
    Manual: 'MANUAL',
    DailySchedule: 'DAILY_SCHEDULE',
    CustomSchedule: 'CUSTOM_SCHEDULE',
    DependencySchedule: 'DEPENDENCY_SCHEDULE'
} as const;

export type ScheduleType = typeof ScheduleType[keyof typeof ScheduleType];


/**
 * 
 * @export
 * @interface SlowlyChangingDimensionType2
 */
export interface SlowlyChangingDimensionType2 {
    /**
     * 
     * @type {string}
     * @memberof SlowlyChangingDimensionType2
     */
    'primary_entity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SlowlyChangingDimensionType2
     */
    'from_column'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SlowlyChangingDimensionType2
     */
    'valid_to_column'?: string | null;
}
/**
 * 
 * @export
 * @interface Table
 */
export interface Table {
    /**
     * 
     * @type {string}
     * @memberof Table
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Table
     */
    'description'?: string;
    /**
     * 
     * @type {TableType}
     * @memberof Table
     */
    'table_type'?: TableType;
    /**
     * 
     * @type {string}
     * @memberof Table
     */
    'source': string;
    /**
     * 
     * @type {string}
     * @memberof Table
     */
    'source_table': string;
    /**
     * 
     * @type {Array<TableEntityMapping>}
     * @memberof Table
     */
    'table_entity_mapping': Array<TableEntityMapping>;
    /**
     * 
     * @type {TableTimeSemantics}
     * @memberof Table
     */
    'table_time_semantics': TableTimeSemantics | null;
    /**
     * 
     * @type {Array<AttributeDataInsertSchema>}
     * @memberof Table
     */
    'attributes_data': Array<AttributeDataInsertSchema>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Table
     */
    'sources'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Table
     */
    'sql'?: string;
}


/**
 * 
 * @export
 * @interface TableEntityAssociationSchema
 */
export interface TableEntityAssociationSchema {
    /**
     * 
     * @type {string}
     * @memberof TableEntityAssociationSchema
     */
    'entity_id': string;
    /**
     * 
     * @type {string}
     * @memberof TableEntityAssociationSchema
     */
    'entity_key_column': string;
    /**
     * 
     * @type {BaseEntityBase}
     * @memberof TableEntityAssociationSchema
     */
    'entity': BaseEntityBase;
}
/**
 * 
 * @export
 * @interface TableEntityMapping
 */
export interface TableEntityMapping {
    /**
     * 
     * @type {string}
     * @memberof TableEntityMapping
     */
    'entity_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TableEntityMapping
     */
    'entity_key_column'?: string | null;
}
/**
 * 
 * @export
 * @interface TableGetSchema
 */
export interface TableGetSchema {
    /**
     * 
     * @type {string}
     * @memberof TableGetSchema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TableGetSchema
     */
    'description'?: string | null;
    /**
     * 
     * @type {TableType}
     * @memberof TableGetSchema
     */
    'table_type'?: TableType | null;
    /**
     * 
     * @type {string}
     * @memberof TableGetSchema
     */
    'id'?: string;
    /**
     * 
     * @type {Array<BaseTable>}
     * @memberof TableGetSchema
     */
    'table_sources': Array<BaseTable>;
    /**
     * 
     * @type {Array<TableEntityAssociationSchema>}
     * @memberof TableGetSchema
     */
    'table_entity_mapping': Array<TableEntityAssociationSchema>;
    /**
     * 
     * @type {Array<FeatureBaseSchema>}
     * @memberof TableGetSchema
     */
    'table_feature_mapping': Array<FeatureBaseSchema>;
    /**
     * 
     * @type {Array<AttributeDataInsertSchema>}
     * @memberof TableGetSchema
     */
    'attribute_data': Array<AttributeDataInsertSchema>;
}


/**
 * 
 * @export
 * @interface TableTimeSemantics
 */
export interface TableTimeSemantics {
    /**
     * 
     * @type {string}
     * @memberof TableTimeSemantics
     */
    'timestamp_column'?: string;
    /**
     * 
     * @type {string}
     * @memberof TableTimeSemantics
     */
    'primary_entity'?: string;
    /**
     * 
     * @type {string}
     * @memberof TableTimeSemantics
     */
    'from_column'?: string;
    /**
     * 
     * @type {string}
     * @memberof TableTimeSemantics
     */
    'valid_to_column'?: string;
    /**
     * 
     * @type {string}
     * @memberof TableTimeSemantics
     */
    'validity_column'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TableType = {
    ExternalTable: 'EXTERNAL_TABLE',
    ViewTable: 'VIEW_TABLE',
    JoinTable: 'JOIN_TABLE',
    PivotTable: 'PIVOT_TABLE',
    EventStoreTable: 'EVENT_STORE_TABLE'
} as const;

export type TableType = typeof TableType[keyof typeof TableType];


/**
 * 
 * @export
 * @interface Team
 */
export interface Team {
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface ToValue
 */
export interface ToValue {
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TransformationType = {
    EventFeature: 'EVENT_FEATURE',
    CompositeFeature: 'COMPOSITE_FEATURE',
    SqlFeature: 'SQL_FEATURE'
} as const;

export type TransformationType = typeof TransformationType[keyof typeof TransformationType];


/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}
/**
 * 
 * @export
 * @interface ViewTableCreateSchema
 */
export interface ViewTableCreateSchema {
    /**
     * 
     * @type {string}
     * @memberof ViewTableCreateSchema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ViewTableCreateSchema
     */
    'description'?: string | null;
    /**
     * 
     * @type {TableType}
     * @memberof ViewTableCreateSchema
     */
    'table_type'?: TableType | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ViewTableCreateSchema
     */
    'sources'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ViewTableCreateSchema
     */
    'sql'?: string | null;
    /**
     * 
     * @type {Array<TableEntityMapping>}
     * @memberof ViewTableCreateSchema
     */
    'table_entity_mapping'?: Array<TableEntityMapping> | null;
    /**
     * 
     * @type {TableTimeSemantics}
     * @memberof ViewTableCreateSchema
     */
    'table_time_semantics': TableTimeSemantics | null;
    /**
     * 
     * @type {Array<AttributeDataInsertSchema>}
     * @memberof ViewTableCreateSchema
     */
    'attributes_data': Array<AttributeDataInsertSchema>;
}



/**
 * BranchApi - axios parameter creator
 * @export
 */
export const BranchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Branch
         * @param {BranchCreate} branchCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBranchBranchPost: async (branchCreate: BranchCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchCreate' is not null or undefined
            assertParamExists('createBranchBranchPost', 'branchCreate', branchCreate)
            const localVarPath = `/branch/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(branchCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Branch By Project Id
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBranchByProjectIdBranchProjectIdGet: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getAllBranchByProjectIdBranchProjectIdGet', 'projectId', projectId)
            const localVarPath = `/branch/{project_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BranchApi - functional programming interface
 * @export
 */
export const BranchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BranchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Branch
         * @param {BranchCreate} branchCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBranchBranchPost(branchCreate: BranchCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBranchBranchPost(branchCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BranchApi.createBranchBranchPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get All Branch By Project Id
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllBranchByProjectIdBranchProjectIdGet(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Branch>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBranchByProjectIdBranchProjectIdGet(projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BranchApi.getAllBranchByProjectIdBranchProjectIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BranchApi - factory interface
 * @export
 */
export const BranchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BranchApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Branch
         * @param {BranchCreate} branchCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBranchBranchPost(branchCreate: BranchCreate, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.createBranchBranchPost(branchCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Branch By Project Id
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBranchByProjectIdBranchProjectIdGet(projectId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<Branch>> {
            return localVarFp.getAllBranchByProjectIdBranchProjectIdGet(projectId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BranchApi - object-oriented interface
 * @export
 * @class BranchApi
 * @extends {BaseAPI}
 */
export class BranchApi extends BaseAPI {
    /**
     * 
     * @summary Create Branch
     * @param {BranchCreate} branchCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchApi
     */
    public createBranchBranchPost(branchCreate: BranchCreate, options?: RawAxiosRequestConfig) {
        return BranchApiFp(this.configuration).createBranchBranchPost(branchCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Branch By Project Id
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchApi
     */
    public getAllBranchByProjectIdBranchProjectIdGet(projectId: string, options?: RawAxiosRequestConfig) {
        return BranchApiFp(this.configuration).getAllBranchByProjectIdBranchProjectIdGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ChangeRequestApi - axios parameter creator
 * @export
 */
export const ChangeRequestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Reviewer
         * @param {string} changeRequestId 
         * @param {AddReviewer} addReviewer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addReviewerChangeRequestAddReviewerChangeRequestIdPost: async (changeRequestId: string, addReviewer: AddReviewer, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeRequestId' is not null or undefined
            assertParamExists('addReviewerChangeRequestAddReviewerChangeRequestIdPost', 'changeRequestId', changeRequestId)
            // verify required parameter 'addReviewer' is not null or undefined
            assertParamExists('addReviewerChangeRequestAddReviewerChangeRequestIdPost', 'addReviewer', addReviewer)
            const localVarPath = `/change-request/add-reviewer/{change_request_id}`
                .replace(`{${"change_request_id"}}`, encodeURIComponent(String(changeRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addReviewer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Change Request
         * @param {ChangeRequestCreate} changeRequestCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChangeRequestChangeRequestPost: async (changeRequestCreate: ChangeRequestCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeRequestCreate' is not null or undefined
            assertParamExists('createChangeRequestChangeRequestPost', 'changeRequestCreate', changeRequestCreate)
            const localVarPath = `/change-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeRequestCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Change Request By Id
         * @param {string} changeRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChangeRequestByIdChangeRequestChangeRequestIdGet: async (changeRequestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeRequestId' is not null or undefined
            assertParamExists('getChangeRequestByIdChangeRequestChangeRequestIdGet', 'changeRequestId', changeRequestId)
            const localVarPath = `/change-request/{change_request_id}`
                .replace(`{${"change_request_id"}}`, encodeURIComponent(String(changeRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Change Requests
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChangeRequestsChangeRequestProjectProjectIdGet: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getChangeRequestsChangeRequestProjectProjectIdGet', 'projectId', projectId)
            const localVarPath = `/change-request/project/{project_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Merge Branch
         * @param {string} changeRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeBranchChangeRequestMergeChangeRequestIdPost: async (changeRequestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeRequestId' is not null or undefined
            assertParamExists('mergeBranchChangeRequestMergeChangeRequestIdPost', 'changeRequestId', changeRequestId)
            const localVarPath = `/change-request/merge/{change_request_id}`
                .replace(`{${"change_request_id"}}`, encodeURIComponent(String(changeRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChangeRequestApi - functional programming interface
 * @export
 */
export const ChangeRequestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChangeRequestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add Reviewer
         * @param {string} changeRequestId 
         * @param {AddReviewer} addReviewer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addReviewerChangeRequestAddReviewerChangeRequestIdPost(changeRequestId: string, addReviewer: AddReviewer, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addReviewerChangeRequestAddReviewerChangeRequestIdPost(changeRequestId, addReviewer, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChangeRequestApi.addReviewerChangeRequestAddReviewerChangeRequestIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Change Request
         * @param {ChangeRequestCreate} changeRequestCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createChangeRequestChangeRequestPost(changeRequestCreate: ChangeRequestCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createChangeRequestChangeRequestPost(changeRequestCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChangeRequestApi.createChangeRequestChangeRequestPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Change Request By Id
         * @param {string} changeRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChangeRequestByIdChangeRequestChangeRequestIdGet(changeRequestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangeRequestGetSchemaWithCommits>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChangeRequestByIdChangeRequestChangeRequestIdGet(changeRequestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChangeRequestApi.getChangeRequestByIdChangeRequestChangeRequestIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Change Requests
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChangeRequestsChangeRequestProjectProjectIdGet(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChangeRequestGetSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChangeRequestsChangeRequestProjectProjectIdGet(projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChangeRequestApi.getChangeRequestsChangeRequestProjectProjectIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Merge Branch
         * @param {string} changeRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mergeBranchChangeRequestMergeChangeRequestIdPost(changeRequestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mergeBranchChangeRequestMergeChangeRequestIdPost(changeRequestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChangeRequestApi.mergeBranchChangeRequestMergeChangeRequestIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ChangeRequestApi - factory interface
 * @export
 */
export const ChangeRequestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChangeRequestApiFp(configuration)
    return {
        /**
         * 
         * @summary Add Reviewer
         * @param {string} changeRequestId 
         * @param {AddReviewer} addReviewer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addReviewerChangeRequestAddReviewerChangeRequestIdPost(changeRequestId: string, addReviewer: AddReviewer, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.addReviewerChangeRequestAddReviewerChangeRequestIdPost(changeRequestId, addReviewer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Change Request
         * @param {ChangeRequestCreate} changeRequestCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChangeRequestChangeRequestPost(changeRequestCreate: ChangeRequestCreate, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.createChangeRequestChangeRequestPost(changeRequestCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Change Request By Id
         * @param {string} changeRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChangeRequestByIdChangeRequestChangeRequestIdGet(changeRequestId: string, options?: RawAxiosRequestConfig): AxiosPromise<ChangeRequestGetSchemaWithCommits> {
            return localVarFp.getChangeRequestByIdChangeRequestChangeRequestIdGet(changeRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Change Requests
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChangeRequestsChangeRequestProjectProjectIdGet(projectId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ChangeRequestGetSchema>> {
            return localVarFp.getChangeRequestsChangeRequestProjectProjectIdGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Merge Branch
         * @param {string} changeRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeBranchChangeRequestMergeChangeRequestIdPost(changeRequestId: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.mergeBranchChangeRequestMergeChangeRequestIdPost(changeRequestId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChangeRequestApi - object-oriented interface
 * @export
 * @class ChangeRequestApi
 * @extends {BaseAPI}
 */
export class ChangeRequestApi extends BaseAPI {
    /**
     * 
     * @summary Add Reviewer
     * @param {string} changeRequestId 
     * @param {AddReviewer} addReviewer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChangeRequestApi
     */
    public addReviewerChangeRequestAddReviewerChangeRequestIdPost(changeRequestId: string, addReviewer: AddReviewer, options?: RawAxiosRequestConfig) {
        return ChangeRequestApiFp(this.configuration).addReviewerChangeRequestAddReviewerChangeRequestIdPost(changeRequestId, addReviewer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Change Request
     * @param {ChangeRequestCreate} changeRequestCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChangeRequestApi
     */
    public createChangeRequestChangeRequestPost(changeRequestCreate: ChangeRequestCreate, options?: RawAxiosRequestConfig) {
        return ChangeRequestApiFp(this.configuration).createChangeRequestChangeRequestPost(changeRequestCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Change Request By Id
     * @param {string} changeRequestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChangeRequestApi
     */
    public getChangeRequestByIdChangeRequestChangeRequestIdGet(changeRequestId: string, options?: RawAxiosRequestConfig) {
        return ChangeRequestApiFp(this.configuration).getChangeRequestByIdChangeRequestChangeRequestIdGet(changeRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Change Requests
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChangeRequestApi
     */
    public getChangeRequestsChangeRequestProjectProjectIdGet(projectId: string, options?: RawAxiosRequestConfig) {
        return ChangeRequestApiFp(this.configuration).getChangeRequestsChangeRequestProjectProjectIdGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Merge Branch
     * @param {string} changeRequestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChangeRequestApi
     */
    public mergeBranchChangeRequestMergeChangeRequestIdPost(changeRequestId: string, options?: RawAxiosRequestConfig) {
        return ChangeRequestApiFp(this.configuration).mergeBranchChangeRequestMergeChangeRequestIdPost(changeRequestId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ClustersApi - axios parameter creator
 * @export
 */
export const ClustersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Domains
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDomainsClusterGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cluster`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClustersApi - functional programming interface
 * @export
 */
export const ClustersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClustersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Domains
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDomainsClusterGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Cluster>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDomainsClusterGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClustersApi.getDomainsClusterGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ClustersApi - factory interface
 * @export
 */
export const ClustersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClustersApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Domains
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDomainsClusterGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<Cluster>> {
            return localVarFp.getDomainsClusterGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClustersApi - object-oriented interface
 * @export
 * @class ClustersApi
 * @extends {BaseAPI}
 */
export class ClustersApi extends BaseAPI {
    /**
     * 
     * @summary Get Domains
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public getDomainsClusterGet(options?: RawAxiosRequestConfig) {
        return ClustersApiFp(this.configuration).getDomainsClusterGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CommitsApi - axios parameter creator
 * @export
 */
export const CommitsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Commit
         * @param {any} commitId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommitCommitsCommitIdGet: async (commitId: any, projectName: string, branchName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commitId' is not null or undefined
            assertParamExists('getCommitCommitsCommitIdGet', 'commitId', commitId)
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('getCommitCommitsCommitIdGet', 'projectName', projectName)
            // verify required parameter 'branchName' is not null or undefined
            assertParamExists('getCommitCommitsCommitIdGet', 'branchName', branchName)
            const localVarPath = `/commits/{commit_id}`
                .replace(`{${"commit_id"}}`, encodeURIComponent(String(commitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectName !== undefined) {
                localVarQueryParameter['project_name'] = projectName;
            }

            if (branchName !== undefined) {
                localVarQueryParameter['branch_name'] = branchName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to list commits with pagination.
         * @summary List Commits
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommitsCommitsCommitsGet: async (projectName: string, branchName: string, pageNumber?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('listCommitsCommitsCommitsGet', 'projectName', projectName)
            // verify required parameter 'branchName' is not null or undefined
            assertParamExists('listCommitsCommitsCommitsGet', 'branchName', branchName)
            const localVarPath = `/commits/commits/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNumber !== undefined) {
                localVarQueryParameter['page_number'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (projectName !== undefined) {
                localVarQueryParameter['project_name'] = projectName;
            }

            if (branchName !== undefined) {
                localVarQueryParameter['branch_name'] = branchName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Revert Commit
         * @param {string} commitId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertCommitCommitsCommitIdRevertPost: async (commitId: string, projectName: string, branchName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commitId' is not null or undefined
            assertParamExists('revertCommitCommitsCommitIdRevertPost', 'commitId', commitId)
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('revertCommitCommitsCommitIdRevertPost', 'projectName', projectName)
            // verify required parameter 'branchName' is not null or undefined
            assertParamExists('revertCommitCommitsCommitIdRevertPost', 'branchName', branchName)
            const localVarPath = `/commits/{commit_id}/revert`
                .replace(`{${"commit_id"}}`, encodeURIComponent(String(commitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectName !== undefined) {
                localVarQueryParameter['project_name'] = projectName;
            }

            if (branchName !== undefined) {
                localVarQueryParameter['branch_name'] = branchName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommitsApi - functional programming interface
 * @export
 */
export const CommitsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommitsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Commit
         * @param {any} commitId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommitCommitsCommitIdGet(commitId: any, projectName: string, branchName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommitGetSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommitCommitsCommitIdGet(commitId, projectName, branchName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommitsApi.getCommitCommitsCommitIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Endpoint to list commits with pagination.
         * @summary List Commits
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCommitsCommitsCommitsGet(projectName: string, branchName: string, pageNumber?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CommitGetSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCommitsCommitsCommitsGet(projectName, branchName, pageNumber, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommitsApi.listCommitsCommitsCommitsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Revert Commit
         * @param {string} commitId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revertCommitCommitsCommitIdRevertPost(commitId: string, projectName: string, branchName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revertCommitCommitsCommitIdRevertPost(commitId, projectName, branchName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommitsApi.revertCommitCommitsCommitIdRevertPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CommitsApi - factory interface
 * @export
 */
export const CommitsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommitsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Commit
         * @param {any} commitId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommitCommitsCommitIdGet(commitId: any, projectName: string, branchName: string, options?: RawAxiosRequestConfig): AxiosPromise<CommitGetSchema> {
            return localVarFp.getCommitCommitsCommitIdGet(commitId, projectName, branchName, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to list commits with pagination.
         * @summary List Commits
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCommitsCommitsCommitsGet(projectName: string, branchName: string, pageNumber?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<CommitGetSchema>> {
            return localVarFp.listCommitsCommitsCommitsGet(projectName, branchName, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Revert Commit
         * @param {string} commitId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertCommitCommitsCommitIdRevertPost(commitId: string, projectName: string, branchName: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.revertCommitCommitsCommitIdRevertPost(commitId, projectName, branchName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommitsApi - object-oriented interface
 * @export
 * @class CommitsApi
 * @extends {BaseAPI}
 */
export class CommitsApi extends BaseAPI {
    /**
     * 
     * @summary Get Commit
     * @param {any} commitId 
     * @param {string} projectName 
     * @param {string} branchName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitsApi
     */
    public getCommitCommitsCommitIdGet(commitId: any, projectName: string, branchName: string, options?: RawAxiosRequestConfig) {
        return CommitsApiFp(this.configuration).getCommitCommitsCommitIdGet(commitId, projectName, branchName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to list commits with pagination.
     * @summary List Commits
     * @param {string} projectName 
     * @param {string} branchName 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitsApi
     */
    public listCommitsCommitsCommitsGet(projectName: string, branchName: string, pageNumber?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return CommitsApiFp(this.configuration).listCommitsCommitsCommitsGet(projectName, branchName, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Revert Commit
     * @param {string} commitId 
     * @param {string} projectName 
     * @param {string} branchName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommitsApi
     */
    public revertCommitCommitsCommitIdRevertPost(commitId: string, projectName: string, branchName: string, options?: RawAxiosRequestConfig) {
        return CommitsApiFp(this.configuration).revertCommitCommitsCommitIdRevertPost(commitId, projectName, branchName, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DomainApi - axios parameter creator
 * @export
 */
export const DomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Domains
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDomainsDomainGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domain`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DomainApi - functional programming interface
 * @export
 */
export const DomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DomainApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Domains
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDomainsDomainGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Doamin>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDomainsDomainGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DomainApi.getDomainsDomainGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DomainApi - factory interface
 * @export
 */
export const DomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DomainApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Domains
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDomainsDomainGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<Doamin>> {
            return localVarFp.getDomainsDomainGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DomainApi - object-oriented interface
 * @export
 * @class DomainApi
 * @extends {BaseAPI}
 */
export class DomainApi extends BaseAPI {
    /**
     * 
     * @summary Get Domains
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainApi
     */
    public getDomainsDomainGet(options?: RawAxiosRequestConfig) {
        return DomainApiFp(this.configuration).getDomainsDomainGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EntityApi - axios parameter creator
 * @export
 */
export const EntityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Entity
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {BaseEntityCreate} baseEntityCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEntityEntityPost: async (projectName: string, branchName: string, baseEntityCreate: BaseEntityCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('createEntityEntityPost', 'projectName', projectName)
            // verify required parameter 'branchName' is not null or undefined
            assertParamExists('createEntityEntityPost', 'branchName', branchName)
            // verify required parameter 'baseEntityCreate' is not null or undefined
            assertParamExists('createEntityEntityPost', 'baseEntityCreate', baseEntityCreate)
            const localVarPath = `/entity/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectName !== undefined) {
                localVarQueryParameter['project_name'] = projectName;
            }

            if (branchName !== undefined) {
                localVarQueryParameter['branch_name'] = branchName;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseEntityCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Entity
         * @param {string} entityId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEntityEntityEntityIdDelete: async (entityId: string, projectName: string, branchName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('deleteEntityEntityEntityIdDelete', 'entityId', entityId)
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('deleteEntityEntityEntityIdDelete', 'projectName', projectName)
            // verify required parameter 'branchName' is not null or undefined
            assertParamExists('deleteEntityEntityEntityIdDelete', 'branchName', branchName)
            const localVarPath = `/entity/{entity_id}`
                .replace(`{${"entity_id"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectName !== undefined) {
                localVarQueryParameter['project_name'] = projectName;
            }

            if (branchName !== undefined) {
                localVarQueryParameter['branch_name'] = branchName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Entities
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEntitiesEntityGet: async (projectName: string, branchName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('getAllEntitiesEntityGet', 'projectName', projectName)
            // verify required parameter 'branchName' is not null or undefined
            assertParamExists('getAllEntitiesEntityGet', 'branchName', branchName)
            const localVarPath = `/entity/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectName !== undefined) {
                localVarQueryParameter['project_name'] = projectName;
            }

            if (branchName !== undefined) {
                localVarQueryParameter['branch_name'] = branchName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Entity By Id
         * @param {string} entityId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityByIdEntityEntityIdGet: async (entityId: string, projectName: string, branchName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getEntityByIdEntityEntityIdGet', 'entityId', entityId)
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('getEntityByIdEntityEntityIdGet', 'projectName', projectName)
            // verify required parameter 'branchName' is not null or undefined
            assertParamExists('getEntityByIdEntityEntityIdGet', 'branchName', branchName)
            const localVarPath = `/entity/{entity_id}`
                .replace(`{${"entity_id"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectName !== undefined) {
                localVarQueryParameter['project_name'] = projectName;
            }

            if (branchName !== undefined) {
                localVarQueryParameter['branch_name'] = branchName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Entity
         * @param {string} entityId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {string | null} [name] 
         * @param {string | null} [author] 
         * @param {number | null} [releaseYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEntityEntityEntityIdPut: async (entityId: string, projectName: string, branchName: string, name?: string | null, author?: string | null, releaseYear?: number | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('updateEntityEntityEntityIdPut', 'entityId', entityId)
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('updateEntityEntityEntityIdPut', 'projectName', projectName)
            // verify required parameter 'branchName' is not null or undefined
            assertParamExists('updateEntityEntityEntityIdPut', 'branchName', branchName)
            const localVarPath = `/entity/{entity_id}`
                .replace(`{${"entity_id"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (author !== undefined) {
                localVarQueryParameter['author'] = author;
            }

            if (releaseYear !== undefined) {
                localVarQueryParameter['release_year'] = releaseYear;
            }

            if (projectName !== undefined) {
                localVarQueryParameter['project_name'] = projectName;
            }

            if (branchName !== undefined) {
                localVarQueryParameter['branch_name'] = branchName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EntityApi - functional programming interface
 * @export
 */
export const EntityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EntityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Entity
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {BaseEntityCreate} baseEntityCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEntityEntityPost(projectName: string, branchName: string, baseEntityCreate: BaseEntityCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEntityEntityPost(projectName, branchName, baseEntityCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EntityApi.createEntityEntityPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Entity
         * @param {string} entityId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEntityEntityEntityIdDelete(entityId: string, projectName: string, branchName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEntityEntityEntityIdDelete(entityId, projectName, branchName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EntityApi.deleteEntityEntityEntityIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get All Entities
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllEntitiesEntityGet(projectName: string, branchName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BaseEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllEntitiesEntityGet(projectName, branchName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EntityApi.getAllEntitiesEntityGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Entity By Id
         * @param {string} entityId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityByIdEntityEntityIdGet(entityId: string, projectName: string, branchName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityByIdEntityEntityIdGet(entityId, projectName, branchName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EntityApi.getEntityByIdEntityEntityIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Entity
         * @param {string} entityId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {string | null} [name] 
         * @param {string | null} [author] 
         * @param {number | null} [releaseYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEntityEntityEntityIdPut(entityId: string, projectName: string, branchName: string, name?: string | null, author?: string | null, releaseYear?: number | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEntityEntityEntityIdPut(entityId, projectName, branchName, name, author, releaseYear, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EntityApi.updateEntityEntityEntityIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EntityApi - factory interface
 * @export
 */
export const EntityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EntityApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Entity
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {BaseEntityCreate} baseEntityCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEntityEntityPost(projectName: string, branchName: string, baseEntityCreate: BaseEntityCreate, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.createEntityEntityPost(projectName, branchName, baseEntityCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Entity
         * @param {string} entityId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEntityEntityEntityIdDelete(entityId: string, projectName: string, branchName: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.deleteEntityEntityEntityIdDelete(entityId, projectName, branchName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Entities
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEntitiesEntityGet(projectName: string, branchName: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BaseEntity>> {
            return localVarFp.getAllEntitiesEntityGet(projectName, branchName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Entity By Id
         * @param {string} entityId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityByIdEntityEntityIdGet(entityId: string, projectName: string, branchName: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getEntityByIdEntityEntityIdGet(entityId, projectName, branchName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Entity
         * @param {string} entityId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {string | null} [name] 
         * @param {string | null} [author] 
         * @param {number | null} [releaseYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEntityEntityEntityIdPut(entityId: string, projectName: string, branchName: string, name?: string | null, author?: string | null, releaseYear?: number | null, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.updateEntityEntityEntityIdPut(entityId, projectName, branchName, name, author, releaseYear, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EntityApi - object-oriented interface
 * @export
 * @class EntityApi
 * @extends {BaseAPI}
 */
export class EntityApi extends BaseAPI {
    /**
     * 
     * @summary Create Entity
     * @param {string} projectName 
     * @param {string} branchName 
     * @param {BaseEntityCreate} baseEntityCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityApi
     */
    public createEntityEntityPost(projectName: string, branchName: string, baseEntityCreate: BaseEntityCreate, options?: RawAxiosRequestConfig) {
        return EntityApiFp(this.configuration).createEntityEntityPost(projectName, branchName, baseEntityCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Entity
     * @param {string} entityId 
     * @param {string} projectName 
     * @param {string} branchName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityApi
     */
    public deleteEntityEntityEntityIdDelete(entityId: string, projectName: string, branchName: string, options?: RawAxiosRequestConfig) {
        return EntityApiFp(this.configuration).deleteEntityEntityEntityIdDelete(entityId, projectName, branchName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Entities
     * @param {string} projectName 
     * @param {string} branchName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityApi
     */
    public getAllEntitiesEntityGet(projectName: string, branchName: string, options?: RawAxiosRequestConfig) {
        return EntityApiFp(this.configuration).getAllEntitiesEntityGet(projectName, branchName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Entity By Id
     * @param {string} entityId 
     * @param {string} projectName 
     * @param {string} branchName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityApi
     */
    public getEntityByIdEntityEntityIdGet(entityId: string, projectName: string, branchName: string, options?: RawAxiosRequestConfig) {
        return EntityApiFp(this.configuration).getEntityByIdEntityEntityIdGet(entityId, projectName, branchName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Entity
     * @param {string} entityId 
     * @param {string} projectName 
     * @param {string} branchName 
     * @param {string | null} [name] 
     * @param {string | null} [author] 
     * @param {number | null} [releaseYear] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityApi
     */
    public updateEntityEntityEntityIdPut(entityId: string, projectName: string, branchName: string, name?: string | null, author?: string | null, releaseYear?: number | null, options?: RawAxiosRequestConfig) {
        return EntityApiFp(this.configuration).updateEntityEntityEntityIdPut(entityId, projectName, branchName, name, author, releaseYear, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EntityMappingApi - axios parameter creator
 * @export
 */
export const EntityMappingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Entity Mapping
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {EntityMappingCreateSchema} entityMappingCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEntityMappingEntityMappingPost: async (projectName: string, branchName: string, entityMappingCreateSchema: EntityMappingCreateSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('createEntityMappingEntityMappingPost', 'projectName', projectName)
            // verify required parameter 'branchName' is not null or undefined
            assertParamExists('createEntityMappingEntityMappingPost', 'branchName', branchName)
            // verify required parameter 'entityMappingCreateSchema' is not null or undefined
            assertParamExists('createEntityMappingEntityMappingPost', 'entityMappingCreateSchema', entityMappingCreateSchema)
            const localVarPath = `/entity-mapping/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectName !== undefined) {
                localVarQueryParameter['project_name'] = projectName;
            }

            if (branchName !== undefined) {
                localVarQueryParameter['branch_name'] = branchName;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityMappingCreateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Entity Mapping
         * @param {string} entityMappingId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEntityMappingEntityMappingEntityMappingIdDelete: async (entityMappingId: string, projectName: string, branchName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityMappingId' is not null or undefined
            assertParamExists('deleteEntityMappingEntityMappingEntityMappingIdDelete', 'entityMappingId', entityMappingId)
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('deleteEntityMappingEntityMappingEntityMappingIdDelete', 'projectName', projectName)
            // verify required parameter 'branchName' is not null or undefined
            assertParamExists('deleteEntityMappingEntityMappingEntityMappingIdDelete', 'branchName', branchName)
            const localVarPath = `/entity-mapping/{entity_mapping_id}`
                .replace(`{${"entity_mapping_id"}}`, encodeURIComponent(String(entityMappingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectName !== undefined) {
                localVarQueryParameter['project_name'] = projectName;
            }

            if (branchName !== undefined) {
                localVarQueryParameter['branch_name'] = branchName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Entity Mapping By Id
         * @param {string} entityMappingId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityMappingByIdEntityMappingEntityMappingIdGet: async (entityMappingId: string, projectName: string, branchName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityMappingId' is not null or undefined
            assertParamExists('getEntityMappingByIdEntityMappingEntityMappingIdGet', 'entityMappingId', entityMappingId)
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('getEntityMappingByIdEntityMappingEntityMappingIdGet', 'projectName', projectName)
            // verify required parameter 'branchName' is not null or undefined
            assertParamExists('getEntityMappingByIdEntityMappingEntityMappingIdGet', 'branchName', branchName)
            const localVarPath = `/entity-mapping/{entity_mapping_id}`
                .replace(`{${"entity_mapping_id"}}`, encodeURIComponent(String(entityMappingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectName !== undefined) {
                localVarQueryParameter['project_name'] = projectName;
            }

            if (branchName !== undefined) {
                localVarQueryParameter['branch_name'] = branchName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Entity Mappings By Branch Id
         * @param {string} branchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityMappingsByBranchIdEntityMappingGetEntityMappingsBranchIdGet: async (branchId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('getEntityMappingsByBranchIdEntityMappingGetEntityMappingsBranchIdGet', 'branchId', branchId)
            const localVarPath = `/entity-mapping/get-entity-mappings/{branch_id}`
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EntityMappingApi - functional programming interface
 * @export
 */
export const EntityMappingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EntityMappingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Entity Mapping
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {EntityMappingCreateSchema} entityMappingCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEntityMappingEntityMappingPost(projectName: string, branchName: string, entityMappingCreateSchema: EntityMappingCreateSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEntityMappingEntityMappingPost(projectName, branchName, entityMappingCreateSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EntityMappingApi.createEntityMappingEntityMappingPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Entity Mapping
         * @param {string} entityMappingId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEntityMappingEntityMappingEntityMappingIdDelete(entityMappingId: string, projectName: string, branchName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteEntityMappingSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEntityMappingEntityMappingEntityMappingIdDelete(entityMappingId, projectName, branchName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EntityMappingApi.deleteEntityMappingEntityMappingEntityMappingIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Entity Mapping By Id
         * @param {string} entityMappingId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityMappingByIdEntityMappingEntityMappingIdGet(entityMappingId: string, projectName: string, branchName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityMappingGetSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityMappingByIdEntityMappingEntityMappingIdGet(entityMappingId, projectName, branchName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EntityMappingApi.getEntityMappingByIdEntityMappingEntityMappingIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Entity Mappings By Branch Id
         * @param {string} branchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityMappingsByBranchIdEntityMappingGetEntityMappingsBranchIdGet(branchId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntityMappingGetSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityMappingsByBranchIdEntityMappingGetEntityMappingsBranchIdGet(branchId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EntityMappingApi.getEntityMappingsByBranchIdEntityMappingGetEntityMappingsBranchIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EntityMappingApi - factory interface
 * @export
 */
export const EntityMappingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EntityMappingApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Entity Mapping
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {EntityMappingCreateSchema} entityMappingCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEntityMappingEntityMappingPost(projectName: string, branchName: string, entityMappingCreateSchema: EntityMappingCreateSchema, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.createEntityMappingEntityMappingPost(projectName, branchName, entityMappingCreateSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Entity Mapping
         * @param {string} entityMappingId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEntityMappingEntityMappingEntityMappingIdDelete(entityMappingId: string, projectName: string, branchName: string, options?: RawAxiosRequestConfig): AxiosPromise<DeleteEntityMappingSchema> {
            return localVarFp.deleteEntityMappingEntityMappingEntityMappingIdDelete(entityMappingId, projectName, branchName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Entity Mapping By Id
         * @param {string} entityMappingId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityMappingByIdEntityMappingEntityMappingIdGet(entityMappingId: string, projectName: string, branchName: string, options?: RawAxiosRequestConfig): AxiosPromise<EntityMappingGetSchema> {
            return localVarFp.getEntityMappingByIdEntityMappingEntityMappingIdGet(entityMappingId, projectName, branchName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Entity Mappings By Branch Id
         * @param {string} branchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityMappingsByBranchIdEntityMappingGetEntityMappingsBranchIdGet(branchId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<EntityMappingGetSchema>> {
            return localVarFp.getEntityMappingsByBranchIdEntityMappingGetEntityMappingsBranchIdGet(branchId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EntityMappingApi - object-oriented interface
 * @export
 * @class EntityMappingApi
 * @extends {BaseAPI}
 */
export class EntityMappingApi extends BaseAPI {
    /**
     * 
     * @summary Create Entity Mapping
     * @param {string} projectName 
     * @param {string} branchName 
     * @param {EntityMappingCreateSchema} entityMappingCreateSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityMappingApi
     */
    public createEntityMappingEntityMappingPost(projectName: string, branchName: string, entityMappingCreateSchema: EntityMappingCreateSchema, options?: RawAxiosRequestConfig) {
        return EntityMappingApiFp(this.configuration).createEntityMappingEntityMappingPost(projectName, branchName, entityMappingCreateSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Entity Mapping
     * @param {string} entityMappingId 
     * @param {string} projectName 
     * @param {string} branchName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityMappingApi
     */
    public deleteEntityMappingEntityMappingEntityMappingIdDelete(entityMappingId: string, projectName: string, branchName: string, options?: RawAxiosRequestConfig) {
        return EntityMappingApiFp(this.configuration).deleteEntityMappingEntityMappingEntityMappingIdDelete(entityMappingId, projectName, branchName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Entity Mapping By Id
     * @param {string} entityMappingId 
     * @param {string} projectName 
     * @param {string} branchName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityMappingApi
     */
    public getEntityMappingByIdEntityMappingEntityMappingIdGet(entityMappingId: string, projectName: string, branchName: string, options?: RawAxiosRequestConfig) {
        return EntityMappingApiFp(this.configuration).getEntityMappingByIdEntityMappingEntityMappingIdGet(entityMappingId, projectName, branchName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Entity Mappings By Branch Id
     * @param {string} branchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityMappingApi
     */
    public getEntityMappingsByBranchIdEntityMappingGetEntityMappingsBranchIdGet(branchId: string, options?: RawAxiosRequestConfig) {
        return EntityMappingApiFp(this.configuration).getEntityMappingsByBranchIdEntityMappingGetEntityMappingsBranchIdGet(branchId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EventStoresApi - axios parameter creator
 * @export
 */
export const EventStoresApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Event Stores
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventStoresEventStoreGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/event-store`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventStoresApi - functional programming interface
 * @export
 */
export const EventStoresApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventStoresApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Event Stores
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventStoresEventStoreGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventStore>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventStoresEventStoreGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventStoresApi.getEventStoresEventStoreGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EventStoresApi - factory interface
 * @export
 */
export const EventStoresApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventStoresApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Event Stores
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventStoresEventStoreGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<EventStore>> {
            return localVarFp.getEventStoresEventStoreGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventStoresApi - object-oriented interface
 * @export
 * @class EventStoresApi
 * @extends {BaseAPI}
 */
export class EventStoresApi extends BaseAPI {
    /**
     * 
     * @summary Get Event Stores
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventStoresApi
     */
    public getEventStoresEventStoreGet(options?: RawAxiosRequestConfig) {
        return EventStoresApiFp(this.configuration).getEventStoresEventStoreGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FeatureApi - axios parameter creator
 * @export
 */
export const FeatureApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Feature
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {BaseFeatureCreate} baseFeatureCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeatureFeaturePost: async (projectName: string, branchName: string, baseFeatureCreate: BaseFeatureCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('createFeatureFeaturePost', 'projectName', projectName)
            // verify required parameter 'branchName' is not null or undefined
            assertParamExists('createFeatureFeaturePost', 'branchName', branchName)
            // verify required parameter 'baseFeatureCreate' is not null or undefined
            assertParamExists('createFeatureFeaturePost', 'baseFeatureCreate', baseFeatureCreate)
            const localVarPath = `/feature/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectName !== undefined) {
                localVarQueryParameter['project_name'] = projectName;
            }

            if (branchName !== undefined) {
                localVarQueryParameter['branch_name'] = branchName;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseFeatureCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Feature
         * @param {string} featureId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeatureFeatureFeatureIdDelete: async (featureId: string, projectName: string, branchName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureId' is not null or undefined
            assertParamExists('deleteFeatureFeatureFeatureIdDelete', 'featureId', featureId)
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('deleteFeatureFeatureFeatureIdDelete', 'projectName', projectName)
            // verify required parameter 'branchName' is not null or undefined
            assertParamExists('deleteFeatureFeatureFeatureIdDelete', 'branchName', branchName)
            const localVarPath = `/feature/{feature_id}`
                .replace(`{${"feature_id"}}`, encodeURIComponent(String(featureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectName !== undefined) {
                localVarQueryParameter['project_name'] = projectName;
            }

            if (branchName !== undefined) {
                localVarQueryParameter['branch_name'] = branchName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Feature By Id
         * @param {string} featureId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureByIdFeatureFeatureIdGet: async (featureId: string, projectName: string, branchName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureId' is not null or undefined
            assertParamExists('getFeatureByIdFeatureFeatureIdGet', 'featureId', featureId)
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('getFeatureByIdFeatureFeatureIdGet', 'projectName', projectName)
            // verify required parameter 'branchName' is not null or undefined
            assertParamExists('getFeatureByIdFeatureFeatureIdGet', 'branchName', branchName)
            const localVarPath = `/feature/{feature_id}`
                .replace(`{${"feature_id"}}`, encodeURIComponent(String(featureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectName !== undefined) {
                localVarQueryParameter['project_name'] = projectName;
            }

            if (branchName !== undefined) {
                localVarQueryParameter['branch_name'] = branchName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Features By Branch Id
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturesByBranchIdFeatureGet: async (projectName: string, branchName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('getFeaturesByBranchIdFeatureGet', 'projectName', projectName)
            // verify required parameter 'branchName' is not null or undefined
            assertParamExists('getFeaturesByBranchIdFeatureGet', 'branchName', branchName)
            const localVarPath = `/feature/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectName !== undefined) {
                localVarQueryParameter['project_name'] = projectName;
            }

            if (branchName !== undefined) {
                localVarQueryParameter['branch_name'] = branchName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeatureApi - functional programming interface
 * @export
 */
export const FeatureApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeatureApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Feature
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {BaseFeatureCreate} baseFeatureCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFeatureFeaturePost(projectName: string, branchName: string, baseFeatureCreate: BaseFeatureCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFeatureFeaturePost(projectName, branchName, baseFeatureCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureApi.createFeatureFeaturePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Feature
         * @param {string} featureId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFeatureFeatureFeatureIdDelete(featureId: string, projectName: string, branchName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFeatureFeatureFeatureIdDelete(featureId, projectName, branchName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureApi.deleteFeatureFeatureFeatureIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Feature By Id
         * @param {string} featureId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureByIdFeatureFeatureIdGet(featureId: string, projectName: string, branchName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseFeature>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureByIdFeatureFeatureIdGet(featureId, projectName, branchName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureApi.getFeatureByIdFeatureFeatureIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Features By Branch Id
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeaturesByBranchIdFeatureGet(projectName: string, branchName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BaseFeature>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeaturesByBranchIdFeatureGet(projectName, branchName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureApi.getFeaturesByBranchIdFeatureGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FeatureApi - factory interface
 * @export
 */
export const FeatureApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeatureApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Feature
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {BaseFeatureCreate} baseFeatureCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeatureFeaturePost(projectName: string, branchName: string, baseFeatureCreate: BaseFeatureCreate, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.createFeatureFeaturePost(projectName, branchName, baseFeatureCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Feature
         * @param {string} featureId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeatureFeatureFeatureIdDelete(featureId: string, projectName: string, branchName: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.deleteFeatureFeatureFeatureIdDelete(featureId, projectName, branchName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Feature By Id
         * @param {string} featureId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureByIdFeatureFeatureIdGet(featureId: string, projectName: string, branchName: string, options?: RawAxiosRequestConfig): AxiosPromise<BaseFeature> {
            return localVarFp.getFeatureByIdFeatureFeatureIdGet(featureId, projectName, branchName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Features By Branch Id
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturesByBranchIdFeatureGet(projectName: string, branchName: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<BaseFeature>> {
            return localVarFp.getFeaturesByBranchIdFeatureGet(projectName, branchName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeatureApi - object-oriented interface
 * @export
 * @class FeatureApi
 * @extends {BaseAPI}
 */
export class FeatureApi extends BaseAPI {
    /**
     * 
     * @summary Create Feature
     * @param {string} projectName 
     * @param {string} branchName 
     * @param {BaseFeatureCreate} baseFeatureCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    public createFeatureFeaturePost(projectName: string, branchName: string, baseFeatureCreate: BaseFeatureCreate, options?: RawAxiosRequestConfig) {
        return FeatureApiFp(this.configuration).createFeatureFeaturePost(projectName, branchName, baseFeatureCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Feature
     * @param {string} featureId 
     * @param {string} projectName 
     * @param {string} branchName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    public deleteFeatureFeatureFeatureIdDelete(featureId: string, projectName: string, branchName: string, options?: RawAxiosRequestConfig) {
        return FeatureApiFp(this.configuration).deleteFeatureFeatureFeatureIdDelete(featureId, projectName, branchName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Feature By Id
     * @param {string} featureId 
     * @param {string} projectName 
     * @param {string} branchName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    public getFeatureByIdFeatureFeatureIdGet(featureId: string, projectName: string, branchName: string, options?: RawAxiosRequestConfig) {
        return FeatureApiFp(this.configuration).getFeatureByIdFeatureFeatureIdGet(featureId, projectName, branchName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Features By Branch Id
     * @param {string} projectName 
     * @param {string} branchName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    public getFeaturesByBranchIdFeatureGet(projectName: string, branchName: string, options?: RawAxiosRequestConfig) {
        return FeatureApiFp(this.configuration).getFeaturesByBranchIdFeatureGet(projectName, branchName, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FeatureSetApi - axios parameter creator
 * @export
 */
export const FeatureSetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Feature Set
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {FeatureSetCreate} featureSetCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeatureSetFeatureSetPost: async (projectName: string, branchName: string, featureSetCreate: FeatureSetCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('createFeatureSetFeatureSetPost', 'projectName', projectName)
            // verify required parameter 'branchName' is not null or undefined
            assertParamExists('createFeatureSetFeatureSetPost', 'branchName', branchName)
            // verify required parameter 'featureSetCreate' is not null or undefined
            assertParamExists('createFeatureSetFeatureSetPost', 'featureSetCreate', featureSetCreate)
            const localVarPath = `/feature-set/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectName !== undefined) {
                localVarQueryParameter['project_name'] = projectName;
            }

            if (branchName !== undefined) {
                localVarQueryParameter['branch_name'] = branchName;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(featureSetCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Feature Set
         * @param {string} featureSetId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeatureSetFeatureSetFeatureSetIdDelete: async (featureSetId: string, projectName: string, branchName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureSetId' is not null or undefined
            assertParamExists('deleteFeatureSetFeatureSetFeatureSetIdDelete', 'featureSetId', featureSetId)
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('deleteFeatureSetFeatureSetFeatureSetIdDelete', 'projectName', projectName)
            // verify required parameter 'branchName' is not null or undefined
            assertParamExists('deleteFeatureSetFeatureSetFeatureSetIdDelete', 'branchName', branchName)
            const localVarPath = `/feature-set/{feature_set_id}`
                .replace(`{${"feature_set_id"}}`, encodeURIComponent(String(featureSetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectName !== undefined) {
                localVarQueryParameter['project_name'] = projectName;
            }

            if (branchName !== undefined) {
                localVarQueryParameter['branch_name'] = branchName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Branch Features Sets
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranchFeaturesSetsFeatureSetGet: async (projectName: string, branchName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('getBranchFeaturesSetsFeatureSetGet', 'projectName', projectName)
            // verify required parameter 'branchName' is not null or undefined
            assertParamExists('getBranchFeaturesSetsFeatureSetGet', 'branchName', branchName)
            const localVarPath = `/feature-set/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectName !== undefined) {
                localVarQueryParameter['project_name'] = projectName;
            }

            if (branchName !== undefined) {
                localVarQueryParameter['branch_name'] = branchName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Feature Set By Id
         * @param {string} featureSetId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureSetByIdFeatureSetFeatureSetIdGet: async (featureSetId: string, projectName: string, branchName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureSetId' is not null or undefined
            assertParamExists('getFeatureSetByIdFeatureSetFeatureSetIdGet', 'featureSetId', featureSetId)
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('getFeatureSetByIdFeatureSetFeatureSetIdGet', 'projectName', projectName)
            // verify required parameter 'branchName' is not null or undefined
            assertParamExists('getFeatureSetByIdFeatureSetFeatureSetIdGet', 'branchName', branchName)
            const localVarPath = `/feature-set/{feature_set_id}`
                .replace(`{${"feature_set_id"}}`, encodeURIComponent(String(featureSetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectName !== undefined) {
                localVarQueryParameter['project_name'] = projectName;
            }

            if (branchName !== undefined) {
                localVarQueryParameter['branch_name'] = branchName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeatureSetApi - functional programming interface
 * @export
 */
export const FeatureSetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeatureSetApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Feature Set
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {FeatureSetCreate} featureSetCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFeatureSetFeatureSetPost(projectName: string, branchName: string, featureSetCreate: FeatureSetCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFeatureSetFeatureSetPost(projectName, branchName, featureSetCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureSetApi.createFeatureSetFeatureSetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Feature Set
         * @param {string} featureSetId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFeatureSetFeatureSetFeatureSetIdDelete(featureSetId: string, projectName: string, branchName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFeatureSetFeatureSetFeatureSetIdDelete(featureSetId, projectName, branchName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureSetApi.deleteFeatureSetFeatureSetFeatureSetIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Branch Features Sets
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBranchFeaturesSetsFeatureSetGet(projectName: string, branchName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeatureSet>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBranchFeaturesSetsFeatureSetGet(projectName, branchName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureSetApi.getBranchFeaturesSetsFeatureSetGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Feature Set By Id
         * @param {string} featureSetId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureSetByIdFeatureSetFeatureSetIdGet(featureSetId: string, projectName: string, branchName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureSetByIdFeatureSetFeatureSetIdGet(featureSetId, projectName, branchName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureSetApi.getFeatureSetByIdFeatureSetFeatureSetIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FeatureSetApi - factory interface
 * @export
 */
export const FeatureSetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeatureSetApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Feature Set
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {FeatureSetCreate} featureSetCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeatureSetFeatureSetPost(projectName: string, branchName: string, featureSetCreate: FeatureSetCreate, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.createFeatureSetFeatureSetPost(projectName, branchName, featureSetCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Feature Set
         * @param {string} featureSetId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeatureSetFeatureSetFeatureSetIdDelete(featureSetId: string, projectName: string, branchName: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.deleteFeatureSetFeatureSetFeatureSetIdDelete(featureSetId, projectName, branchName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Branch Features Sets
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranchFeaturesSetsFeatureSetGet(projectName: string, branchName: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<FeatureSet>> {
            return localVarFp.getBranchFeaturesSetsFeatureSetGet(projectName, branchName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Feature Set By Id
         * @param {string} featureSetId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureSetByIdFeatureSetFeatureSetIdGet(featureSetId: string, projectName: string, branchName: string, options?: RawAxiosRequestConfig): AxiosPromise<FeatureSet> {
            return localVarFp.getFeatureSetByIdFeatureSetFeatureSetIdGet(featureSetId, projectName, branchName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeatureSetApi - object-oriented interface
 * @export
 * @class FeatureSetApi
 * @extends {BaseAPI}
 */
export class FeatureSetApi extends BaseAPI {
    /**
     * 
     * @summary Create Feature Set
     * @param {string} projectName 
     * @param {string} branchName 
     * @param {FeatureSetCreate} featureSetCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureSetApi
     */
    public createFeatureSetFeatureSetPost(projectName: string, branchName: string, featureSetCreate: FeatureSetCreate, options?: RawAxiosRequestConfig) {
        return FeatureSetApiFp(this.configuration).createFeatureSetFeatureSetPost(projectName, branchName, featureSetCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Feature Set
     * @param {string} featureSetId 
     * @param {string} projectName 
     * @param {string} branchName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureSetApi
     */
    public deleteFeatureSetFeatureSetFeatureSetIdDelete(featureSetId: string, projectName: string, branchName: string, options?: RawAxiosRequestConfig) {
        return FeatureSetApiFp(this.configuration).deleteFeatureSetFeatureSetFeatureSetIdDelete(featureSetId, projectName, branchName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Branch Features Sets
     * @param {string} projectName 
     * @param {string} branchName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureSetApi
     */
    public getBranchFeaturesSetsFeatureSetGet(projectName: string, branchName: string, options?: RawAxiosRequestConfig) {
        return FeatureSetApiFp(this.configuration).getBranchFeaturesSetsFeatureSetGet(projectName, branchName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Feature Set By Id
     * @param {string} featureSetId 
     * @param {string} projectName 
     * @param {string} branchName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureSetApi
     */
    public getFeatureSetByIdFeatureSetFeatureSetIdGet(featureSetId: string, projectName: string, branchName: string, options?: RawAxiosRequestConfig) {
        return FeatureSetApiFp(this.configuration).getFeatureSetByIdFeatureSetFeatureSetIdGet(featureSetId, projectName, branchName, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FiltersApi - axios parameter creator
 * @export
 */
export const FiltersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve filter data based on the project name
         * @summary Get filter data
         * @param {FilterType} filterType Filter Type
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilterDataFilterGet: async (filterType: FilterType, projectName: string, branchName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filterType' is not null or undefined
            assertParamExists('getFilterDataFilterGet', 'filterType', filterType)
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('getFilterDataFilterGet', 'projectName', projectName)
            // verify required parameter 'branchName' is not null or undefined
            assertParamExists('getFilterDataFilterGet', 'branchName', branchName)
            const localVarPath = `/filter/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filterType !== undefined) {
                localVarQueryParameter['filter_type'] = filterType;
            }

            if (projectName !== undefined) {
                localVarQueryParameter['project_name'] = projectName;
            }

            if (branchName !== undefined) {
                localVarQueryParameter['branch_name'] = branchName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FiltersApi - functional programming interface
 * @export
 */
export const FiltersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FiltersApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve filter data based on the project name
         * @summary Get filter data
         * @param {FilterType} filterType Filter Type
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilterDataFilterGet(filterType: FilterType, projectName: string, branchName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilterDataFilterGet(filterType, projectName, branchName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FiltersApi.getFilterDataFilterGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FiltersApi - factory interface
 * @export
 */
export const FiltersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FiltersApiFp(configuration)
    return {
        /**
         * Retrieve filter data based on the project name
         * @summary Get filter data
         * @param {FilterType} filterType Filter Type
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilterDataFilterGet(filterType: FilterType, projectName: string, branchName: string, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: any; }> {
            return localVarFp.getFilterDataFilterGet(filterType, projectName, branchName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FiltersApi - object-oriented interface
 * @export
 * @class FiltersApi
 * @extends {BaseAPI}
 */
export class FiltersApi extends BaseAPI {
    /**
     * Retrieve filter data based on the project name
     * @summary Get filter data
     * @param {FilterType} filterType Filter Type
     * @param {string} projectName 
     * @param {string} branchName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiltersApi
     */
    public getFilterDataFilterGet(filterType: FilterType, projectName: string, branchName: string, options?: RawAxiosRequestConfig) {
        return FiltersApiFp(this.configuration).getFilterDataFilterGet(filterType, projectName, branchName, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProjectApi - axios parameter creator
 * @export
 */
export const ProjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Project
         * @param {ProjectCreate} projectCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectProjectPost: async (projectCreate: ProjectCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectCreate' is not null or undefined
            assertParamExists('createProjectProjectPost', 'projectCreate', projectCreate)
            const localVarPath = `/project/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectProjectGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/project/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Project
         * @param {ProjectCreate} projectCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProjectProjectPost(projectCreate: ProjectCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProjectProjectPost(projectCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.createProjectProjectPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get All Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllProjectProjectGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Project>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllProjectProjectGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.getAllProjectProjectGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Project
         * @param {ProjectCreate} projectCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectProjectPost(projectCreate: ProjectCreate, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.createProjectProjectPost(projectCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectProjectGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<Project>> {
            return localVarFp.getAllProjectProjectGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI {
    /**
     * 
     * @summary Create Project
     * @param {ProjectCreate} projectCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public createProjectProjectPost(projectCreate: ProjectCreate, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).createProjectProjectPost(projectCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getAllProjectProjectGet(options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).getAllProjectProjectGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SchedulerApi - axios parameter creator
 * @export
 */
export const SchedulerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Scheduler
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {FeatureGenerationScheduleCreate} featureGenerationScheduleCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSchedulerSchedulerFeatureGenerationSchedulePost: async (projectName: string, branchName: string, featureGenerationScheduleCreate: FeatureGenerationScheduleCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('createSchedulerSchedulerFeatureGenerationSchedulePost', 'projectName', projectName)
            // verify required parameter 'branchName' is not null or undefined
            assertParamExists('createSchedulerSchedulerFeatureGenerationSchedulePost', 'branchName', branchName)
            // verify required parameter 'featureGenerationScheduleCreate' is not null or undefined
            assertParamExists('createSchedulerSchedulerFeatureGenerationSchedulePost', 'featureGenerationScheduleCreate', featureGenerationScheduleCreate)
            const localVarPath = `/scheduler/feature-generation-schedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectName !== undefined) {
                localVarQueryParameter['project_name'] = projectName;
            }

            if (branchName !== undefined) {
                localVarQueryParameter['branch_name'] = branchName;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(featureGenerationScheduleCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Schedules
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {JobType} [jobType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSchedulesSchedulerFeatureGenerationScheduleGet: async (projectName: string, branchName: string, jobType?: JobType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('getAllSchedulesSchedulerFeatureGenerationScheduleGet', 'projectName', projectName)
            // verify required parameter 'branchName' is not null or undefined
            assertParamExists('getAllSchedulesSchedulerFeatureGenerationScheduleGet', 'branchName', branchName)
            const localVarPath = `/scheduler/feature-generation-schedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jobType !== undefined) {
                localVarQueryParameter['job_type'] = jobType;
            }

            if (projectName !== undefined) {
                localVarQueryParameter['project_name'] = projectName;
            }

            if (branchName !== undefined) {
                localVarQueryParameter['branch_name'] = branchName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Schedule By Id
         * @param {string} scheduleId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleByIdSchedulerFeatureGenerationScheduleScheduleIdGet: async (scheduleId: string, projectName: string, branchName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduleId' is not null or undefined
            assertParamExists('getScheduleByIdSchedulerFeatureGenerationScheduleScheduleIdGet', 'scheduleId', scheduleId)
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('getScheduleByIdSchedulerFeatureGenerationScheduleScheduleIdGet', 'projectName', projectName)
            // verify required parameter 'branchName' is not null or undefined
            assertParamExists('getScheduleByIdSchedulerFeatureGenerationScheduleScheduleIdGet', 'branchName', branchName)
            const localVarPath = `/scheduler/feature-generation-schedule/{schedule_id}`
                .replace(`{${"schedule_id"}}`, encodeURIComponent(String(scheduleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectName !== undefined) {
                localVarQueryParameter['project_name'] = projectName;
            }

            if (branchName !== undefined) {
                localVarQueryParameter['branch_name'] = branchName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SchedulerApi - functional programming interface
 * @export
 */
export const SchedulerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SchedulerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Scheduler
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {FeatureGenerationScheduleCreate} featureGenerationScheduleCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSchedulerSchedulerFeatureGenerationSchedulePost(projectName: string, branchName: string, featureGenerationScheduleCreate: FeatureGenerationScheduleCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureGenerationCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSchedulerSchedulerFeatureGenerationSchedulePost(projectName, branchName, featureGenerationScheduleCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SchedulerApi.createSchedulerSchedulerFeatureGenerationSchedulePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get All Schedules
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {JobType} [jobType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSchedulesSchedulerFeatureGenerationScheduleGet(projectName: string, branchName: string, jobType?: JobType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeatureGenerationScheduleGetSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSchedulesSchedulerFeatureGenerationScheduleGet(projectName, branchName, jobType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SchedulerApi.getAllSchedulesSchedulerFeatureGenerationScheduleGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Schedule By Id
         * @param {string} scheduleId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScheduleByIdSchedulerFeatureGenerationScheduleScheduleIdGet(scheduleId: string, projectName: string, branchName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureGenerationDetailedGetSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScheduleByIdSchedulerFeatureGenerationScheduleScheduleIdGet(scheduleId, projectName, branchName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SchedulerApi.getScheduleByIdSchedulerFeatureGenerationScheduleScheduleIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SchedulerApi - factory interface
 * @export
 */
export const SchedulerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SchedulerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Scheduler
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {FeatureGenerationScheduleCreate} featureGenerationScheduleCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSchedulerSchedulerFeatureGenerationSchedulePost(projectName: string, branchName: string, featureGenerationScheduleCreate: FeatureGenerationScheduleCreate, options?: RawAxiosRequestConfig): AxiosPromise<FeatureGenerationCreateResponse> {
            return localVarFp.createSchedulerSchedulerFeatureGenerationSchedulePost(projectName, branchName, featureGenerationScheduleCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Schedules
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {JobType} [jobType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSchedulesSchedulerFeatureGenerationScheduleGet(projectName: string, branchName: string, jobType?: JobType, options?: RawAxiosRequestConfig): AxiosPromise<Array<FeatureGenerationScheduleGetSchema>> {
            return localVarFp.getAllSchedulesSchedulerFeatureGenerationScheduleGet(projectName, branchName, jobType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Schedule By Id
         * @param {string} scheduleId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleByIdSchedulerFeatureGenerationScheduleScheduleIdGet(scheduleId: string, projectName: string, branchName: string, options?: RawAxiosRequestConfig): AxiosPromise<FeatureGenerationDetailedGetSchema> {
            return localVarFp.getScheduleByIdSchedulerFeatureGenerationScheduleScheduleIdGet(scheduleId, projectName, branchName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SchedulerApi - object-oriented interface
 * @export
 * @class SchedulerApi
 * @extends {BaseAPI}
 */
export class SchedulerApi extends BaseAPI {
    /**
     * 
     * @summary Create Scheduler
     * @param {string} projectName 
     * @param {string} branchName 
     * @param {FeatureGenerationScheduleCreate} featureGenerationScheduleCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public createSchedulerSchedulerFeatureGenerationSchedulePost(projectName: string, branchName: string, featureGenerationScheduleCreate: FeatureGenerationScheduleCreate, options?: RawAxiosRequestConfig) {
        return SchedulerApiFp(this.configuration).createSchedulerSchedulerFeatureGenerationSchedulePost(projectName, branchName, featureGenerationScheduleCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Schedules
     * @param {string} projectName 
     * @param {string} branchName 
     * @param {JobType} [jobType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public getAllSchedulesSchedulerFeatureGenerationScheduleGet(projectName: string, branchName: string, jobType?: JobType, options?: RawAxiosRequestConfig) {
        return SchedulerApiFp(this.configuration).getAllSchedulesSchedulerFeatureGenerationScheduleGet(projectName, branchName, jobType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Schedule By Id
     * @param {string} scheduleId 
     * @param {string} projectName 
     * @param {string} branchName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public getScheduleByIdSchedulerFeatureGenerationScheduleScheduleIdGet(scheduleId: string, projectName: string, branchName: string, options?: RawAxiosRequestConfig) {
        return SchedulerApiFp(this.configuration).getScheduleByIdSchedulerFeatureGenerationScheduleScheduleIdGet(scheduleId, projectName, branchName, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TableApi - axios parameter creator
 * @export
 */
export const TableApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Table
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {Table} table 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTableTablePost: async (projectName: string, branchName: string, table: Table, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('createTableTablePost', 'projectName', projectName)
            // verify required parameter 'branchName' is not null or undefined
            assertParamExists('createTableTablePost', 'branchName', branchName)
            // verify required parameter 'table' is not null or undefined
            assertParamExists('createTableTablePost', 'table', table)
            const localVarPath = `/table/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectName !== undefined) {
                localVarQueryParameter['project_name'] = projectName;
            }

            if (branchName !== undefined) {
                localVarQueryParameter['branch_name'] = branchName;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(table, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Table By Id
         * @param {string} tableId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTableByIdTableTableIdGet: async (tableId: string, projectName: string, branchName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tableId' is not null or undefined
            assertParamExists('getTableByIdTableTableIdGet', 'tableId', tableId)
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('getTableByIdTableTableIdGet', 'projectName', projectName)
            // verify required parameter 'branchName' is not null or undefined
            assertParamExists('getTableByIdTableTableIdGet', 'branchName', branchName)
            const localVarPath = `/table/{table_id}`
                .replace(`{${"table_id"}}`, encodeURIComponent(String(tableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectName !== undefined) {
                localVarQueryParameter['project_name'] = projectName;
            }

            if (branchName !== undefined) {
                localVarQueryParameter['branch_name'] = branchName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Tables By Branch Id
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTablesByBranchIdTableGet: async (projectName: string, branchName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('getTablesByBranchIdTableGet', 'projectName', projectName)
            // verify required parameter 'branchName' is not null or undefined
            assertParamExists('getTablesByBranchIdTableGet', 'branchName', branchName)
            const localVarPath = `/table/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectName !== undefined) {
                localVarQueryParameter['project_name'] = projectName;
            }

            if (branchName !== undefined) {
                localVarQueryParameter['branch_name'] = branchName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TableApi - functional programming interface
 * @export
 */
export const TableApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TableApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Table
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {Table} table 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTableTablePost(projectName: string, branchName: string, table: Table, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTableTablePost(projectName, branchName, table, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TableApi.createTableTablePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Table By Id
         * @param {string} tableId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTableByIdTableTableIdGet(tableId: string, projectName: string, branchName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTableByIdTableTableIdGet(tableId, projectName, branchName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TableApi.getTableByIdTableTableIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Tables By Branch Id
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTablesByBranchIdTableGet(projectName: string, branchName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TableGetSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTablesByBranchIdTableGet(projectName, branchName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TableApi.getTablesByBranchIdTableGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TableApi - factory interface
 * @export
 */
export const TableApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TableApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Table
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {Table} table 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTableTablePost(projectName: string, branchName: string, table: Table, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.createTableTablePost(projectName, branchName, table, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Table By Id
         * @param {string} tableId 
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTableByIdTableTableIdGet(tableId: string, projectName: string, branchName: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getTableByIdTableTableIdGet(tableId, projectName, branchName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Tables By Branch Id
         * @param {string} projectName 
         * @param {string} branchName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTablesByBranchIdTableGet(projectName: string, branchName: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<TableGetSchema>> {
            return localVarFp.getTablesByBranchIdTableGet(projectName, branchName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TableApi - object-oriented interface
 * @export
 * @class TableApi
 * @extends {BaseAPI}
 */
export class TableApi extends BaseAPI {
    /**
     * 
     * @summary Create Table
     * @param {string} projectName 
     * @param {string} branchName 
     * @param {Table} table 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TableApi
     */
    public createTableTablePost(projectName: string, branchName: string, table: Table, options?: RawAxiosRequestConfig) {
        return TableApiFp(this.configuration).createTableTablePost(projectName, branchName, table, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Table By Id
     * @param {string} tableId 
     * @param {string} projectName 
     * @param {string} branchName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TableApi
     */
    public getTableByIdTableTableIdGet(tableId: string, projectName: string, branchName: string, options?: RawAxiosRequestConfig) {
        return TableApiFp(this.configuration).getTableByIdTableTableIdGet(tableId, projectName, branchName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Tables By Branch Id
     * @param {string} projectName 
     * @param {string} branchName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TableApi
     */
    public getTablesByBranchIdTableGet(projectName: string, branchName: string, options?: RawAxiosRequestConfig) {
        return TableApiFp(this.configuration).getTablesByBranchIdTableGet(projectName, branchName, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TeamApi - axios parameter creator
 * @export
 */
export const TeamApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Domains
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDomainsTeamGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/team`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeamApi - functional programming interface
 * @export
 */
export const TeamApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeamApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Domains
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDomainsTeamGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Team>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDomainsTeamGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeamApi.getDomainsTeamGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TeamApi - factory interface
 * @export
 */
export const TeamApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeamApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Domains
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDomainsTeamGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<Team>> {
            return localVarFp.getDomainsTeamGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeamApi - object-oriented interface
 * @export
 * @class TeamApi
 * @extends {BaseAPI}
 */
export class TeamApi extends BaseAPI {
    /**
     * 
     * @summary Get Domains
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamApi
     */
    public getDomainsTeamGet(options?: RawAxiosRequestConfig) {
        return TeamApiFp(this.configuration).getDomainsTeamGet(options).then((request) => request(this.axios, this.basePath));
    }
}



