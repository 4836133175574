import { Box } from "@mui/material";
import { FlexColumnBox, FlexRowBox } from "../../../../../../Styles";
import ConfirmationModal from "../../../../../DataLayer/Components/ConfirmationModal";
import { CommonCreateAttributeProps } from "../Types";
import {
  LabelTypography,
  SmallSizeLightColorTypography,
} from "../../../../../../Styles/CustomTypography";
import { InputTextField } from "../../../../../../Components/Forms/InputTextField";
import { BlueButton, GreyButton } from "../../../../../../Styles/Button";
import { useState } from "react";

type AllowedValuesProps = {
  open: boolean;
  closeHandler: () => void;
  editIndex?: number;
  resetEditIndex?: () => void;
} & CommonCreateAttributeProps;

export const AllowedValues = ({
  state,
  dispatch,
  open,
  closeHandler,
  editIndex,
  resetEditIndex,
}: AllowedValuesProps) => {
  const [currInput, setCurrInput] = useState<string>(
    editIndex !== undefined ? state.allowedValues[editIndex] : ""
  );

  const handleSubmit = () => {
    if (!currInput) return;
    if (!state.allowedValues.includes(currInput)) {
      if (editIndex) {
        const newVal = state.allowedValues;
        state.allowedValues[editIndex] = currInput;
        dispatch({
          type: "SET_ALLOWED_VALUES",
          payload: newVal,
        });
      } else {
        dispatch({
          type: "SET_ALLOWED_VALUES",
          payload: [...state.allowedValues, currInput],
        });
      }
    }
    closeHandler();
    if (resetEditIndex) resetEditIndex();
  };

  const getChildren = () => {
    return (
      <FlexColumnBox sx={{ padding: "1rem", gap: "1rem" }}>
        <Box>
          <LabelTypography>Value</LabelTypography>
          <SmallSizeLightColorTypography>
            An allowable value for this attribute
          </SmallSizeLightColorTypography>
          <InputTextField
            onChange={(e) => {
              setCurrInput(e.target.value);
            }}
            value={currInput}
            violationArray={[]}
          />
        </Box>
        <FlexRowBox sx={{ justifyContent: "flex-end", gap: "1rem" }}>
          <GreyButton onClick={closeHandler}>Cancel</GreyButton>
          <BlueButton onClick={handleSubmit} disabled={currInput.length < 1}>
            Create
          </BlueButton>
        </FlexRowBox>
      </FlexColumnBox>
    );
  };

  return (
    <ConfirmationModal
      isLoading={false}
      open={open}
      heading="Create"
      message=""
      children={getChildren()}
      hideButtons={true}
    />
  );
};
