import React from "react";
import { TEventStoreTableData } from "../../../../../../Types/CreateTableForm";
import { Action } from "../../../../../../Reducers/CreateTableReducer";
import { FlexColumnBox, FlexRowBox } from "../../../../../../Styles";
import { Box } from "@mui/material";
import { LabelTypography } from "../../../../../../Styles/CustomTypography";
import { CustomDropDown } from "../../../../../../Components/Forms/DropDown";
import { InputTextField } from "../../../../../../Components/Forms/InputTextField";
import { GreyButton } from "../../../../../../Styles/Button";

type EventStoreTableProps = {
  data: TEventStoreTableData;
  dispatch: React.Dispatch<Action>;
};

export const EventStoreTable = ({ data, dispatch }: EventStoreTableProps) => {
  return (
    <FlexColumnBox sx={{ gap: "1rem" }}>
      <Box>
        <LabelTypography>Event Store</LabelTypography>
        <FlexRowBox sx={{ gap: "1rem" }}>
          <Box sx={{ flexGrow: 1 }}>
            <CustomDropDown
              data={[]}
              multiple={false}
              selectedItems={[]}
              updateSelectedItems={() => {}}
            />
          </Box>
          <GreyButton>Browse</GreyButton>
        </FlexRowBox>
      </Box>
      <Box>
        <InputTextField
          onChange={(event) => {
            dispatch({
              type: "SET_EVENT_STORE_TABLE_DATA",
              payload: {
                sourceTopic: event.target.value,
              },
            });
          }}
          value={data.sourceTopic}
          violationArray={[]}
          title="Source topic"
          description="The name of the kafka topic"
          placeholder="topic"
        />
      </Box>
      <Box>
        <LabelTypography>Entity</LabelTypography>
        <FlexRowBox sx={{ gap: "1rem" }}>
          <Box sx={{ flexGrow: 1 }}>
            <CustomDropDown
              data={[]}
              multiple={false}
              selectedItems={[]}
              updateSelectedItems={() => {}}
            />
          </Box>
          <GreyButton>Browse</GreyButton>
        </FlexRowBox>
      </Box>
    </FlexColumnBox>
  );
};
