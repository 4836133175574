import { BaseEntity, BaseEntityBase } from "../../Client/typescript-axios-client-generated";
import { TRowProps } from "../../Packages/Table/Types";
import { TEntity } from "../../Types";

type toTableRowDataProps = {
    quickLookClickHandler: (e: string) => void;
    viewClickHandler: (e: string) => void;
    editClickHandler: (e: string) => void;
    duplicateClickHandler: (e: string) => void;
    deleteClickHandler: (e: string) => void;
}

export const toTableRowData = (entity: BaseEntity,clickHandlers: toTableRowDataProps): TRowProps => {
  return {
    row_id: entity.id,
    data: {
      name: {
        column_id: "name",
        text: entity.name,
        link: `/data-layer/entities/${entity.id}`,
      },
      updated: {
        column_id: "updated",
        text: "today",
        toolTip: "12/3/2024",
      },
      quick_look: {
        column_id: "quick_look",
        text: "Open",
        onClickHandler: clickHandlers.quickLookClickHandler,
      },
      view: {
        column_id: "view",
        text: "View",
        onClickHandler: clickHandlers.viewClickHandler,
      },
      moreOptions: [
        {
          column_id: "moreOptions",
          text: "Edit",
          onClickHandler: clickHandlers.editClickHandler,
        },
        {
          column_id: "moreOptions",
          text: "Duplicate",
          onClickHandler: clickHandlers.duplicateClickHandler,
        },
        {
          column_id: "moreOptions",
          text: "Delete",
          onClickHandler: clickHandlers.deleteClickHandler,
        },
      ]
    },
  };
};

export const toTableRowDataArray = (entities: BaseEntity[], clickHandlers: toTableRowDataProps): TRowProps[] => {
  try{
    return entities.map((entity) => toTableRowData(entity, clickHandlers));
  }catch(err){
    return []
  }
};

export const fromEntityToTEntity = (entity: BaseEntity): TEntity => {
    return {
        id: entity.id,
        name: entity.name,
        description: entity.description ?? '',
        entityType: entity.entity_type,
        outputColumn: entity.output_column ?? '',
        type: entity.data_type,
        combineEntities: []
    }
}

export const fromEntityToTEntityArray = (entites: BaseEntity[]): TEntity[] => {
  return entites.map(entity => fromEntityToTEntity(entity))
}