import { SingleSelectInput } from "../../../../../Components/Forms/SingleSelection";
import { FeatureTypes } from "../../../../../Constants/FormData/FeatureTypes";
import { FlexColumnBox, FlexRowBox } from "../../../../../Styles";
import { GreyButton } from "../../../../../Styles/Button";
import { TransformationType } from "../../../../../Types/CreateFeatureForm";

type TSelectTransformationType = {
  transformationType: TransformationType | undefined;
  dispatch: any;
};

export const SelectTransformationType = ({
  transformationType,
  dispatch,
}: TSelectTransformationType) => {
  const handleSelectTransformationType = (selectedOptionId: string) => {
    dispatch({
      type: "SET_TRANSFORMATION_TYPE",
      payload: selectedOptionId as TransformationType,
    });
  };

  const handleChangeClick = () => {
    dispatch({
      type: "SET_TRANSFORMATION_TYPE",
      payload: undefined,
    })
  }

  const showSelectTransformationType = transformationType ? false : true;
  const selectedFeature = FeatureTypes.find(feature => feature.optionId === transformationType);
  const data = selectedFeature ? [selectedFeature] : [];

  return (
    <FlexColumnBox>
      {showSelectTransformationType && (
        <SingleSelectInput
          label={""}
          data={FeatureTypes}
          onSelect={handleSelectTransformationType}
          selectedOptionId={transformationType ?? ""}
        />
      )}
      {!showSelectTransformationType && (
        <FlexRowBox sx={{gap: '1rem'}} >
        <SingleSelectInput label={""} data={data} onSelect={()=>{}}
         selectedOptionId={""}/>
         <FlexColumnBox sx={{justifyContent: 'center'}} >
         <GreyButton onClick={handleChangeClick} >Change</GreyButton>
         </FlexColumnBox>
         
         </FlexRowBox>
      )}
    </FlexColumnBox>
  );
};
