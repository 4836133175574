import { useEffect, useState } from "react";
import { FlexColumnBox, FlexRowBox } from "../../../../../../Styles";
import { ChangeRequestData } from "./Components/ChangeRequestData";
import { NavLink, useParams } from "react-router-dom";
import { Colors } from "../../../../../../Constants";
import { ChangeRequestDetail } from "./Components/ChangeRequestDetail";
import React from "react";
import { ChangeRequestGetSchemaWithCommits } from "../../../../../../Client/typescript-axios-client-generated";
import { getChangeReqeustById } from "../../../../../../Services/getChangeRequestById";
import { useToast } from "../../../../../../Hooks/useToast";
import { ToastType } from "../../../../../../Context/Providers/ToastProvider";
import { BlueButton } from "../../../../../../Styles/Button";
import { HeadingTypography } from "../../../../../../Styles/CustomTypography";
import { Box, CircularProgress } from "@mui/material";
import ThreeDotsMenu from "../../../../../../Components/MenuListModal";
import { Changes } from "./Components/Changes";
import { Loader } from "../../../../../../Components/Loader";
import { CommitsTable } from "../../../ChangeLogs/Commits";

enum OpenedTab {
  DETAILS,
  COMMITS,
  CHANGES,
}

export const ViewChangeRequest = () => {
  const [openedTab, setOpenedTab] = useState<OpenedTab>(OpenedTab.DETAILS);
  const linkClass = "link navtab";
  const activeTabClassName = linkClass + " " + "active-link";
  const [changeRequestData, setChangeRequestData] = useState<
    ChangeRequestGetSchemaWithCommits | undefined
  >(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  const { changeRequestId } = useParams();

  const toast = useToast();

  useEffect(() => {
    setLoading(true);
    getChangeReqeustById(changeRequestId ?? "")
      .then((data) => {
        setChangeRequestData(data);
      })
      .catch((err) => {
        toast.open("Failed to get change request details", ToastType.ERROR);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [changeRequestId]);

  const handleSubmit = () => {};

  const handleCloseChangeRequest = () => {};
  if (loading) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <FlexColumnBox
        sx={{
          flexGrow: 1,
          borderLeft: `1px solid ${Colors.SIDE_LINK_HOVER_COLOR}`,
        }}
      >
        <FlexRowBox
          sx={{
            justifyContent: "space-between",
            borderBottom: `1px solid ${Colors.SIDE_LINK_HOVER_COLOR}`,
            padding: "1rem",
          }}
        >
          <HeadingTypography>{changeRequestData?.name ?? ""}</HeadingTypography>
          <FlexRowBox>
            {true && (
              <BlueButton
                disabled={loading}
                startIcon={loading ? <CircularProgress size={20} /> : ""}
                onClick={handleSubmit}
              >
                Merge
              </BlueButton>
            )}
            <ThreeDotsMenu
              options={[
                <Box
                  sx={{ minWidth: "130px", color: "red", textAlign: "right" }}
                >
                  Close
                </Box>,
              ]}
              top="6px"
            />
          </FlexRowBox>
        </FlexRowBox>

        <FlexColumnBox
          sx={{
            padding: "2rem",
            gap: "1.5rem",
            backgroundColor:
              openedTab === OpenedTab.COMMITS
                ? Colors.APP_VERY_LIGHT_BLUE_COLOR
                : "",
          }}
        >
          <ChangeRequestData data={changeRequestData} />
          <FlexColumnBox>
            <FlexRowBox>
              <NavLink
                onClick={() => {
                  setOpenedTab(OpenedTab.DETAILS);
                }}
                className={
                  openedTab === OpenedTab.DETAILS
                    ? activeTabClassName
                    : linkClass
                }
                to=""
              >
                Details
              </NavLink>
              <NavLink
                onClick={() => {
                  setOpenedTab(OpenedTab.COMMITS);
                }}
                className={
                  openedTab === OpenedTab.COMMITS
                    ? activeTabClassName
                    : linkClass
                }
                to=""
              >
                Commits
              </NavLink>
              <NavLink
                onClick={() => {
                  setOpenedTab(OpenedTab.CHANGES);
                }}
                className={
                  openedTab === OpenedTab.CHANGES
                    ? activeTabClassName
                    : linkClass
                }
                to=""
              >
                Changes
              </NavLink>
            </FlexRowBox>
            <FlexColumnBox
              sx={{
                border: `1px solid ${Colors.SIDE_LINK_HOVER_COLOR}`,
                padding: "1rem",
              }}
            >
              {openedTab === OpenedTab.DETAILS && (
                <ChangeRequestDetail data={changeRequestData} />
              )}
              {openedTab === OpenedTab.COMMITS && (
                <Box style={{ padding: "2rem" }}>
                  <CommitsTable commits={changeRequestData?.commits ?? []} />
                </Box>
              )}
              {openedTab === OpenedTab.CHANGES && (
                <Changes data={changeRequestData} />
              )}
            </FlexColumnBox>
          </FlexColumnBox>
        </FlexColumnBox>
      </FlexColumnBox>
    </React.Fragment>
  );
};
