import { Box } from "@mui/material";
import { Action } from "../../../../../Reducers/CreateFeatureReducer";
import { FlexColumnBox } from "../../../../../Styles";
import {
  TSqlFeature,
  TSqlFeatureValidationError,
} from "../../../../../Types/CreateFeatureForm";
import {
  DescriptionTypography,
  LabelTypography,
} from "../../../../../Styles/CustomTypography";
import { CustomDropDown } from "../../../../../Components/Forms/DropDown";
import { Colors } from "../../../../../Constants";

type SqlFeatureProps = {
  data: TSqlFeature;
  dispatch: React.Dispatch<Action>;
  validationErrors: TSqlFeatureValidationError;
};

export const SqlFeature = ({
  data,
  dispatch,
  validationErrors,
}: SqlFeatureProps) => {
  return (
    <FlexColumnBox sx={{ gap: "1rem" }}>
      <Box>
        <LabelTypography>Sources</LabelTypography>
        <DescriptionTypography color={Colors.LINK_TEXT_COLOR}>
          Tables to make available to the SQL elaboration
        </DescriptionTypography>
        <CustomDropDown
          data={[]}
          multiple
          selectedItems={[]}
          updateSelectedItems={function (selectedItems: string[]): void {
            throw new Error("Function not implemented.");
          }}
          validationsErrors={validationErrors.sources}
        />
      </Box>
      <Box>
        <LabelTypography>Elaborated features</LabelTypography>
        <DescriptionTypography>
          Extract some features and we'll show them here
        </DescriptionTypography>
      </Box>
    </FlexColumnBox>
  );
};
