import { FeatureSetCreate } from "../Client/typescript-axios-client-generated";
import { ApiClient } from "../Utils/ApiClient";

export const createFeatureSet = async (projectName: string,branchName: string,body: FeatureSetCreate) => {
  return ApiClient.featureSetClient
    .createFeatureSetFeatureSetPost(projectName, branchName, body)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};
