import { Box } from "@mui/material";
import { CreateFormHeader } from "../../../../Components/CreateFormHeader";
import { FlexColumnBox, FlexRowBox } from "../../../../Styles";
import { GenericMetaInformation } from "../../../../Components/MetaInformation";
import { InputTextField } from "../../../../Components/Forms/InputTextField";
import RichTextField from "../../../../Components/Forms/RichTextInputField";
import {
  LabelTypography,
  MediumSizeHeading,
  SmallSizeLightColorTypography,
  SmallThinDarkColorTypography,
} from "../../../../Styles/CustomTypography";
import { CustomSwitch } from "../../../../Components/CustomSwitch";
import { CustomDropDown } from "../../../../Components/Forms/DropDown";
import { SingleSelectInput } from "../../../../Components/Forms/SingleSelection";
import "./Styles/index.css";
import { AddDestination } from "./Components/AddDestionation";
import { GreyButton } from "../../../../Styles/Button";
import {
  KeyValueInput,
  Pair,
} from "../../../../Components/Forms/KeyValueInput";
import { useMetaInformation } from "./Components/Hooks/useMetainformation";
import { useCreateSchedule } from "./Components/Hooks/useCreateSchedule";
import { convertStringToEnum } from "../../../../Utils/CommonFunctions";
import { ERetryType, ETarget, TDestination } from "./Type";
import {
  CreationMode,
  JobType,
  ScheduleType,
} from "../../../../Client/typescript-axios-client-generated";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { createSchedule } from "../../../../Services/createSchedule";
import { useVersionControlContext } from "../../../../Hooks/useVersionControlContext";
import { useToast } from "../../../../Hooks/useToast";
import { ToastType } from "../../../../Context/Providers/ToastProvider";
import { initialState } from "../../../../Constants/DefaultStateValues/createScheduleFormDeafultState";

export const CreateSchedule = () => {
  const [updateDestinationIndex, setUpdateDestinationIndex] = useState<
    number | undefined
  >(undefined);
  const { state, updateMetaInformation } = useMetaInformation();
  const { createScheduleState, dispatch } = useCreateSchedule();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const versionControlContext = useVersionControlContext();

  const toast = useToast();

  const branchesList = versionControlContext
    ? versionControlContext.branchesList.map((branch) => {
        return {
          itemId: branch.id,
          label: branch.name,
        };
      })
    : [];

  const handleAddDestination = (dest: TDestination, index?: number) => {
    let updatedDestinations = createScheduleState.selectedDestination;
    if (index !== undefined && updatedDestinations.length > index) {
      updatedDestinations[index] = dest;
    } else {
      updatedDestinations = [...updatedDestinations, dest];
    }
    dispatch({
      type: "SET_SELECTED_DESTINATION",
      payload: updatedDestinations,
    });
    dispatch({
      type: "SET_ADD_DESTINATION_OPENED",
      payload: false,
    });
    setUpdateDestinationIndex(undefined);
  };

  const submitHandler = () => {
    if (!versionControlContext) return;
    const destinations: any[] = createScheduleState.selectedDestination.map(
      (dest) => {
        return {
          destination: dest.destination ?? "",
          tableName: dest.tableName,
          mode: dest.mode,
          viewTable: null, // Ensure the property name matches exactly
          allowFieldAddition: dest.allowFieldAddition,
          allowFieldRelaxation: dest.allowFieldRelaxation,
          partitionOverwriteMode: dest.partitionOverwriteMode,
          bigqueryIntermediateFormat: true,
        };
      }
    );
    let target;
    if (createScheduleState.target === ETarget.OFFICIAL) {
      const mainBranch = versionControlContext.branchesList.find(
        (branch) => branch.name === "main"
      );
      if (!mainBranch) return;
      target = mainBranch.id;
    } else {
      target = createScheduleState.branch[0];
    }

    setIsLoading(true);
    createSchedule(
      versionControlContext?.currentProjectName,
      versionControlContext?.currentBranchName,
      {
        labels: state.labels,
        contains_pii: state.containsPii,
        contains_token: state.containsToken,
        contains_usagedata: state.containsUsageData,
        contains_web_token: state.containsWebCookie,
        creator: state.creator,
        domain_id: state.domain[0],
        owner_id: state.owner[0],
        name: createScheduleState.name,
        cluster_id: createScheduleState.cluster,
        creation_mode: CreationMode.Standard,
        branch_id: target,
        schedule_type: createScheduleState.scheduleType,
        feature_set_id: createScheduleState.featureSet[0],
        model_dependencies: "",
        post_process_workflow: "",
        retry:
          createScheduleState.retryType === ERetryType.FIXED
            ? {
                waiting_time:
                  createScheduleState.backOff.hours * 60 * 60 +
                  createScheduleState.backOff.minutes * 60,
                max_attempts: createScheduleState.maxAttempts,
              }
            : null,
        daily_schedule_attributes:
          createScheduleState.scheduleType === ScheduleType.DailySchedule
            ? {
                run_time: createScheduleState.utcTime,
              }
            : null,
        custom_schedule_attributes:
          createScheduleState.scheduleType === ScheduleType.CustomSchedule
            ? {
                cron: createScheduleState.cron,
              }
            : null,
        dependency_schedules_attributes:
          createScheduleState.scheduleType === ScheduleType.DependencySchedule
            ? createScheduleState.dependentJob
            : null,
        schedule_failure_email_list: state.scheduleFailureEmailList,
        additional_spark_properties:
          createScheduleState.showAdditionalSparkProperty
            ? createScheduleState.additionalSparkProperty
            : null,
      }
    )
      .then((data) => {
        toast.open("Successfully created.", ToastType.SUCCESS);
        dispatch({ type: "RESET_STATE", payload: initialState });
      })
      .catch((err) => {
        toast.open(
          "Something went wrong while creating schedule.",
          ToastType.ERROR
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CreateFormHeader
        heading={"Create Schedules"}
        submitButtonText="Create Schedule"
        submitHandler={submitHandler}
        loading={isLoading}
      />
      <FlexRowBox sx={{ gap: "1rem", padding: "2rem" }}>
        <FlexColumnBox sx={{ flexGrow: 1, width: "100%", gap: "1rem" }}>
          <InputTextField
            onChange={(e: any) => {
              dispatch({ type: "SET_NAME", payload: e.target.value });
            }}
            value={createScheduleState.name}
            title="Name"
            description="Name must be unique, start with a lower case latter and may only contains underscores, letters and numbers"
            violationArray={[]}
          />
          <Box>
            <LabelTypography>Description (Optional)</LabelTypography>
            <RichTextField
              input={createScheduleState.description}
              setInput={(val: string) => {
                dispatch({ type: "SET_DESCRIPTION", payload: val });
              }}
            />
          </Box>
          <Box>
            <LabelTypography>Enabled</LabelTypography>
            <CustomSwitch
              checked={createScheduleState.enabled}
              onChange={() => {
                dispatch({
                  type: "SET_ENABLED",
                  payload: !createScheduleState.enabled,
                });
              }}
            />
          </Box>
          <Box>
            <LabelTypography>Cluster</LabelTypography>
            <SmallSizeLightColorTypography>
              Select the cluster this Schedule will run on
            </SmallSizeLightColorTypography>
            <FlexRowBox sx={{ gap: "1rem" }}>
              <Box sx={{ flexGrow: 1 }}>
                <CustomDropDown
                  data={createScheduleState.clusterData.map((cluster: any) => {
                    return { label: cluster.name, itemId: cluster.id };
                  })}
                  multiple={false}
                  selectedItems={
                    createScheduleState.cluster
                      ? [createScheduleState.cluster]
                      : []
                  }
                  updateSelectedItems={(selectedItems) => {
                    dispatch({
                      type: "SET_CLUSTER",
                      payload: selectedItems[0],
                    });
                  }}
                />
              </Box>
              <GreyButton>Browse</GreyButton>
            </FlexRowBox>
          </Box>
          {createScheduleState.standardOrStream == "STANDARD" && (
            <Box>
              <LabelTypography>Cluster Property Set (Optional)</LabelTypography>
              <SmallSizeLightColorTypography>
                Select the Cluster Property Set this schedule will run on
              </SmallSizeLightColorTypography>
              <CustomDropDown
                data={[]}
                multiple={false}
                selectedItems={[]}
                updateSelectedItems={() => {}}
              />
            </Box>
          )}
          <Box>
            <LabelTypography>Additional Spark Properties</LabelTypography>
            <SmallSizeLightColorTypography>
              Allow additional Spark properties when running this job (it's
              usually better to use a Cluster Property Set).
            </SmallSizeLightColorTypography>
            <CustomSwitch
              checked={createScheduleState.showAdditionalSparkProperty}
              onChange={() => {
                dispatch({
                  type: "SET_SHOW_ADDITIONAL_SPARK_PROPERTY",
                  payload: !createScheduleState.showAdditionalSparkProperty,
                });
              }}
            />
          </Box>
          {createScheduleState.showAdditionalSparkProperty && (
            <Box>
              <KeyValueInput
                pairs={createScheduleState.additionalSparkProperty}
                updatePair={(pairs: Pair[]) => {
                  dispatch({
                    type: "SET_ADDITIONAL_SPARK_PROPERTY",
                    payload: pairs,
                  });
                }}
              />
            </Box>
          )}
          <SingleSelectInput
            label={"Standard or Steaming"}
            data={[
              {
                optionId: "STANDARD",
                title: "Standard",
                description: "Generate data in daily batches",
              },
              {
                optionId: "STREAMING",
                title: "Streaming",
                description:
                  "Run a long-running process to continuously generate data as it arrives (Kafka only).",
              },
            ]}
            onSelect={(id: string) => {
              dispatch({ type: "SET_STANDARD_OR_STREAM", payload: id });
            }}
            selectedOptionId={createScheduleState.standardOrStream}
            showAsGrid
            columnCount={2}
          />
          <Box>
            {createScheduleState.addDestinationOpened && (
              <AddDestination
                closeHandler={() => {
                  dispatch({
                    type: "SET_ADD_DESTINATION_OPENED",
                    payload: false,
                  });
                }}
                createHandler={handleAddDestination}
                destinationToUpdate={
                  updateDestinationIndex !== undefined
                    ? {
                        ...createScheduleState.selectedDestination[
                          updateDestinationIndex as number
                        ],
                        index: updateDestinationIndex,
                      }
                    : undefined
                }
              />
            )}{" "}
            {/*This is confiemation model component*/}
            <LabelTypography>Destinations</LabelTypography>
            <FlexColumnBox className="destination-view">
              {createScheduleState.selectedDestination.map((dest, index) => {
                return (
                  <Box>
                    <Box className="content-view">
                      <NavLink to={""}>{dest.destination}</NavLink>
                      <LabelTypography>Table</LabelTypography>
                      <SmallSizeLightColorTypography>
                        {dest.tableName}
                      </SmallSizeLightColorTypography>
                      <LabelTypography>Mode</LabelTypography>
                      <SmallSizeLightColorTypography>
                        {dest.mode}
                      </SmallSizeLightColorTypography>
                      {dest.allowFieldAddition && (
                        <LabelTypography>Field Addition</LabelTypography>
                      )}
                      {dest.allowFieldRelaxation && (
                        <LabelTypography>Field Relaxation</LabelTypography>
                      )}
                      {dest.partitionOverwriteMode && (
                        <LabelTypography>
                          Partition Overwrite mode
                        </LabelTypography>
                      )}
                    </Box>
                    <FlexRowBox>
                      <Box
                        className="edit-button"
                        onClick={() => {
                          dispatch({
                            type: "SET_ADD_DESTINATION_OPENED",
                            payload: true,
                          });
                          setUpdateDestinationIndex(index);
                        }}
                      >
                        Edit
                      </Box>
                      <Box
                        className="delete-button"
                        onClick={() => {
                          dispatch({
                            type: "SET_SELECTED_DESTINATION",
                            payload:
                              createScheduleState.selectedDestination.filter(
                                (_, idx) => {
                                  return index != idx;
                                }
                              ),
                          });
                        }}
                      >
                        Delete
                      </Box>
                    </FlexRowBox>
                  </Box>
                );
              })}

              <Box
                className="add-input-container"
                onClick={() => {
                  dispatch({
                    type: "SET_ADD_DESTINATION_OPENED",
                    payload: true,
                  });
                }}
              >
                <Box className="add-input-content">
                  <span style={{ fontSize: "1.3-rem" }}>+</span>
                  <br />
                  <Box>Add Destination</Box>
                </Box>
              </Box>
            </FlexColumnBox>
          </Box>
          <Box>
            <MediumSizeHeading>Target</MediumSizeHeading>
            <SingleSelectInput
              label={"Target Branch or Commit"}
              data={[
                {
                  optionId: ETarget.OFFICIAL,
                  title: "Official Branch",
                  description:
                    "Retrieve the Feature Set Configuration from the Official Branch.",
                },
                {
                  optionId: ETarget.BRANCH,
                  title: "Branch",
                  description:
                    "Retrieve the Feature Set Configuration from a specific Branch.",
                },
              ]}
              onSelect={(id: string) => {
                const convertedId = convertStringToEnum(ETarget, id);
                if (convertedId)
                  dispatch({ type: "SET_TARGET", payload: convertedId });
              }}
              selectedOptionId={createScheduleState.target}
              showAsGrid
              columnCount={2}
            />
          </Box>
          {createScheduleState.target == ETarget.BRANCH && (
            <Box>
              <LabelTypography>Branch</LabelTypography>
              <SmallSizeLightColorTypography>
                Select the set of Feature that will be stored in this schedule
              </SmallSizeLightColorTypography>
              <CustomDropDown
                data={branchesList}
                multiple={false}
                selectedItems={createScheduleState.branch}
                updateSelectedItems={(selectedItems) => {
                  dispatch({
                    type: "SET_BRANCH",
                    payload: selectedItems,
                  });
                }}
              />
            </Box>
          )}
          <Box>
            <LabelTypography>Feature Set</LabelTypography>
            <SmallSizeLightColorTypography>
              Select the set of Feature that will be stored in this Schedule
            </SmallSizeLightColorTypography>
            <FlexRowBox sx={{ gap: "1rem" }}>
              <Box sx={{ flexGrow: 1 }}>
                <CustomDropDown
                  data={createScheduleState.featureSetData.map((featureSet) => {
                    return {
                      itemId: featureSet.id,
                      label: featureSet.name,
                    };
                  })}
                  multiple={false}
                  selectedItems={createScheduleState.featureSet}
                  updateSelectedItems={(selectedItems) => {
                    dispatch({
                      type: "SET_FEATURE_SET",
                      payload: selectedItems,
                    });
                  }}
                />
              </Box>
              <GreyButton>Browse</GreyButton>
            </FlexRowBox>
          </Box>
          <Box>
            <LabelTypography>Entity Population (Optional)</LabelTypography>
            <SmallSizeLightColorTypography>
              Select the Entity Population for this schedule.
            </SmallSizeLightColorTypography>
            <FlexRowBox sx={{ gap: "1rem" }}>
              <Box sx={{ flexGrow: 1 }}>
                <CustomDropDown
                  data={[]}
                  multiple={false}
                  selectedItems={[]}
                  updateSelectedItems={() => {}}
                />
              </Box>
              <GreyButton>Browse</GreyButton>
            </FlexRowBox>
          </Box>
          <Box>
            <LabelTypography>Includes Metadata</LabelTypography>
            <SmallSizeLightColorTypography>
              If switches on, we'll include the schedule run id and a valid at
              timestamp information in the results
            </SmallSizeLightColorTypography>
            <CustomSwitch />
          </Box>
          <Box>
            <MediumSizeHeading>Schedule</MediumSizeHeading>
            <SingleSelectInput
              label={"Schedule Type"}
              data={[
                {
                  optionId: ScheduleType.Manual,
                  title: "Manual",
                  description: "Run when manually triggered.",
                },
                {
                  optionId: ScheduleType.DailySchedule,
                  title: "Daily Schedule",
                  description: "Run dailt at specific time.",
                },
                {
                  optionId: ScheduleType.CustomSchedule,
                  title: "Custom Schedule",
                  description: "Run according to a cron.",
                },
                {
                  optionId: ScheduleType.DependencySchedule,
                  title: "Dependency Schedule",
                  description: "Run on success of dependent jobs.",
                },
              ]}
              onSelect={(id: string) => {
                const convertedVal = convertStringToEnum(ScheduleType, id);
                if (convertedVal) {
                  dispatch({
                    type: "SET_SCHEDULE_TYPE",
                    payload: convertedVal,
                  });
                }
              }}
              showAsGrid
              columnCount={2}
              selectedOptionId={createScheduleState.scheduleType}
            />
          </Box>
          {createScheduleState.scheduleType === ScheduleType.DailySchedule && (
            <Box>
              <LabelTypography>Time (UTC)</LabelTypography>
              <SmallThinDarkColorTypography>
                Choose the time of day (UTC) you would like this to run
              </SmallThinDarkColorTypography>
              <input
                type="time"
                value={createScheduleState.utcTime}
                onChange={(e) => {
                  dispatch({
                    type: "SET_UTC_TIME",
                    payload: e.target.value,
                  });
                }}
              ></input>
            </Box>
          )}
          {createScheduleState.scheduleType === ScheduleType.CustomSchedule && (
            <Box>
              <LabelTypography>Cron</LabelTypography>
              <SmallSizeLightColorTypography>
                Define a custom Schedule by entring a Cron strinsg.
              </SmallSizeLightColorTypography>
              <InputTextField
                onChange={function (e: any): void {
                  throw new Error("Function not implemented.");
                }}
                value={""}
                violationArray={[]}
              />
            </Box>
          )}
          {(createScheduleState.scheduleType === ScheduleType.DailySchedule ||
            createScheduleState.scheduleType ===
              ScheduleType.CustomSchedule) && (
            <FlexRowBox>
              <Box>
                <LabelTypography>Date Offset (Optional)</LabelTypography>
                <SmallSizeLightColorTypography>
                  Run feature generation up to n days ago
                </SmallSizeLightColorTypography>
                <InputTextField
                  onChange={function (e: any): void {
                    throw new Error("Function not implemented.");
                  }}
                  value={""}
                  violationArray={[]}
                />
              </Box>
            </FlexRowBox>
          )}
          {createScheduleState.scheduleType ===
            ScheduleType.DependencySchedule && (
            <Box>
              <LabelTypography>Dependent Jobs</LabelTypography>
              <Box className="add-input-container" onClick={() => {}}>
                <Box className="add-input-content">
                  <span style={{ fontSize: "1.3-rem" }}>+</span>
                  <br />
                  <Box>Add Job</Box>
                </Box>
              </Box>
            </Box>
          )}
          {createScheduleState.scheduleType !== ScheduleType.Manual && (
            <Box>
              <SingleSelectInput
                label={"Retry"}
                data={[
                  {
                    optionId: ERetryType.DONT_RETRY,
                    title: "Don't Retry",
                    description: "Don't retry the run if it fails.",
                  },
                  {
                    optionId: ERetryType.FIXED,
                    title: "Fixed",
                    description: "Define a Backoff and Max Attempts.",
                  },
                ]}
                showAsGrid
                onSelect={(id: string) => {
                  const convertedId = convertStringToEnum(ERetryType, id);
                  if (convertedId) {
                    dispatch({ type: "SET_RETRY_TYPE", payload: convertedId });
                  }
                }}
                selectedOptionId={createScheduleState.retryType}
              />
            </Box>
          )}
          {createScheduleState.retryType === ERetryType.FIXED && (
            <Box>
              <LabelTypography>Backoff</LabelTypography>
              <SmallSizeLightColorTypography>
                How long to wait before trying again.
              </SmallSizeLightColorTypography>
              <FlexRowBox sx={{ gap: "2rem" }}>
                <FlexRowBox>
                  <InputTextField
                    onChange={(e: any) => {
                      if (e.target.value < 0) return;
                      dispatch({
                        type: "SET_BACKOFF",
                        payload: {
                          ...createScheduleState.backOff,
                          hours: e.target.value,
                        },
                      });
                    }}
                    value={createScheduleState.backOff.hours}
                    violationArray={[]}
                    style={{
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                    type="number"
                  />
                  <Box className="input-post-badge">Hrs</Box>
                </FlexRowBox>
                <FlexRowBox>
                  <InputTextField
                    onChange={(e) => {
                      if (e.target.value < 0) return;
                      dispatch({
                        type: "SET_BACKOFF",
                        payload: {
                          ...createScheduleState.backOff,
                          minutes: e.target.value,
                        },
                      });
                    }}
                    value={createScheduleState.backOff.minutes}
                    violationArray={[]}
                    style={{
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                    type="number"
                  />
                  <Box className="input-post-badge">Mins</Box>
                </FlexRowBox>
              </FlexRowBox>
            </Box>
          )}
          {createScheduleState.retryType === ERetryType.FIXED && (
            <Box>
              <LabelTypography>Max Attempts</LabelTypography>
              <SmallSizeLightColorTypography>
                How many times this should be tried again if it fails.
              </SmallSizeLightColorTypography>
              <InputTextField
                onChange={(e: any) => {
                  if (e.target.value < 0) return;
                  dispatch({
                    type: "SET_MAX_ATTEMPTS",
                    payload: e.target.value,
                  });
                }}
                value={createScheduleState.maxAttempts}
                violationArray={[]}
                type="number"
              />
            </Box>
          )}
        </FlexColumnBox>
        <GenericMetaInformation
          labels={state.labels}
          containPii={state.containsPii}
          containToken={state.containsToken}
          containsUsageData={state.containsUsageData}
          containsWebToken={state.containsWebCookie}
          creator={state.creator}
          domain={state.domain}
          owner={state.owner}
          domainsData={createScheduleState.domains}
          ownerData={createScheduleState.owner}
          mergeFileCsv={state.mergeFilesCsv}
          modelDependencies={state.modelDependency}
          scheduleFailureEmailList={state.scheduleFailureEmailList}
          updateData={updateMetaInformation}
        />
      </FlexRowBox>
    </Box>
  );
};
