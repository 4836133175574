import { TItemData } from "../Components/Forms/DropDown";

  
export const sampleData: TItemData[] = [
{
    label: "Item 1",
    description: "Description for item 1",
    itemId: "item1",
},
{
    label: "Item 2",
    description: "Description for item 2",
    itemId: "item2",
},
{
    label: "Item 3",
    description: "Description for item 3",
    itemId: "item3",
},
{
    label: "Item 4",
    description: "Description for item 4",
    itemId: "item4",
},
{
    label: "Item 5",
    description: "Description for item 5",
    itemId: "item5",
},
{
    label: "Item 6",
    description: "Description for item 6",
    itemId: "item6",
},
{
    label: "Item 7",
    description: "Description for item 7",
    itemId: "item7",
},
{
    label: "Item 8",
    description: "Description for item 8",
    itemId: "item8",
},
{
    label: "Item 9",
    description: "Description for item 9",
    itemId: "item9",
},
{
    label: "Item 10",
    description: "Description for item 10",
    itemId: "item10",
},
{
    label: "Item 11",
    description: "Description for item 11",
    itemId: "item11",
},
{
    label: "Item 12",
    description: "Description for item 12",
    itemId: "item12",
},
{
    label: "Item 13",
    description: "Description for item 13",
    itemId: "item13",
},
{
    label: "Item 14",
    description: "Description for item 14",
    itemId: "item14",
},
{
    label: "Item 15",
    description: "Description for item 15",
    itemId: "item15",
},
{
    label: "Item 16",
    description: "Description for item 15",
    itemId: "item16",
},
];
  