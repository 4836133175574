import { useEffect, useReducer } from "react";
import { defaultCreateTableData } from "../Constants/DefaultStateValues/CreateTableFormDefaultState";
import { createTableReducer } from "../Reducers/CreateTableReducer";
import { fromDomainToTDomainArray } from "../Utils/Mapper/DomainMapper";
import { getAllTeams } from "../Services/getTeams";
import { fromEntityToTEntityArray } from "../Utils/Mapper/EntitesMapper";
import { getTables } from "../Services/getTables";
import { useVersionControlContext } from "./useVersionControlContext";
import { useGetEntities } from "./DataFetcher/Entity/useGetEntities";
import { useGetDomains } from "./DataFetcher/Domain/useGetDomains";

export const useCreateTableForm = () => {
  const [createTableState, dispatch] = useReducer(
    createTableReducer,
    defaultCreateTableData
  );
  const versionControlContext = useVersionControlContext();

  const { domains } = useGetDomains();

  const { entities } = useGetEntities();

  useEffect(() => {
    const mappedDomainData = fromDomainToTDomainArray(domains);
    dispatch({
      type: "SET_DOMAINS_DATA",
      payload: mappedDomainData,
    });
    getAllTeams()
      .then((data) => {
        let mappedOwnerData = fromDomainToTDomainArray(data);
        dispatch({
          type: "SET_OWNERS_DATA",
          payload: mappedOwnerData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [domains]);

  useEffect(() => {
    let convertedEntites = fromEntityToTEntityArray(entities);
    dispatch({
      type: "SET_EXTERNAL_TABLE_DATA",
      payload: {
        entitiesData: convertedEntites,
      },
    });
    dispatch({
      type: "SET_VIEW_TABLE_DATA",
      payload: {
        entitiesData: convertedEntites,
      },
    });
  }, [entities]);

  useEffect(() => {
    if (!versionControlContext?.currentBranch) return;
    getTables(
      versionControlContext.currentProjectName,
      versionControlContext.currentBranchName
    )
      .then((data) => {
        dispatch({
          type: "SET_SOURCES_TABLE_DATA",
          payload: data,
        });
      })
      .catch((err) => {});
  }, [versionControlContext?.currentBranch]);

  return {
    createTableState,
    dispatch,
  };
};
