import { useParams } from "react-router-dom";
import { CreateFormHeader } from "../../../../Components/CreateFormHeader";
import { FlexColumnBox, FlexRowBox } from "../../../../Styles";
import {
  LabelTypography,
  MediumSizeDarkColorThinTypography,
  MediumSizeHeading,
  SmallSizeLightColorTypography,
} from "../../../../Styles/CustomTypography";
import { Box, Button } from "@mui/material";
import { useToast } from "../../../../Hooks/useToast";
import { ToastType } from "../../../../Context/Providers/ToastProvider";
import { GenericTable } from "../../../../Packages/Table";
import { RedButton } from "../../../../Styles/Button";

export const ChangeLogDetail = () => {
  const { commitId } = useParams();

  const toast = useToast();

  const handleCopyClick = () => {
    navigator.clipboard.writeText(commitId ?? "");
    toast.open("Copied", ToastType.SUCCESS);
  };

  const handleClickRevert = () => {

  }
  
  const handleClickCherryPick = () => {

  }

  return (
    <FlexColumnBox sx={{ width: "100%" }}>
      <CreateFormHeader heading={"commit message"} />
      <FlexColumnBox sx={{ padding: "2rem", gap: "1rem" }}>
        <Box>
          <LabelTypography>Commit ID</LabelTypography>
          <FlexRowBox sx={{ alignItems: "center" }}>
            <MediumSizeDarkColorThinTypography>
              {commitId}
            </MediumSizeDarkColorThinTypography>
            <Button sx={{ textTransform: "none" }} onClick={handleCopyClick}>
              Copy
            </Button>
          </FlexRowBox>
        </Box>
        <Box>
          <LabelTypography>Author</LabelTypography>
          <MediumSizeDarkColorThinTypography>
            Kakumanu,R,Ravi,XCJ1C
          </MediumSizeDarkColorThinTypography>
        </Box>
        <Box>
          <LabelTypography>Time</LabelTypography>
          <MediumSizeDarkColorThinTypography>
            Jul 12, 2024 3:36 PM (IST)
          </MediumSizeDarkColorThinTypography>
        </Box>
        <Box sx={{margin: '64px 0', gap: '1rem'}} >
          <MediumSizeHeading>Changes</MediumSizeHeading>
          <GenericTable
            rows={[]}
            tableHeader={[]}
            selectAllRows={function (): void {
              throw new Error("Function not implemented.");
            }}
            updateSelectedRow={function (rowId: string): void {
              throw new Error("Function not implemented.");
            }}
            totalRows={0}
            selectedRows={[]}
          />
        </Box>
        <Box>
          <MediumSizeHeading>Danger Zone</MediumSizeHeading>
          <LabelTypography>Revert this commit</LabelTypography>
          <SmallSizeLightColorTypography>
            This will create a new commit on{" "}
            <i>urgent_nbs_fix_for_removal_of_payg</i> which reverts the changes
            of this commit.
            <br />
            it will not remove the commit from the history of a branch
          </SmallSizeLightColorTypography>
          <RedButton onClick={handleClickRevert} >Revert</RedButton>
        </Box>
        <Box>
          <LabelTypography>Cherry-pick this commit</LabelTypography>
          <SmallSizeLightColorTypography>
            This will create a new commit on{" "}
            <i>urgent_nbs_fix_for_removal_of_payg</i> which contains the changes
            for this commit.
            <br />
            This is useful for bringing a specific change on one branch into
            another.
          </SmallSizeLightColorTypography>
          <RedButton onClick={handleClickCherryPick} >Cherry-pick this commit</RedButton>
        </Box>
      </FlexColumnBox>
    </FlexColumnBox>
  );
};
