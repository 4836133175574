import { Typography } from "@mui/material";
import { GenericFilter } from "../../../../Components/GenericFilter/GenericFilter";
import {
  DownWardAnimatedBox,
  FlexColumnBox,
  FlexRowBox,
} from "../../../../Styles";
import { useNavigate } from "react-router-dom";
import { GenericTable } from "../../../../Packages/Table";
import { BlueButton, GreenButton, RedButton } from "../../../../Styles/Button";
import { Colors } from "../../../../Constants";
import { exportedData } from "../../../../TestingData/DataLayer";
import React, { useEffect, useState } from "react";
import { useSelectedRow } from "../../../../Hooks/useSelectedRows";
import { TableGetSchema } from "../../../../Client/typescript-axios-client-generated";
import { useVersionControlContext } from "../../../../Hooks/useVersionControlContext";
import { getTables } from "../../../../Services/getTables";
import { useToast } from "../../../../Hooks/useToast";
import { ToastType } from "../../../../Context/Providers/ToastProvider";
import { TRowProps } from "../../../../Packages/Table/Types";
import { mapToTableData } from "../../../../Utils/Mapper/TableMapping";
import { TFilter, TFilterData } from "../../../../Types";
import { useFilter } from "../../../../Hooks/useFilter";
import { filterDataHelper } from "../../../../Hooks/useFilterData";
import { usePermissionContext } from "../../../../Hooks/usePermissionContext";
import { CustomToolTip } from "../../../../Styles/ToolTip";
import { PERMISSION_DENIED_TOOLTIP_MESSAGE } from "../../../../Constants/messages";

export const Tables = () => {
  const { selectAllRows, updateSelectedRow, totalRows, selectedRows } =
    useSelectedRow(exportedData.dummyData.rows);
  exportedData.dummyData.selectAllRows = selectAllRows;
  exportedData.dummyData.updateSelectedRow = updateSelectedRow;
  exportedData.dummyData.totalRows = totalRows;
  exportedData.dummyData.selectedRows = selectedRows;

  const versionControlContext = useVersionControlContext();
  const toast = useToast();

  const [tableData, setTableData] = useState<TableGetSchema[]>([]);
  const [tableDataForTable, setTableDataForTable] = useState<TRowProps[]>([]);
  const [filter, setFilter] = useState<TFilter>({});
  const permissionContext = usePermissionContext();

  useEffect(() => {
    if (!versionControlContext?.currentBranch) return;
    getTables(
      versionControlContext.currentProjectName,
      versionControlContext.currentBranchName
    )
      .then((data) => {
        setTableData(data);
      })
      .catch((err) => {
        toast.open("Failed to load table data", ToastType.ERROR);
      });
  }, [versionControlContext?.currentBranch]);

  useEffect(() => {
    const filteredTableData = filterDataHelper(filter, tableData);
    const convertedData = mapToTableData(filteredTableData);
    setTableDataForTable(convertedData);
  }, [tableData, filter]);

  const navigate = useNavigate();

  const { filterComponentData } = useFilter("Table");

  return (
    <React.Fragment>
      <FlexRowBox sx={{ flexGrow: 1 }}>
        <GenericFilter
          globalSearchEnabled={true}
          data={filterComponentData}
          onChangeFilter={setFilter}
        />
        <FlexColumnBox
          sx={{
            padding: "2rem",
            backgroundColor: Colors.APP_VERY_LIGHT_BLUE_COLOR,
            flexGrow: 1,
          }}
        >
          <FlexRowBox sx={{ justifyContent: "space-between" }}>
            <DownWardAnimatedBox flag={selectedRows.length > 0}>
              <FlexColumnBox>
                <FlexRowBox sx={{ gap: "5px" }}>
                  <GreenButton>Add to project</GreenButton>
                  <RedButton>Delete</RedButton>
                </FlexRowBox>
                <Typography sx={{ color: Colors.LINK_TEXT_COLOR }}>
                  {selectedRows.length} row selected
                </Typography>
              </FlexColumnBox>
            </DownWardAnimatedBox>
            <FlexColumnBox>
              <CustomToolTip
                title={
                  permissionContext?.permissions.createTable
                    ? ""
                    : PERMISSION_DENIED_TOOLTIP_MESSAGE
                }
                placement="top"
              >
                <BlueButton
                  onClick={() => {
                    if (!permissionContext?.permissions.createTable) return;
                    navigate("/data-layer/tables/create-table");
                  }}
                  sx={{
                    height: "40px",
                    cursor: permissionContext?.permissions.createTable
                      ? ""
                      : "not-allowed",
                  }}
                  className={permissionContext?.permissions.createTable ? "" : 'disabled-button'}
                >
                  Create Table
                </BlueButton>
              </CustomToolTip>
              <Typography></Typography>
            </FlexColumnBox>
          </FlexRowBox>
          <GenericTable
            rows={tableDataForTable}
            tableHeader={exportedData.dummyData.tableHeader}
            selectAllRows={selectAllRows}
            updateSelectedRow={updateSelectedRow}
            totalRows={totalRows}
            selectedRows={selectedRows}
          />
        </FlexColumnBox>
      </FlexRowBox>
    </React.Fragment>
  );
};
