import React, { useRef, useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { Colors } from "../../../Constants";
import {
  DropDownItemBox,
  DropDownSelectedItem,
  FlexColumnBox,
  FlexRowBox,
} from "../../../Styles";
import "../../../Styles/CSS/dropDown.css";
import { isMatchingSubString } from "../../../Utils/CommonFunctions";
import {
  DescriptionTypography,
  LabelTypography,
  LightColorTypography,
  SmallThinDarkColorTypography,
} from "../../../Styles/CustomTypography";

// SVG icon for the dropdown symbol
const DropDownIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M6 9l6 6 6-6" />
  </svg>
);

export type TItemData = {
  label: string;
  description?: string;
  itemId: string;
};

type CustomDropDownProps = {
  data: TItemData[];
  multiple: boolean;
  placeholder?: string;
  selectedItems: string[];
  updateSelectedItems: (selectedItems: string[]) => void;
  validationsErrors?: string[];
};

// Existing imports...

export const CustomDropDown = ({
  data,
  multiple,
  placeholder,
  selectedItems,
  updateSelectedItems,
  validationsErrors,
}: CustomDropDownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropDownHovered, setIsDropDownHovered] = useState<boolean>(false);
  const [inputFieldText, setInputFieldText] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFocus = () => {
    setIsOpen(true);
  };

  const handleBlur = () => {
    setIsOpen(false);
    if (!isDropDownHovered) setInputFieldText("");
  };

  const handleItemSelect = (itemId: string) => {
    let newSelectedItems = [...selectedItems];

    if (newSelectedItems.includes(itemId)) {
      newSelectedItems = newSelectedItems.filter(
        (selectedId) => itemId !== selectedId
      );
    } else {
      if (multiple) newSelectedItems.push(itemId);
      else newSelectedItems = [itemId];
    }

    updateSelectedItems(newSelectedItems);
    setIsDropDownHovered(false);
  };

  const handleCrossClick = () => {
    updateSelectedItems([]);
    setInputFieldText("");
  };

  const handleItemCrossClick = (itemId: string) => {
    let newArray = [...selectedItems].filter(
      (selectedId) => selectedId != itemId
    );
    updateSelectedItems(newArray);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Backspace" && document.activeElement === inputRef.current) {
      if (inputFieldText.length === 0 && selectedItems.length > 0) {
        const newArray = [...selectedItems];
        newArray.pop();
        updateSelectedItems(newArray);
      }
    }
  };

  // Calculate input field height based on the number of selected items

  return (
    <FlexColumnBox style={{ padding: "0 0 0 0", position: "relative" }}>
      <FlexRowBox
        style={{
          borderRadius: "4px",
          outline: "none",
          flexWrap: "wrap",
          alignItems: "center",
          padding: "2px 8px",
          border: `${isOpen ? "2px" : "1px"} solid ${
            validationsErrors?.length
              ? "red"
              : isOpen
              ? "rgb(38, 132, 255)"
              : Colors.SIDE_LINK_HOVER_COLOR
          }`,
        }}
        onClick={() => {
          inputRef.current?.focus();
        }}
      >
        {selectedItems.length === 0 && !isOpen && (
          <LightColorTypography sx={{ color: Colors.LINK_TEXT_COLOR }}>
            {placeholder ? placeholder : "Please select..."}
          </LightColorTypography>
        )}
        {selectedItems.map((item, index) => {
          const currItem = data.find((listItem) => listItem.itemId === item);
          if(multiple)
          return (
            <DropDownSelectedItem key={item}>
              <FlexRowBox>
                <SmallThinDarkColorTypography>
                  {currItem?.label}{" "}
                </SmallThinDarkColorTypography>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleItemCrossClick(item);
                  }}
                  className="hoverable-span"
                >
                  &times;
                </span>
              </FlexRowBox>
            </DropDownSelectedItem>
          );
          else {
            return (
              <SmallThinDarkColorTypography>
              {currItem?.label}{" "}
            </SmallThinDarkColorTypography>
            )
          }
        })}
        <input
          type="text"
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={inputFieldText}
          ref={inputRef}
          onKeyDown={handleKeyDown}
          onChange={(e) => {
            setInputFieldText(e.target.value);
          }}
          style={{
            border: "none",
            outline: "none",
            color: Colors.LINK_TEXT_COLOR,
            width: `${2 + inputFieldText.length * 9}px`,
          }}
        />
        <FlexRowBox
          sx={{
            alignItems: "center",
            gap: "3px",
            marginLeft: "auto",
            alignSelf: "flex-start",
          }}
        >
          {selectedItems.length > 0 && (
            <span
              style={{
                display: "flex",
                fontWeight: "bold",
                cursor: "pointer",
                fontSize: "20px",
              }}
              onClick={handleCrossClick}
            >
              ×
            </span>
          )}
          <span
            style={{
              marginLeft: "4px",
              height: "24px", // Adjust the height of the line
              borderRight: `1px solid ${Colors.LINK_TEXT_COLOR}`,
            }}
          />
          <span
            style={{
              cursor: "pointer",
              marginTop: "7px",
            }}
          >
            {DropDownIcon}
          </span>
        </FlexRowBox>
      </FlexRowBox>
      {(isOpen || isDropDownHovered) && (
        <Box
          onMouseEnter={() => {
            setIsDropDownHovered(true);
          }}
          onMouseLeave={() => {
            setIsDropDownHovered(false);
          }}
          style={{
            position: "absolute",
            top: "calc(100% + 5px)", // Adjust the distance from the top
            left: "0",
            width: "100%",
            maxHeight: "300px",
            overflow: "auto",
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)', // Adjust box shadow as needed
            border: `1px solid ${Colors.SIDE_LINK_HOVER_COLOR}`,
            borderRadius: "5px",
            backgroundColor: "white", // Set background color to cover elements underneath
            zIndex: 999, // Set z-index to cover elements underneath
          }}
        >
          {/* Your dropdown content here */}
          {data
            .filter((menuItem) =>
              isMatchingSubString(inputFieldText, menuItem.label)
            )
            .map((item) => (
              <DropDownItemBox
                isSelected={selectedItems.includes(item.itemId)}
                key={item.itemId}
                onClick={() => {
                  handleItemSelect(item.itemId);
                }}
              >
                <Typography sx={{ fontWeight: item.description ? "bold" : "" }}>
                  {item.label}
                </Typography>
                <Typography sx={{ color: Colors.LINK_TEXT_COLOR }}>
                  {item.description}
                </Typography>
              </DropDownItemBox>
            ))}
        </Box>
      )}
      {validationsErrors?.map((violation) => {
        return (
          <DescriptionTypography color={"red"}>
            <span
              style={{
                display: "inline-block",
                verticalAlign: "middle",
                marginRight: "5px",
                backgroundColor: "red",
                width: "6px",
                height: "6px",
                borderRadius: "50%",
              }}
            ></span>
            {violation}
          </DescriptionTypography>
        );
      })}
    </FlexColumnBox>
  );
};
