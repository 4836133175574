const ClustersOptions = [
    {
        id: 'f1b9708a-10e2-4d43-a527-b17e178d244e',
        name: 'Create using bigquery',
    },
    {
        id: '9c0f99ee-8b94-4b91-b2d8-b73e19c39c2f',
        name: 'Create using development'
    },
    {
        id: '0672d67e-3615-43a8-81a9-c45b7f6d02a1',
        name: 'Create using event_store_cluster'
    },
    {
        id: '19e1bb28-1d15-4cd1-953f-69b1458e9e3e',
        name: 'Create using production'
    },
    {
        id: '82328f77-eeb2-42f1-8f52-667c0e724236',
        name: 'Create using spark_on_k8s_hist_cluster'
    },
    {
        id: '6d9b6d1b-7351-47cf-8e29-0160e20a1c15',
        name: 'Create using spark_on_k8s_preview_cluster'
    }
];

export default ClustersOptions;
