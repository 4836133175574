import { ApiClient } from "../Utils/ApiClient";

export const getSourcesTypes = async () => {
  return ApiClient.sourceClient
    .getSourcesSourceSourceTypesGet()
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};
