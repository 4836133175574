import { TransformationType } from "../../Types/CreateFeatureForm";

export const FeatureTypes = [
    {
      optionId: TransformationType.EVENT_FEATURE,
      title: "Event Feature",
      description: "An aggregation of a data from a Table over rows or time, defined parametrically.",
    },
    {
      optionId: TransformationType.COMPOSITE_FEATURE,
      title: "Composite Feature",
      description: "Combine one or more other Features together with scaler functions.",
    },
    {
      optionId: TransformationType.SQL_FEATURE,
      title: "SQL Feature",
      description: "Generate multiple Features from a simple SQL statement.",
    },
  ]

export const CreationModeOptions = [
  {
    optionId: "STANDARD",
    title: "Standard",
    description: "Generate data in daily batches",
  },
  {
    optionId: "STREAMING",
    title: "Streaming",
    description:
      "Run a long-running process to continuously generate data as it arrives (Kafka only).",
  },
]