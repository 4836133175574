import { FeatureSet, Team, Doamin, ScheduleType } from "../../../../../Client/typescript-axios-client-generated";
import { Pair } from "../../../../../Components/Forms/KeyValueInput";

export interface CreateScheduleState {
  name: string;
  description: string;
  enabled: boolean;
  cluster: string;
  showAdditionalSparkProperty: boolean;
  standardOrStream: string;
  destinations: string[];
  target: string;
  branch: string[];
  featureSet: string[];
  entityPopulation: string[];
  includeMetadata: boolean;
  scheduleType: ScheduleType;
  utcTime: string;
  dataOffset: string;
  cron: string;
  dependentJob: string[];
  retryType: string;
  additionalSparkProperty: Pair[];
  addDestinationOpened: boolean;
  selectedDestination: TDestination[];
  addJobOpened: boolean;
  selectedJobs: string[];
  backOff: TBackOff;
  maxAttempts: number;
  clusterData: any;
  featureSetData: FeatureSet[];
  domains: Doamin[];
  owner: Team[];
}

export type Action =
  | { type: "SET_NAME"; payload: string }
  | { type: "SET_DESCRIPTION"; payload: string }
  | { type: "SET_ENABLED"; payload: boolean }
  | { type: "SET_CLUSTER"; payload: string }
  | { type: "SET_SHOW_ADDITIONAL_SPARK_PROPERTY"; payload: boolean }
  | { type: "SET_STANDARD_OR_STREAM"; payload: string }
  | { type: "SET_DESTINATIONS"; payload: string[] }
  | { type: "SET_TARGET"; payload: string }
  | { type: "SET_BRANCH"; payload: string[] }
  | { type: "SET_FEATURE_SET"; payload: string[] }
  | { type: "SET_ENTITY_POPULATION"; payload: string[] }
  | { type: "SET_INCLUDE_METADATA"; payload: boolean }
  | { type: "SET_SCHEDULE_TYPE"; payload: ScheduleType }
  | { type: "SET_UTC_TIME"; payload: string }
  | { type: "SET_DATA_OFFSET"; payload: string }
  | { type: "SET_CRON"; payload: string }
  | { type: "SET_DEPENDENT_JOB"; payload: string[] }
  | { type: "SET_RETRY_TYPE"; payload: string }
  | { type: "SET_ADDITIONAL_SPARK_PROPERTY"; payload: Pair[] }
  | { type: "SET_ADD_DESTINATION_OPENED"; payload: boolean }
  | { type: "SET_SELECTED_DESTINATION"; payload: TDestination[] }
  | { type: "SET_BACKOFF"; payload: TBackOff }
  | { type: "SET_MAX_ATTEMPTS"; payload: number }
  | {type: "SET_DOMAINS_DATA"; payload: Doamin[]}
  | {type: "SET_TEAMS_DATA"; payload: Team[]}
  | {type: "SET_FEATURE_SET_DATA"; payload: FeatureSet[]}
  | {type: "SET_CLUSTERS_DATA"; payload: any}
  | {type: "RESET_STATE"; payload: CreateScheduleState}

export enum ERetryType {
  DONT_RETRY = "Don't Retry",
  FIXED = "Fixed",
}

export type TBackOff = {
  hours: number;
  minutes: number;
};

export enum ETarget {
  OFFICIAL = "Official",
  BRANCH = "Branch",
}

export enum EMode {
  ERROR_IF_EXISTS = "Error If Exists",
  APPEND = "Append",
  OVERWRITE = "Overwrite",
  IGNORE = "Ignore",
}

export type TDestination = {
  destination: string | undefined;
  tableName: string;
  mode: EMode;
  allowFieldAddition: boolean;
  allowFieldRelaxation: boolean;
  partitionOverwriteMode: boolean;
  bigqueryIntermediateFormat: string;
};
