interface NameUrlMappingType {
    [key: string]: string;
  }

export const NameUrlMapping: NameUrlMappingType = {
    "/" : "Home",
    "/data-layer" : "Data Layer",
    "/governance": "Governance",
    "/governance/project": "Projects",
    "/governance/project/create-project": "Create Project",
    "/data-layer/tables": "Tables",
    "/data-layer/tables/create-table": "Create Table",
    "/data-layer/entities": "Entities",
    "/data-layer/entities/create-entity": "Create Entity",
    "/data-layer/features": "Features",
    "/data-layer/features/create-feature": "Create Feature",
    "/data-layer/entity-mappings": "Entity Mappings",
    "/data-layer/entity-mappings/create": "Create Entity Mappings",
}