import { ApiClient } from "../Utils/ApiClient";

export const getEntityMappings = async (projectName: string, branchName: string) => {
  return ApiClient.entityMappingApiClient
    .getEntityMappingsByBranchIdEntityMappingGetEntityMappingsBranchIdGet(projectName)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};
