export const WindowOptions = [
  {
    optionId: "OPEN_WINDOW",
    title: "Open Window",
    description: "Aggregate over all data preceding the Feature date.",
  },
  {
    optionId: "ROW_WINDOW",
    title: "Row Window",
    description:
      "Include a fixed number of events immediately before the Feature date.",
  },
  {
    optionId: "HOUR_WINDOW",
    title: "Hour Window",
    description:
      "Include events within a number of hours before the Feature timestamp.",
  },
  {
    optionId: "DAY_WINDOW",
    title: "Day Window",
    description:
      "Includes events within a number of days before the Feature date.",
  },
  {
    optionId: "MONTH_WINDOW",
    title: "Month Window",
    description:
      "Includes events within a number of months before the Feature date.",
  },
];
