import { Box } from "@mui/material";
import { createPortal } from "react-dom";
import { GlobalSearchType } from "..";
import { MediumSizeDarkColorTypogrphy } from "../../../Styles/CustomTypography";
import "./global-search.css";

type ResultContainerProps = {
  type: GlobalSearchType;
  value: string;
};

export const ResultContainer = ({ type, value }: ResultContainerProps) => {
  const isOpen = value.length > 0;
  if(!isOpen) {
    return null;
  }
  return createPortal(
    <Box className={`result-container ${isOpen ? "" : "hidden"}`}>
      <MediumSizeDarkColorTypogrphy>
        No Result found
      </MediumSizeDarkColorTypogrphy>
    </Box>,
    document.body
  );
};
