import React from 'react';
import { CircularProgress, Box } from '@mui/material';
import { Colors } from '../Constants';

export const Loader = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh-100px',
        flexGrow: 1
      }}
    >
      <CircularProgress sx={{color: Colors.APP_LIGHT_GREY}} size={55} />
    </Box>
  );
};