import { Typography } from "@mui/material";
import { GenericFilter } from "../../../../Components/GenericFilter/GenericFilter";
import {
  DownWardAnimatedBox,
  FlexColumnBox,
  FlexRowBox,
} from "../../../../Styles";
import { useNavigate } from "react-router-dom";
import { GenericTable } from "../../../../Packages/Table";
import { BlueButton, GreenButton, RedButton } from "../../../../Styles/Button";
import { Colors } from "../../../../Constants";
import { exportedData } from "../../../../TestingData/DataLayer";
import React, { useEffect, useMemo, useState } from "react";
import { useSelectedRow } from "../../../../Hooks/useSelectedRows";
import { useVersionControlContext } from "../../../../Hooks/useVersionControlContext";
import { ChangeRequestGetSchema } from "../../../../Client/typescript-axios-client-generated";
import { getChangeRequests } from "../../../../Services/getChangeRequests";
import { useToast } from "../../../../Hooks/useToast";
import { ToastType } from "../../../../Context/Providers/ToastProvider";
import { ChangeRequestTableHeader } from "../../../../Constants/TableHeaders/ChangeRequest";
import { mapToChangeRequestTableData } from "../../../../Utils/Mapper/ChangeRequestMapper";
import { Loader } from "../../../../Components/Loader";

export const ChangeRequests = () => {
  const [changeRequests, setChangeRequests] = useState<
    ChangeRequestGetSchema[]
  >([]);

  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const { selectAllRows, updateSelectedRow, totalRows, selectedRows } =
    useSelectedRow(exportedData.dummyData.rows);

  const versionControlContext = useVersionControlContext();

  const navigate = useNavigate();

  const toast = useToast();

  useEffect(() => {
    if (!versionControlContext?.currentProject) return;
    setDataLoading(true);
    getChangeRequests(versionControlContext?.currentProject)
      .then((data) => {
        setChangeRequests(data);
      })
      .catch((err) => {
        toast.open("Failed to fetch chnage requests data", ToastType.ERROR);
      }).finally(() => {
        setDataLoading(false);
      })
  }, [versionControlContext?.currentProject]);

  const handleViewClick = (row_id: string) => {
    navigate('/governance/change-requests/'+row_id)
  }

  const tableData = useMemo(() => {
    return mapToChangeRequestTableData(changeRequests, handleViewClick);
  }, [changeRequests])

  if(dataLoading) {
    return <Loader/>
  }

  return (
    <React.Fragment>
      <FlexRowBox sx={{ flexGrow: 1 }}>
        <GenericFilter
          globalSearchEnabled={true}
          data={exportedData.filterData.data}
        />
        <FlexColumnBox
          sx={{
            padding: "2rem",
            backgroundColor: Colors.APP_VERY_LIGHT_BLUE_COLOR,
            flexGrow: 1,
          }}
        >
          <FlexRowBox sx={{ justifyContent: "space-between" }}>
            <DownWardAnimatedBox flag={selectedRows.length > 0}>
              <FlexColumnBox>
                <FlexRowBox sx={{ gap: "5px" }}>
                  <GreenButton>Add to project</GreenButton>
                  <RedButton>Delete</RedButton>
                </FlexRowBox>
                <Typography sx={{ color: Colors.LINK_TEXT_COLOR }}>
                  {selectedRows.length} row selected
                </Typography>
              </FlexColumnBox>
            </DownWardAnimatedBox>
            <FlexColumnBox>
              <BlueButton
                onClick={() => {
                  navigate("/governance/change-requests/create");
                }}
                sx={{ height: "40px" }}
              >
                Create Change Request
              </BlueButton>
              <Typography></Typography>
            </FlexColumnBox>
          </FlexRowBox>
          <GenericTable
            rows={tableData}
            tableHeader={ChangeRequestTableHeader}
            selectAllRows={selectAllRows}
            updateSelectedRow={updateSelectedRow}
            totalRows={totalRows}
            selectedRows={selectedRows}
            hideCheckbox={true}
          />
        </FlexColumnBox>
      </FlexRowBox>
    </React.Fragment>
  );
};
