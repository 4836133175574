import { Box } from "@mui/material";
import { FlexRowBox } from "../../Styles"
import './Components/global-search.css';
import SearchIcon from '@mui/icons-material/Search';
import { DownArrowIcon } from "../icons/DownArrowIcon";
import { useState } from "react";
import { StyledMenu } from "../../Styles/MenuItem";
import { ResultContainer } from "./Components/ResultContainer";

export enum GlobalSearchType {
    ALL = 'All',
    TABLES = 'Tables',
    ENTITIES = 'Entities',
    FEATURES = 'Features',
    FEATURE_TEMPLATES = 'Feature Templates',
    FEATURE_SETS = 'Feature Sets',
    SCHEDULES = 'Schedules',
}

export const GlobalSearch = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selected, setSelected] = useState<GlobalSearchType>(GlobalSearchType.ALL);
    const [input, setInput] = useState<string>('');

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
      };    
    
    const closeMenu = () => {
        setAnchorEl(null);
    }
    const updateAnchorElement = (val: GlobalSearchType) => {
        setSelected(val);
        setAnchorEl(null);
    }

    return (
        <>
        <ResultContainer type={GlobalSearchType.ALL} value={input}/>
        <FlexRowBox className="global-search-container" >
            <Box sx={{padding: '0 8px', marginBottom: -.8}} ><SearchIcon/></Box>
            <Box className="global-search-input" ><input placeholder="Search..." className="input-field" value={input} onChange={(e) => {setInput(e.target.value)}} ></input></Box>
            <button className="select-search-type" style={{padding: '6px 12px'}} onClick={handleClick} >{selected.toString()}<DownArrowIcon/></button>
            { anchorEl && <StyledMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={closeMenu}
            transitionDuration={0}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{ style: { minWidth: 'auto', marginTop: '0.2rem', padding: '0.5rem 0' } }}
        >
                <Box>
                    <Box className = "select-item" onClick={() => {updateAnchorElement(GlobalSearchType.ALL)}} >All</Box>
                    <Box className="divider" />
                    <Box className = "select-item disable" >Data Sources</Box>
                    <Box className = "select-item" onClick={() => {updateAnchorElement(GlobalSearchType.TABLES)}} >Tables</Box>
                    <Box className = "select-item" onClick={() => {updateAnchorElement(GlobalSearchType.ENTITIES)}} >Entities</Box>
                    <Box className="divider" />
                    <Box className = "select-item disable" >Features</Box>
                    <Box className = "select-item" onClick={() => {updateAnchorElement(GlobalSearchType.FEATURES)}} >Features</Box>
                    <Box className = "select-item" onClick={() => {updateAnchorElement(GlobalSearchType.FEATURE_TEMPLATES)}} >Feature Templates</Box>
                    <Box className = "select-item" onClick={() => {updateAnchorElement(GlobalSearchType.FEATURE_SETS)}} >Feature Sets</Box>
                    <Box className = "select-item" onClick={() => {updateAnchorElement(GlobalSearchType.SCHEDULES)}} >Schedules</Box>
                </Box>
            </StyledMenu>}
        </FlexRowBox>
        </>
    )
}