import React, { useState } from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/theme-github';
import './SqlEditor.css'; // Import CSS for styling
import { Colors } from '../../Constants';

type SqlEditorProps = {
  data: string;
  onChange: (data: string) => void;
  readonly?:boolean
}

function SqlEditor({
  data,
  onChange,
  readonly
}: SqlEditorProps) {
  const [editorHeight, setEditorHeight] = useState('60px'); // Initial height for 3 lines

  const handleEditorChange = (newValue: string) => {
    // Calculate new height based on number of lines
    const newHeight = `${newValue.split('\n').length * 20 + 40}px`; // Adjust line height as needed
    setEditorHeight(newHeight);
    onChange(newValue)
  };

  return (
    <AceEditor
      mode="sql"
      theme="github"
      onChange={handleEditorChange}
      value={data}
      name="sql-editor"
      readOnly={readonly}
      editorProps={{ $blockScrolling: true, highlightActiveLine: true }} // Highlight active line
      style={{ height: editorHeight, borderRadius: '0.2rem', color: 'white', backgroundColor: Colors.DARK_THEME, border: `1px solid ${Colors.LINK_TEXT_COLOR}` }} // Set dynamic height
      setOptions={{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: false,
        autoScrollEditorIntoView: true,
      }}
    />
  );
}

export default SqlEditor;
