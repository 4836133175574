import { Box } from "@mui/material"
import { FlexColumnBox } from "../../../../../../Styles"
import { LabelTypography } from "../../../../../../Styles/CustomTypography"
import { BlueButton, HollowButton } from "../../../../../../Styles/Button"

export const ModelingTab = () => {
    return (
        <FlexColumnBox>
            <Box>
                <LabelTypography>Column Mappings</LabelTypography>
                <HollowButton>Add Column decoration</HollowButton>
            </Box>
        </FlexColumnBox>
    )
}