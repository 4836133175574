import { BaseEntityCreate } from "../Client/typescript-axios-client-generated";
import { ApiClient } from "../Utils/ApiClient";

export const createEntity = async (projectName: string, branchName: string ,body: BaseEntityCreate) => {
  return ApiClient.entityApiClient
    .createEntityEntityPost(projectName, branchName,body)
    .then((data) => {
      return Promise.resolve(data.data)
    })
    .catch((err) => {
      return Promise.reject(err)
    });
};
