import { TableBody } from "@mui/material";
import { RowsProps } from "../Types";
import { Row } from "./Row";

export const Rows = ({ rows, tableHeader, updateSelect, selectedRows, hideCheckbox, padding }: RowsProps) => {

  return (
    <TableBody>
      {rows.map((row,index) => {
        return <Row key={index} row={row} tableHeader={tableHeader} updateSelect={updateSelect} selected={selectedRows} hideCheckbox={hideCheckbox} padding={padding} />;
      })}
    </TableBody>
  );
};
