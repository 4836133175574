import styled from "@emotion/styled";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

// Define the Accordion without shadow
export const AccordianWithoutShadow = styled(Accordion)({
    boxShadow: 'none',
    transition: 'all 0.3s ease', // Smooth transition
    margin: 0, // Ensure no margin change on open/close
    padding: '4px',
    '&:before': {
        display: 'none', // Remove the default before pseudo-element
    }
});

// Optional: Define AccordionSummary and AccordionDetails if needed for further control
export const StyledAccordionSummary = styled(AccordionSummary)({
    padding: 0, // Ensure padding doesn't cause a shift
    margin: 0,
    '&.Mui-expanded': {
        minHeight: 'auto', // Ensure consistent height
    },
    '& .MuiAccordionSummary-content': {
        margin: 0, // Ensure content margin doesn't cause shift
        '&.Mui-expanded': {
            margin: 0,
        },
    },
});

export const StyledAccordionDetails = styled(AccordionDetails)<{ removeLeftPadding?: boolean }>(({ removeLeftPadding }) => ({
    padding: 5, // Apply padding based on removePadding prop
    paddingLeft: removeLeftPadding ? 0 : 5,
}));