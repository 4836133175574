import { useMemo, useState } from "react";
import { TRowProps } from "../Packages/Table/Types";

export const useSelectedRow = (data: TRowProps[]) => {
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const allRowIds = useMemo(() => {
    return data.map((val) => val.row_id);
  }, [data]);

  const updateSelectedRow = (rowId: string) => {
    let newSelectedRows;
    if (selectedRows.includes(rowId)) {
      newSelectedRows = selectedRows.filter((rId) => rId != rowId);
    } else {
      newSelectedRows = [...selectedRows, rowId];
    }
    setSelectedRows(newSelectedRows);
  };

  const selectAllRows = () => {
    if (selectedRows.length === allRowIds.length) {
      disSelectAll();
      return;
    }
    setSelectedRows(allRowIds);
  };
  const disSelectAll = () => {
    setSelectedRows([]);
  };

  return {
    selectAllRows,
    selectedRows,
    updateSelectedRow,
    totalRows: allRowIds.length,
  };
};