import { CreateChangeRequestForm } from "./CreateChangeRequestForm";

export const CreateChangeRequest = () => {


    const handleSubmit = () => {

    }

  return (
      <CreateChangeRequestForm/>
  );
};
