import { FlexColumnBox } from "../Styles";
import {
  DescriptionTypography,
  LabelTypography,
  MediumSizeLightColorBoldTypograpgy,
} from "../Styles/CustomTypography";
import { Box } from "@mui/material";
import { InputTextField } from "./Forms/InputTextField";
import { CustomSwitch } from "./CustomSwitch";
import { CustomDropDown } from "./Forms/DropDown";
import { TDomain } from "../Types/Domain";
import { TTeam } from "../Types/Teams";
import { MultipleInputTextField } from "./Forms/MultipleInputTextField";

export enum UpdateMetaInformationType {
  LABEL,
  CONTAINS_PII,
  CONTAINS_TOKEN,
  CONTAINS_USAGEDATA,
  CONTAINS_WEB_TOKEN,
  CREATOR,
  DOMAIN,
  OWNER,
  OWNER_APPROVES_USAGE,
  OWNER_REVIEW_CHANGES,
  MERGE_FILES_CSV,
  MODEL_DEPENDENCIES,
  SCHEDULE_FAILURE_EMAIL_LIST,
}

export type UpdateMetaInformationFunctionType =
  | {
      type:
        | UpdateMetaInformationType.LABEL
        | UpdateMetaInformationType.DOMAIN
        | UpdateMetaInformationType.OWNER
        | UpdateMetaInformationType.SCHEDULE_FAILURE_EMAIL_LIST;
      payload: string[];
    }
  | {
      type:
        | UpdateMetaInformationType.CONTAINS_PII
        | UpdateMetaInformationType.CONTAINS_TOKEN
        | UpdateMetaInformationType.CONTAINS_USAGEDATA
        | UpdateMetaInformationType.CONTAINS_WEB_TOKEN
        | UpdateMetaInformationType.OWNER_APPROVES_USAGE
        | UpdateMetaInformationType.OWNER_REVIEW_CHANGES
        | UpdateMetaInformationType.MERGE_FILES_CSV;
      payload: boolean;
    }
  | {
      type:
        | UpdateMetaInformationType.CREATOR
        | UpdateMetaInformationType.MODEL_DEPENDENCIES;
      payload: string;
    };

type GenericMetaInformationProps = {
  labels: string[];
  containPii: boolean;
  containToken: boolean;
  containsUsageData: boolean;
  containsWebToken: boolean;
  creator: string;
  domain: string[];
  owner: string[];
  domainsData: TDomain[];
  ownerData: TTeam[];
  ownerApproveUsage?: boolean;
  ownerReviewChanges?: boolean;
  mergeFileCsv?: boolean;
  modelDependencies?: string;
  postProcessWorkFlow?: string;
  scheduleFailureEmailList?: string[];
  updateData: (action: UpdateMetaInformationFunctionType) => void;
  readonly?: boolean;
};

export const GenericMetaInformation = ({
  labels,
  containPii,
  containToken,
  containsWebToken,
  containsUsageData,
  creator,
  domain,
  owner,
  ownerData,
  domainsData,
  ownerApproveUsage,
  ownerReviewChanges,
  mergeFileCsv,
  modelDependencies,
  scheduleFailureEmailList,
  updateData,
  readonly,
}: GenericMetaInformationProps) => {
  const updateLabels = (labels: string[]) => {
    updateData({
      type: UpdateMetaInformationType.LABEL,
      payload: labels,
    });
  };

  return (
    <FlexColumnBox sx={{ gap: "1rem" }}>
      <Box>
        <LabelTypography>Labels</LabelTypography>
        <MultipleInputTextField
          enteredValues={labels}
          onChange={updateLabels}
          readonly={readonly}
        />
      </Box>
      <Box>
        <LabelTypography>CONTAINS_PII</LabelTypography>
        <CustomSwitch
          checked={containPii}
          onChange={() => {
            if (readonly) return;
            updateData({
              type: UpdateMetaInformationType.CONTAINS_PII,
              payload: !containPii,
            });
          }}
        />
      </Box>
      <Box>
        <LabelTypography>CONTAINS_TOKEN</LabelTypography>
        <DescriptionTypography>
          Attribute to identify if object contains tokenised data
        </DescriptionTypography>
        <CustomSwitch
          checked={containToken}
          onChange={() => {
            if (readonly) return;
            updateData({
              type: UpdateMetaInformationType.CONTAINS_TOKEN,
              payload: !containToken,
            });
          }}
        />
      </Box>
      <Box>
        <LabelTypography>CONTAINS_USAGEDATA</LabelTypography>
        <DescriptionTypography sx={{ maxWidth: "400px" }}>
          This needs to be marked true: if this TABLE/VIEW has at least one
          field having usage data. if this FEATURE uses a field having usage
          data if this FEATURE SET/SCHEDULE/VIEW MATERIALISATION at least one
          field having usage data
        </DescriptionTypography>
        <CustomSwitch
          checked={containsUsageData}
          onChange={() => {
            if (readonly) return;
            updateData({
              type: UpdateMetaInformationType.CONTAINS_USAGEDATA,
              payload: !containsUsageData,
            });
          }}
        />
      </Box>
      <Box>
        <LabelTypography>
          CONTAINS_WEB_COOKIE_OR_APP_TRACKED_DATA
        </LabelTypography>
        <CustomSwitch
          checked={containsWebToken}
          onChange={() => {
            if (readonly) return;
            updateData({
              type: UpdateMetaInformationType.CONTAINS_WEB_TOKEN,
              payload: !containsWebToken,
            });
          }}
        />
      </Box>
      <Box>
        <LabelTypography>CREATOR</LabelTypography>
        <DescriptionTypography>
          Definition creator (email address)
        </DescriptionTypography>
        <InputTextField
          onChange={(event) => {
            if (readonly) return;
            updateData({
              type: UpdateMetaInformationType.CREATOR,
              payload: event.target.value,
            });
          }}
          value={creator}
          violationArray={[]}
        />
      </Box>
      <Box>
        <LabelTypography>DOMAIN</LabelTypography>
        {readonly && <MediumSizeLightColorBoldTypograpgy>
          {domainsData.find(dom => dom.id === domain[0])?.name}
        </MediumSizeLightColorBoldTypograpgy>}
        {!readonly && (
          <CustomDropDown
            data={domainsData.map((currDomain) => {
              return {
                itemId: currDomain.id,
                label: currDomain.name,
              };
            })}
            multiple={false}
            selectedItems={domain}
            updateSelectedItems={(selectedItems) => {
              updateData({
                type: UpdateMetaInformationType.DOMAIN,
                payload: selectedItems,
              });
            }}
            validationsErrors={[]}
          />
        )}
      </Box>
      <Box>
        <LabelTypography>OWNER</LabelTypography>
        {readonly && <MediumSizeLightColorBoldTypograpgy>
          {ownerData.find(own => own.id === owner[0])?.name}
        </MediumSizeLightColorBoldTypograpgy>}
        {!readonly && <CustomDropDown
          data={ownerData.map((currOwner) => {
            return {
              itemId: currOwner.id,
              label: currOwner.name,
            };
          })}
          multiple={false}
          selectedItems={owner}
          updateSelectedItems={(selectedItems) => {
            updateData({
              type: UpdateMetaInformationType.OWNER,
              payload: selectedItems,
            });
          }}
          validationsErrors={[]}
        />}
      </Box>
      {ownerApproveUsage !== undefined && (
        <Box>
          <LabelTypography>Owner Approved Usage</LabelTypography>
          <DescriptionTypography>
            If selected, the Owner will be added as an approver to change
            Requests when:
          </DescriptionTypography>
          <DescriptionTypography>
            -Table is used by a feature - Feature is used in a Feature set
          </DescriptionTypography>
          <CustomSwitch
            checked={ownerApproveUsage}
            onChange={() => {
              updateData({
                type: UpdateMetaInformationType.OWNER_APPROVES_USAGE,
                payload: !ownerApproveUsage,
              });
            }}
          />
        </Box>
      )}
      {ownerReviewChanges !== undefined && (
        <Box>
          <LabelTypography>Owner Reviews Changes</LabelTypography>
          <DescriptionTypography>
            If selected, the Owner will be added as an approver to Change
            Requests when the Feature, Table or Feature Set is edited
          </DescriptionTypography>
          <CustomSwitch
            checked={ownerReviewChanges}
            onChange={() => {
              updateData({
                type: UpdateMetaInformationType.OWNER_REVIEW_CHANGES,
                payload: !ownerReviewChanges,
              });
            }}
          />
        </Box>
      )}
      {mergeFileCsv !== undefined && (
        <Box>
          <LabelTypography>MERGE_FILES_CSV</LabelTypography>
          <DescriptionTypography>
            Schedule only attribute for defining wether outputted csv parts
            should be merged into fewer parts close to 1gb in size
          </DescriptionTypography>
          <CustomSwitch
            checked={mergeFileCsv}
            onChange={() => {
              updateData({
                type: UpdateMetaInformationType.MERGE_FILES_CSV,
                payload: !mergeFileCsv,
              });
            }}
          />
        </Box>
      )}
      {modelDependencies !== undefined && (
        <Box>
          <InputTextField
            onChange={(e: any) => {
              updateData({
                type: UpdateMetaInformationType.MODEL_DEPENDENCIES,
                payload: e.target.value,
              });
            }}
            value={modelDependencies}
            violationArray={[]}
            title="MODEL DEPENDENCIES"
            description="ML models dependent in this Schedule"
          />
        </Box>
      )}
      {scheduleFailureEmailList && (
        <Box>
          <LabelTypography>SCHEDULE_FAILURE_EMAIL_LIST</LabelTypography>
          <MultipleInputTextField
            enteredValues={scheduleFailureEmailList}
            onChange={(emails: string[]) => {
              updateData({
                type: UpdateMetaInformationType.SCHEDULE_FAILURE_EMAIL_LIST,
                payload: emails,
              });
            }}
            readonly={readonly}
          />
        </Box>
      )}
    </FlexColumnBox>
  );
};
