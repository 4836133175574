import React, { useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import CustomTab from "../../../../../Components/Tab";
import { useCreateFeatureForm } from "../../../../../Hooks/useCreateFeature";
import { FlexColumnBox, FlexRowBox } from "../../../../../Styles";
import { CreateFeature } from "./CreateFeature";
import { MetaInformation } from "./MetaInformation";
import { Colors } from "../../../../../Constants";
import { DataExplorer } from "./DataExplorer";
import {
  GreyButton,
  GreenButton,
  BlueButtonWithoutPadding,
} from "../../../../../Styles/Button";
import { DownArrowIcon } from "../../../../../Components/icons/DownArrowIcon";
import { StyledMenu } from "../../../../../Styles/MenuItem";
import {
  DescriptionTypography,
  HeadingTypography,
  LabelTypography,
} from "../../../../../Styles/CustomTypography";
import SqlEditor from "../../../../../Components/SqlEditor";
import { CustomSwitch } from "../../../../../Components/CustomSwitch";
import { PreviewFilter } from "./PreviewFilter";

import { TransformationType } from "../../../../../Types/CreateFeatureForm";
import { createFeature } from "../../../../../Services/createFeature";
import {
  EventFeatureWindowType,
  TransformationType as TransformationTypeClient,
} from "../../../../../Client/typescript-axios-client-generated";
import { useVersionControlContext } from "../../../../../Hooks/useVersionControlContext";
import { useToast } from "../../../../../Hooks/useToast";
import { ToastType } from "../../../../../Context/Providers/ToastProvider";
import { getClusters } from "../../../../../Components/GetClusters";

export const CreateFeatureTabs = () => {
  const { createFeatureState, dispatch } = useCreateFeatureForm();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [previewFilterOpen, setPreviewFilterOpen] = useState<boolean>(false);

  const versionControlContext = useVersionControlContext();
  const toast = useToast();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const validateFields = (): boolean => {
    let isValidationFailed = false;
    if (!createFeatureState.name) {
      dispatch({
        type: "UPDATE_VALIDATION_ERRORS",
        payload: {
          name: ["name is required"],
        },
      });
      isValidationFailed = true;
    }

    if (
      createFeatureState.transformationType === TransformationType.EVENT_FEATURE
    ) {
      const eventFeature = createFeatureState[TransformationType.EVENT_FEATURE];
      if (!eventFeature.table || eventFeature.table.length === 0) {
        dispatch({
          type: "UPDATE_EVENT_FEATURE_VALIDATION_ERROR",
          payload: {
            table: ["Table is required to create this feature"],
          },
        });
        isValidationFailed = true;
      }
      if (!eventFeature.aggregation || eventFeature.aggregation.length === 0) {
        dispatch({
          type: "UPDATE_EVENT_FEATURE_VALIDATION_ERROR",
          payload: {
            aggregation: [
              "aggregation function need to specify to create event feature",
            ],
          },
        });
        isValidationFailed = true;
      }
    }

    if (
      createFeatureState.transformationType ===
      TransformationType.COMPOSITE_FEATURE
    ) {
      const compositeFeature =
        createFeatureState[TransformationType.COMPOSITE_FEATURE];
      if (!compositeFeature.entity || compositeFeature.entity.length === 0) {
        dispatch({
          type: "UPDATE_COMPOSITE_FEATURE_VALIDATION_ERROR",
          payload: {
            entity: ["Entity is required"],
          },
        });
        isValidationFailed = true;
      }
      if (
        !compositeFeature.featureDependencies ||
        compositeFeature.featureDependencies.length === 0
      ) {
        dispatch({
          type: "UPDATE_COMPOSITE_FEATURE_VALIDATION_ERROR",
          payload: {
            feature_dependencies: [
              "feature dependencies is required to create composite feature",
            ],
          },
        });
        isValidationFailed = true;
      }
    }

    if (
      createFeatureState.transformationType === TransformationType.SQL_FEATURE
    ) {
      const sqlFeature = createFeatureState[TransformationType.SQL_FEATURE];
      if (!sqlFeature.sources || sqlFeature.sources.length === 0) {
        dispatch({
          type: "UPDATE_SQL_FEATURE_VALIDATION_ERROR",
          payload: {
            sources: ["Source tables is required to create sql base feature"],
          },
        });
        isValidationFailed = true;
      }
    }

    return !isValidationFailed;
  };

  const handleSubmit = () => {
    if (!validateFields()) {
      return;
    }

    setLoading(true);
    if (versionControlContext === undefined) return;

    createFeature(
      versionControlContext.currentProjectName,
      versionControlContext.currentBranchName,
      {
        name: createFeatureState.name,
        transformation_type: TransformationTypeClient.EventFeature,
        event_table: createFeatureState.transformationType ===
          TransformationType.EVENT_FEATURE
          ? createFeatureState[TransformationType.EVENT_FEATURE].table[0]
          : null,
        event_aggregation: createFeatureState.transformationType ===
          TransformationType.EVENT_FEATURE
          ? createFeatureState[TransformationType.EVENT_FEATURE]
            .aggregation[0]
          : null,
        aggregation_expression: createFeatureState.transformationType ===
          TransformationType.EVENT_FEATURE
          ? createFeatureState[TransformationType.EVENT_FEATURE]
            .aggregationExpression
          : createFeatureState.transformationType ===
            TransformationType.COMPOSITE_FEATURE
            ? createFeatureState[TransformationType.COMPOSITE_FEATURE]
              .aggregationExpression
            : null,
        event_additional_filter: createFeatureState.transformationType ===
          TransformationType.EVENT_FEATURE
          ? createFeatureState[TransformationType.EVENT_FEATURE]
            .additionalFilterValue
          : null,
        event_post_aggregation_filter: createFeatureState.transformationType ===
          TransformationType.EVENT_FEATURE
          ? createFeatureState[TransformationType.EVENT_FEATURE]
            .postAggregationExpressionValue
          : null,
        event_window_type: EventFeatureWindowType.DayWindow, //createFeatureState[TransformationType.EVENT_FEATURE].windowType,
        event_window_size: createFeatureState[TransformationType.EVENT_FEATURE].windowSize,
        composite_entity_id: createFeatureState.transformationType ===
          TransformationType.COMPOSITE_FEATURE
          ? createFeatureState[TransformationType.COMPOSITE_FEATURE].entity[0]
          : null,
        entity_restrictions: createFeatureState.transformationType ===
          TransformationType.EVENT_FEATURE
          ? createFeatureState[TransformationType.EVENT_FEATURE]
            .entityRestriction
          : [],
        dependencies_mapping: createFeatureState.transformationType ===
          TransformationType.COMPOSITE_FEATURE
          ? createFeatureState[TransformationType.COMPOSITE_FEATURE]
            .featureDependencies
          : [],
        sources_mapping: createFeatureState.transformationType ===
          TransformationType.SQL_FEATURE
          ? createFeatureState[TransformationType.SQL_FEATURE].sources
          : [],
        attributes_data: Object.keys(createFeatureState.attributeData).map((key) => {
          return {
            attribute_id: key,
            value: createFeatureState.attributeData[key],
          }
        })
      }
    )
      .then((data) => {
        toast.open("Feature is created successfully", ToastType.SUCCESS);
      })
      .catch((err) => {
        toast.open("Failed to create toast", ToastType.ERROR);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChooseCluster = (created_using: string) => {
    setAnchorEl(null);
    handleSubmit();
  };

  return (
    <FlexColumnBox sx={{ flexGrow: 1 }}>
      <DropDownMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        minWidth={""}
        children={getClusters(handleChooseCluster)}
      />
      <FlexRowBox
        sx={{
          justifyContent: "space-between",
          borderBottom: `1px solid ${Colors.SIDE_LINK_HOVER_COLOR}`,
          padding: "1rem",
        }}
      >
        <HeadingTypography>Create Feature</HeadingTypography>
        <FlexRowBox>
          <BlueButtonWithoutPadding>
            <BlueButtonWithoutPadding
              sx={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: "0",
                borderRight: "1px solid white",
              }}
              disabled={loading}
              startIcon={
                loading && <CircularProgress size={20} color="inherit" />
              }
              onClick={handleSubmit}
            >
              Create Feature
            </BlueButtonWithoutPadding>
            <BlueButtonWithoutPadding
              disabled={loading}
              onClick={handleClick}
              sx={{ padding: 0, minWidth: 0 }}
            >
              <DownArrowIcon />
            </BlueButtonWithoutPadding>
          </BlueButtonWithoutPadding>
        </FlexRowBox>
      </FlexRowBox>
      <FlexRowBox sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            padding: "2rem",
            borderRight: `1px solid ${Colors.SIDE_LINK_HOVER_COLOR}`,
          }}
        >
          <CustomTab
            data={[
              {
                tabText: "Definition",
                tabPanel: (
                  <CreateFeature
                    createFeatureState={createFeatureState}
                    dispatch={dispatch}
                  />
                ),
              },
              {
                tabText: "Meta Information",
                tabPanel: (
                  <MetaInformation
                    createFeatureState={createFeatureState}
                    dispatch={dispatch}
                  />
                ),
              },
            ]}
          />
        </Box>
        <FlexColumnBox
          sx={{
            padding: "2rem",
            flexGrow: "1",
            backgroundColor: Colors.APP_VERY_LIGHT_BLUE_COLOR,
          }}
        >
          {(createFeatureState.transformationType ===
            TransformationType.COMPOSITE_FEATURE ||
            createFeatureState.transformationType ===
              TransformationType.EVENT_FEATURE) && (
            <Box sx={{ position: "sticky", top: "100px" }}>
              <CustomTab
                data={[
                  {
                    tabText: "Data Explorer",
                    tabPanel: <DataExplorer />,
                  },
                  {
                    tabText: "Preview",
                    tabPanel: (
                      <PreviewFilter
                        createFeatureState={createFeatureState}
                        dispatch={dispatch}
                      />
                    ),
                  },
                  {
                    tabText: "Sample",
                    tabPanel: (
                      <MetaInformation
                        createFeatureState={createFeatureState}
                        dispatch={dispatch}
                      />
                    ),
                  },
                  {
                    tabText: "Debug",
                    tabPanel: (
                      <MetaInformation
                        createFeatureState={createFeatureState}
                        dispatch={dispatch}
                      />
                    ),
                  },
                ]}
              />
            </Box>
          )}
          {createFeatureState.transformationType ===
            TransformationType.SQL_FEATURE && (
            <FlexColumnBox sx={{ gap: "1rem" }}>
              <Box>
                <LabelTypography>Expression</LabelTypography>
                <DescriptionTypography>
                  Enter an SQL expression here to elaborate into features
                </DescriptionTypography>
                <DescriptionTypography>
                  To drive the expression and see the features generated,
                  'Extract'
                </DescriptionTypography>
                <GreyButton>Extract Features</GreyButton>
              </Box>
              <br />
              <SqlEditor
                data={""}
                onChange={function (data: string): void {
                  throw new Error("Function not implemented.");
                }}
              />
              <GreyButton
                onClick={() => {
                  setPreviewFilterOpen((prev) => !prev);
                }}
              >
                Preview Filters
              </GreyButton>
              {previewFilterOpen && (
                <Box sx={{ backgroundColor: "white", padding: "1rem" }}>
                  <LabelTypography>Use Preview Samples</LabelTypography>
                  <DescriptionTypography>
                    Load preview samples for tables instead of the original
                    table. This usually makes queries faster, but might lead to
                    inconsistencies when performing joins.
                  </DescriptionTypography>
                  <CustomSwitch />
                </Box>
              )}
              <GreenButton>Execute Statement</GreenButton>
            </FlexColumnBox>
          )}
        </FlexColumnBox>
      </FlexRowBox>
    </FlexColumnBox>
  );
};

type CreateFeatureMenuProps = {
  anchorEl: any;
  handleClose: () => void;
  minWidth?: string;
  children: React.ReactNode;
};

export const DropDownMenu = ({
  anchorEl,
  handleClose,
  minWidth,
  children,
}: CreateFeatureMenuProps) => {
  return (
    <StyledMenu
      transitionDuration={0}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      PaperProps={{
        style: { minWidth: minWidth || "auto", marginTop: "0.2rem" },
      }}
    >
      {children}
    </StyledMenu>
  );
};
