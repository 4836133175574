import { Box, Typography } from "@mui/material";
import {
  FlexColumnBox,
  FlexRowBox,
  UnderLineOnHoverNavLink,
} from "../../../../../Styles";
import {
  FeatureSetTableDescription,
  LabelTypography,
  MediumSizeDarkColorTypogrphy,
  TableTypography,
} from "../../../../../Styles/CustomTypography";
import { useMemo, useState } from "react";
import { FeatureSet } from "../../../../../Client/typescript-axios-client-generated";
import { Colors } from "../../../../../Constants";
import { GreyButton, RedButton } from "../../../../../Styles/Button";
import SearchIcon from "@mui/icons-material/Search";

import { GenericTable } from "../../../../../Packages/Table";
import { InputTextField } from "../../../../../Components/Forms/InputTextField";
import { TRowProps } from "../../../../../Packages/Table/Types";

type FeatureSetQuickViewProps = {
  data?: FeatureSet;
};

export const FeatureSetQuickView = ({ data }: FeatureSetQuickViewProps) => {
  const [searchFeatures, setSearchFeature] = useState<string>("");
  const [showSearchFeature, setShowSearchFeature] = useState<boolean>(false);

  const selectedFeatureTableData: TRowProps[] = useMemo(() => {
    if (!data) return [];
    return data.features.map((feature) => {
      return {
        row_id: feature.name,
        data: {
          name: {
            column_id: feature.name,
            text: feature.name ?? "",
          },
          description: {
            column_id: "description",
            text: feature.description ?? "",
            buttonElement: FeatureSetTableDescription,
          },
          quickLook: {
            column_id: "quickLook",
            text: "Quick Look",
            onClickHandler: () => {},
            buttonElement: GreyButton,
          },
        },
      };
    });
  }, [data]);

  const tableHead = [
    {
      column_id: "name",
      label: "",
      isSortable: "",
      visible: true,
    },
    {
      column_id: "description",
      label: "",
      isSortable: "",
      visible: true,
    },
    {
      column_id: "quickLook",
      label: "",
      isSortable: "",
      visible: true,
    },
    {
      column_id: "moreOptions",
      label: "",
      isSortable: "",
      visible: false,
    },
  ];

  return (
    <FlexColumnBox sx={{padding: '1rem .5rem', gap: '1rem'}} >
      <Box>
        <LabelTypography>Name</LabelTypography>
        <MediumSizeDarkColorTypogrphy>
          {data?.name}
        </MediumSizeDarkColorTypogrphy>
      </Box>
      <Box>
        <LabelTypography>Description</LabelTypography>
        <MediumSizeDarkColorTypogrphy>
          {data?.description}
        </MediumSizeDarkColorTypogrphy>
      </Box>
      <Box>
        <LabelTypography>Entity</LabelTypography>
        <UnderLineOnHoverNavLink
          style={{ color: Colors.APP_BLUE_COLOR }}
          to={""}
        >
          account_id
        </UnderLineOnHoverNavLink>
      </Box>
      <Box sx={{ paddingTop: "1rem" }}>
        <Typography sx={{ fontWeight: "600", fontSize: "22px" }}>
          Features in feature set {data?.features?.length}
        </Typography>
        <FlexRowBox
          sx={{ gap: 1, alignItems: "center", justifyContent: "flex-end" }}
        >
          {showSearchFeature && (
            <InputTextField
              onChange={(event) => {
                setSearchFeature(event.target.value);
              }}
              value={searchFeatures}
              violationArray={[]}
              placeholder="search"
            />
          )}
          <Box
            onClick={() => {
              setShowSearchFeature((prev) => !prev);
            }}
          >
            <SearchIcon />
          </Box>
        </FlexRowBox>
        <GenericTable
          rows={
            selectedFeatureTableData.length < 1
              ? [
                  {
                    row_id: "1",
                    data: {
                      info: {
                        column_id: "info",
                        text: "Features in the feature set will show here",
                        buttonElement: TableTypography,
                      },
                    },
                  },
                ]
              : selectedFeatureTableData
          }
          tableHeader={
            selectedFeatureTableData.length < 1
              ? [
                  {
                    column_id: "info",
                    label: "",
                    isSortable: "",
                    visible: true,
                  },
                ]
              : tableHead
          }
          selectAllRows={() => {}}
          updateSelectedRow={() => {}}
          totalRows={0}
          selectedRows={[]}
          hidePagination={true}
          hideHeader={true}
          hideCheckbox={selectedFeatureTableData.length < 1}
        />
      </Box>
    </FlexColumnBox>
  );
};
