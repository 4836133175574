import { Box } from "@mui/material"
import { FlexColumnBox } from "../../../../../../../../Styles"
import { LabelTypography, SmallSizeLightColorTypography } from "../../../../../../../../Styles/CustomTypography"
import { useState } from "react"

export const FeatureStats = () => {

    const [loading, setLoading] = useState<boolean>(true);
    const [dataLoadFailed, setDataLoadFailed] = useState<boolean>(false);


    return (
        <FlexColumnBox>
            Feature Stats
        </FlexColumnBox>
    )
}