import { Route, Routes } from "react-router-dom";
import { Datawarehouse } from "../Pages/DataLayer/Datawarehouse";
import { Tables } from "../Pages/DataLayer/Components/Tables";
import { Entities } from "../Pages/DataLayer/Components/Entities";
import { NavBar } from "./Navbar/NavBar";
import { CreateTable } from "../Pages/DataLayer/Components/Tables/Components/CreateTable";
import { Governance } from "../Pages/Governance";
import { CreateProject } from "../Pages/Governance/Components/Project.tsx/Components/CreateProject";
import { ProjectsTable } from "../Pages/Governance/Components/Project.tsx/Components/ProjectsTable";
import { CreateEntity } from "../Pages/DataLayer/Components/Entities/Components/CreateEntity";
import { Features } from "../Pages/DataLayer/Components/Features";
import { CreateFeatureTabs } from "../Pages/DataLayer/Components/Features/Components/CreateFeatureTabs";
import { CreateEntityMapping } from "../Pages/DataLayer/Components/EntityMapping/CreateEntityMapping";
import { EntityMapping } from "../Pages/DataLayer/Components/EntityMapping";
import { ViewEntityMapping } from "../Pages/DataLayer/Components/EntityMapping/ViewEntityMapping";
import { FeatureSet } from "../Pages/DataLayer/Components/FeatureSet";
import { CreateFeatureSet } from "../Pages/DataLayer/Components/FeatureSet/Components/CreateFeatureSet";
import { ChangeRequests } from "../Pages/Governance/Components/ChangeRequest";
import { CreateChangeRequest } from "../Pages/Governance/Components/ChangeRequest/Components/CreateChangeRequest";
import { ViewChangeRequest } from "../Pages/Governance/Components/ChangeRequest/Components/ViewChangeRequest";
import { BranchesTable } from "../Pages/Governance/Components/Branch.tsx/Components/BranchTable";
import { CreateBranch } from "../Pages/Governance/Components/Branch.tsx/Components/CreateBranchPage";
import { ChangeLog } from "../Pages/Governance/Components/ChangeLogs";
import { ChangeLogDetail } from "../Pages/Governance/Components/ChangeLogs/ChangeLog";
import { Schedules } from "../Pages/Schedules";
import { CreateSchedule } from "../Pages/Schedules/Components/CreateSchedule";
import { FeatureGeneration } from "../Pages/Schedules/Components/FeatureGeneration";
import { ViewFeatureGenerationSchedule } from "../Pages/Schedules/Components/FeatureGeneration/ViewFeatureGenerationSchedule";
import { Configuration } from "../Pages/Configuration";
import { Sources } from "../Pages/Configuration/Components/Sources";
import { CreateSource } from "../Pages/Configuration/Components/Sources/CreateSource";
import { Destination } from "../Pages/Configuration/Components/Destination";
import { CreateDestination } from "../Pages/Configuration/Components/Destination/CreateDestination";
import { LabelsAndAttributes } from "../Pages/Configuration/Components/LabelAndAttributes";
import { CreateLabelsOrAttributes } from "../Pages/Configuration/Components/LabelAndAttributes/CreateLabelsOrAttributes/CreateLabelsOrAttributes";
import { CreateOrchestrator } from "../Pages/Configuration/Components/Orchestrator/Components/CreateOrcherstrator";
import { Orchestrators } from "../Pages/Configuration/Components/Orchestrator";
import { FeatureDetail } from "../Pages/DataLayer/Components/Features/Components/FeatureDetail";
import { FeatureDataLineage } from "../Pages/DataLayer/Components/Features/Components/FeatureDetail/Components/Data Lineage";
import { FeatureHistory } from "../Pages/DataLayer/Components/Features/Components/FeatureDetail/Components/History";
import { FeaturePreview } from "../Pages/DataLayer/Components/Features/Components/FeatureDetail/Components/Preview";
import { FeatureStats } from "../Pages/DataLayer/Components/Features/Components/FeatureDetail/Components/Statistics";
import { FeatureDetailPage } from "../Pages/DataLayer/Components/Features/Components/FeatureDetail/Components/Details";
import { usePermissionContext } from "../Hooks/usePermissionContext";
import React from "react";
import PermissionDenied from "./PermissionDenied";

export const RouteHandler = () => {
  const permissionContext = usePermissionContext();

  const checkPermission = (
    component: React.ReactNode,
    isPermission: boolean | null | undefined
  ) => {
    if (isPermission) {
      return component;
    } else {
      return <PermissionDenied />;
    }
  };

  return (
    <Routes>
      <Route path="/" element={<NavBar />}>
        <Route path="data-layer" element={<Datawarehouse />}>
          <Route
            path="tables/create-table"
            element={checkPermission(
              <CreateTable />,
              permissionContext?.permissions.createTable
            )}
          />
          <Route path="tables" element={<Tables />} />
          <Route path="entities" element={<Entities />} />
          <Route
            path="entities/create-entity"
            element={checkPermission(
              <CreateEntity />,
              permissionContext?.permissions.createEntity
            )}
          />
          <Route path="entities-mapping" element={<Entities />} />
          <Route path="features" element={<Features />} />
          <Route
            path="features/create-feature"
            element={checkPermission(
              <CreateFeatureTabs />,
              permissionContext?.permissions.createFeature
            )}
          />
          <Route path="features/:featureId" element={<FeatureDetail />}>
            <Route path="" element={<FeatureDetailPage />} />
            <Route path="lineage" element={<FeatureDataLineage />} />
            <Route path="history" element={<FeatureHistory />} />
            <Route path="preview" element={<FeaturePreview />} />
            <Route path="stats" element={<FeatureStats />} />
          </Route>
          <Route path="entity-mappings" element={<EntityMapping />} />
          <Route
            path="entity-mappings/create"
            element={checkPermission(
              <CreateEntityMapping />,
              true
            )}
          />
          <Route
            path="entity-mappings/:entityMappingId"
            element={<ViewEntityMapping />}
          />
          <Route path="feature-sets" element={<FeatureSet />} />
          <Route
            path="feature-sets/create"
            element={checkPermission(
              <CreateFeatureSet />,
              true
            )}
          />
        </Route>
        <Route path="schedules" element={<Schedules />}>
          <Route
            path="feature-generation"
            element={<FeatureGeneration />}
          />
          <Route
            path="feature-generation/create-schedule"
            element={checkPermission(
              <CreateSchedule />,
              permissionContext?.permissions.createSchedule
            )}
          />
          <Route
            path="feature-generation/:scheduleId"
            element={<ViewFeatureGenerationSchedule />}
          />
        </Route>
        <Route path="workbooks" element={<Datawarehouse />}>
          <Route path="tables" element={<Tables />} />
          <Route path="entities" element={<Entities />} />
          <Route path="tables" element={<Entities />} />
        </Route>
        <Route path="governance" element={<Governance />}>
          <Route
            path="projects"
            element={<ProjectsTable />}
          />
          <Route
            path="projects/create"
            element={checkPermission(
              <CreateProject />,
              permissionContext?.permissions.createProject
            )}
          />
          <Route path="branches" element={<BranchesTable />} />
          <Route
            path="branches/create"
            element={checkPermission(
              <CreateBranch />,
              permissionContext?.permissions.createBranch
            )}
          />
          <Route path="branch" />
          <Route path="change-requests" element={<ChangeRequests />} />
          <Route
            path="change-requests/create"
            element={checkPermission(
              <CreateChangeRequest />,
              true
            )}
          />
          <Route
            path="change-requests/:changeRequestId"
            element={<ViewChangeRequest />}
          />
          <Route path="commits" element={<ChangeLog />} />
          <Route path="commits/:commitId" element={<ChangeLogDetail />} />
        </Route>
        <Route path="configuration" element={<Configuration />}>
          <Route path="sources" element={<Sources />} />
          <Route
            path="sources/create"
            element={checkPermission(
              <CreateSource />,
              permissionContext?.permissions.createSource
            )}
          />
          <Route path="destinations" element={<Destination />} />
          <Route
            path="destinations/create"
            element={checkPermission(
              <CreateDestination />,
              permissionContext?.permissions.createDestination
            )}
          />
          <Route
            path="labels-and-attributes"
            element={<LabelsAndAttributes />}
          />
          <Route
            path="labels-and-attributes/create"
            element={checkPermission(
              <CreateLabelsOrAttributes />,
              true
            )}
          />
          <Route path="orchestrators" element={<Orchestrators />} />
          <Route
            path="orchestrators/create"
            element={checkPermission(
              <CreateOrchestrator />,
              permissionContext?.permissions.createOrchestrator
            )}
          />
        </Route>
      </Route>
    </Routes>
  );
};
