import { useMemo } from "react";
import { CommitGetSchema } from "../../../../Client/typescript-axios-client-generated";
import { TRowProps } from "../../../../Packages/Table/Types";
import { formattedReadableDate } from "../../../../Utils/dateTimeUtils";
import { BlueButton, GreenButton } from "../../../../Styles/Button";
import { useToast } from "../../../../Hooks/useToast";
import {
  DownWardAnimatedBox,
  FlexColumnBox,
  FlexRowBox,
} from "../../../../Styles";
import { LightColorTypography } from "../../../../Styles/CustomTypography";
import { GenericTable } from "../../../../Packages/Table";
import { CommitTableHeader } from "../../../../Constants/TableHeaders/Commit";
import { useSelectedRow } from "../../../../Hooks/useSelectedRows";
import { ToastType } from "../../../../Context/Providers/ToastProvider";
import { useNavigate } from "react-router-dom";

type CommitsTableProps = {
  commits: CommitGetSchema[];
};

export const CommitsTable = ({ commits }: CommitsTableProps) => {
  const navigate = useNavigate();
  const commitsTableData: TRowProps[] = useMemo(() => {
    return commits.map((commit) => {
      return {
        row_id: commit.id,
        data: {
          id: {
            column_id: "id",
            text: commit.id,
          },
          author: {
            column_id: "author",
            text: commit.created_by ?? "",
          },
          description: {
            column_id: "description",
            text: commit.description,
          },
          created_at: {
            column_id: "created_at",
            text: formattedReadableDate(commit.created_at) ?? "",
          },
          show_changes: {
            column_id: "show_changes",
            text: "Show Changes",
            buttonElement: BlueButton,
            onClickHandler: (rowId: string) => { navigate('/governance/commits/'+commit.id) },
          },
        },
      };
    });
  }, [commits]);

  const { selectAllRows, updateSelectedRow, totalRows, selectedRows } =
    useSelectedRow(commitsTableData);
  const toast = useToast();

  const handleCompareTwoCommits = () => {
    if (selectedRows.length != 2) {
      toast.open("You must select 2 commits for a comparison", ToastType.ERROR);
    }
  };

  return (
    <FlexColumnBox>
      <FlexRowBox sx={{ justifyContent: "flex-end" }}>
        {selectedRows.length > 0 && (
          <DownWardAnimatedBox flag={selectedRows.length > 0}>
            <FlexColumnBox>
              <GreenButton onClick={handleCompareTwoCommits}>
                Compare 2 Commits
              </GreenButton>
              <LightColorTypography sx={{ padding: "8px 0" }}>
                {selectedRows.length} Rows selected
              </LightColorTypography>
            </FlexColumnBox>
          </DownWardAnimatedBox>
        )}
      </FlexRowBox>
      <GenericTable
        rows={commitsTableData}
        tableHeader={CommitTableHeader}
        selectAllRows={selectAllRows}
        updateSelectedRow={updateSelectedRow}
        totalRows={totalRows}
        selectedRows={selectedRows}
        hidePagination={true}
      />
    </FlexColumnBox>
  );
};
