import { useEffect, useState } from "react";
import { Doamin } from "../../../Client/typescript-axios-client-generated";
import { useVersionControlContext } from "../../useVersionControlContext";
import { getAllDomains } from "../../../Services/getDomains";

export const useGetDomains = () => {
  const [domains, setDomains] = useState<Doamin[]>([]);
  const versionControlContext = useVersionControlContext();

  const updateDomains = (data: Doamin[]) => {
    setDomains(data);
  };

  useEffect(() => {
    if (!versionControlContext?.currentBranch) return;
    getAllDomains()
      .then((data) => {
        setDomains(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [versionControlContext?.currentBranch]);

  return {
    domains,
    updateDomains
  };
};
