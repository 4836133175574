import { ApiClient } from "../Utils/ApiClient";

export const deleteFeatureSetById = async (
  projectName: string,
  branchName: string,
  featureSetId: string
) => {
  ApiClient.featureSetClient
    .deleteFeatureSetFeatureSetFeatureSetIdDelete(
      projectName,
      branchName,
      featureSetId
    )
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};
