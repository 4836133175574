import SideBar from "./Components/SideBar";
import { FlexRowBox } from "../../Styles";
import { Outlet } from "react-router-dom";

export const Datawarehouse = () => {

    return (
        <FlexRowBox>
            <SideBar/>
            <Outlet/>
        </FlexRowBox>
    );
}