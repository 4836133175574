import React from "react";
import { Box } from "@mui/material";
import { InputTextField } from "../../../Components/Forms/InputTextField";
import { LabelTypography } from "../../../Styles/CustomTypography";
import RichTextField from "../../../Components/Forms/RichTextInputField";
import { useCreateSource } from "../Hooks/useSourceContext";

export const NameAndDescription = () => {
  const createSourceContext = useCreateSource();

  return (
    <React.Fragment>
      <Box>
        <InputTextField
          onChange={function (e: any): void {
            createSourceContext.dispatch({
              type: "UPDATE_NAME",
              payload: e.target.value,
            });
          }}
          value={createSourceContext.state.name}
          title="Name"
          description="Name must be unique, start with a lower case letter and may only
          contain underscores, letters and numbers"
          violationArray={[]}
        />
      </Box>
      <Box>
        <LabelTypography>Description (Optional)</LabelTypography>
        <RichTextField
          input={createSourceContext.state.description}
          setInput={(e: string) => {
            createSourceContext.dispatch({
              type: "UPDATE_DESCRIPTION",
              payload: e,
            });
          }}
        />
      </Box>
    </React.Fragment>
  );
};
