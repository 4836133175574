import { createContext } from "react";
import { Branch } from "../Client/typescript-axios-client-generated";

export type TVersionControlContext = {
    currentBranch: string;
    currentProject: string;
    currentProjectName: string;
    currentBranchName: string;
    branchesList: Branch[];
    projectsList: TProject[];
    updateCurrentProject: (id: string) => void;
    updateCurrentBranch: (id: string) => void;
}

export type TBranch = {
    name: string;
    id: string;
}

export type TProject = {
    name: string;
    description?: string;
    id: string;
}

const VersionControlContext = createContext<TVersionControlContext|undefined>(undefined);

export default VersionControlContext;