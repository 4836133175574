import { Box } from "@mui/material";
import { CustomDropDown } from "../../../../../Components/Forms/DropDown";
import { GreenButton, GreyButton } from "../../../../../Styles/Button";
import { useState } from "react";
import { useVersionControlContext } from "../../../../../Hooks/useVersionControlContext";
import { LabelTypography } from "../../../../../Styles/CustomTypography";
import { InputTextField } from "../../../../../Components/Forms/InputTextField";
import { createBranch } from "../../../../../Services/createBranch";
import { useToast } from "../../../../../Hooks/useToast";
import { ToastType } from "../../../../../Context/Providers/ToastProvider";
import { FlexColumnBox, FlexRowBox } from "../../../../../Styles";

export const CreateBranch = () => {
  const versionControlContext = useVersionControlContext();
  const currProject = versionControlContext?.currentProject
    ? [versionControlContext.currentProject]
    : [];
  const currBranch = versionControlContext?.currentBranch
    ? [versionControlContext.currentBranch]
    : [];
  const [project, setProject] = useState<string[]>(currProject);
  const [fromBranch, setFromBranch] = useState<string[]>(currBranch);
  const [newBranchName, setNewBranchName] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();

  const updateSelectedProject = (p: string[]) => {
    setProject(p);
  };

  const updateSelectedBranch = (b: string[]) => {
    setFromBranch(b);
  };

  const handleCreateBranch = () => {
    // Add logic to create the branch using selected project, fromBranch, and newBranchName
    setIsLoading(true);
    createBranch({
      name: newBranchName,
      project_id: project[0],
      from_branch_id: fromBranch[0],
    })
      .then((data) => {
        setIsLoading(false);
        toast.open("Branch created successfully.", ToastType.SUCCESS);
      })
      .catch(() => {
        console.log("error occured while creating new branch");
        setIsLoading(false);
      });
  };

  const createBranchContent = () => (
    <>
      <Box>
        <LabelTypography>Select Project</LabelTypography>
        <CustomDropDown
          data={
            versionControlContext?.projectsList.map((project) => ({
              label: project.name,
              description: project.description || "", // Ensure description is always a string
              itemId: project.id,
            })) ?? []
          }
          multiple={false}
          selectedItems={project}
          updateSelectedItems={updateSelectedProject}
        />
      </Box>
      <Box>
        <LabelTypography>Select Branch</LabelTypography>
        <CustomDropDown
          data={
            versionControlContext?.branchesList.map((branch) => ({
              label: branch.name,
              itemId: branch.id,
            })) ?? []
          }
          multiple={false}
          selectedItems={fromBranch}
          updateSelectedItems={updateSelectedBranch}
        />
      </Box>
      <Box>
        <LabelTypography>New Branch Name</LabelTypography>
        <InputTextField
          onChange={(e) => {
            setNewBranchName(e.target.value);
          }}
          value={newBranchName}
          violationArray={[]}
        />
      </Box>

      <GreenButton onClick={handleCreateBranch} disabled={isLoading || (project.length<1 || fromBranch.length<1 || newBranchName.length<1)} >Create Branch</GreenButton>
    </>
  );

  return (
    <FlexColumnBox sx={{ padding: "2rem", flexGrow: 1, gap: "1rem" }}>
      {createBranchContent()}
    </FlexColumnBox>
  );
};
