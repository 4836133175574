/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AllowedValues
 */
export interface AllowedValues {
    /**
     * 
     * @type {string}
     * @memberof AllowedValues
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface AttributeCreate
 */
export interface AttributeCreate {
    /**
     * 
     * @type {string}
     * @memberof AttributeCreate
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AttributeCreate
     */
    'type_id': string;
    /**
     * 
     * @type {boolean}
     * @memberof AttributeCreate
     */
    'mandatory': boolean;
    /**
     * 
     * @type {string}
     * @memberof AttributeCreate
     */
    'url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttributeCreate
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AttributeCreate
     */
    'attribute_entity_mapping': Array<string>;
    /**
     * 
     * @type {Array<AllowedValues>}
     * @memberof AttributeCreate
     */
    'allowed_values'?: Array<AllowedValues> | null;
}
/**
 * 
 * @export
 * @interface AttributeDataInsertSchema
 */
export interface AttributeDataInsertSchema {
    /**
     * 
     * @type {string}
     * @memberof AttributeDataInsertSchema
     */
    'attribute_id': string;
    /**
     * 
     * @type {any}
     * @memberof AttributeDataInsertSchema
     */
    'value': any;
}
/**
 * 
 * @export
 * @interface EntityType
 */
export interface EntityType {
    /**
     * 
     * @type {string}
     * @memberof EntityType
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EntityType
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EntityType
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof EntityType
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof EntityType
     */
    'created_by': string;
    /**
     * 
     * @type {string}
     * @memberof EntityType
     */
    'updated_by': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FilterType = {
    Source: 'Source',
    Destination: 'Destination'
} as const;

export type FilterType = typeof FilterType[keyof typeof FilterType];


/**
 * 
 * @export
 * @interface GetAllowedValuesSchema
 */
export interface GetAllowedValuesSchema {
    /**
     * 
     * @type {string}
     * @memberof GetAllowedValuesSchema
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof GetAllowedValuesSchema
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface GetAttributeResponse
 */
export interface GetAttributeResponse {
    /**
     * 
     * @type {string}
     * @memberof GetAttributeResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetAttributeResponse
     */
    'type_id': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetAttributeResponse
     */
    'mandatory': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAttributeResponse
     */
    'url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAttributeResponse
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAttributeResponse
     */
    'id': string;
    /**
     * 
     * @type {Array<EntityType>}
     * @memberof GetAttributeResponse
     */
    'applies_to': Array<EntityType>;
    /**
     * 
     * @type {Array<GetAllowedValuesSchema>}
     * @memberof GetAttributeResponse
     */
    'allowedValues': Array<GetAllowedValuesSchema>;
    /**
     * 
     * @type {string}
     * @memberof GetAttributeResponse
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface GetAttributeTypeSchema
 */
export interface GetAttributeTypeSchema {
    /**
     * 
     * @type {string}
     * @memberof GetAttributeTypeSchema
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetAttributeTypeSchema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetAttributeTypeSchema
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof GetAttributeTypeSchema
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof GetAttributeTypeSchema
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof GetAttributeTypeSchema
     */
    'created_by': string;
    /**
     * 
     * @type {string}
     * @memberof GetAttributeTypeSchema
     */
    'updated_by': string;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface OrchestatorDeleteResponseSchema
 */
export interface OrchestatorDeleteResponseSchema {
    /**
     * 
     * @type {boolean}
     * @memberof OrchestatorDeleteResponseSchema
     */
    'result': boolean;
}
/**
 * 
 * @export
 * @interface OrchestatorTypeInDB
 */
export interface OrchestatorTypeInDB {
    /**
     * 
     * @type {string}
     * @memberof OrchestatorTypeInDB
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrchestatorTypeInDB
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<object>}
     * @memberof OrchestatorTypeInDB
     */
    'orchestrator_config_format': Array<object>;
    /**
     * 
     * @type {string}
     * @memberof OrchestatorTypeInDB
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface OrchestratorCreate
 */
export interface OrchestratorCreate {
    /**
     * 
     * @type {string}
     * @memberof OrchestratorCreate
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrchestratorCreate
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof OrchestratorCreate
     */
    'orchestrator_type_id': string;
    /**
     * 
     * @type {any}
     * @memberof OrchestratorCreate
     */
    'config_data'?: any;
}
/**
 * 
 * @export
 * @interface OrchestratorGetSchema
 */
export interface OrchestratorGetSchema {
    /**
     * 
     * @type {string}
     * @memberof OrchestratorGetSchema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrchestratorGetSchema
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof OrchestratorGetSchema
     */
    'orchestrator_type_id': string;
    /**
     * 
     * @type {any}
     * @memberof OrchestratorGetSchema
     */
    'config_data'?: any;
    /**
     * 
     * @type {string}
     * @memberof OrchestratorGetSchema
     */
    'id': string;
    /**
     * 
     * @type {OrchestatorTypeInDB}
     * @memberof OrchestratorGetSchema
     */
    'orchestrator_type': OrchestatorTypeInDB;
}
/**
 * 
 * @export
 * @interface SourceCreate
 */
export interface SourceCreate {
    /**
     * 
     * @type {string}
     * @memberof SourceCreate
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SourceCreate
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SourceCreate
     */
    'source_type_id': string;
    /**
     * 
     * @type {any}
     * @memberof SourceCreate
     */
    'config_data': any;
    /**
     * 
     * @type {Array<AttributeDataInsertSchema>}
     * @memberof SourceCreate
     */
    'attribute_data': Array<AttributeDataInsertSchema>;
}
/**
 * 
 * @export
 * @interface SourceGetSchema
 */
export interface SourceGetSchema {
    /**
     * 
     * @type {string}
     * @memberof SourceGetSchema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SourceGetSchema
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SourceGetSchema
     */
    'source_type_id': string;
    /**
     * 
     * @type {any}
     * @memberof SourceGetSchema
     */
    'config_data': any;
    /**
     * 
     * @type {SourceTypeGetSchema}
     * @memberof SourceGetSchema
     */
    'source_type': SourceTypeGetSchema;
    /**
     * 
     * @type {Array<AttributeDataInsertSchema>}
     * @memberof SourceGetSchema
     */
    'attribute_data': Array<AttributeDataInsertSchema>;
}
/**
 * 
 * @export
 * @interface SourceTypeGetSchema
 */
export interface SourceTypeGetSchema {
    /**
     * 
     * @type {string}
     * @memberof SourceTypeGetSchema
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SourceTypeGetSchema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SourceTypeGetSchema
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface UpdateAttribute
 */
export interface UpdateAttribute {
    /**
     * 
     * @type {string}
     * @memberof UpdateAttribute
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAttribute
     */
    'type_id': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAttribute
     */
    'mandatory': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateAttribute
     */
    'url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAttribute
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAttribute
     */
    'attribute_entity_mapping': Array<string>;
    /**
     * 
     * @type {Array<AllowedValues>}
     * @memberof UpdateAttribute
     */
    'allowed_values'?: Array<AllowedValues> | null;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}

/**
 * FiltersApi - axios parameter creator
 * @export
 */
export const FiltersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Filter
         * @param {FilterType} [filterType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilterFilterGet: async (filterType?: FilterType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/filter/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filterType !== undefined) {
                localVarQueryParameter['filter_type'] = filterType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FiltersApi - functional programming interface
 * @export
 */
export const FiltersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FiltersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Filter
         * @param {FilterType} [filterType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilterFilterGet(filterType?: FilterType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilterFilterGet(filterType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FiltersApi.getFilterFilterGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FiltersApi - factory interface
 * @export
 */
export const FiltersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FiltersApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Filter
         * @param {FilterType} [filterType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilterFilterGet(filterType?: FilterType, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: any; }> {
            return localVarFp.getFilterFilterGet(filterType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FiltersApi - object-oriented interface
 * @export
 * @class FiltersApi
 * @extends {BaseAPI}
 */
export class FiltersApi extends BaseAPI {
    /**
     * 
     * @summary Get Filter
     * @param {FilterType} [filterType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FiltersApi
     */
    public getFilterFilterGet(filterType?: FilterType, options?: RawAxiosRequestConfig) {
        return FiltersApiFp(this.configuration).getFilterFilterGet(filterType, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LabelAndAttributeApi - axios parameter creator
 * @export
 */
export const LabelAndAttributeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Entity Type To Attribute
         * @param {string} attributeId 
         * @param {UpdateAttribute} updateAttribute 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEntityTypeToAttributeAttributesAddEntityTypeAttributeIdPut: async (attributeId: string, updateAttribute: UpdateAttribute, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attributeId' is not null or undefined
            assertParamExists('addEntityTypeToAttributeAttributesAddEntityTypeAttributeIdPut', 'attributeId', attributeId)
            // verify required parameter 'updateAttribute' is not null or undefined
            assertParamExists('addEntityTypeToAttributeAttributesAddEntityTypeAttributeIdPut', 'updateAttribute', updateAttribute)
            const localVarPath = `/attributes/add-entity-type/{attribute_id}`
                .replace(`{${"attribute_id"}}`, encodeURIComponent(String(attributeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAttribute, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Attribute
         * @param {AttributeCreate} attributeCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAttributeAttributesCreateAttributePost: async (attributeCreate: AttributeCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attributeCreate' is not null or undefined
            assertParamExists('createAttributeAttributesCreateAttributePost', 'attributeCreate', attributeCreate)
            const localVarPath = `/attributes/create-attribute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attributeCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Attribute By Id
         * @param {string} attributeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttributeByIdAttributesAttributeIdGet: async (attributeId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attributeId' is not null or undefined
            assertParamExists('getAttributeByIdAttributesAttributeIdGet', 'attributeId', attributeId)
            const localVarPath = `/attributes/{attribute_id}`
                .replace(`{${"attribute_id"}}`, encodeURIComponent(String(attributeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Attributes
         * @param {string} [entityId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttributesAttributesGet: async (entityId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/attributes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (entityId !== undefined) {
                localVarQueryParameter['entity_id'] = entityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Entity Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityTypesAttributesGetAttributeTypesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/attributes/get-attribute-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Entity Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityTypesAttributesGetEntityTypesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/attributes/get-entity-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LabelAndAttributeApi - functional programming interface
 * @export
 */
export const LabelAndAttributeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LabelAndAttributeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add Entity Type To Attribute
         * @param {string} attributeId 
         * @param {UpdateAttribute} updateAttribute 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addEntityTypeToAttributeAttributesAddEntityTypeAttributeIdPut(attributeId: string, updateAttribute: UpdateAttribute, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addEntityTypeToAttributeAttributesAddEntityTypeAttributeIdPut(attributeId, updateAttribute, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LabelAndAttributeApi.addEntityTypeToAttributeAttributesAddEntityTypeAttributeIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Attribute
         * @param {AttributeCreate} attributeCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAttributeAttributesCreateAttributePost(attributeCreate: AttributeCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAttributeAttributesCreateAttributePost(attributeCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LabelAndAttributeApi.createAttributeAttributesCreateAttributePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Attribute By Id
         * @param {string} attributeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAttributeByIdAttributesAttributeIdGet(attributeId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAttributeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAttributeByIdAttributesAttributeIdGet(attributeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LabelAndAttributeApi.getAttributeByIdAttributesAttributeIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Attributes
         * @param {string} [entityId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAttributesAttributesGet(entityId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetAttributeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAttributesAttributesGet(entityId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LabelAndAttributeApi.getAttributesAttributesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Entity Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityTypesAttributesGetAttributeTypesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetAttributeTypeSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityTypesAttributesGetAttributeTypesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LabelAndAttributeApi.getEntityTypesAttributesGetAttributeTypesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Entity Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityTypesAttributesGetEntityTypesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntityType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityTypesAttributesGetEntityTypesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LabelAndAttributeApi.getEntityTypesAttributesGetEntityTypesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LabelAndAttributeApi - factory interface
 * @export
 */
export const LabelAndAttributeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LabelAndAttributeApiFp(configuration)
    return {
        /**
         * 
         * @summary Add Entity Type To Attribute
         * @param {string} attributeId 
         * @param {UpdateAttribute} updateAttribute 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEntityTypeToAttributeAttributesAddEntityTypeAttributeIdPut(attributeId: string, updateAttribute: UpdateAttribute, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.addEntityTypeToAttributeAttributesAddEntityTypeAttributeIdPut(attributeId, updateAttribute, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Attribute
         * @param {AttributeCreate} attributeCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAttributeAttributesCreateAttributePost(attributeCreate: AttributeCreate, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.createAttributeAttributesCreateAttributePost(attributeCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Attribute By Id
         * @param {string} attributeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttributeByIdAttributesAttributeIdGet(attributeId: string, options?: RawAxiosRequestConfig): AxiosPromise<GetAttributeResponse> {
            return localVarFp.getAttributeByIdAttributesAttributeIdGet(attributeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Attributes
         * @param {string} [entityId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttributesAttributesGet(entityId?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<GetAttributeResponse>> {
            return localVarFp.getAttributesAttributesGet(entityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Entity Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityTypesAttributesGetAttributeTypesGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<GetAttributeTypeSchema>> {
            return localVarFp.getEntityTypesAttributesGetAttributeTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Entity Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityTypesAttributesGetEntityTypesGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<EntityType>> {
            return localVarFp.getEntityTypesAttributesGetEntityTypesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LabelAndAttributeApi - object-oriented interface
 * @export
 * @class LabelAndAttributeApi
 * @extends {BaseAPI}
 */
export class LabelAndAttributeApi extends BaseAPI {
    /**
     * 
     * @summary Add Entity Type To Attribute
     * @param {string} attributeId 
     * @param {UpdateAttribute} updateAttribute 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelAndAttributeApi
     */
    public addEntityTypeToAttributeAttributesAddEntityTypeAttributeIdPut(attributeId: string, updateAttribute: UpdateAttribute, options?: RawAxiosRequestConfig) {
        return LabelAndAttributeApiFp(this.configuration).addEntityTypeToAttributeAttributesAddEntityTypeAttributeIdPut(attributeId, updateAttribute, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Attribute
     * @param {AttributeCreate} attributeCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelAndAttributeApi
     */
    public createAttributeAttributesCreateAttributePost(attributeCreate: AttributeCreate, options?: RawAxiosRequestConfig) {
        return LabelAndAttributeApiFp(this.configuration).createAttributeAttributesCreateAttributePost(attributeCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Attribute By Id
     * @param {string} attributeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelAndAttributeApi
     */
    public getAttributeByIdAttributesAttributeIdGet(attributeId: string, options?: RawAxiosRequestConfig) {
        return LabelAndAttributeApiFp(this.configuration).getAttributeByIdAttributesAttributeIdGet(attributeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Attributes
     * @param {string} [entityId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelAndAttributeApi
     */
    public getAttributesAttributesGet(entityId?: string, options?: RawAxiosRequestConfig) {
        return LabelAndAttributeApiFp(this.configuration).getAttributesAttributesGet(entityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Entity Types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelAndAttributeApi
     */
    public getEntityTypesAttributesGetAttributeTypesGet(options?: RawAxiosRequestConfig) {
        return LabelAndAttributeApiFp(this.configuration).getEntityTypesAttributesGetAttributeTypesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Entity Types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabelAndAttributeApi
     */
    public getEntityTypesAttributesGetEntityTypesGet(options?: RawAxiosRequestConfig) {
        return LabelAndAttributeApiFp(this.configuration).getEntityTypesAttributesGetEntityTypesGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrchestratorsApi - axios parameter creator
 * @export
 */
export const OrchestratorsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Orchestrator
         * @param {OrchestratorCreate} orchestratorCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrchestratorOrchestratorCreatePost: async (orchestratorCreate: OrchestratorCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orchestratorCreate' is not null or undefined
            assertParamExists('createOrchestratorOrchestratorCreatePost', 'orchestratorCreate', orchestratorCreate)
            const localVarPath = `/orchestrator/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orchestratorCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Orchestrator By Id
         * @param {string} orchestratorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrchestratorByIdOrchestratorOrchestratorIdDelete: async (orchestratorId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orchestratorId' is not null or undefined
            assertParamExists('deleteOrchestratorByIdOrchestratorOrchestratorIdDelete', 'orchestratorId', orchestratorId)
            const localVarPath = `/orchestrator/{orchestrator_id}`
                .replace(`{${"orchestrator_id"}}`, encodeURIComponent(String(orchestratorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Orchestrator By Id
         * @param {string} orchestratorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrchestratorByIdOrchestratorOrchestratorIdGet: async (orchestratorId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orchestratorId' is not null or undefined
            assertParamExists('getOrchestratorByIdOrchestratorOrchestratorIdGet', 'orchestratorId', orchestratorId)
            const localVarPath = `/orchestrator/{orchestrator_id}`
                .replace(`{${"orchestrator_id"}}`, encodeURIComponent(String(orchestratorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Orchestrators
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrchestratorsOrchestratorGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orchestrator/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Orchestrators
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrchestratorsOrchestratorGetOrchestratorTypesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orchestrator/get-orchestrator-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Orchestrator
         * @param {string} orchestratorId 
         * @param {OrchestratorCreate} orchestratorCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrchestratorOrchestratorUpdateOrchestratorIdPut: async (orchestratorId: string, orchestratorCreate: OrchestratorCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orchestratorId' is not null or undefined
            assertParamExists('updateOrchestratorOrchestratorUpdateOrchestratorIdPut', 'orchestratorId', orchestratorId)
            // verify required parameter 'orchestratorCreate' is not null or undefined
            assertParamExists('updateOrchestratorOrchestratorUpdateOrchestratorIdPut', 'orchestratorCreate', orchestratorCreate)
            const localVarPath = `/orchestrator/update/{orchestrator_id}`
                .replace(`{${"orchestrator_id"}}`, encodeURIComponent(String(orchestratorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orchestratorCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrchestratorsApi - functional programming interface
 * @export
 */
export const OrchestratorsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrchestratorsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Orchestrator
         * @param {OrchestratorCreate} orchestratorCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrchestratorOrchestratorCreatePost(orchestratorCreate: OrchestratorCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrchestratorOrchestratorCreatePost(orchestratorCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrchestratorsApi.createOrchestratorOrchestratorCreatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Orchestrator By Id
         * @param {string} orchestratorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrchestratorByIdOrchestratorOrchestratorIdDelete(orchestratorId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrchestatorDeleteResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrchestratorByIdOrchestratorOrchestratorIdDelete(orchestratorId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrchestratorsApi.deleteOrchestratorByIdOrchestratorOrchestratorIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Orchestrator By Id
         * @param {string} orchestratorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrchestratorByIdOrchestratorOrchestratorIdGet(orchestratorId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrchestratorGetSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrchestratorByIdOrchestratorOrchestratorIdGet(orchestratorId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrchestratorsApi.getOrchestratorByIdOrchestratorOrchestratorIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Orchestrators
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrchestratorsOrchestratorGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrchestratorGetSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrchestratorsOrchestratorGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrchestratorsApi.getOrchestratorsOrchestratorGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Orchestrators
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrchestratorsOrchestratorGetOrchestratorTypesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrchestatorTypeInDB>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrchestratorsOrchestratorGetOrchestratorTypesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrchestratorsApi.getOrchestratorsOrchestratorGetOrchestratorTypesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Orchestrator
         * @param {string} orchestratorId 
         * @param {OrchestratorCreate} orchestratorCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrchestratorOrchestratorUpdateOrchestratorIdPut(orchestratorId: string, orchestratorCreate: OrchestratorCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrchestratorOrchestratorUpdateOrchestratorIdPut(orchestratorId, orchestratorCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrchestratorsApi.updateOrchestratorOrchestratorUpdateOrchestratorIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrchestratorsApi - factory interface
 * @export
 */
export const OrchestratorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrchestratorsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Orchestrator
         * @param {OrchestratorCreate} orchestratorCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrchestratorOrchestratorCreatePost(orchestratorCreate: OrchestratorCreate, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.createOrchestratorOrchestratorCreatePost(orchestratorCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Orchestrator By Id
         * @param {string} orchestratorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrchestratorByIdOrchestratorOrchestratorIdDelete(orchestratorId: string, options?: RawAxiosRequestConfig): AxiosPromise<OrchestatorDeleteResponseSchema> {
            return localVarFp.deleteOrchestratorByIdOrchestratorOrchestratorIdDelete(orchestratorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Orchestrator By Id
         * @param {string} orchestratorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrchestratorByIdOrchestratorOrchestratorIdGet(orchestratorId: string, options?: RawAxiosRequestConfig): AxiosPromise<OrchestratorGetSchema> {
            return localVarFp.getOrchestratorByIdOrchestratorOrchestratorIdGet(orchestratorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Orchestrators
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrchestratorsOrchestratorGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<OrchestratorGetSchema>> {
            return localVarFp.getOrchestratorsOrchestratorGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Orchestrators
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrchestratorsOrchestratorGetOrchestratorTypesGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<OrchestatorTypeInDB>> {
            return localVarFp.getOrchestratorsOrchestratorGetOrchestratorTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Orchestrator
         * @param {string} orchestratorId 
         * @param {OrchestratorCreate} orchestratorCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrchestratorOrchestratorUpdateOrchestratorIdPut(orchestratorId: string, orchestratorCreate: OrchestratorCreate, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.updateOrchestratorOrchestratorUpdateOrchestratorIdPut(orchestratorId, orchestratorCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrchestratorsApi - object-oriented interface
 * @export
 * @class OrchestratorsApi
 * @extends {BaseAPI}
 */
export class OrchestratorsApi extends BaseAPI {
    /**
     * 
     * @summary Create Orchestrator
     * @param {OrchestratorCreate} orchestratorCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrchestratorsApi
     */
    public createOrchestratorOrchestratorCreatePost(orchestratorCreate: OrchestratorCreate, options?: RawAxiosRequestConfig) {
        return OrchestratorsApiFp(this.configuration).createOrchestratorOrchestratorCreatePost(orchestratorCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Orchestrator By Id
     * @param {string} orchestratorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrchestratorsApi
     */
    public deleteOrchestratorByIdOrchestratorOrchestratorIdDelete(orchestratorId: string, options?: RawAxiosRequestConfig) {
        return OrchestratorsApiFp(this.configuration).deleteOrchestratorByIdOrchestratorOrchestratorIdDelete(orchestratorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Orchestrator By Id
     * @param {string} orchestratorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrchestratorsApi
     */
    public getOrchestratorByIdOrchestratorOrchestratorIdGet(orchestratorId: string, options?: RawAxiosRequestConfig) {
        return OrchestratorsApiFp(this.configuration).getOrchestratorByIdOrchestratorOrchestratorIdGet(orchestratorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Orchestrators
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrchestratorsApi
     */
    public getOrchestratorsOrchestratorGet(options?: RawAxiosRequestConfig) {
        return OrchestratorsApiFp(this.configuration).getOrchestratorsOrchestratorGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Orchestrators
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrchestratorsApi
     */
    public getOrchestratorsOrchestratorGetOrchestratorTypesGet(options?: RawAxiosRequestConfig) {
        return OrchestratorsApiFp(this.configuration).getOrchestratorsOrchestratorGetOrchestratorTypesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Orchestrator
     * @param {string} orchestratorId 
     * @param {OrchestratorCreate} orchestratorCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrchestratorsApi
     */
    public updateOrchestratorOrchestratorUpdateOrchestratorIdPut(orchestratorId: string, orchestratorCreate: OrchestratorCreate, options?: RawAxiosRequestConfig) {
        return OrchestratorsApiFp(this.configuration).updateOrchestratorOrchestratorUpdateOrchestratorIdPut(orchestratorId, orchestratorCreate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SourcesApi - axios parameter creator
 * @export
 */
export const SourcesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Destination
         * @param {SourceCreate} sourceCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDestinationSourceCreateDestinationPost: async (sourceCreate: SourceCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceCreate' is not null or undefined
            assertParamExists('createDestinationSourceCreateDestinationPost', 'sourceCreate', sourceCreate)
            const localVarPath = `/source/create-destination`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sourceCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Sources
         * @param {SourceCreate} sourceCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSourcesSourceCreateSourcePost: async (sourceCreate: SourceCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceCreate' is not null or undefined
            assertParamExists('createSourcesSourceCreateSourcePost', 'sourceCreate', sourceCreate)
            const localVarPath = `/source/create-source`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sourceCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Sources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSourcesSourceDestinationsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/source/destinations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Sources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSourcesSourceSourceTypesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/source/source_types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Sources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSourcesSourceSourcesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/source/sources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SourcesApi - functional programming interface
 * @export
 */
export const SourcesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SourcesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Destination
         * @param {SourceCreate} sourceCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDestinationSourceCreateDestinationPost(sourceCreate: SourceCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDestinationSourceCreateDestinationPost(sourceCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SourcesApi.createDestinationSourceCreateDestinationPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Sources
         * @param {SourceCreate} sourceCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSourcesSourceCreateSourcePost(sourceCreate: SourceCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSourcesSourceCreateSourcePost(sourceCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SourcesApi.createSourcesSourceCreateSourcePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Sources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSourcesSourceDestinationsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSourcesSourceDestinationsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SourcesApi.getSourcesSourceDestinationsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Sources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSourcesSourceSourceTypesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSourcesSourceSourceTypesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SourcesApi.getSourcesSourceSourceTypesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Sources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSourcesSourceSourcesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SourceGetSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSourcesSourceSourcesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SourcesApi.getSourcesSourceSourcesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SourcesApi - factory interface
 * @export
 */
export const SourcesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SourcesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Destination
         * @param {SourceCreate} sourceCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDestinationSourceCreateDestinationPost(sourceCreate: SourceCreate, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.createDestinationSourceCreateDestinationPost(sourceCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Sources
         * @param {SourceCreate} sourceCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSourcesSourceCreateSourcePost(sourceCreate: SourceCreate, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.createSourcesSourceCreateSourcePost(sourceCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Sources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSourcesSourceDestinationsGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getSourcesSourceDestinationsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Sources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSourcesSourceSourceTypesGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getSourcesSourceSourceTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Sources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSourcesSourceSourcesGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<SourceGetSchema>> {
            return localVarFp.getSourcesSourceSourcesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SourcesApi - object-oriented interface
 * @export
 * @class SourcesApi
 * @extends {BaseAPI}
 */
export class SourcesApi extends BaseAPI {
    /**
     * 
     * @summary Create Destination
     * @param {SourceCreate} sourceCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SourcesApi
     */
    public createDestinationSourceCreateDestinationPost(sourceCreate: SourceCreate, options?: RawAxiosRequestConfig) {
        return SourcesApiFp(this.configuration).createDestinationSourceCreateDestinationPost(sourceCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Sources
     * @param {SourceCreate} sourceCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SourcesApi
     */
    public createSourcesSourceCreateSourcePost(sourceCreate: SourceCreate, options?: RawAxiosRequestConfig) {
        return SourcesApiFp(this.configuration).createSourcesSourceCreateSourcePost(sourceCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Sources
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SourcesApi
     */
    public getSourcesSourceDestinationsGet(options?: RawAxiosRequestConfig) {
        return SourcesApiFp(this.configuration).getSourcesSourceDestinationsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Sources
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SourcesApi
     */
    public getSourcesSourceSourceTypesGet(options?: RawAxiosRequestConfig) {
        return SourcesApiFp(this.configuration).getSourcesSourceSourceTypesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Sources
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SourcesApi
     */
    public getSourcesSourceSourcesGet(options?: RawAxiosRequestConfig) {
        return SourcesApiFp(this.configuration).getSourcesSourceSourcesGet(options).then((request) => request(this.axios, this.basePath));
    }
}



