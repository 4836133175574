import { ApiClient } from "../Utils/ApiClient";

export const getEntityMappingById = async (
  projectName: string,
  branchName: string,
  id: string
) => {
  return ApiClient.entityMappingApiClient
    .getEntityMappingByIdEntityMappingEntityMappingIdGet(
      projectName,
      branchName,
      id
    )
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};
