import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { Colors } from "../Constants";

export const BaseButton = styled(Button)({
    padding: '.375rem .75rem',
    fontSize: '.875rem',
    lineHeight: 1.5,
    margin: 0,
    border: '1px solid transparent',
    whiteSpace: 'nowrap',
})

export const BlueButton = styled(BaseButton)({
    backgroundColor: Colors.APP_BLUE_COLOR,
    borderColor: '#234afa',
    color: 'white',
    transition: 'background-color 0.3s ease',
    textTransform: 'none',
    fontWeight: 600,
    borderRadius: '.25rem',
    '&:hover' : {
        backgroundColor: '#0056b3' /* Darker shade of blue on hover */
    }
})

export const BlueButtonWithoutPadding = styled(Button)({
    backgroundColor: Colors.APP_BLUE_COLOR,
    padding: '0 8px',
    minWidth: 0,
    color: 'white',
    transition: 'background-color 0.3s ease',
    textTransform: 'none',
    fontWeight: 600,
    '&:hover' : {
        backgroundColor: '#0056b3' /* Darker shade of blue on hover */
    }
})


export const GreenButton = styled(Button)({
    backgroundColor: Colors.APP_GREEN_BUTTON_COLOR,
    borderColor: Colors.APP_GREEN_BUTTON_COLOR,
    color: 'white',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease',
    textTransform: 'none',
    '&:hover' : {
        backgroundColor: Colors.APP_ON_HOVER_GREEN_BUTTON_COLOR /* Darker shade of blue on hover */
    }
})

export const RedButton = styled(Button)({
    backgroundColor: Colors.APP_RED_COLOR,
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease',
    color: 'white',
    textTransform: 'none',
    '&:hover' : {
        backgroundColor: Colors.APP_DARK_RED_COLOR
    }
})

export const GreyButton = styled(Button)({
    backgroundColor: Colors.APP_CANCEL_BUTTON_BORDER_COLOR,
    color: Colors.BLUE_TEXT_COLOR,
    fontWeight: 'bold',
    borderRadius: '.25rem',
    border: '1px solid'+Colors.SIDE_LINK_HOVER_COLOR,
    textTransform: 'none',
    '&:hover' : {
    }
    
});

export const HollowButton = styled(Button)({
    padding: '.25rem .5rem .175rem',
    borderRadius: '.25rem',
    fontWeight: 600,
    fontSize: '.765625rem',
    lineHeight: 1.5,
    borderColor: '#234afa',
    border: '1px solid',
    color: '#234afa',
    '&:hover' : {
        backgroundColor: '#234afa',
        color: '#fff',
    }
})

export const BranchButton = styled(Button)({
    fontFamily: 'inherit',
    fontWeight: 500,
    fontSize: 14,
    cursor: 'pointer',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 32,
    minWidth: 'max-content',
    color: `${Colors.APP_LIGHT_GREY}`,
    backgroundColor: `${Colors.SIDE_LINK_HOVER_COLOR}`,
    borderRadius: 6,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'rgba(31, 35, 40, 0.15)',
    padding: '0px 12px',
    gap: 8,
    textTransform: 'none',
  });

// export const RedButton = styled(Button)({
//     backgroundColor: Colors.APP_RED_COLOR,
//     fontWeight: 'bold',
//     transition: 'background-color 0.3s ease',
//     color: 'white',
//     '&:hover' : {
//         backgroundColor: Colors.APP_DARK_RED_COLOR
//     }
// })


// export const RichFieldToolbarButton = styled(Button)({
//     textTransform: "none",
//     color: "#2c3e50",
//     width: '30px',
//     height: '30px',
//     padding: 0,
//     borderRadius: '3px',
//     minWidth: 0,
//     '&:hover': {
//         border: `1px solid ${Colors.SIDE_LINK_HOVER_COLOR}`
//     }
// });

export const RichFieldToolbarButton = styled(Button)(
    ({ isActive }: { isActive?: boolean }) => ({
      transition: "color 0.3s", // Smooth transition effect for color change
      border: isActive ? `1px solid ${Colors.SIDE_LINK_HOVER_COLOR}` : '1px solid transparent',
      color: Colors.LINK_TEXT_COLOR,
      minWidth: 0,
      "&:hover": {
        border: `1px solid ${Colors.SIDE_LINK_HOVER_COLOR}`
      },
    })
  );
