import { Box, CircularProgress, Typography } from "@mui/material";
import CustomTab from "../../../../../Components/Tab";
import React, { Suspense, useRef, useState } from "react";
import { FlexColumnBox, FlexRowBox } from "../../../../../Styles";
import { TableTab } from "./TableTab/TableTab";
import { Colors } from "../../../../../Constants";
import {
  BlueButton,
  GreenButton,
  GreyButton,
} from "../../../../../Styles/Button";
import { useCreateTableForm } from "../../../../../Hooks/useCreateTableForm";
import { ModelingTab } from "./ModelingTab/ModelingTab";
import { DownArrowIcon } from "../../../../../Components/icons/DownArrowIcon";
import { getClusters } from "../../../../../Components/GetClusters";
import { createTable } from "../../../../../Services/createTable";
import { useVersionControlContext } from "../../../../../Hooks/useVersionControlContext";
import {
  Table,
  TableEntityMapping,
  TableTimeSemantics,
  TableType,
} from "../../../../../Client/typescript-axios-client-generated";
import { convertStringToEnum } from "../../../../../Utils/CommonFunctions";
import { useToast } from "../../../../../Hooks/useToast";
import { ToastType } from "../../../../../Context/Providers/ToastProvider";
import { useNavigate } from "react-router-dom";
import { DropDownMenu } from "../../Features/Components/CreateFeatureTabs";
import { CustomToolTip } from "../../../../../Styles/ToolTip";
import { usePermissionContext } from "../../../../../Hooks/usePermissionContext";
import { PERMISSION_DENIED_TOOLTIP_MESSAGE } from "../../../../../Constants/messages";

const MetaInformation = React.lazy(
  () => import("./MetaInformationTab/MetaInformatiom")
);

export const CreateTable = () => {
  const { createTableState, dispatch } = useCreateTableForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const versionControlContext = useVersionControlContext();

  const permissionContext = usePermissionContext();

  const handleChooseCluster = () => {};
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toast = useToast();
  const navigate = useNavigate();

  const handleSubmit = () => {
    let externalTableData = createTableState.table.externalTableData;
    let viewTableData = createTableState.table.viewTableData;
    const tableTypeEnum = convertStringToEnum(
      TableType,
      createTableState.table.tableType
    );
    if (!tableTypeEnum) return;
    let tableTimeSemantics: TableTimeSemantics | null = null;
    if (
      tableTypeEnum == TableType.ExternalTable ||
      tableTypeEnum == TableType.ViewTable
    ) {
      const usableData =
        tableTypeEnum == "EXTERNAL_TABLE" ? externalTableData : viewTableData;
      if (usableData.tableTimeSemantics == "EVENT_OR_FACT_TABLE") {
        tableTimeSemantics = {
          timestamp_column: usableData.eventOrFactTable.timestampColumn,
        };
      } else if (
        usableData.tableTimeSemantics == "SLOWLY_CHANGING_DIMENSION_TYPE_2"
      ) {
        tableTimeSemantics = {
          primary_entity: usableData.slowlyChangingDimension.primaryEntity[0],
          from_column: usableData.slowlyChangingDimension.fromColumn,
          valid_to_column: usableData.slowlyChangingDimension.validToColumn,
        };
      } else if (usableData.tableTimeSemantics == "POINT_IN_TIME") {
        tableTimeSemantics = {
          primary_entity: usableData.pointInTime.primaryEntity[0],
          validity_column: usableData.pointInTime.validityColumn,
        };
      }
    }
    if (versionControlContext === undefined) return;

    let tableEntityMapping: TableEntityMapping[] = [];

    if (
      tableTypeEnum === TableType.ExternalTable ||
      tableTypeEnum === TableType.ViewTable
    ) {
      const usableData =
        tableTypeEnum == TableType.ExternalTable
          ? externalTableData
          : viewTableData;
      tableEntityMapping = usableData.entityMapping.map((entityMapping) => {
        return {
          entity_id: entityMapping.entity,
          entity_key_column: entityMapping.entityKeyColumn,
        };
      });
    }

    let body: any = {
      id: null,
      name: createTableState.table.name,
      description: createTableState.table.description,
      table_type: tableTypeEnum,
      attributes_data: Object.keys(createTableState.metaInformation).map(
        (key) => {
          return {
            attribute_id: key,
            value: createTableState.metaInformation[key],
          };
        }
      ),
    };

    if (tableTypeEnum == TableType.ExternalTable) {
      body.table_time_semantics = tableTimeSemantics;
      body.source = externalTableData.source[0];
      body.source_table = externalTableData.source_table;
      body.table_entity_mapping = tableEntityMapping;
    } else if (tableTypeEnum == TableType.ViewTable) {
      body.table_time_semantics = tableTimeSemantics;
      body.sources = viewTableData.sources;
      body.table_entity_mapping = tableEntityMapping;
      body.sql = viewTableData.sql;
    } else {
      toast.open("Not supported", ToastType.WARNING);
      return;
    }

    createTable(
      versionControlContext.currentProjectName,
      versionControlContext.currentBranchName,
      body
    )
      .then((data) => {
        toast.open("Table is created successfully", ToastType.SUCCESS);
        navigate("/data-layer/tables");
      })
      .catch((err) => {
        toast.open(
          "Something went wrong while creating table",
          ToastType.ERROR
        );
      });
  };

  return (
    <FlexColumnBox
      sx={{
        flexGrow: 1,
        borderLeft: `1px solid ${Colors.SIDE_LINK_HOVER_COLOR}`,
      }}
    >
      <DropDownMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        minWidth={""}
        children={getClusters(handleChooseCluster)}
      />
      <FlexRowBox
        sx={{
          justifyContent: "space-between",
          padding: "1rem",
          borderBottom: `1px solid ${Colors.SIDE_LINK_HOVER_COLOR}`,
        }}
      >
        <Typography variant="h4">Create Table</Typography>
        <FlexRowBox>
          <BlueButton>
            <CustomToolTip
              title={
                permissionContext?.permissions.createTable
                  ? ""
                  : PERMISSION_DENIED_TOOLTIP_MESSAGE
              }
            >
              <BlueButton
                disabled={loading}
                startIcon={
                  loading && <CircularProgress size={20} color="inherit" />
                }
                sx={{ borderRadius: "0" }}
                onClick={handleSubmit}
                className="disabled-button"
              >
                Create Table
              </BlueButton>
            </CustomToolTip>
            <BlueButton
              disabled={loading}
              onClick={handleClick}
              sx={{ borderRadius: "0", borderLeft: "1px solid white" }}
            >
              <DownArrowIcon />
            </BlueButton>
          </BlueButton>
        </FlexRowBox>
      </FlexRowBox>
      <FlexRowBox>
        <Box
          sx={{
            flexGrow: 1,
            padding: "2rem",
            borderRight: `1px solid ${Colors.SIDE_LINK_HOVER_COLOR}`,
            width: "50%",
          }}
        >
          <CustomTab
            data={[
              {
                tabText: "Table",
                tabPanel: (
                  <TableTab
                    createTableState={createTableState}
                    dispatch={dispatch}
                  />
                ),
              },
              {
                tabText: "Modeling",
                tabPanel: <ModelingTab />,
              },
              {
                tabText: "Meta Information",
                tabPanel: (
                  <Suspense fallback={<Box>Loading....</Box>}>
                    <MetaInformation
                      createTableState={createTableState}
                      dispatch={dispatch}
                    />
                  </Suspense>
                ),
              },
            ]}
          />
        </Box>
        <Box
          sx={{
            padding: "2rem",
            width: "50%",
            backgroundColor: Colors.APP_VERY_LIGHT_BLUE_COLOR,
          }}
        >
          <FlexColumnBox
            style={{
              gap: "1rem",
              paddingTop: "1rem",
              position: "sticky",
              top: "100px",
            }}
          >
            <GreyButton>Preview Filter</GreyButton>
            <GreenButton>Generate Preview</GreenButton>
          </FlexColumnBox>
        </Box>
      </FlexRowBox>
    </FlexColumnBox>
  );
};
