import { useEffect, useState } from "react";
import { getUserPermissionTree } from "../Services/getUserPermissionsTree";
import { PermissionPathMappings } from "../Constants/PermissionPathMapping";

export const usePermissionContextProvider = () => {
  const [permissionTree, setPermissionTree] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [failed, setFailed] = useState<boolean>(false);

  const fetchPermissionsTree = async () => {
    try {
      const data = await getUserPermissionTree(
        "3bc9c48f-15e1-4dcc-9cde-4fbeedd3bbab"
      );
      setPermissionTree(data);
    } catch {
      setFailed(true);
    } finally {
      setLoading(false);
    }
  };

  const isPermission = (path: string[]): boolean => {
    if (path.length == 0) return false;
    let baseTree = permissionTree[path[0]];
    for (let i = 1; i < path.length; i++) {
      if (!baseTree || !baseTree["_resources"]) return false;
      baseTree = baseTree["_resources"][path[i]];
    }
    if (!baseTree || !baseTree["is_permission"]) return false;
    return baseTree["is_permission"];
  };

  useEffect(() => {
    fetchPermissionsTree();
  }, []);

  const permissions = {
    createFeature: isPermission
      ? isPermission(PermissionPathMappings.featureCreate)
      : false,
    deleteFeature: isPermission
      ? isPermission(PermissionPathMappings.featureDelete)
      : false,
    updateFeature: isPermission
      ? isPermission(PermissionPathMappings.featureUpdate)
      : false,
    readFeature: isPermission
      ? isPermission(PermissionPathMappings.featureRead)
      : false,
    previewFeature: isPermission
      ? isPermission(PermissionPathMappings.featurePreview)
      : false,

    createFeatureset: isPermission
      ? isPermission(PermissionPathMappings.featuresetCreate)
      : false,
    updateFeatureset: isPermission
      ? isPermission(PermissionPathMappings.featuresetUpdate)
      : false,
    deleteFeatureset: isPermission
      ? isPermission(PermissionPathMappings.featuresetDelete)
      : false,
    readFeatureset: isPermission
      ? isPermission(PermissionPathMappings.featuresetRead)
      : false,

    createEntity: isPermission
      ? isPermission(PermissionPathMappings.entityCreate)
      : false,
    updateEntity: isPermission
      ? isPermission(PermissionPathMappings.entityUpdate)
      : false,
    deleteEntity: isPermission
      ? isPermission(PermissionPathMappings.entityDelete)
      : false,
    readEntity: isPermission
      ? isPermission(PermissionPathMappings.entityRead)
      : false,

    createTable: isPermission
      ? isPermission(PermissionPathMappings.tableCreate)
      : false,
    updateTable: isPermission
      ? isPermission(PermissionPathMappings.tableUpdate)
      : false,
    deleteTable: isPermission
      ? isPermission(PermissionPathMappings.tableDelete)
      : false,
    readTable: isPermission
      ? isPermission(PermissionPathMappings.tableRead)
      : false,

    createSchedule: isPermission
      ? isPermission(PermissionPathMappings.scheduleCreate)
      : false,
    updateSchedule: isPermission
      ? isPermission(PermissionPathMappings.scheduleUpdate)
      : false,
    deleteSchedule: isPermission
      ? isPermission(PermissionPathMappings.scheduleDelete)
      : false,
    readSchedule: isPermission
      ? isPermission(PermissionPathMappings.scheduleRead)
      : false,

    createProject: isPermission
      ? isPermission(PermissionPathMappings.projectCreate)
      : false,
    deleteProject: isPermission
      ? isPermission(PermissionPathMappings.projectDelete)
      : false,

    createBranch: isPermission
      ? isPermission(PermissionPathMappings.branchCreate)
      : false,
    updateBranch: isPermission
      ? isPermission(PermissionPathMappings.branchUpdate)
      : false,
    deleteBranch: isPermission
      ? isPermission(PermissionPathMappings.branchDelete)
      : false,

    mergeChangeRequest: isPermission
      ? isPermission(PermissionPathMappings.mergeChangeRequest)
      : false,
    editChangeRequest: isPermission
      ? isPermission(PermissionPathMappings.editChangeRequest)
      : false,
    closeChangeRequest: isPermission
      ? isPermission(PermissionPathMappings.closeChangeRequest)
      : false,

    createLabel: isPermission
      ? isPermission(PermissionPathMappings.labelCreate)
      : false,
    deleteLabel: isPermission
      ? isPermission(PermissionPathMappings.labelDelete)
      : false,
    editLabel: isPermission
      ? isPermission(PermissionPathMappings.labelEdit)
      : false,
    readLabel: isPermission
      ? isPermission(PermissionPathMappings.labelRead)
      : false,

    createDestination: isPermission
      ? isPermission(PermissionPathMappings.destinationCreate)
      : false,
    deleteDestination: isPermission
      ? isPermission(PermissionPathMappings.destinationDelete)
      : false,
    updateDestination: isPermission
      ? isPermission(PermissionPathMappings.destinationUpdate)
      : false,
    readDestination: isPermission
      ? isPermission(PermissionPathMappings.destinationRead)
      : false,

    createSource: isPermission
      ? isPermission(PermissionPathMappings.sourceCreate)
      : false,
    updateSource: isPermission
      ? isPermission(PermissionPathMappings.sourceUpdate)
      : false,
    deleteSource: isPermission
      ? isPermission(PermissionPathMappings.sourceDelete)
      : false,
    readSource: isPermission
      ? isPermission(PermissionPathMappings.sourceRead)
      : false,

    createOrchestrator: isPermission
      ? isPermission(PermissionPathMappings.orchestratorCreate)
      : false,
    deleteOrchestrator: isPermission
      ? isPermission(PermissionPathMappings.orchestratorDelete)
      : false,
    updateOrchestrator: isPermission
      ? isPermission(PermissionPathMappings.orchestratorUpdate)
      : false,
    readOrchestrator: isPermission
      ? isPermission(PermissionPathMappings.orchestratorRead)
      : false,
  };

  return {
    loading,
    failed,
    isPermission,
    permissions,
  };
};
