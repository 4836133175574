import React, { useState } from "react";
import { BlueButton } from "../../../Styles/Button";
import { DownArrowIcon } from "../../icons/DownArrowIcon";
import { Box } from "@mui/material";
import { CommonDropDownMenuContent } from "../../CommonDropDownElement";
import { DropDownMenu } from "../../../Pages/DataLayer/Components/Features/Components/CreateFeatureTabs";
import { useNavigate } from "react-router-dom";
import { usePermissionContext } from "../../../Hooks/usePermissionContext";

export const NavbarCreateButton = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const navigate = useNavigate();
  const permissionContext = usePermissionContext();
  const createFeaturePermission = permissionContext?.permissions.createFeature;
  const createEntityPermission = permissionContext?.permissions.createEntity;
  const createTablePermission = permissionContext?.permissions.createTable;
  const createSchedulePermisision =
    permissionContext?.permissions.createSchedule;
  const createFeatureSetPermission =
    permissionContext?.permissions.createFeatureset;

  const handleButtonClick = (event: any) => {
    // You can perform any action on button click here
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box sx={{ marginLeft: ".5rem" }}>
        <BlueButton onClick={handleButtonClick}>
          Create
          <DownArrowIcon />
        </BlueButton>
      </Box>
      <DropDownMenu
        minWidth="175px"
        anchorEl={anchorEl}
        handleClose={handleClose}
      >
        <CommonDropDownMenuContent
          data={[
            {
              label: "Feature",
              onClickHandler: () => {
                if (!createFeaturePermission) return;
                navigate("/data-layer/features/create-feature");
                setAnchorEl(null);
              },
              permission: createFeaturePermission,
            },
            {
              label: "Feature Template",
              onClickHandler: () => {
                setAnchorEl(null);
              },
              permission: true
            },
            {
              label: "Feature Set",
              onClickHandler: () => {
                if (!createFeatureSetPermission) return;
                navigate("/data-layer/feature-sets/create");
                setAnchorEl(null);
              },
              permission: createFeatureSetPermission
            },
            {
              label: "Schedule",
              onClickHandler: () => {
                if (!createSchedulePermisision) return;
                setAnchorEl(null);
              },
              permission: createSchedulePermisision
            },
            {
              label: "Table",
              onClickHandler: () => {
                if (!createTablePermission) return;
                navigate("/data-layer/tables/create-table");
                setAnchorEl(null);
              },
              permission: createTablePermission
            },
            {
              label: "Entity",
              onClickHandler: () => {
                if (!createEntityPermission) return;
                navigate("/data-layer/entities/create-entity");
                setAnchorEl(null);
              },
              permission: createEntityPermission
            },
          ]}
        />
      </DropDownMenu>
    </React.Fragment>
  );
};
