import React, { useEffect, useRef } from "react";
import { useEditor, EditorContent, BubbleMenu } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import Underline from "@tiptap/extension-underline";
import Strike from "@tiptap/extension-strike";
import Blockquote from "@tiptap/extension-blockquote";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import ListItem from "@tiptap/extension-list-item";
import Link from "@tiptap/extension-link";
import { Box, Button, Typography } from "@mui/material";
import "./RichTextField.css";
import { RichFieldToolbarButton } from "../../../Styles/Button";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import AddLinkIcon from "@mui/icons-material/AddLink";
import { DoubleQuotesIcon } from "../../icons/DubleQuotesIcon";

type RichTextFieldProps = {
  input: string;
  setInput: (e: string) => void;
  editorBackgroundColors?: string;
  readonly?: boolean;
};

const RichTextField = ({
  input,
  setInput,
  editorBackgroundColors,
  readonly,
}: RichTextFieldProps) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Bold,
      Italic,
      Underline,
      Strike,
      Blockquote,
      BulletList,
      OrderedList,
      ListItem,
      Link,
    ],
    content: JSON.parse(input),
    onUpdate: readonly
      ? undefined
      : ({ editor }) => {
          const json = editor.getJSON();
          setInput(JSON.stringify(json));
        },
  });

  useEffect(() => {
    if (editor) {
      editor.commands.setContent(JSON.parse(input));
    }
    const editorContainer = document.querySelector(".editor-container");
    const proseMirror = editorContainer?.querySelector(
      ".ProseMirror"
    ) as HTMLElement;

    const focusProseMirror = () => {
      proseMirror?.focus();
    };

    editorContainer?.addEventListener("click", focusProseMirror);
    return () => {
      editorContainer?.removeEventListener("click", focusProseMirror);
    };
  }, [input, editor]);

  const addLink = () => {
    const url = prompt("Enter URL");
    if (url) {
      editor?.chain().focus().setLink({ href: url }).run();
    }
  };
  const editorRef = useRef<HTMLDivElement | null>(null);

  if (readonly) {
    return (
      <EditorContent
        onClick={() => {}}
        ref={editorRef}
        className="editor-container"
        editor={editor}
        style={{ backgroundColor: editorBackgroundColors }}
      />
    );
  }

  return (
    <Box>
      {editor && (
        <Box className="rich-text-field-toolbar">
          <RichFieldToolbarButton
            onClick={() => editor.chain().focus().toggleBold().run()}
            isActive={editor.isActive("bold")}
          >
            <FormatBoldIcon />
          </RichFieldToolbarButton>
          <RichFieldToolbarButton
            onClick={() => editor.chain().focus().toggleItalic().run()}
            isActive={editor.isActive("italic")}
          >
            <FormatItalicIcon />
          </RichFieldToolbarButton>
          <i className="seprator">|</i>
          <RichFieldToolbarButton
            onClick={() => editor.chain().focus().toggleBlockquote().run()}
            isActive={editor.isActive("blockquote")}
          >
            <DoubleQuotesIcon />
          </RichFieldToolbarButton>
          <RichFieldToolbarButton
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            isActive={editor.isActive("bulletList")}
          >
            <FormatListBulletedIcon />
          </RichFieldToolbarButton>
          <RichFieldToolbarButton
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            isActive={editor.isActive("orderedList")}
          >
            <FormatListNumberedIcon />
          </RichFieldToolbarButton>
          <i className="seprator">|</i>
          <RichFieldToolbarButton
            onClick={addLink}
            isActive={editor.isActive("link")}
          >
            <AddLinkIcon />
          </RichFieldToolbarButton>
        </Box>
      )}
      <EditorContent
        onClick={() => {
          editorRef.current?.focus();
        }}
        ref={editorRef}
        className="editor-container"
        editor={editor}
        style={{ backgroundColor: editorBackgroundColors }}
      />
    </Box>
  );
};

export default RichTextField;
