import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { FlexRowBox } from "../../../../../../../Styles";
import { Colors } from "../../../../../../../Constants";

type TApprovalTextProps = {
  approvedBy: string;
  approvedAt: string;
};

export const ApprovalText = ({
  approvedBy,
  approvedAt,
}: TApprovalTextProps) => {
  return (
    <FlexRowBox>
      <CheckCircleIcon sx={{fontSize: '1.1rem', marginTop: '.25rem'}} />
      <span style={{color: Colors.APP_GREEN_BUTTON_COLOR, lineHeight: 1.5, marginLeft: '.5rem'}} >
        <strong>{approvedBy}</strong>
        approved at
        <strong>{approvedAt}</strong>
      </span>
    </FlexRowBox>
  );
};
