import { Box, Typography } from "@mui/material";
import { GenericFilter } from "../../../../Components/GenericFilter/GenericFilter";
import { GenericTable } from "../../../../Packages/Table";
import {
  DownWardAnimatedBox,
  FlexColumnBox,
  FlexRowBox,
  RightFloatTableMenuItemStyle,
} from "../../../../Styles";
import { BlueButton, RedButton } from "../../../../Styles/Button";
import { useNavigate } from "react-router-dom";
import { useSelectedRow } from "../../../../Hooks/useSelectedRows";
import { Colors } from "../../../../Constants";
import { useEffect, useMemo, useState } from "react";
import { GetAttributeResponse } from "../../../../Client/configuration-client";
import { getAttributes } from "../../../../Services/getAttributes";
import { TRowProps } from "../../../../Packages/Table/Types";
import { LabelOrAttributesTableHeader } from "../../../../Constants/TableHeaders/LabelAndAttributes";
import { Label } from "../../../../Styles/Label";
import ThreeDotsMenu from "../../../../Components/MenuListModal";
import { FilterType, TFilter, TFilterData } from "../../../../Types";
import { usePermissionContext } from "../../../../Hooks/usePermissionContext";
import { CustomToolTip } from "../../../../Styles/ToolTip";

export const LabelsAndAttributes = () => {
  const navigate = useNavigate();
  const { selectAllRows, selectedRows, updateSelectedRow, totalRows } =
    useSelectedRow([]);
  const [attributes, setAttributes] = useState<GetAttributeResponse[]>([]);
  const [filter, setFilter] = useState<TFilter>({});

  const permissionContext = usePermissionContext();

  const isCreateAttributePermission = useMemo(() => {
    if (!permissionContext) return false;
    return permissionContext.isPermission([
      "configurations",
      "configurations.labelandattributes",
      "configurations.labelandattributes.create"
    ]);
  }, []);

  const onFilterChange = (filter: TFilter) => {
    setFilter(filter);
  };

  useEffect(() => {
    getAttributes()
      .then((data) => {
        setAttributes(data);
      })
      .catch((err) => {});
  }, []);

  const tableData: TRowProps[] = attributes
    .filter((attribute) => {
      const condition1 =
        filter["type"] && filter["type"].length > 0
          ? filter["type"].includes(attribute.type)
          : true;
      let condition2 = true;
      if (filter["applies_to"] && filter["applies_to"].length > 0) {
        condition2 = false;
        filter["applies_to"].map((option: any) => {
          const isPresent = attribute.applies_to.find(
            (ele) => ele.name === option
          );
          if (isPresent) {
            condition2 = true;
          }
        });
      }
      return condition1 && condition2;
    })
    .map((attribute) => {
      return {
        row_id: attribute.name,
        data: {
          name: {
            column_id: "name",
            text: attribute.name,
          },
          type: {
            column_id: "type",
            text: attribute.type,
          },
          labels: {
            column_id: "labels",
            text: "",
            reactNode: (
              <FlexRowBox sx={{ gap: "5px" }}>
                {attribute.applies_to.map((ele) => {
                  return <Label>{ele.name}</Label>;
                })}
              </FlexRowBox>
            ),
          },
          view: {
            column_id: "view",
            text: "",
            reactNode: (
              <FlexRowBox>
                <BlueButton>View</BlueButton>
                <ThreeDotsMenu
                  minWidth="175px"
                  options={[
                    <RightFloatTableMenuItemStyle
                      showBorderBottom={true}
                      onClick={() => {}}
                    >
                      Duplicate
                    </RightFloatTableMenuItemStyle>,
                    <RightFloatTableMenuItemStyle
                      showBorderBottom={false}
                      color={Colors.APP_RED_COLOR}
                      onClick={() => {}}
                    >
                      Delete
                    </RightFloatTableMenuItemStyle>,
                  ]}
                />
              </FlexRowBox>
            ),
          },
        },
      };
    });

  const typeCount: any = {};
  const appliesToCount: any = {};

  attributes.map((attribute) => {
    if (attribute.type in typeCount) {
      typeCount[attribute.type]++;
    } else {
      typeCount[attribute.type] = 1;
    }

    attribute.applies_to.map((ele) => {
      if (ele.name in appliesToCount) {
        appliesToCount[ele.name]++;
      } else {
        appliesToCount[ele.name] = 1;
      }
    });
  });

  const filterData: TFilterData[] = [
    {
      id: "type",
      label: "Type",
      type: FilterType.CHECKBOX,
      data: Object.keys(typeCount).map((key) => {
        return {
          id: key,
          label: key,
          count: typeCount[key],
        };
      }),
    },
    {
      id: "applies_to",
      label: "Applies To",
      type: FilterType.CHECKBOX,
      data: Object.keys(appliesToCount).map((key) => {
        return {
          id: key,
          label: key,
          count: appliesToCount[key],
        };
      }),
    },
  ];

  return (
    <FlexRowBox sx={{ flexGrow: 1 }}>
      <GenericFilter
        data={filterData}
        globalSearchEnabled={true}
        onChangeFilter={onFilterChange}
      />
      <Box
        sx={{
          flexGrow: "1",
          padding: "2rem",
          backgroundColor: Colors.APP_VERY_LIGHT_BLUE_COLOR,
        }}
      >
        <FlexRowBox sx={{ justifyContent: "space-between" }}>
          <DownWardAnimatedBox flag={selectedRows.length > 0}>
            <FlexColumnBox>
              <FlexRowBox sx={{ gap: "5px" }}>
                <RedButton>Delete</RedButton>
              </FlexRowBox>
              <Typography sx={{ color: Colors.LINK_TEXT_COLOR }}>
                {selectedRows.length} row selected
              </Typography>
            </FlexColumnBox>
          </DownWardAnimatedBox>
          <FlexColumnBox>
            <CustomToolTip
              placement="top"
              title={
                isCreateAttributePermission ? "" : "You don't have permission."
              }
            >
              <BlueButton
                onClick={() => {
                  if(!isCreateAttributePermission) return;
                  navigate("/configuration/labels-and-attributes/create");
                }}
                sx={{ height: "40px", cursor: isCreateAttributePermission ? '' : 'not-allowed' }}
                className={isCreateAttributePermission ? '' : 'disabled-button'}
              >
                Create Label or Attribute
              </BlueButton>
            </CustomToolTip>
            <Typography></Typography>
          </FlexColumnBox>
        </FlexRowBox>

        <GenericTable
          rows={tableData}
          tableHeader={LabelOrAttributesTableHeader}
          selectAllRows={selectAllRows}
          updateSelectedRow={updateSelectedRow}
          totalRows={totalRows}
          selectedRows={selectedRows}
        />
      </Box>
    </FlexRowBox>
  );
};
