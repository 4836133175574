import { TableGetSchema, TableType } from "../../Client/typescript-axios-client-generated"
import { TCol, TRowProps } from "../../Packages/Table/Types"
import { BlueButton, GreyButton } from "../../Styles/Button"

export const mapToTableData = (tables: TableGetSchema[]): TRowProps[] => {
    return tables.map((table) => {
        return {
            row_id: table.id ?? '',
            data: {
                name: {
                    column_id: 'name',
                    text: table.name,
                    link: '/data-layer/tables/'+table.id
                },
                tableType: {
                    column_id: 'tableType',
                    text: table.table_type ?? ''
                },
                source: getSourcesForTable(table),
                labels: [],
                updated: {
                    column_id: 'updated',
                    text: '3 month ago',
                    toolTip: 'Mar 26, 2024 3:09 PM(IST)',
                },
                quickLook: {
                    column_id: 'quickLook',
                    text: 'Open',
                    onClickHandler: () => {},
                    buttonElement: GreyButton
                },
                view: {
                    column_id: 'view',
                    text: 'View',
                    buttonElement: BlueButton,
                    onClickHandler: () => {},

                }
            }
        }
    })
}

const getSourcesForTable = (table:TableGetSchema): TCol | TCol[] => {
    if(table.table_type === TableType.ExternalTable) {
        return {
            column_id: 'source',
            text: '',
            link: '/configuration/sources/'
        }
    }else{
        return table.table_sources.map((table_source) => {
            return {
                column_id: 'source',
                text: table_source.name,
                link: '/data-layer/tables/'+""
            }
        })
    }
}