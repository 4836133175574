import { Box, CircularProgress } from "@mui/material";
import { FlexColumnBox, FlexRowBox } from "../../../../../Styles";
import { BlueButton } from "../../../../../Styles/Button";
import { Colors } from "../../../../../Constants";
import CustomTab from "../../../../../Components/Tab";
import { useEffect, useState } from "react";
import { CreateFeatureSetForm } from "./CreateFeatureSetForm";
import { TEntity, TFilter } from "../../../../../Types";
import { getAllFeaturesByBranchId } from "../../../../../Services/getAllFeatures";
import { useVersionControlContext } from "../../../../../Hooks/useVersionControlContext";
import { useToast } from "../../../../../Hooks/useToast";
import { ToastType } from "../../../../../Context/Providers/ToastProvider";
import { Preview } from "./Preview";
import { HeadingTypography } from "../../../../../Styles/CustomTypography";
import { validateStringContainOnlyLowerCaseOrUnderScore } from "../../../../../Utils/CommonFunctions";
import { fromEntityToTEntityArray } from "../../../../../Utils/Mapper/EntitesMapper";
import { createFeatureSet } from "../../../../../Services/createFeatureSet";
import { useLocation, useNavigate } from "react-router-dom";
import { getFeatureSetById } from "../../../../../Services/getFeatureSetById";
import { Loader } from "../../../../../Components/Loader";
import { getAllBranchEntities } from "../../../../../Services/getAllEntities";
import { BaseFeature } from "../../../../../Client/typescript-axios-client-generated";
import { GenericLabelAndAttributesInput } from "../../../../../Packages/LabelsAndAttribute";

export const CreateFeatureSet = () => {
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("{}");
  const [selectedEntity, setSelectedEntity] = useState<string[]>([]);
  const [entityData, setEntityData] = useState<TEntity[]>([]); // Initialize this with appropriate data
  const [selectedFeature, setSelectedFeature] = useState<string[]>([]);
  const [featureData, setFeatureData] = useState<BaseFeature[]>([]); // Initialize this with appropriate data
  const [nameValidationArray, setNameValidationArray] = useState<string[]>([]);
  const [entityValidationArray, setEntityValidationArray] = useState<string[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dataLoadFailed, setDataLoadFailed] = useState<boolean>(false);
  const [attributes, setAttributes] = useState<TFilter>({});

  const navigate = useNavigate();

  const versionControlContext = useVersionControlContext();
  const toast = useToast();

  const location = useLocation();
  const { state } = location;
  const { edit, duplicate, featureSetId } = state
    ? state
    : { edit: false, duplicate: false, featureSetId: "" };

  useEffect(() => {
    if (!versionControlContext?.currentBranch) return;

    const fetchAllData = async () => {
      try {
        const [features, entities, featureSetData] =
          await Promise.all([
            getAllFeaturesByBranchId(
              versionControlContext.currentProjectName,
              versionControlContext.currentBranchName
            ),
            getAllBranchEntities(
              versionControlContext.currentProjectName,
              versionControlContext.currentBranchName
            ),
            edit || duplicate
              ? getFeatureSetById(
                  versionControlContext.currentProjectName,
                  versionControlContext.currentBranchName,
                  featureSetId
                )
              : Promise.resolve(""),
          ]);

        setFeatureData(features);

        const convertedEntityData = fromEntityToTEntityArray(entities);
        setEntityData(convertedEntityData);

        if (edit || duplicate) {
          if (typeof featureSetData === "string") {
            throw Error("Failed to load data");
          }
          setName(featureSetData.name);
          setDescription(featureSetData.description ?? "{}");
          setSelectedEntity([featureSetData.entity_id]);
          setSelectedFeature(featureSetData.features.map(feature => feature.id ?? '')); // need to add id in feature base get schema
        }
        setIsLoading(false);
      } catch (err) {
        console.error(err);
        toast.open("Failed to load data", ToastType.ERROR);
        setDataLoadFailed(true);
      }
    };

    fetchAllData();
  }, [versionControlContext?.currentBranch]);

  const updateSelectedEntity = (selectedEntity: string[]) => {
    setEntityValidationArray([]);
    if (selectedEntity.length === 0) {
      setEntityValidationArray([
        ...entityValidationArray,
        "This field is required",
      ]);
    }
    setSelectedEntity(selectedEntity);
  };

  const getMetaInformation = () => {
    return (
      <GenericLabelAndAttributesInput
        entity_type_id={""}
        onChange={(e: any) => {
          setAttributes(e);
        }}
      />
    );
  };

  const handleCreateFeatureSet = () => {
    // Logic for creating the feature set goes here
    setNameValidationArray([]);
    setEntityValidationArray([]);
    let isValidationFailed = false;
    if (
      !validateStringContainOnlyLowerCaseOrUnderScore(name) ||
      name.length === 0
    ) {
      setNameValidationArray([
        ...nameValidationArray,
        "Please enter valid name",
      ]);
      isValidationFailed = true;
    }
    if (selectedEntity.length === 0) {
      setEntityValidationArray([
        ...entityValidationArray,
        "This field is required",
      ]);
      isValidationFailed = true;
    }
    if (selectedFeature.length === 0) {
      toast.open(
        "Atleast one feature is requied to create feature set",
        ToastType.WARNING
      );
      isValidationFailed = true;
    }
    if (
      !versionControlContext?.currentBranch ||
      !versionControlContext.currentProject
    ) {
      toast.open("Failed to find branch and project", ToastType.WARNING);
      return;
    }
    if (isValidationFailed) return;

    if (edit) {
      handleUpdateFeatureSet();
    }
    if (versionControlContext === undefined) return;

    createFeatureSet(
      versionControlContext.currentProjectName,
      versionControlContext.currentBranchName,
      {
        name: name,
        entity_id: selectedEntity[0],
        features: selectedFeature,
        attributes_data: Object.keys(attributes).map((key) => {
          return {
            attribute_id: key,
            value: attributes[key]
          }
        })
      }
    )
      .then((data) => {
        toast.open("Feature Set created successfully.", ToastType.SUCCESS);
        navigate("/data-layer/feature-sets");
      })
      .catch((err) => {
        toast.open(
          "Something went wrong while creating Feature Set",
          ToastType.ERROR
        );
      });
  };

  const handleUpdateFeatureSet = () => {
    // TODO implement this funciton with backend after backend work is finished
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <FlexColumnBox sx={{ flexGrow: 1 }}>
      <FlexRowBox
        sx={{
          justifyContent: "space-between",
          borderBottom: `1px solid ${Colors.SIDE_LINK_HOVER_COLOR}`,
          padding: "1rem",
        }}
      >
        <HeadingTypography>
          {edit ? "Update" : "Create"} Feature Set
        </HeadingTypography>
        <FlexRowBox>
          <BlueButton
            disabled={false}
            startIcon={false && <CircularProgress size={20} color="inherit" />}
            sx={{ borderRadius: "0" }}
            onClick={handleCreateFeatureSet}
          >
            {edit ? "Update" : "Create"} Feature Set
          </BlueButton>
        </FlexRowBox>
      </FlexRowBox>
      <FlexRowBox>
        <Box sx={{ padding: "2rem", width: "50%" }}>
          <CustomTab
            data={[
              {
                tabText: "Feature Set",
                tabPanel: (
                  <CreateFeatureSetForm
                    name={name}
                    description={description}
                    selectedEntity={selectedEntity}
                    entityData={entityData}
                    selectedFeature={selectedFeature}
                    featureData={featureData}
                    updateName={setName}
                    updateDescription={setDescription}
                    updateSelectedEntity={updateSelectedEntity}
                    updateSelectedFeature={setSelectedFeature}
                    nameValidationArray={nameValidationArray}
                    entityValidationArray={entityValidationArray}
                  />
                ),
              },
              {
                tabText: "Meta Information",
                tabPanel: getMetaInformation(),
              },
            ]}
          />
        </Box>

        <Preview />
      </FlexRowBox>
    </FlexColumnBox>
  );
};
