import { SingleSelectInputData } from "../../Components/Forms/SingleSelection";

export const tableTimeSemantics: SingleSelectInputData[] = [
    {
        optionId: "EVENT_OR_FACT_TABLE",
        title: "Event or Fact table",
        description: "Each row reperesent an Event or Fact which occured at a specific point in time"
    },
    {
        optionId: "SLOWLY_CHANGING_DIMENSION_TYPE_2",
        title: "Slowly Changing Dimension Type 2",
        description: "Each row has a valid from date(inclusive) and a valid to date (exclusive)"
    },
    {
        optionId: "POINT_IN_TIME",
        title: "Point in Time",
        description: "Each row represents state which is valid from a point in time going forwards. it acts like an SCD2 table where the valid to date is inferred from the following rows valid from date."
    },
    {
        optionId: "NONE",
        title: "None",
        description: "The table does not have time information"
    }
]
