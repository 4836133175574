import { CreateFormHeader } from "../../../Components/CreateFormHeader";
import { ToastType } from "../../../Context/Providers/ToastProvider";
import { useToast } from "../../../Hooks/useToast";
import { createSource } from "../../../Services/createSource";
import { useCreateSource } from "../Hooks/useSourceContext";
import { CreateSourceType } from "../Type";

type HeaderProps = {
  type: CreateSourceType;
};

export const Header = ({ type }: HeaderProps) => {
  const createSourceContext = useCreateSource();

  const toast = useToast();

  const handleCreateSource = () => {
    const selectedSourceType = createSourceContext.state.source_types_data.find(
      (sourceType) => sourceType.id === createSourceContext.state.source_type[0]
    );

    if (!selectedSourceType) return;

    const selectConfigData =
      createSourceContext.state.config_data[selectedSourceType.id];

    createSource({
      name: createSourceContext.state.name,
      source_type_id: createSourceContext.state.source_type[0],
      description: "",
      config_data: {
        bucket: selectConfigData.bucket,
        base_path: selectConfigData.base_path,
        file_type: selectConfigData.file_type,
        url: selectConfigData.url,
        database: selectConfigData.database,
        bootstrap_server: selectConfigData.bootstrap_server,
        schema_registry_url: selectConfigData.schema_registry_url,
      },
      attribute_data: Object.entries(createSourceContext.state.attribute_data).map((entry) => {
          return {
            attribute_id: entry[0],
            value: entry[1],
          };
      }),
    })
      .then((data: any) => {
        toast.open("Source created successfully", ToastType.SUCCESS);
      })
      .catch((err: any) => {
        toast.open(
          "Something went wrong while creating Source",
          ToastType.ERROR
        );
      });
  };

  const handleCreateDestination = () => {};

  return (
    <CreateFormHeader
      heading={
        type === CreateSourceType.SOURCE
          ? "Create Source"
          : "Create Destination"
      }
      submitButtonText="Create Source"
      submitHandler={handleCreateSource}
    />
  );
};
