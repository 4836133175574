import { useState } from "react";
import { TableCell, TableRow, Accordion, AccordionDetails } from "@mui/material";
import { TRowProps, TTableHeader } from "../Types";
import { GenericColumn } from "./Column";
import { StyledThinBorderCheckBox } from "../../../Styles/CheckBoxes";
import { Colors } from "../../../Constants";

type RowProps = {
  row: TRowProps;
  tableHeader: TTableHeader[];
  selected: string[];
  updateSelect: (rowId: string) => void;
  hideCheckbox?: boolean;
  padding?: string;
};

export const Row = ({ row, tableHeader, selected, updateSelect, hideCheckbox, padding }: RowProps) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const isSelected = (rowId: string) => selected.includes(rowId);

  const handleClick = () => {
    updateSelect(row.row_id);
  };

  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <TableRow key={row.row_id} tabIndex={-1} sx={{ cursor: "pointer", margin: '0',':hover': { backgroundColor: Colors.APP_VERY_LIGHT_BLUE_COLOR } }}>
        {!hideCheckbox && <TableCell sx={{padding: padding}} >
          <StyledThinBorderCheckBox checked={isSelected(row.row_id)} onChange={handleClick} sx={{margin: '0', padding: '0'}} />
        </TableCell>}
        {tableHeader.map((column_detail) => {
          return <GenericColumn key={column_detail.column_id} column={row.data[column_detail.column_id]} rowId={row.row_id} padding={padding} />;
        })}
      </TableRow>
      {row.currentlyOpenedElementId === row.row_id && <TableRow>
        <TableCell colSpan={tableHeader.length + 1} sx={{ padding: 0 }}>
          <Accordion expanded={expanded === row.row_id} onChange={handleAccordionChange(row.row_id)}>
            <AccordionDetails>
              {/* Put the content you want to display inside the accordion here */}
              {row.accordianElement}
            </AccordionDetails>
          </Accordion>
        </TableCell>
      </TableRow>}
    </>
  );
};
