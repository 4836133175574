import { EntityMappingCreateSchema } from "../Client/typescript-axios-client-generated";
import { ApiClient } from "../Utils/ApiClient";

export const createEntityMapping = async (projectName: string,branchName: string ,body: EntityMappingCreateSchema) => {
  ApiClient.entityMappingApiClient
    .createEntityMappingEntityMappingPost(projectName, branchName, body)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};
