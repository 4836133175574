import { Box, FormGroup } from "@mui/material";
import { InputTextField } from "../../../../../Components/Forms/InputTextField";
import { FlexColumnBox } from "../../../../../Styles";
import { SelectTransformationType } from "./SelectTransformationType";
import {
  LabelTypography,
  SmallSizeLightColorTypography,
} from "../../../../../Styles/CustomTypography";
import { EventFeature } from "./EventFeature";
import {
  TCreateFeatureData,
  TransformationType,
} from "../../../../../Types/CreateFeatureForm";
import { Action } from "../../../../../Reducers/CreateFeatureReducer";
import { CompositeFeature } from "./CompositeFeature";
import { SqlFeature } from "./SqlFeature";
import RichTextField from "../../../../../Components/Forms/RichTextInputField";

type CreateFeatureDefinitionProps = {
  createFeatureState: TCreateFeatureData;
  dispatch: React.Dispatch<Action>;
};

export const CreateFeature = ({
  createFeatureState,
  dispatch,
}: CreateFeatureDefinitionProps) => {
  return (
    <FlexColumnBox sx={{ gap: "1rem" }}>
      <SelectTransformationType
        transformationType={createFeatureState.transformationType}
        dispatch={dispatch}
      />
      { createFeatureState.transformationType && <FormGroup sx={{ gap: "1rem" }}>
        <Box>
          <LabelTypography>Name</LabelTypography>
          <SmallSizeLightColorTypography>
            Name must be unique, start with a lower case letter and may only
            contain underscores, letters and numbers.
          </SmallSizeLightColorTypography>
          <InputTextField
            onChange={(e) => {
              dispatch({
                type: "UPDATE_NAME",
                payload: e.target.value,
              });
            }}
            value={createFeatureState.name}
            violationArray={createFeatureState.validationErrors.name}
          />
        </Box>
        <Box>
          <Box>
            <LabelTypography>Description (optional)</LabelTypography>
          </Box>
          <RichTextField
            input={createFeatureState.description}
            setInput={(description) => {
              dispatch({
                type: "UPDATE_DESCRIPTION",
                payload: description,
              });
            }}
          />
        </Box>
        {createFeatureState.transformationType ===
          TransformationType.EVENT_FEATURE && (
          <EventFeature
            data={createFeatureState[TransformationType.EVENT_FEATURE]}
            dispatch={dispatch}
            validationError={createFeatureState.eventFeatureValidationError}
          />
        )}
        {createFeatureState.transformationType ===
          TransformationType.COMPOSITE_FEATURE && (
          <CompositeFeature
            data={createFeatureState[TransformationType.COMPOSITE_FEATURE]}
            dispatch={dispatch}
            validationErrors={
              createFeatureState.compositeFeatureValidationError
            }
          />
        )}
        {createFeatureState.transformationType ===
          TransformationType.SQL_FEATURE && (
          <SqlFeature
            data={createFeatureState[TransformationType.SQL_FEATURE]}
            dispatch={dispatch}
            validationErrors={createFeatureState.sqlFeatureValidationError}
          />
        )}
      </FormGroup>}
    </FlexColumnBox>
  );
};
