import { CustomDropDown } from "../../../../../Components/Forms/DropDown";
import { FlexColumnBox } from "../../../../../Styles";
import {
  TEventFeature,
  TEventFeatureValidationError,
} from "../../../../../Types/CreateFeatureForm";
import { Action } from "../../../../../Reducers/CreateFeatureReducer";
import { aggregationFunctions } from "../../../../../Constants/AggregationFunctionData";
import { Box } from "@mui/material";
import {
  LabelTypography,
  SmallSizeLightColorTypography,
} from "../../../../../Styles/CustomTypography";
import SqlEditor from "../../../../../Components/SqlEditor";
import { CustomSwitch } from "../../../../../Components/CustomSwitch";
import { useEffect, useMemo, useState } from "react";
import { SingleSelectInput } from "../../../../../Components/Forms/SingleSelection";
import { WindowOptions } from "../../../../../Constants/WindowOptions";
import { InputTextField } from "../../../../../Components/Forms/InputTextField";
import { TableGetSchema } from "../../../../../Client/typescript-axios-client-generated";
import { useVersionControlContext } from "../../../../../Hooks/useVersionControlContext";
import { getTables } from "../../../../../Services/getTables";
import { useGetEntities } from "../../../../../Hooks/DataFetcher/Entity/useGetEntities";

type EventFeatureProps = {
  data: TEventFeature;
  dispatch: React.Dispatch<Action>;
  validationError: TEventFeatureValidationError;
};

export const EventFeature = ({
  data,
  dispatch,
  validationError,
}: EventFeatureProps) => {
  const [tablesData, setTablesData] = useState<TableGetSchema[]>([]);
  const versionControlContext = useVersionControlContext();

  const { entities } = useGetEntities();

  useEffect(() => {
    if (!versionControlContext?.currentBranch) return;
    getTables(
      versionControlContext.currentProjectName,
      versionControlContext.currentBranchName
    )
      .then((data) => {
        setTablesData(data);
      })
      .catch((err) => {});
  }, [versionControlContext?.currentBranch]);

  const dropDownTableData = useMemo(() => {
    return tablesData.map((table) => {
      return {
        label: table.name,
        itemId: table.id ?? "",
      };
    });
  }, [tablesData]);

  const handleAggregationExpressionChnage = (newValue: string) => {
    dispatch({
      type: "UPDATE_EVENT_FEATURE",
      payload: {
        ...data,
        aggregationExpression: newValue,
      },
    });
  };

  const handleAdditionalFilterValueChange = (newValue: string) => {
    dispatch({
      type: "UPDATE_EVENT_FEATURE",
      payload: {
        ...data,
        additionalFilterValue: newValue,
      },
    });
  };

  const handlePostAggregationExpressionValueChange = (newValue: string) => {
    dispatch({
      type: "UPDATE_EVENT_FEATURE",
      payload: {
        ...data,
        postAggregationExpressionValue: newValue,
      },
    });
  };

  let windowTypeLabel: any = data.windowType;
  windowTypeLabel = windowTypeLabel.split("_");
  if (
    windowTypeLabel.length >= 2 &&
    windowTypeLabel[0].length >= 1 &&
    windowTypeLabel[1].length >= 1
  ) {
    windowTypeLabel =
      windowTypeLabel[0].charAt(0).toUpperCase() +
      windowTypeLabel[0].slice(1).toLowerCase() +
      " " +
      windowTypeLabel[1].charAt(0).toUpperCase() +
      windowTypeLabel[1].slice(1).toLowerCase();
  }

  return (
    <FlexColumnBox sx={{ gap: "1rem" }}>
      <Box>
        <LabelTypography>Table</LabelTypography>
        <CustomDropDown
          data={dropDownTableData}
          multiple={false}
          selectedItems={data.table}
          updateSelectedItems={(selectedItems) => {
            if (validationError.table.length > 0) {
              dispatch({
                type: "UPDATE_EVENT_FEATURE_VALIDATION_ERROR",
                payload: { table: [] },
              });
            }
            dispatch({
              type: "UPDATE_EVENT_FEATURE",
              payload: { ...data, table: selectedItems },
            });
          }}
          validationsErrors={validationError.table}
        />
      </Box>
      <Box>
        <LabelTypography>Entity Restriction (Optional)</LabelTypography>
        <CustomDropDown
          data={entities.map((entity) => {
            return {
              itemId: entity.id,
              label: entity.output_column ?? "",
            };
          })}
          multiple
          selectedItems={data.entityRestriction}
          updateSelectedItems={(selectedItems) => {
            dispatch({
              type: "UPDATE_EVENT_FEATURE",
              payload: { ...data, entityRestriction: selectedItems },
            });
          }}
        />
      </Box>
      <Box>
        <LabelTypography>Aggregation</LabelTypography>
        <CustomDropDown
          validationsErrors={validationError.aggregation}
          data={aggregationFunctions}
          multiple={false}
          selectedItems={data.aggregation}
          updateSelectedItems={(selectedItems) => {
            if (validationError.aggregation.length > 0) {
              dispatch({
                type: "UPDATE_EVENT_FEATURE_VALIDATION_ERROR",
                payload: { aggregation: [] },
              });
            }
            dispatch({
              type: "UPDATE_EVENT_FEATURE",
              payload: { ...data, aggregation: selectedItems },
            });
          }}
        />
      </Box>
      <Box>
        <LabelTypography>Aggregation Expression</LabelTypography>
        <Box sx={{ maxWidth: "400px" }}>
          <SmallSizeLightColorTypography>
            Enter the SQL expression to select the data to pass into the
            aggregation function. This may be as simple as a column name but can
            include function calls and CASE expressions.
          </SmallSizeLightColorTypography>
          <SmallSizeLightColorTypography>
            To find the sum over a column called profit the aggregation would be
            sum expression would be:
          </SmallSizeLightColorTypography>
          <SmallSizeLightColorTypography>
            - profit
          </SmallSizeLightColorTypography>
          <SmallSizeLightColorTypography>
            If you need the current_date use the feature_date() function
            instead. To calculate a person age with an aggregation of Last the
            expression might be:
          </SmallSizeLightColorTypography>
          <SmallSizeLightColorTypography>
            - datediff(feature_date(), date_of_birth) / 365.2425
          </SmallSizeLightColorTypography>
        </Box>
        <SqlEditor
          data={data.aggregationExpression}
          onChange={handleAggregationExpressionChnage}
        />
      </Box>
      <Box>
        <LabelTypography>Additional Filter</LabelTypography>
        {data.additionalFilter && (
          <SmallSizeLightColorTypography maxWidth={"400px"}>
            Addtional filters can be used to remove rows that are not relevent
            prior to aggregation. Any SQL expression that is valid here.
          </SmallSizeLightColorTypography>
        )}
        <CustomSwitch
          value={data.additionalFilter}
          onChange={() => {
            dispatch({
              type: "UPDATE_EVENT_FEATURE",
              payload: {
                ...data,
                additionalFilter: !data.additionalFilter,
              },
            });
          }}
        />
        <br />
        <br />
        {data.additionalFilter && (
          <SqlEditor
            data={data.additionalFilterValue}
            onChange={handleAdditionalFilterValueChange}
          />
        )}
      </Box>
      <Box>
        <LabelTypography>Post Aggregation Expression</LabelTypography>
        {data.postAggregationExpresion && (
          <SmallSizeLightColorTypography maxWidth={"400px"}>
            Post aggregation expressions are run after the aggregation has
            completed. These are specified with a single argument lambda
            expression which is applied to the aggregation result.
          </SmallSizeLightColorTypography>
        )}
        <CustomSwitch
          value={data.postAggregationExpresion}
          onChange={() => {
            dispatch({
              type: "UPDATE_EVENT_FEATURE",
              payload: {
                ...data,
                postAggregationExpresion: !data.postAggregationExpresion,
              },
            });
          }}
        />
        <br />
        <br />
        {data.postAggregationExpresion && (
          <SqlEditor
            data={data.postAggregationExpressionValue}
            onChange={handlePostAggregationExpressionValueChange}
          />
        )}
      </Box>
      {data.table.length > 0 && (
        <Box>
          <SingleSelectInput
            label={"Window Type"}
            data={WindowOptions}
            onSelect={(optionId) => {
              dispatch({
                type: "UPDATE_EVENT_FEATURE",
                payload: {
                  windowType: optionId,
                },
              });
            }}
            selectedOptionId={data.windowType}
          />
        </Box>
      )}
      {data.windowType != "OPEN_WINDOW" && (
        <Box>
          <LabelTypography>{windowTypeLabel} Size</LabelTypography>
          <SmallSizeLightColorTypography>
            Enter the window size
          </SmallSizeLightColorTypography>
          <InputTextField
            onChange={(event) => {
              const value = event.target.value;
              dispatch({
                type: "UPDATE_EVENT_FEATURE",
                payload: {
                  windowSize: parseInt(value),
                },
              });
            }}
            value={data.windowSize}
            violationArray={[]}
            type="number"
          />
        </Box>
      )}
    </FlexColumnBox>
  );
};
