import { ApiClient } from "../Utils/ApiClient";

export const getFeatureGenerationById = (
  schedule_id: string,
  project_name: string,
  branch_name: string
) => {
  return ApiClient.scheduleClient
    .getScheduleByIdSchedulerFeatureGenerationScheduleScheduleIdGet(
      schedule_id,
      project_name,
      branch_name
    )
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};
