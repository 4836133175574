import React, { useState } from "react";
import { BranchButton } from "../Styles/Button";
import { BranchIcon } from "./icons/BranchIcon";
import { DownArrowIcon } from "./icons/DownArrowIcon";
import { Box } from "@mui/material";
import { GithubMenu } from "./GithubMenu";
import { LabelTypography } from "../Styles/CustomTypography";
import { Branch } from "./Branch";
import { FlexColumnBox, UnderLineOnHoverNavLink } from "../Styles";
import { NavLink } from "react-router-dom";
import { useVersionControlContext } from "../Hooks/useVersionControlContext";
import { isMatchingSubString } from "../Utils/CommonFunctions";
import { CreateBranch } from "../Pages/Governance/Components/Branch.tsx/Components/CreateBranch";
import { Colors } from "../Constants";

const GithubBranchesDropdown = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchBranch, setSearchBranch] = useState<string>("");
  const versionControlContext = useVersionControlContext();
  const [openCreateBranchModal, setOpenCreateBranchModal] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUpdateBranchSeacrh = (branch: string) => {
    setSearchBranch(branch);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCreateBranch = () => {
    setOpenCreateBranchModal(true);
  }

  const handleCancelAndCompletion = () => {
    setOpenCreateBranchModal(false)
  }

  const branchMenu = () => {
    return (
      <FlexColumnBox>
        {versionControlContext?.branchesList
          .filter((branch) => isMatchingSubString(searchBranch, branch.name))
          .map((branch) => {
            return (
              <Box
                onClick={() => {
                  versionControlContext.updateCurrentBranch(branch.id);
                }}
              >
                <Branch
                  name={branch.name}
                  isSelected={versionControlContext.currentBranch === branch.id}
                  isDefault={branch.name == "main"}
                />
              </Box>
            );
          })}
        <Box sx={{ textAlign: "center" }}>
          <UnderLineOnHoverNavLink to="/" style = {{color: `${Colors.APP_BLUE_COLOR}`}}>
            view all
          </UnderLineOnHoverNavLink>
        </Box>
      </FlexColumnBox>
    );
  };

  return (
    <Box>
      {openCreateBranchModal && (<CreateBranch handleCancelAndCompletion={handleCancelAndCompletion}/>)}
      <BranchButton onClick={handleClick}>
        <span>
          <BranchIcon />
        </span>
        <span>
          <LabelTypography>{versionControlContext?.branchesList?.find(branch => branch.id === versionControlContext.currentBranch)?.name}</LabelTypography>
        </span>
        <span>
          <DownArrowIcon />
        </span>
      </BranchButton>
      <GithubMenu
        anchorEl={anchorEl}
        title={"Switch branches"}
        children={branchMenu()}
        handleClose={handleClose}
        searchPlaceholder={"Seach Branch"}
        search={searchBranch}
        handleUpdatedSeacrh={handleUpdateBranchSeacrh}
        handlePlusClick={handleCreateBranch}
      />
    </Box>
  );
};

export default GithubBranchesDropdown;