import React from 'react';
import ReactDiffViewer from 'react-diff-viewer';

interface DiffViewerProps {
  oldText: string;
  newText: string;
}

const DiffViewer: React.FC<DiffViewerProps> = ({ oldText, newText }) => {
  return (
    <div className="diff-container" style={{maxHeight: '100px', overflow: 'scroll'}} >
      <ReactDiffViewer
        oldValue={oldText}
        newValue={newText}
        splitView={true} // Enable split view
        showDiffOnly={true} // Show only lines that differ
        hideLineNumbers={true}
        styles={{
          lineNumber: {
            backgroundColor: '#f4f4f4',
          },
          gutter: {
            backgroundColor: '#eee',
          },
        }}
      />
    </div>
  );
};

export default DiffViewer;
