import { Box, List, ListItem } from "@mui/material";
import { FlexColumnBox, FlexRowBox } from "../../../../../Styles";
import {
  LabelTypography,
  SmallSizeLightColorTypography,
} from "../../../../../Styles/CustomTypography";
import { CustomDropDown } from "../../../../../Components/Forms/DropDown";
import { BlueButton, GreyButton } from "../../../../../Styles/Button";
import ConfirmationModal from "../../../../DataLayer/Components/ConfirmationModal";
import { Colors } from "../../../../../Constants";
import "../Styles/index.css";
import { InputTextField } from "../../../../../Components/Forms/InputTextField";
import { SingleSelectInput } from "../../../../../Components/Forms/SingleSelection";
import { EMode, TDestination } from "../Type";
import { CustomSwitch } from "../../../../../Components/CustomSwitch";
import { useState } from "react";
import { convertStringToEnum } from "../../../../../Utils/CommonFunctions";

type AddDestinationProps = {
  closeHandler: () => void;
  createHandler: (data: TDestination, index?: number) => void;
  destinationToUpdate?: { index: number } & TDestination;
};

export const AddDestination = ({
  createHandler,
  closeHandler,
  destinationToUpdate,
}: AddDestinationProps) => {
  console.log(destinationToUpdate)
  const [destination, setDestination] = useState<TDestination>(
    destinationToUpdate
      ? destinationToUpdate
      : {
          destination: undefined,
          tableName: "",
          mode: EMode.ERROR_IF_EXISTS,
          allowFieldAddition: false,
          allowFieldRelaxation: false,
          partitionOverwriteMode: false,
          bigqueryIntermediateFormat: "",
        }
  );

  const getChildren = () => {
    return (
      <FlexColumnBox sx={{ padding: "1rem", gap: "2rem" }}>
        <Box>
          <LabelTypography>Destination</LabelTypography>
          <FlexRowBox sx={{ gap: "1rem" }}>
            <Box sx={{ flexGrow: 1 }}>
              <CustomDropDown
                data={[
                  {
                    label: "destination1",
                    itemId: "1",
                  },
                ]}
                multiple={false}
                selectedItems={
                  destination.destination ? [destination.destination] : []
                }
                updateSelectedItems={(selectedItems: string[]) => {
                  setDestination((prev) => {
                    return { ...prev, destination: selectedItems[0] };
                  });
                }}
              />
            </Box>

            <GreyButton>Browse</GreyButton>
          </FlexRowBox>
        </Box>
        <Box>
          <LabelTypography>Table Name</LabelTypography>
          <SmallSizeLightColorTypography>
            Available macros to use in table name include:
          </SmallSizeLightColorTypography>
          <ul className="list">
            <li className="list-item">
              <SmallSizeLightColorTypography>
                <span className="highlight">{"{{run_date}}:"}</span>replaced
                with date at the time of writing to the destination.
              </SmallSizeLightColorTypography>
            </li>
            <li className="list-item">
              <SmallSizeLightColorTypography>
                <span className="highlight">{"{{run_timestamp}}:"}</span>
                replaced with timestamp at the time of writing to the
                destination.
              </SmallSizeLightColorTypography>
            </li>
            <li className="list-item">
              <SmallSizeLightColorTypography>
                <span className="highlight">{"{{run_date}}:"}</span>replaced
                with unique run identifier at the time of writing to the
                destination.
              </SmallSizeLightColorTypography>
            </li>
          </ul>
          <SmallSizeLightColorTypography>Example</SmallSizeLightColorTypography>
          <ul className="list">
            <li className="list-item">
              <SmallSizeLightColorTypography>
                <span className="highlight">{"output_{{run_date}}"}</span> will
                result in the table output_2024-07-23
              </SmallSizeLightColorTypography>
            </li>
          </ul>
          <InputTextField
            onChange={(e) => {
              setDestination((prev) => {
                return { ...prev, tableName: e.target.value };
              });
            }}
            value={destination.tableName}
            violationArray={[]}
            placeholder="table_name"
          />
        </Box>
        <Box>
          <SingleSelectInput
            label={"Mode"}
            data={[
              {
                optionId: EMode.ERROR_IF_EXISTS,
                title: "ErrorIfExists",
                description:
                  "If data already exists, an exception is expected to be thrown.",
              },
              {
                optionId: EMode.APPEND,
                title: "Append",
                description:
                  "If data/table already exists, contents of the DataFrame are expected to be appended to exiting data.",
              },
              {
                optionId: EMode.OVERWRITE,
                title: "Overwrite",
                description:
                  "If data/table already exists, existing data is expected to be overwritten by the contents of the DataFrames.",
              },
              {
                optionId: EMode.IGNORE,
                title: "Ignore",
                description:
                  "If data already exists, the save operation is expected nor to save the contents of the DataFrame and not to change the existing data.",
              },
            ]}
            onSelect={(id: string) => {
              const convertedId = convertStringToEnum(EMode, id);
              if (convertedId)
                setDestination((prev) => {
                  return { ...prev, mode: convertedId };
                });
            }}
            selectedOptionId={destination.mode}
            showAsGrid
            minWidth="200px"
            columnCount={2}
          />
        </Box>
        <Box>
          <LabelTypography>Allow Field Addidtion</LabelTypography>
          <SmallSizeLightColorTypography>
            Allow the schema of the destination table to be updated to include
            row columns (eg. new feature added to feature set).
          </SmallSizeLightColorTypography>
          <CustomSwitch
            checked={destination.allowFieldAddition}
            onChange={() => {
              setDestination((prev) => {
                return {
                  ...prev,
                  allowFieldAddition: !prev.allowFieldAddition,
                };
              });
            }}
          />
        </Box>
        <Box>
          <LabelTypography>Allow Field Relaxation</LabelTypography>
          <SmallSizeLightColorTypography>
            Allow a required column in the destination table schema to be made
            nullabe (eg. feature removed from feature set).
          </SmallSizeLightColorTypography>
          <CustomSwitch
            checked={destination.allowFieldRelaxation}
            onChange={() => {
              setDestination((prev) => {
                return {
                  ...prev,
                  allowFieldRelaxation: !prev.allowFieldRelaxation,
                };
              });
            }}
          />
        </Box>
        <Box>
          <LabelTypography>Partition Overwrite Mode</LabelTypography>
          <SmallSizeLightColorTypography>
            When the mode is Overwrite and Partition Overwrite is on, only
            partition generated in the run are overwritten and not the entire
            table. This is not an atomic operation
          </SmallSizeLightColorTypography>
          <CustomSwitch
            checked={destination.partitionOverwriteMode}
            onChange={() => {
              setDestination((prev) => {
                return {
                  ...prev,
                  partitionOverwriteMode: !prev.partitionOverwriteMode,
                };
              });
            }}
          />
        </Box>
        <Box>
          <LabelTypography>Bigquery Intermediate Format</LabelTypography>
          <SmallSizeLightColorTypography>
            The format of the data before it is loaded to BigQuery
          </SmallSizeLightColorTypography>
        </Box>
        <FlexRowBox
          sx={{
            justifyContent: "flex-end",
            gap: "1rem",
            borderTop: `1px solid ${Colors.SIDE_LINK_HOVER_COLOR}`,
            paddingTop: "1rem",
          }}
        >
          <GreyButton onClick={closeHandler}>Cancel</GreyButton>
          <BlueButton
            onClick={() => {
              if(destinationToUpdate){
                createHandler(destination, destinationToUpdate.index)
              }else{
                createHandler(destination);
              }
            }}
            disabled={
              !destination.destination ||
              !destination.tableName ||
              !destination.mode
            }
          >
            {destinationToUpdate ? 'Update' : 'Create'}
          </BlueButton>
        </FlexRowBox>
      </FlexColumnBox>
    );
  };

  return (
    <ConfirmationModal
      isLoading={false}
      open={true}
      positionTop="50%"
      message=""
      children={getChildren()}
      hideButtons={true}
    />
  );
};
