import BackupTableIcon from "@mui/icons-material/BackupTable";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";
import ExploreIcon from "@mui/icons-material/Explore";
import StraightenIcon from "@mui/icons-material/Straighten";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import PieChartIcon from "@mui/icons-material/PieChart";
import Groups2Icon from "@mui/icons-material/Groups2";
import { SidebarProps } from "../../Components/Sidebar";

export const DataLayerSidebarData: Omit<SidebarProps, "isToggled" | "toggle"> = {
  data: [
    {
      title: "Data Modeling",
      links: [
        {
          to: "/data-layer/tables",
          label: "Tables",
          icon: <BackupTableIcon />,
        },
        {
          to: "/data-layer/entities",
          label: "Entities",
          icon: <PermIdentityIcon />,
        },
        {
          to: "/data-layer/entity-mappings",
          label: "Entity Mapping",
          icon: <EscalatorWarningIcon />,
        },
      ],
    },
    {
      title: "Semantic Layer",
      links: [
        {
          to: "/data-layer/features",
          label: "Features",
          icon: <ExploreIcon />,
        },
        {
          to: "/data-layer/dimensions",
          label: "Dimensions",
          icon: <StraightenIcon />,
        },
      ],
    },
    {
      title: "Data Products",
      links: [
        {
          to: "/data-layer/feature-sets",
          label: "Feature Sets",
          icon: <BusinessCenterIcon />,
        },
        {
          to: "/data-layer/metrics-sets",
          label: "Metrics Sets",
          icon: <PieChartIcon />,
        },
        {
          to: "/data-layer/entity-populations",
          label: "Entity Populations",
          icon: <Groups2Icon />,
        },
      ],
    },
  ],
};
