import { GenericLabelAndAttributesInput } from "../../LabelsAndAttribute";
import { TFormData } from "../../LabelsAndAttribute/Type";
import { useCreateSource } from "../Hooks/useSourceContext";

export const CreateSourceLabelAndAttribute = () => {
  const sourceContext = useCreateSource();
  const updateAttributeData = (attributeData: TFormData) => {
    sourceContext.dispatch({
      type: "UPDATE_ATTRIBUTE_DATA",
      payload: attributeData,
    });
  };

  return (
    <GenericLabelAndAttributesInput
      entity_type_id={"568b1198-dcac-40c5-8a8e-085b08daaf1c"}
      onChange={updateAttributeData}
    />
  );
};
