import { createContext } from 'react';


// PermissionEnum.ts
export enum PermissionKeys {
   CREATE_FEATURE = "createFeature",
   DELETE_FEATURE = "deleteFeature",
   UPDATE_FEATURE = "updateFeature",
   READ_FEATURE = "readFeature",
   PREVIEW_FEATURE = "previewFeature",

   CREATE_FEATURESET = "createFeatureset",
   UPDATE_FEATURESET = "updateFeatureset",
   DELETE_FEATURESET = "deleteFeatureset",
   READ_FEATURESET = "readFeatureset",

   CREATE_ENTITY = "createEntity",
   UPDATE_ENTITY = "updateEntity",
   DELETE_ENTITY = "deleteEntity",
   READ_ENTITY = "readEntity",

   CREATE_TABLE = "createTable",
   UPDATE_TABLE = "updateTable",
   DELETE_TABLE = "deleteTable",
   READ_TABLE = "readTable",

   CREATE_SCHEDULE = "createSchedule",
   UPDATE_SCHEDULE = "updateSchedule",
   DELETE_SCHEDULE = "deleteSchedule",
   READ_SCHEDULE = "readSchedule",

   CREATE_PROJECT = "createProject",
   DELETE_PROJECT = "deleteProject",

   CREATE_BRANCH = "createBranch",
   UPDATE_BRANCH = "updateBranch",
   DELETE_BRANCH = "deleteBranch",

   MERGE_CHANGE_REQUEST = "mergeChangeRequest",
   EDIT_CHANGE_REQUEST = "editChangeRequest",
   CLOSE_CHANGE_REQUEST = "closeChangeRequest",

   CREATE_LABEL = "createLabel",
   DELETE_LABEL = "deleteLabel",
   EDIT_LABEL = "editLabel",
   READ_LABEL = "readLabel",

   CREATE_DESTINATION = "createDestination",
   DELETE_DESTINATION = "deleteDestination",
   UPDATE_DESTINATION = "updateDestination",
   READ_DESTINATION = "readDestination",

   CREATE_SOURCE = "createSource",
   UPDATE_SOURCE = "updateSource",
   DELETE_SOURCE = "deleteSource",
   READ_SOURCE = "readSource",

   CREATE_ORCHESTRATOR = "createOrchestrator",
   DELETE_ORCHESTRATOR = "deleteOrchestrator",
   UPDATE_ORCHESTRATOR = "updateOrchestrator",
   READ_ORCHESTRATOR = "readOrchestrator",
}


export interface PermissionContextProps {
   isPermission: (permissionPath: string[]) => boolean;
   permissions: Record<PermissionKeys, boolean | undefined>;
}

export const PermissionContext = createContext<PermissionContextProps | undefined>(undefined);
