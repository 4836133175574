import { Box } from "@mui/material";
import { Action } from "../../../../../Reducers/CreateFeatureReducer";
import { FlexColumnBox } from "../../../../../Styles";
import {
  TCompositeFeature,
  TCompositeFeatureValidationError,
} from "../../../../../Types/CreateFeatureForm";
import {
  DescriptionTypography,
  LabelTypography,
} from "../../../../../Styles/CustomTypography";
import { CustomDropDown } from "../../../../../Components/Forms/DropDown";
import SqlEditor from "../../../../../Components/SqlEditor";
import { useGetEntities } from "../../../../../Hooks/DataFetcher/Entity/useGetEntities";

type CompositeFeatureProps = {
  data: TCompositeFeature;
  dispatch: React.Dispatch<Action>;
  validationErrors: TCompositeFeatureValidationError;
};

export const CompositeFeature = ({
  data,
  dispatch,
  validationErrors,
}: CompositeFeatureProps) => {
  const { entities } = useGetEntities();

  const handleEntityChange = (selectedItems: string[]) => {
    if (validationErrors.entity.length > 0) {
      dispatch({
        type: "UPDATE_COMPOSITE_FEATURE_VALIDATION_ERROR",
        payload: { entity: [] },
      });
    }
    dispatch({
      type: "UPDATE_COMPOSITE_FEATURE",
      payload: {
        ...data,
        entity: selectedItems,
        featureDependencies: [],
      },
    });
  };

  const handleFeatureDependenciesChange = (selectedItems: string[]) => {
    if (validationErrors.feature_dependencies.length > 0) {
      dispatch({
        type: "UPDATE_COMPOSITE_FEATURE_VALIDATION_ERROR",
        payload: { feature_dependencies: [] },
      });
    }
    dispatch({
      type: "UPDATE_COMPOSITE_FEATURE",
      payload: {
        ...data,
        featureDependencies: selectedItems,
      },
    });
  };

  const handleAggregationExpressionChange = (newValue: string) => {
    dispatch({
      type: "UPDATE_COMPOSITE_FEATURE",
      payload: {
        ...data,
        aggregationExpression: newValue,
      },
    });
  };

  return (
    <FlexColumnBox sx={{ gap: "1rem" }}>
      <Box>
        <LabelTypography>Entity</LabelTypography>
        <DescriptionTypography>
          Changing the Entity will remove the Feature Dependencies below.
        </DescriptionTypography>
        <CustomDropDown
          data={entities.map((entity) => {
            return {
              itemId: entity.id,
              label: entity.output_column ?? "",
            };
          })}
          multiple={false}
          selectedItems={data.entity}
          updateSelectedItems={handleEntityChange}
          validationsErrors={validationErrors.entity}
        />
      </Box>
      <Box>
        <LabelTypography>Feature Dependencies</LabelTypography>
        <DescriptionTypography>
          The Features that this row Feature will use as dependencies for its
          expression
        </DescriptionTypography>
        <CustomDropDown
          data={[]}
          multiple
          selectedItems={[]}
          updateSelectedItems={handleFeatureDependenciesChange}
          validationsErrors={validationErrors.feature_dependencies}
        />
      </Box>
      <Box>
        <LabelTypography>Aggregation Expression</LabelTypography>
        <SqlEditor
          data={data.aggregationExpression}
          onChange={handleAggregationExpressionChange}
        />
      </Box>
    </FlexColumnBox>
  );
};
