import { CircularProgress } from "@mui/material";
import { Colors } from "../Constants";
import { FlexRowBox } from "../Styles";
import { BlueButton } from "../Styles/Button";
import { HeadingTypography } from "../Styles/CustomTypography";

type CreateFormHeaderProps = {
  heading: string;
  submitButtonText?: string;
  submitHandler?: () => void;
  loading?: boolean;
};

export const CreateFormHeader = ({
  heading,
  submitButtonText,
  submitHandler,
  loading
}: CreateFormHeaderProps) => {
  return (
    <FlexRowBox
      sx={{
        justifyContent: "space-between",
        borderBottom: `1px solid ${Colors.SIDE_LINK_HOVER_COLOR}`,
        padding: "1rem",
      }}
    >
      <HeadingTypography>{heading}</HeadingTypography>
      {submitButtonText && <BlueButton disabled={loading} startIcon={loading ? <CircularProgress size={20} /> : ''} onClick={submitHandler}>{submitButtonText}</BlueButton>}
    </FlexRowBox>
  );
};
