import { Table } from "../Client/typescript-axios-client-generated";
import { ApiClient } from "../Utils/ApiClient";

export const createTable = async (projectName: string, branchName: string,body: Table) => {
  return ApiClient.tableClient
    .createTableTablePost(projectName, branchName, body)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};
