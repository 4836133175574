import { useEffect, useReducer, useState } from "react";
import { Action, TCreateAttribute } from "../Types";
import { getAttributeTypes } from "../../../../../../Services/getAttributeTypes";
import { getEntityTypes } from "../../../../../../Services/getEntityTypes";

const reducer = (state: TCreateAttribute, action: Action): TCreateAttribute => {
  switch (action.type) {
    case "SET_NAME":
      return { ...state, name: action.payload };
    case "SET_ATTRIBUTE_TYPE":
      return { ...state, attributeType: action.payload };
    case "SET_DESCRIPTION":
      return { ...state, description: action.payload };
    case "SET_MANDATORY":
      return { ...state, mandatory: action.payload };
    case "SET_APPLIES_TO":
      return { ...state, appliesTo: action.payload };
    case "SET_ATTRIBUTE_TYPE_DATA":
      return { ...state, attributeTypesData: action.payload };
    case "SET_ENTITY_TYPES_DATA":
      return { ...state, entityTypesData: action.payload };
    case "SET_ALLOWED_VALUES":
      return { ...state, allowedValues: action.payload };
    default:
      return state;
  }
};

const initialState: TCreateAttribute = {
  name: "",
  attributeType: "",
  description: "{}",
  mandatory: false,
  appliesTo: [],
  attributeTypesData: [],
  entityTypesData: [],
  allowedValues: [],
};

export const useCreateAttributes = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const attributeTypeDataPromise = getAttributeTypes();
    const entityTypesDataPromise = getEntityTypes();
    Promise.all([attributeTypeDataPromise, entityTypesDataPromise])
      .then((data) => {
        dispatch({ type: "SET_ATTRIBUTE_TYPE_DATA", payload: data[0] });
        dispatch({ type: "SET_ENTITY_TYPES_DATA", payload: data[1] });
      })
      .catch((err) => {});
  }, []);

  return {
    state,
    dispatch,
  };
};
