import { TTableHeader } from "../../Packages/Table/Types";

export const ChangeRequestDiffTableHeader: TTableHeader[] = [
    {
        column_id: "type",
        label: "Operation Type",
        isSortable: "",
        visible: true
    },
    {
        column_id: "change_type",
        label: "Item Type",
        isSortable: "",
        visible: true
    },
    {
        column_id: "item_name",
        label: "Item Name",
        isSortable: "",
        visible: true
    },
    {
        column_id: 'view',
        label: '',
        isSortable: '',
        visible: false
    }
]