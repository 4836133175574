import { Box, Button, IconButton, TableCell, Typography } from "@mui/material";
import { TCol } from "../Types";
import { BlueButton } from "../../../Styles/Button";
import {
  FlexRowBox,
  RightFloatTableMenuItemStyle,
  UnderLineOnHoverNavLink,
} from "../../../Styles";
import { CustomToolTip } from "../../../Styles/ToolTip";
import { Label } from "../../../Styles/Label";
import React, { useState } from "react";
import { MediumSizeDarkColorThinTypography } from "../../../Styles/CustomTypography";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../../Constants";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DropDownMenu } from "../../../Pages/DataLayer/Components/Features/Components/CreateFeatureTabs";

type GenericColumnProps = {
  column: TCol | TCol[] | undefined;
  rowId: string;
  padding?: string;
};

export const GenericColumn = ({ column, rowId, padding }: GenericColumnProps) => {
  const buildColumnForExtraOptions = (data: TCol[]): React.ReactNode => {
    return <MoreOptions data={data} rowId={rowId} />;
  };

  const buildColumForLabels = (data: TCol[]): React.ReactNode => {
    let extraLabels = "";
    for (let i = 2; i < data.length; i++) {
      extraLabels += data[i].text;
      if (i < data.length - 1) {
        extraLabels += ", ";
      }
    }
    const shownLabels = data.slice(0, 2);
    const lengthOfHiddenLabels = Math.max(0, data.length - 2);

    return (
      <FlexRowBox style={{ gap: "3px" }}>
        {shownLabels.map((label) => {
          return (
            <Label
              onClick={() => {
                if (label.onClickHandler) label.onClickHandler(rowId);
              }}
            >
              {label.text}
            </Label>
          );
        })}
        {lengthOfHiddenLabels > 0 && (
          <CustomToolTip placement="top" title={extraLabels}>
            <BlueButton>+{lengthOfHiddenLabels}</BlueButton>
          </CustomToolTip>
        )}
      </FlexRowBox>
    );
  };

  const buildColumn = (data: TCol | TCol[] | undefined): React.ReactNode => {
    if (!data) return <></>;

    if (Array.isArray(data)) {
      if (data.length >= 1 && data[0].column_id === "labels") {
        return buildColumForLabels(data);
      }
      if (data.length >= 1 && data[0].column_id === "moreOptions") {
        return buildColumnForExtraOptions(data);
      }
      return (
        <FlexRowBox>
          {data.map((val, index) => {
            return (
              <>
                {buildColumn(val)}
                <MediumSizeDarkColorThinTypography>
                  {index === data.length - 1 ? "" : ","}{" "}
                </MediumSizeDarkColorThinTypography>
              </>
            );
          })}
        </FlexRowBox>
      );
    } else {
      if(data.reactNode) {
        return data.reactNode;
      }
      if (data.onClickHandler) {
        const ButtonElement = data.buttonElement ?? BlueButton;
        if (data.toolTip) {
          return (
            <CustomToolTip placement="top" title={data.toolTip}>
              <ButtonElement
                onClick={() => {
                  if (data.onClickHandler) data.onClickHandler(rowId);
                }}
              >
                {" "}
                {data.text}{" "}
              </ButtonElement>
            </CustomToolTip>
          );
        } else {
          return (
            <ButtonElement
              onClick={() => {
                if (data.onClickHandler) data.onClickHandler(rowId);
              }}
            >
              {" "}
              {data.text}{" "}
            </ButtonElement>
          );
        }
      }
      if (data.link) {
        if (data.toolTip) {
          return (
            <CustomToolTip placement="top" title={data.toolTip}>
              <UnderLineOnHoverNavLink to={data.link}>
                <MediumSizeDarkColorThinTypography>
                  {data.text}
                </MediumSizeDarkColorThinTypography>
              </UnderLineOnHoverNavLink>
            </CustomToolTip>
          );
        } else {
          return (
            <UnderLineOnHoverNavLink to={data.link}>
              <MediumSizeDarkColorThinTypography>
                {data.text}
              </MediumSizeDarkColorThinTypography>
            </UnderLineOnHoverNavLink>
          );
        }
      }
      if (data.toolTip) {
        return (
          <CustomToolTip placement="top" title={data.toolTip}>
            <MediumSizeDarkColorThinTypography>
              {data.text}
            </MediumSizeDarkColorThinTypography>
          </CustomToolTip>
        );
      }
      let Element = data.buttonElement
        ? data.buttonElement
        : MediumSizeDarkColorThinTypography;
      return <Element sx={{whiteSpace: 'nowrap'}} > {data.text} </Element>;
    }
  };
  let bcolor = '';
  if(!Array.isArray(column)){
    bcolor = column?.backgroundColor ? column.backgroundColor : '';
  }

  return <>{<TableCell sx={{backgroundColor: bcolor, padding: padding ?? ''}} >{buildColumn(column)}</TableCell>}</>;
};

export const MoreOptions = ({
  data,
  rowId,
}: {
  data: TCol[];
  rowId: string;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const navigate = useNavigate();

  const handleButtonClick = (event: any) => {
    // You can perform any action on button click here
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteOption = data.find((option) => option.text === "Delete");
  let newData: TCol[] = data;
  newData = [...data.filter((option) => option.text != "Delete")];

  return (
    <React.Fragment>
      <IconButton
        onClick={handleButtonClick}
        sx={{
          color: Colors.LINK_TEXT_COLOR,
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)", // Change background color on hover
          },
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <DropDownMenu
        minWidth="175px"
        anchorEl={anchorEl}
        handleClose={handleClose}
      >
        {newData.map((option, index) => {
          return (
            <RightFloatTableMenuItemStyle
              showBorderBottom={deleteOption && index === newData.length - 1}
              onClick={() => {
                if (option.onClickHandler) option.onClickHandler(rowId);
              }}
            >
              {option.text}
            </RightFloatTableMenuItemStyle>
          );
        })}
        {deleteOption && (
          <RightFloatTableMenuItemStyle onClick={() => {
            if(deleteOption.onClickHandler)
              deleteOption.onClickHandler(rowId);
          }} color={Colors.APP_RED_COLOR}>
            {deleteOption.text}
          </RightFloatTableMenuItemStyle>
        )}
      </DropDownMenu>
    </React.Fragment>
  );
};
