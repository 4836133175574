export const PermissionPathMappings = {
    // Feature Permissions
    featureCreate: ["feature", "feature.create"],
    featureDelete: ["feature", "feature.delete"],
    featureUpdate: ["feature", "feature.update"],
    featureRead: ["feature", "feature.read"],
    featurePreview: ["feature", "feature.preview"],
  
    // Feature Set Permissions
    featuresetCreate: ["featureset", "featureset.create"],
    featuresetUpdate: ["featureset", "featureset.update"],
    featuresetDelete: ["featureset", "featureset.delete"],
    featuresetRead: ["featureset", "featureset.read"],
  
    // Entity Permissions
    entityCreate: ["entity", "entity.create"],
    entityUpdate: ["entity", "entity.update"],
    entityDelete: ["entity", "entity.delete"],
    entityRead: ["entity", "entity.read"],
  
    // Table Permissions
    tableCreate: ["table", "table.create"],
    tableUpdate: ["table", "table.update"],
    tableDelete: ["table", "table.delete"],
    tableRead: ["table", "table.read"],
  
    // Schedule Permissions
    scheduleCreate: ["schedule", "schedule.create"],
    scheduleUpdate: ["schedule", "schedule.update"],
    scheduleDelete: ["schedule", "schedule.delete"],
    scheduleRead: ["schedule", "schedule.read"],
  
    // Governance Permissions
    projectCreate: ["governance", "governance.project", "governance.project.create"],
    projectDelete: ["governance", "governance.project", "governance.project.delete"],
    branchCreate: ["governance", "governance.project.branch", "governance.project.branch.create"],
    branchUpdate: ["governance", "governance.project.branch", "governance.project.branch.update"],
    branchDelete: ["governance", "governance.project.branch", "governance.project.branch.delete"],
    mergeChangeRequest: ["governance", "governance.changerequest.merge"],
    editChangeRequest: ["governance", "governance.changerequest.edit"],
    closeChangeRequest: ["governance", "governance.changerequest.close"],
  
    // Configurations Permissions
    labelCreate: ["configurations", "configurations.labelandattributes", "configurations.labelandattributes.create"],
    labelDelete: ["configurations", "configurations.labelandattributes", "configurations.labelandattributes.delete"],
    labelEdit: ["configurations", "configurations.labelandattributes", "configurations.labelandattributes.edit"],
    labelRead: ["configurations", "configurations.labelandattributes", "configurations.labelandattributes.read"],
  
    destinationCreate: ["configurations", "configurations.destinations", "configurations.destinations.create"],
    destinationDelete: ["configurations", "configurations.destinations", "configurations.destinations.delete"],
    destinationUpdate: ["configurations", "configurations.destinations", "configurations.destinations.update"],
    destinationRead: ["configurations", "configurations.destinations", "configurations.destinations.read"],
  
    sourceCreate: ["configurations", "configurations.sources", "configurations.sources.create"],
    sourceUpdate: ["configurations", "configurations.sources", "configurations.sources.update"],
    sourceDelete: ["configurations", "configurations.sources", "configurations.sources.delete"],
    sourceRead: ["configurations", "configurations.sources", "configurations.sources.read"],
  
    orchestratorCreate: ["configurations", "configurations.orchestrator", "configurations.orchestrator.create"],
    orchestratorDelete: ["configurations", "configurations.orchestrator", "configurations.orchestrator.delete"],
    orchestratorUpdate: ["configurations", "configurations.orchestrator", "configurations.orchestrator.update"],
    orchestratorRead: ["configurations", "configurations.orchestrator", "configurations.orchestrator.read"],
  };
  