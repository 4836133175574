import { useState } from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { FlexColumnBox, FlexRowBox, UnderLineOnHoverNavLink } from "../../Styles";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { windowOrTab } from "../../Utils";
import { Colors } from "../../Constants";
import GithubBranchesDropdown from "../GithubBranchDropdown";
import { NameUrlMapping } from "../../Constants/NameUrlMapping";
import GithubProjectsDropdown from "../GithubProjectDropDown";
import { NavbarCreateButton } from "./Components/NavbarCreateButton";
import { GlobalSearch } from "../GlobalSearch";

export const StyledFlexRowBox = styled(FlexRowBox)({
  alignItems: "center", // Example alignment
  padding: "10px 20px", // Example padding
});

export const StyledNavLink = styled(NavLink)({
  textDecoration: "none",
  color: Colors.SIDEBAR_ICON_COLORS, // Example text color
  padding: "0.3rem 0.4rem", // Example padding
  margin: '0 .5rem',
  transition: "background-color 0.3s ease", // Example transition
  borderRadius: "0.25rem",
  fontWeight: 500,
  "&:hover": {
    backgroundColor: Colors.APP_CANCEL_BUTTON_COLOR, // Example active background color
    color: Colors.APP_LIGHT_GREY,
  },
  "&.active": {
    backgroundColor: Colors.APP_CANCEL_BUTTON_COLOR, // Example active background color
    color: Colors.APP_LIGHT_GREY,
  },
});

const MobileViewNavBar = ({ menuOpened, closeMenu }: any) => {
  return (
    <Box
      sx={{
        padding: "1rem",
        paddingTop: "0",
        position: "fixed",
        top: "38px",
        left: 0,
        width: "100%",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        backgroundColor: 'white',
        zIndex: 1,
      }}
    >
      {menuOpened && (
        <List>
          <ListItem
            button
            component={StyledNavLink}
            to={"./data-layer"}
            onClick={closeMenu}
          >
            <ListItemText primary="Data Layer" />
          </ListItem>
          <Divider sx={{ backgroundColor: "rgba(0, 0, 0, 0.01)" }} />{" "}
          {/* Very light divider */}
          <ListItem
            button
            component={StyledNavLink}
            to={"./schedules"}
            onClick={closeMenu}
          >
            <ListItemText primary="Schedules" />
          </ListItem>
          <Divider sx={{ backgroundColor: "rgba(0, 0, 0, 0.01)" }} />{" "}
          {/* Very light divider */}
          <ListItem
            button
            component={StyledNavLink}
            to={"./workbooks"}
            onClick={closeMenu}
          >
            <ListItemText primary="Workbooks" />
          </ListItem>
          <Divider sx={{ backgroundColor: "rgba(0, 0, 0, 0.01)" }} />{" "}
          {/* Very light divider */}
          <ListItem
            button
            component={StyledNavLink}
            to={"./Governance"}
            onClick={closeMenu}
          >
            <ListItemText primary="Governance" />
          </ListItem>
          <Divider sx={{ backgroundColor: "rgba(0, 0, 0, 0.01)" }} />{" "}
          {/* Very light divider */}
          <ListItem
            button
            component={StyledNavLink}
            to={"./Configuration"}
            onClick={closeMenu}
          >
            <ListItemText primary="Configuration" />
          </ListItem>
        </List>
      )}
    </Box>
  );
};

export const GetNavLink = () => {
  const location = useLocation();
  const path = location.pathname;
  const paths = ["/"];
  let substr = "/";

  for (let i = 1; i < path.length; i++) {
    if (path[i] === "/") {
      paths.push(substr);
    }
    substr += path[i];
  }
  if (substr != "/") paths.push(substr);

  return (
    <FlexRowBox sx={{ gap: "0.3rem" }}>
      {paths.map((ele: string, index: number) => {
        if (
          Object.keys(NameUrlMapping).includes(ele) &&
          typeof ele === "string"
        ) {
          let color = Colors.LINK_TEXT_COLOR;
          if (index == paths.length - 1) {
            color = `${Colors.APP_LIGHT_GREY}`;
          }
          return (
            <>
              <UnderLineOnHoverNavLink
                style={{ fontWeight: 550, color: color }}
                key={index}
                to={ele}
              >
                {NameUrlMapping[ele]}
              </UnderLineOnHoverNavLink>
              {index != paths.length - 1 && (
                <span style={{ color: color }}>/</span>
              )}
            </>
          );
        } else {
          return null; // Return null for keys not found in NameUrlMapping
        }
      })}
    </FlexRowBox>
  );
};

export const Governance = () => {
  return (
    <>
      <FlexRowBox
        sx={{
          padding: "0.5rem 1rem",
          borderBottom: `1px solid ${Colors.SIDE_LINK_HOVER_COLOR}`,
          alignItems: "center",
        }}
      >
        <GetNavLink />
        <FlexRowBox
          style={{
            flexGrow: 1,
            justifyContent: "flex-end",
            gap: "1rem",
          }}
        >
          <GithubProjectsDropdown />
          <GithubBranchesDropdown />
        </FlexRowBox>
      </FlexRowBox>
    </>
  );
};

export const NavBar = () => {
  const [menuOpened, setMenuOpened] = useState(false);

  const toggleMenu = () => {
    setMenuOpened((prev) => !prev);
  };

  const closeMenu = () => {
    setMenuOpened(false);
  };

  const isTab = windowOrTab();

  return (
    <FlexColumnBox>
      <FlexColumnBox
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1000,
          backgroundColor: "white",
        }}
      >
        <StyledFlexRowBox
          sx={{
            borderBottom: `1px solid ${Colors.SIDE_LINK_HOVER_COLOR}`,
            padding: ".5rem 1rem",
            justifyContent: 'space-between'
          }}
        >
          <MenuIcon
            sx={{ display: isTab ? "flex" : "none" }}
            onClick={toggleMenu}
          />
          <Box sx={{ display: isTab ? "none" : "flex" }}>
            <StyledNavLink to={"./data-layer/features"}>Data Layer</StyledNavLink>
            <StyledNavLink to={"./schedules/feature-generation"}>Schedules</StyledNavLink>
            <StyledNavLink to={"./workbooks"}>Workbooks</StyledNavLink>
            <StyledNavLink to={"./governance/change-requests"}>Governance</StyledNavLink>
            <StyledNavLink to={"./configuration/labels-and-attributes"}>Configuration</StyledNavLink>
            <NavbarCreateButton/>
          </Box>
          <GlobalSearch/>
        </StyledFlexRowBox>
        <Governance />
      </FlexColumnBox>

      {isTab && menuOpened && (
        <MobileViewNavBar menuOpened={menuOpened} closeMenu={closeMenu} />
      )}
      <Outlet />
    </FlexColumnBox>
  );
};
