export const getCurrentTimeZone = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return timezone;
};

export const formattedReadableDate = (datetime: string) => {
    const date = new Date(datetime+'Z');
    const currDate = new Date();
    console.log('date' + date)
    console.log('currdate' + currDate)
  
    const diffInMs = currDate.getTime() - date.getTime();
    console.log(diffInMs)

    const msPerMinute = 1000 * 60;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerWeek = msPerDay * 7;
    const msPerMonth = msPerDay * 30.44; // Approximation
    const msPerYear = msPerDay * 365.25; // Approximation
  
    const yearsDiff = Math.floor(diffInMs / msPerYear);
    const monthsDiff = Math.floor(diffInMs / msPerMonth);
    const weeksDiff = Math.floor(diffInMs / msPerWeek);
    const daysDiff = Math.floor(diffInMs / msPerDay);
    const hoursDiff = Math.floor(diffInMs / msPerHour);
    const minutesDiff = Math.floor(diffInMs / msPerMinute);
  
    if (yearsDiff > 0) {
      return `${yearsDiff} year${yearsDiff > 1 ? "s" : ""} ago`;
    }
    if (monthsDiff > 0) {
      return `${monthsDiff} month${monthsDiff > 1 ? "s" : ""} ago`;
    }
    if (weeksDiff > 0) {
      return `${weeksDiff} week${weeksDiff > 1 ? "s" : ""} ago`;
    }
    if (daysDiff > 0) {
      return `${daysDiff} day${daysDiff > 1 ? "s" : ""} ago`;
    }
    if (hoursDiff > 0) {
      return `${hoursDiff} hour${hoursDiff > 1 ? "s" : ""} ago`;
    }
    if (minutesDiff > 0) {
      return `${minutesDiff} minute${minutesDiff > 1 ? "s" : ""} ago`;
    }
    return `just now`;
  };
  