import { ApiClient } from "../Utils/ApiClient";

export const getUserPermissionTree = async (userId: string) => {
  return ApiClient.userClient
    .getAllPermissionsListUsersGetAllPermissionsListUserIdGet(userId)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      throw err;
    });
};
