import { Box } from "@mui/material";
import { CreateFormHeader } from "../../../../../Components/CreateFormHeader";
import { InputTextField } from "../../../../../Components/Forms/InputTextField";
import RichTextField from "../../../../../Components/Forms/RichTextInputField";
import { FlexColumnBox } from "../../../../../Styles";
import { LabelTypography } from "../../../../../Styles/CustomTypography";
import { CustomDropDown } from "../../../../../Components/Forms/DropDown";
import { useEffect, useState } from "react";
import { OrchestatorTypeInDB } from "../../../../../Client/configuration-client";
import { getOrchestratorTypes } from "../../../../../Services/getOrchestratorTypes";
import { createOrchestrator } from "../../../../../Services/createOrchestrator";
import { useToast } from "../../../../../Hooks/useToast";
import { ToastType } from "../../../../../Context/Providers/ToastProvider";
import { useLocation } from "react-router-dom";
import { getOrchestratorById } from "../../../../../Services/getOrchestratorById";

export const CreateOrchestrator = () => {
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("{}");
  const [orchestratorType, setOrchestratorType] = useState<string[]>([]);
  const [configData, setConfigData] = useState<Record<string, any>>({});
  const [orchestratorTypesData, setOrchestratorTypesData] = useState<
    OrchestatorTypeInDB[]
  >([]);

  const toast = useToast();
  const location = useLocation();

  const { orchestratorId, isEdit, isDuplicate } = location.state
    ? location.state
    : { orchestratorId: undefined, isEdit: undefined, isDuplicate: undefined };

  useEffect(() => {
    const promise1 = getOrchestratorTypes();
    const promise2 = orchestratorId ? getOrchestratorById(orchestratorId) : Promise.resolve(undefined);
    Promise.all([promise1, promise2])
      .then((resolvedData: any) => {
        const data = resolvedData[0];
        setOrchestratorTypesData(data);
        const config_data: any = {};
        data.map((sourceType: OrchestatorTypeInDB) => {
          const configFormat: any = {};
          sourceType.orchestrator_config_format?.map((field: any) => {
            configFormat[field.key] = "";
          });
          config_data[sourceType.id] = configFormat;
        });
        setConfigData(configData);
        const oData = resolvedData[1];
        if(oData !== undefined) {
          setName(oData.name);
          setDescription(oData.description);
          setOrchestratorType([oData.orchestrator_type_id])
          setConfigData({
            [oData.orchestrator_type_id]: oData.config_data
          });

        }
      })
      .catch((err) => {});
  }, []);

  const dropDownData = orchestratorTypesData.map((type) => {
    return {
      itemId: type.id,
      description: "",
      label: type.name,
    };
  });

  const selectedType = orchestratorTypesData.find((type) => {
    return type.id == orchestratorType[0];
  });

  const properties: Array<any> =
    selectedType !== undefined && selectedType !== null
      ? selectedType.orchestrator_config_format
      : [];

  const handleSubmit = () => {
    if(isEdit) {

    }
    createOrchestrator({
      name: name,
      description: description,
      orchestrator_type_id: orchestratorType[0],
      config_data: configData[orchestratorType[0]],
    })
      .then((data) => {
        toast.open("Orchestrator created successfully", ToastType.SUCCESS);
      })
      .catch((err) => {
        toast.open(
          "Something went wrong while creating orechestrator",
          ToastType.ERROR
        );
      });
  };
  
  const submitButtonText = isEdit ? "Edit Orchestrator" : "Create Orchestrator"

  return (
    <FlexColumnBox sx={{ flexGrow: 1 }}>
      <CreateFormHeader
        heading={submitButtonText}
        submitButtonText={submitButtonText}
        submitHandler={handleSubmit}
      />
      <FlexColumnBox sx={{ padding: "2rem", gap: "1rem" }}>
        <InputTextField
          onChange={(e) => {
            setName(e.target.value);
          }}
          value={name}
          violationArray={[]}
          title="Name"
          description=""
        />
        <Box>
          <LabelTypography>Description</LabelTypography>
          <RichTextField
            input={description}
            setInput={(val) => {
              setDescription(val);
            }}
          />
        </Box>
        <Box>
          <LabelTypography>Orchestrator Type</LabelTypography>
          <CustomDropDown
            data={dropDownData}
            multiple={false}
            selectedItems={orchestratorType}
            updateSelectedItems={(selectedItem) => {
              setOrchestratorType(selectedItem);
            }}
          />
        </Box>
        {/* <ReactJson
        src={configData}
        onEdit={(edit) => setConfigData(edit.updated_src)}
        onAdd={(add) => setConfigData(add.updated_src)}
        onDelete={(del) => setConfigData(del.updated_src)}
        theme="monokai"
        displayDataTypes={false}
        displayObjectSize={false}
      /> */}
        {selectedType &&
          properties.map((property) => {
            return (
              <InputTextField
                key={property.key} // Add a unique key for each property
                onChange={(e) => {
                  const newConfigData = {
                    ...configData,
                    [selectedType.id]: {
                      ...configData[selectedType.id],
                      [property.key]: e.target.value,
                    },
                  };
                  setConfigData(newConfigData);
                }}
                value={configData[selectedType.id]?.[property.key] || ""}
                violationArray={[]}
                title={property.label}
                description={property.description}
                placeholder={property.placeholder}
              />
            );
          })}
      </FlexColumnBox>
    </FlexColumnBox>
  );
};
