import React, { useState } from "react";
import { Box, IconButton, Menu } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Colors } from "../../Constants";
import { DropDownMenu } from "../../Pages/DataLayer/Components/Features/Components/CreateFeatureTabs";

interface ThreeDotsMenuProps {
  options: React.ReactNode[];
  minWidth?: string;
  top?: string;
}

const ThreeDotsMenu: React.FC<ThreeDotsMenuProps> = ({
  options,
  top,
  minWidth,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton
        onClick={handleClick}
        sx={{
          color: Colors.LINK_TEXT_COLOR,
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)", // Change background color on hover
          },
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <DropDownMenu
        minWidth={minWidth}
        anchorEl={anchorEl}
        handleClose={handleClose}
      >
        {options.map((option, index) => option)}
      </DropDownMenu>
    </Box>
  );
};

export default ThreeDotsMenu;
