import React from "react";
import { usePermissionContextProvider } from "../../Hooks/usePermissionContextProvider";
import { PermissionContext } from "../PermissionContext";

type PermissionContextProviderProps = {
  children: React.ReactNode;
};

export const PermissionContextProvider = ({
  children,
}: PermissionContextProviderProps) => {
  const { loading, failed, isPermission, permissions } =
    usePermissionContextProvider();

  const value = { isPermission, permissions };

  return (
    <PermissionContext.Provider value={value}>
      {loading ? (
        <div>Loading...</div>
      ) : failed ? (
        <div
          style={{
            fontFamily: "Arial, sans-serif",
            color: "#ffffff",
            backgroundColor: "#ff4c4c",
            padding: "20px",
            borderRadius: "8px",
            textAlign: "center",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            maxWidth: "400px",
            margin: "50px auto",
          }}
        >
          Something went wrong, please refresh the page.
        </div>
      ) : (
        children
      )}
    </PermissionContext.Provider>
  );
};
