import { ApiClient } from "../Utils/ApiClient";

export const deleteOrchestrator = async (id: string) => {
  return ApiClient.orchestratorClient
    .deleteOrchestratorByIdOrchestratorOrchestratorIdDelete(id)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};
