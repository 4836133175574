import { OrchestratorCreate } from "../Client/configuration-client"
import { ApiClient } from "../Utils/ApiClient"

export const createOrchestrator = async (body: OrchestratorCreate) => {
    return ApiClient.orchestratorClient.createOrchestratorOrchestratorCreatePost(body).then((data) => {
        return data.data;
    }).catch((err) => {
        console.log(err);
        throw err;
    })
}