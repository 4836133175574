import React, { useState, ChangeEvent, FormEvent } from "react";
import { BlueButton } from "../../../Styles/Button";
import { Box } from "@mui/material";
import "./index.css";
import { FlexRowBox } from "../../../Styles";
import { Colors } from "../../../Constants";

export interface Pair {
  key: string;
  value: string;
}

type KeyValueInputProps = {
  pairs: Pair[];
  updatePair: (e: Pair[]) => void;
}

export const KeyValueInput = ({ pairs, updatePair }: KeyValueInputProps) => {
  const [error, setError] = useState<string>("");

  const handleAddPair = () => {
    updatePair([...pairs, { key: "", value: "" }]);
  };

  const handleInputChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    const newPairs = [...pairs];
    newPairs[index][name as keyof Pair] = value;

    if (name === "key") {
      const keys = newPairs.map((pair) => pair.key);
      const duplicates = keys.filter(
        (key, idx) => key && keys.indexOf(key) !== idx
      );
      if (duplicates.length > 0) {
        setError("Duplicate keys are not allowed.");
      } else {
        setError("");
      }
    }

    updatePair(newPairs);
  };

  const handleRemovePair = (index: number) => {
    const newPairs = pairs.filter((_, pairIndex) => pairIndex !== index);
    updatePair(newPairs);
    setError("");
  };

  return (
    <div>
      <form className="key-value-form">
        <FlexRowBox
          sx={{ borderBottom: `2px solid ${Colors.SIDE_LINK_HOVER_COLOR}` }}
        >
          <Box
            className="input-field-key-value"
            sx={{ flexGrow: 1, fontWeight: "bold" }}
          >
            Property Key
          </Box>
          <Box
            className="input-field-key-value"
            sx={{ flexGrow: 1.7, fontWeight: "bold" }}
          >
            Value
          </Box>
        </FlexRowBox>
        {pairs.map((pair, index) => (
          <FlexRowBox className="input-row" key={index}>
            <input
              type="text"
              name="key"
              placeholder="Key"
              value={pair.key}
              className="input-field-key-value"
              onChange={(event) => handleInputChange(index, event)}
            />
            <input
              type="text"
              name="value"
              placeholder="Value"
              className="input-field-key-value"
              value={pair.value}
              onChange={(event) => handleInputChange(index, event)}
            />
            <button
              className="remove-button"
              type="button"
              onClick={() => handleRemovePair(index)}
            >
              Remove
            </button>
          </FlexRowBox>
        ))}
        {error && <p style={{ color: "red" }}>{error}</p>}
      </form>
      <Box sx={{ marginTop: "1rem" }}>
        <BlueButton type="button" onClick={handleAddPair}>
          Add Pair
        </BlueButton>
      </Box>
    </div>
  );
};
