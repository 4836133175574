import styled from "@emotion/styled";
import { Box, Menu } from "@mui/material";
import { FlexRowBox } from "./BoxStyle";
import { Colors } from "../Constants";

export const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiMenu-paper': {
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)', // Adjust box shadow as needed
    borderRadius: '.25rem', // Adjust border radius as needed
    overflow: 'hidden',
    border: `0.1px solid ${Colors.SIDE_LINK_HOVER_COLOR}`,
    fontSize: '.875rem',
    color: `${Colors.APP_LIGHT_GREY}`,
  },
  '& .MuiMenuItem-root': {
    padding: '8px 16px', // Adjust padding as needed
    '&:hover': {
      backgroundColor: Colors.SIDE_LINK_HOVER_COLOR, // Optional: Add hover effect
    },
  },
}));

export const StyledMenuItem = styled(FlexRowBox)({
  justifyContent: 'space-between',
  fontSize: '14px',
  lineHeight: '20px',
  cursor: 'pointer',
  padding: 8,
  alignItems: 'center',
})
