import React, { useState } from "react";
import { BranchButton } from "../Styles/Button";
import { BranchIcon } from "./icons/BranchIcon";
import { DownArrowIcon } from "./icons/DownArrowIcon";
import { Box } from "@mui/material";
import { GithubMenu } from "./GithubMenu";
import { LabelTypography } from "../Styles/CustomTypography";
import { Branch } from "./Branch";
import { FlexColumnBox, UnderLineOnHoverNavLink } from "../Styles";
import { useVersionControlContext } from "../Hooks/useVersionControlContext";
import { isMatchingSubString } from "../Utils/CommonFunctions";
import { Colors } from "../Constants";

const GithubProjectDropdown = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchProject, setSearchProject] = useState<string>("");
  const versionControlContext = useVersionControlContext();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUpdateBranchSeacrh = (project: string) => {
    setSearchProject(project);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const branchMenu = () => {
    return (
      <FlexColumnBox>
        {versionControlContext?.projectsList
          ?.filter((project) =>
            isMatchingSubString(searchProject, project.name)
          )
          ?.map((project) => {
            return (
              <Box
                onClick={() => {
                  versionControlContext.updateCurrentProject(project.id);
                }}
              >
                <Branch
                  name={project.name}
                  isSelected={
                    versionControlContext.currentProject === project.id
                  }
                  isDefault={project.name == "main"}
                />
              </Box>
            );
          })}
        <Box sx={{ textAlign: "center" }}>
          <UnderLineOnHoverNavLink
            to="/"
            style={{ color: `${Colors.APP_BLUE_COLOR}` }}
          >
            view all
          </UnderLineOnHoverNavLink>
        </Box>
      </FlexColumnBox>
    );
  };

  return (
    <Box>
      <BranchButton onClick={handleClick}>
        <span>
          <BranchIcon />
        </span>
        <span>
          <LabelTypography>
            {
              versionControlContext?.projectsList?.find(
                (project) => project.id === versionControlContext.currentProject
              )?.name
            }
          </LabelTypography>
        </span>
        <span>
          <DownArrowIcon />
        </span>
      </BranchButton>
      <GithubMenu
        anchorEl={anchorEl}
        title={"Switch project"}
        children={branchMenu()}
        handleClose={handleClose}
        searchPlaceholder={"Seach Project"}
        search={searchProject}
        handleUpdatedSeacrh={handleUpdateBranchSeacrh}
      />
    </Box>
  );
};

export default GithubProjectDropdown;
