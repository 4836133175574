import {
  TCreateFeatureData,
  TransformationType,
} from "../../Types/CreateFeatureForm";

export const initialState: TCreateFeatureData = {
  transformationType: undefined,
  name: "",
  description: "{}",
  [TransformationType.EVENT_FEATURE]: {
    table: [],
    entityRestriction: [],
    aggregation: [],
    additionalFilter: false,
    additionalFilterValue: "",
    postAggregationExpresion: false,
    postAggregationExpressionValue: "",
    aggregationExpression: "",
    windowSize: 0,
    windowType: "OPEN_WINDOW"
  },
  [TransformationType.COMPOSITE_FEATURE]: {
    entity: [],
    featureDependencies: [],
    aggregationExpression: "",
  },
  [TransformationType.SQL_FEATURE]: {
    sources: [],
  },
  validationErrors: {
    name: [],
    labels: [],
    creator: [],
    domain: [],
    owner: [],
  },
  eventFeatureValidationError: {
    table: [],
    aggregation: []
  },
  compositeFeatureValidationError: {
    entity: [],
    feature_dependencies: []
  },
  sqlFeatureValidationError: {
    sources: []
  },
  eventFeaturePreviewData: {
    dataPreviewDate: "",
    dataPreviewEntity: [],
    previewEntityPopulation: [],
    cluster: []
  },
  compositeFeaturePreviewData: {
    dataPreviewDate: "",
    dataPreviewEntity: [],
    previewEntityPopulation: [],
    cluster: []
  },
  attributeData: {}
};
