import { useEffect, useState } from "react";
import { Colors } from "../../../../Constants";
import { useVersionControlContext } from "../../../../Hooks/useVersionControlContext";
import { FlexColumnBox, FlexRowBox } from "../../../../Styles";
import {
  HeadingTypography,
  OpenChangeRequestStatus,
} from "../../../../Styles/CustomTypography";
import { CommitGetSchema } from "../../../../Client/typescript-axios-client-generated";
import { getChangeLogs } from "../../../../Services/getChangeLogs";
import { CommitsTable } from "./Commits";
import { BlueButton } from "../../../../Styles/Button";
import { CircularProgress } from "@mui/material";

export const ChangeLog = () => {
  const [commits, setCommits] = useState<CommitGetSchema[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [loadMoreCommitLoading, setLoadMoreCommitLoading] =
    useState<boolean>(false);
  const [allCommitLoaded, setAllCommitLoaded] = useState<boolean>(false);

  const versionControlContext = useVersionControlContext();
  const [branch, setBranch] = useState<string | undefined>(
    versionControlContext?.currentBranch
  );

  useEffect(() => {
    if (!versionControlContext) return;

    if (!branch || branch != versionControlContext.currentBranch) {
      setBranch(versionControlContext.currentBranch);
      setCommits([]);
    }
    getChangeLogs(
      versionControlContext.currentProjectName,
      versionControlContext.currentBranchName,
      pageNumber,
      pageSize
    )
      .then((data) => {
        if (data.length < pageSize) {
          setAllCommitLoaded(true);
        }
        setCommits((prev) => [...prev, ...data]);
      })
      .catch((err) => {})
      .finally(() => {
        setLoadMoreCommitLoading(false);
      });
  }, [versionControlContext?.currentBranch, pageNumber, pageSize]);

  useEffect(() => {
    setPageNumber(1);
    setPageSize(10);
  }, [versionControlContext?.currentBranch]);

  return (
    <FlexColumnBox sx={{ flexGrow: 1 }}>
      <FlexRowBox
        sx={{
          borderBottom: `1px solid ${Colors.SIDE_LINK_HOVER_COLOR}`,
          padding: "1rem",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <HeadingTypography>Change Log</HeadingTypography>
        <OpenChangeRequestStatus sx={{ marginBottom: -1 }}>
          {versionControlContext?.currentBranchName}
        </OpenChangeRequestStatus>
      </FlexRowBox>
      <FlexColumnBox
        sx={{
          backgroundColor: Colors.APP_VERY_LIGHT_BLUE_COLOR,
          padding: "2rem",
        }}
      >
        <CommitsTable commits={commits} />
        {!allCommitLoaded && (
          <FlexRowBox sx={{ justifyContent: "flex-end", paddingTop: "1rem" }}>
            <BlueButton
              onClick={() => {
                setLoadMoreCommitLoading(true);
                setPageNumber((prev) => prev + 1);
              }}
              disabled={loadMoreCommitLoading}
            >
              {loadMoreCommitLoading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                "Load More Commits"
              )}
            </BlueButton>
          </FlexRowBox>
        )}
      </FlexColumnBox>
    </FlexColumnBox>
  );
};
