import { Box, Typography } from "@mui/material";
import { GenericFilter } from "../../../../Components/GenericFilter/GenericFilter";
import { GenericTable } from "../../../../Packages/Table";
import {
  DownWardAnimatedBox,
  FlexColumnBox,
  FlexRowBox,
  RightFloatTableMenuItemStyle,
} from "../../../../Styles";
import { BlueButton, GreenButton, RedButton } from "../../../../Styles/Button";
import { useNavigate } from "react-router-dom";
import { useSelectedRow } from "../../../../Hooks/useSelectedRows";
import { Colors } from "../../../../Constants";
import { useFilter } from "../../../../Hooks/useFilter";
import {
  FilterType,
  SourceGetSchema,
} from "../../../../Client/configuration-client";
import { useEffect, useMemo, useState } from "react";
import { getSources } from "../../../../Services/getSources";
import { useToast } from "../../../../Hooks/useToast";
import { ToastType } from "../../../../Context/Providers/ToastProvider";
import { SourceTableHeader } from "../../../../Constants/TableHeaders/SourceTableHeader";
import { TRowProps } from "../../../../Packages/Table/Types";
import ThreeDotsMenu from "../../../../Components/MenuListModal";
import { usePermissionContext } from "../../../../Hooks/usePermissionContext";
import { CustomToolTip } from "../../../../Styles/ToolTip";
import { PERMISSION_DENIED_TOOLTIP_MESSAGE } from "../../../../Constants/messages";

export const Sources = () => {
  const navigate = useNavigate();
  const { selectAllRows, selectedRows, updateSelectedRow, totalRows } =
    useSelectedRow([]);

  const [sources, setSources] = useState<SourceGetSchema[]>([]);

  const { filterComponentData } = useFilter(FilterType.Source);
  const toast = useToast();

  const usePermissions = usePermissionContext();

  const isCreateSourcePermission = useMemo(() => {
    if (!usePermissions) return false;
    return usePermissions.isPermission([
      "configurations",
      "configurations.sources",
      "configurations.sources.create",
    ]);
  }, []);

  useEffect(() => {
    getSources()
      .then((data) => {
        setSources(data);
      })
      .catch((err) => {
        // console.log(err);
        toast.open(
          "Something went wrong while fetching sources",
          ToastType.ERROR
        );
      });
  }, []);

  const tableData: TRowProps[] = sources.map((source) => {
    return {
      row_id: source.config_data,
      data: {
        name: {
          column_id: "name",
          text: source.name,
        },
        type: {
          column_id: "type",
          text: source.source_type.name,
        },
        view: {
          column_id: "view",
          text: "",
          reactNode: (
            <FlexRowBox sx={{ justifyContent: "flex-end" }}>
              <BlueButton>View</BlueButton>
              <ThreeDotsMenu
                minWidth="175px"
                options={[
                  <RightFloatTableMenuItemStyle
                    showBorderBottom={false}
                    onClick={() => {}}
                  >
                    Edit
                  </RightFloatTableMenuItemStyle>,
                  <RightFloatTableMenuItemStyle
                    showBorderBottom={true}
                    onClick={() => {}}
                  >
                    Duplicate
                  </RightFloatTableMenuItemStyle>,
                  <RightFloatTableMenuItemStyle
                    showBorderBottom={false}
                    color={Colors.APP_RED_COLOR}
                    onClick={() => {}}
                  >
                    Delete
                  </RightFloatTableMenuItemStyle>,
                ]}
              />
            </FlexRowBox>
          ),
        },
      },
    };
  });

  return (
    <FlexRowBox sx={{ flexGrow: 1 }}>
      <GenericFilter data={filterComponentData} globalSearchEnabled={true} />
      <Box sx={{ flexGrow: "1", padding: "2rem" }}>
        <FlexRowBox sx={{ justifyContent: "space-between" }}>
          <DownWardAnimatedBox flag={selectedRows.length > 0}>
            <FlexColumnBox>
              <FlexRowBox sx={{ gap: "5px" }}>
                <GreenButton>Add to project</GreenButton>
                <RedButton>Delete</RedButton>
              </FlexRowBox>
              <Typography sx={{ color: Colors.LINK_TEXT_COLOR }}>
                {selectedRows.length} row selected
              </Typography>
            </FlexColumnBox>
          </DownWardAnimatedBox>
          <FlexColumnBox>
            <CustomToolTip
              placement="top"
              title={
                isCreateSourcePermission
                  ? ""
                  : PERMISSION_DENIED_TOOLTIP_MESSAGE
              }
            >
              <BlueButton
                onClick={() => {
                  if (!isCreateSourcePermission) return;
                  navigate("/configuration/sources/create");
                }}
                sx={{
                  height: "40px",
                  cursor: isCreateSourcePermission ? "" : "not-allowed",
                }}
                className={isCreateSourcePermission ? "" : "disabled-button"}
              >
                Create Source
              </BlueButton>
            </CustomToolTip>
            <Typography></Typography>
          </FlexColumnBox>
        </FlexRowBox>

        <GenericTable
          rows={tableData}
          tableHeader={SourceTableHeader}
          selectAllRows={selectAllRows}
          updateSelectedRow={updateSelectedRow}
          totalRows={totalRows}
          selectedRows={selectedRows}
        />
      </Box>
    </FlexRowBox>
  );
};
