import { useState } from "react";
import { SingleSelectInput } from "../../../../Components/Forms/SingleSelection";
import { FlexColumnBox, FlexRowBox } from "../../../../Styles";
import ConfirmationModal from "../../../DataLayer/Components/ConfirmationModal";
import { Box, Typography } from "@mui/material";
import { InputTextField } from "../../../../Components/Forms/InputTextField";
import "./feature-generation.css";
import {
  LabelTypography,
  SmallSizeLightColorTypography,
} from "../../../../Styles/CustomTypography";
import { CustomDropDown } from "../../../../Components/Forms/DropDown";
import { convertStringToEnum } from "../../../../Utils/CommonFunctions";
import { BlueButton, GreyButton } from "../../../../Styles/Button";

type CreateManualRunProps = {
  open: boolean;
  closeHandler: () => void;
};

enum ManualRunType {
  SINGLE_DAY = "Single Day",
  HISTORICAL_BACKFILL = "Historical Backfill",
  APPEND_TO_EXISTING_RUN = "Append To Existing Run",
}

export const CreateManualRun = ({ open, closeHandler }: CreateManualRunProps) => {
  const [type, setType] = useState<ManualRunType>(ManualRunType.SINGLE_DAY);
  const [singleDayDate, setSingleDayDate] = useState<string>("");
  const [dataStartDate, setDataStartDate] = useState<string>("");
  const [dateEndDate, setDataEndDate] = useState<string>("");

  const getChildren = () => {
    return (
      <FlexColumnBox sx={{ width: "800px", gap: "1.5rem", padding: "1rem" }}>
        <SingleSelectInput
          label={""}
          data={[
            {
              optionId: ManualRunType.SINGLE_DAY,
              title: ManualRunType.SINGLE_DAY,
              description:
                "Generate Features using all the data up until 00:00 on the given date, therefore data on the date itself is not included. Output 1 row per Entity with the given date.",
            },
            {
              optionId: ManualRunType.HISTORICAL_BACKFILL,
              title: ManualRunType.HISTORICAL_BACKFILL,
              description:
                "Generates Features for every date in the range. On each date there will be one row per Entity using all data prior to midnight on that date.",
            },
            {
              optionId: ManualRunType.APPEND_TO_EXISTING_RUN,
              title: ManualRunType.APPEND_TO_EXISTING_RUN,
              description:
                "Generate only new features in a feature set, adding to a run which has already calculated features for the required entities.",
            },
          ]}
          onSelect={(selectItem) => {
            const convertedEnum = convertStringToEnum(
              ManualRunType,
              selectItem
            );
            if (convertedEnum) {
              setType(convertedEnum);
            }
          }}
          selectedOptionId={type}
          showAsGrid
          columnCount={2}
        />
        {type === ManualRunType.SINGLE_DAY && (
          <>
            <Box>
              <InputTextField
                onChange={(e) => {
                  setSingleDayDate(e.target.value);
                }}
                value={singleDayDate}
                violationArray={[]}
                type="date"
                title="Date"
                description="Choose the date you would like the manual run to generate data for."
              />
            </Box>
            <div className="alert-message-container success">
              <p className="alert-message-text">
                This will include data up until{" "}
              </p>
              <p className="alert-message-text">
                For more detailed information on these dates, read the
                documentation
              </p>
            </div>
          </>
        )}
        {type === ManualRunType.HISTORICAL_BACKFILL && (
          <>
            <Box>
              <InputTextField
                onChange={(e) => {
                  setDataStartDate(e.target.value);
                }}
                value={dataStartDate}
                violationArray={[]}
                title="Data Start Date"
                description="Choose the start date you would like the manual run to generate data for"
                type="date"
              />
            </Box>
            <Box>
              <InputTextField
                onChange={(e) => {
                  setDataEndDate(e.target.value);
                }}
                value={dateEndDate}
                violationArray={[]}
                title="Data End Date"
                description="Choose the end date you would like to manual run to generate data for."
                type="date"
              />
            </Box>
          </>
        )}
        {type === ManualRunType.APPEND_TO_EXISTING_RUN && (
          <>
            <Box className="alert-message-container warning">
              <Typography>
                Adding to run is advanced usage and may lead to inconsistent
                results.
              </Typography>
              <ul style={{ paddingLeft: "2rem" }}>
                <li>
                  Features will only be generated which are not included in the
                  existing run, meaning result can be inconsistent when data is
                  updated.
                </li>
                <li>
                  Entities which aren't included in the appended run will have
                  suprious missing values where pre-calculated features were
                  expected.
                </li>
              </ul>
            </Box>
            <Box>
              <LabelTypography>Run ID</LabelTypography>
              <CustomDropDown
                data={[]}
                multiple={false}
                selectedItems={[]}
                updateSelectedItems={function (selectedItems: string[]): void {
                  throw new Error("Function not implemented.");
                }}
              />
              <SmallSizeLightColorTypography>
                Please select the run id to append to
              </SmallSizeLightColorTypography>
            </Box>
          </>
        )}
        <FlexRowBox sx={{ flexDirection: "row-reverse", gap: "1rem" }}>
          <BlueButton>Run</BlueButton>
          <GreyButton onClick={closeHandler} >Cancel</GreyButton>
        </FlexRowBox>
      </FlexColumnBox>
    );
  };

  return (
    <ConfirmationModal
      heading="Run Schedule Now"
      message=""
      isLoading={false}
      open={open}
      hideButtons
    >
      {getChildren()}
    </ConfirmationModal>
  );
};
