import { FlexRowBox } from "../../Styles";
import { Outlet } from "react-router-dom";
import SideBar from "./Components/Sidebar";

export const Configuration = () => {

    return (
        <FlexRowBox>
            <SideBar/>
            <Outlet/>
        </FlexRowBox>
    );
}