import { Box, IconButton, Typography } from "@mui/material";
import {
  FlexColumnBox,
  FlexRowBox,
  UnderLineOnHoverNavLink,
} from "../../../../Styles";
import {
  HeadingTypography,
  LabelTypography,
  SmallSizeLightColorTypography,
} from "../../../../Styles/CustomTypography";
import { BlueButton, GreyButton, RedButton } from "../../../../Styles/Button";
import { Colors } from "../../../../Constants";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getEntityMappingById } from "../../../../Services/getEntityMappingById";
import { useToast } from "../../../../Hooks/useToast";
import { ToastType } from "../../../../Context/Providers/ToastProvider";
import { CustomSwitch } from "../../../../Components/CustomSwitch";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Loader } from "../../../../Components/Loader";
import ConfirmationModal from "../ConfirmationModal";
import { deleteEntityMapping } from "../../../../Services/deleteEntityMapping";
import { DropDownMenu } from "../Features/Components/CreateFeatureTabs";
import { EntityMappingGetSchema } from "../../../../Client/typescript-axios-client-generated";
import { useVersionControlContext } from "../../../../Hooks/useVersionControlContext";

export const ViewEntityMapping = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [entityMappingData, setEntityMappingData] = useState<
    EntityMappingGetSchema | undefined
  >(undefined);
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const toast = useToast();

  const navigate = useNavigate();

  const { entityMappingId } = useParams();

  const versionControlContext = useVersionControlContext();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const duplicateClickHandler = () => {
    navigate("/data-layer/entity-mappings/create", {
      state: { entityMappingData: entityMappingData, duplicate: true },
    });
  };

  const deleteClickHandler = () => {
    setOpenDeleteConfirmationModal(true);
  };

  const handleModalClose = () => {
    setOpenDeleteConfirmationModal(false);
  };

  const modalConfirmationHandler = () => {
    if (versionControlContext === undefined) return;
    if (entityMappingId) {
      deleteEntityMapping(
        versionControlContext.currentProjectName,
        versionControlContext.currentBranchName,
        entityMappingId
      )
        .then((data) => {
          if (!data.result) throw Error();
          toast.open(
            `Entity Mapping '${
              entityMappingData?.from_entity.name +
              " to " +
              entityMappingData?.to_entity.name
            }' Deleted Successfully`,
            ToastType.SUCCESS
          );
          navigate("/data-layer/entity-mappings");
        })
        .catch((err) => {
          toast.open(
            "Something went wrong while deleting entity mapping",
            ToastType.ERROR
          );
        });
    }
  };

  useEffect(() => {
    if (!entityMappingId) {
      toast.open(
        "Something went wrong can't load entity mapping data",
        ToastType.ERROR
      );
      return;
    }
    if (!versionControlContext) return;
    getEntityMappingById(
      versionControlContext.currentProjectName,
      versionControlContext.currentBranchName,
      entityMappingId
    )
      .then((data) => {
        setEntityMappingData(data);
      })
      .catch((err) => {
        toast.open(
          "Something went wrong can't load entity mapping data",
          ToastType.ERROR
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [entityMappingId]);
  console.log(entityMappingData);
  if (isLoading) {
    return (
      <FlexColumnBox sx={{ width: "100%" }}>
        <Loader />
      </FlexColumnBox>
    );
  }
  return (
    <FlexColumnBox sx={{ flexGrow: 1 }}>
      <ConfirmationModal
        isLoading={false}
        closeHandler={handleModalClose}
        message="Are you sure you want to delete Entity Mapping?"
        heading="Delete Entity Mapping"
        open={openDeleteConfirmationModal}
        secondaryCustomButton={RedButton}
        primaryCustomButton={GreyButton}
        positionTop="60%"
        confirmHandler={modalConfirmationHandler}
      />
      <DropDownMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        minWidth={""}
        children={getOptions({ duplicateClickHandler, deleteClickHandler })}
      />
      <FlexRowBox
        sx={{
          justifyContent: "space-between",
          borderBottom: `1px solid ${Colors.SIDE_LINK_HOVER_COLOR}`,
          padding: "1rem",
        }}
      >
        <HeadingTypography>
          {entityMappingData?.from_entity.name +
            " to " +
            entityMappingData?.to_entity.name}
        </HeadingTypography>
        <FlexRowBox>
          <BlueButton onClick={() => {}}>Edit Entity Mapping</BlueButton>
          <IconButton
            onClick={handleClick}
            sx={{
              color: Colors.LINK_TEXT_COLOR,
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)", // Change background color on hover
              },
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </FlexRowBox>
      </FlexRowBox>
      <FlexColumnBox
        sx={{ paddingLeft: "2rem", gap: "2rem", paddingTop: "2rem" }}
      >
        <Box>
          <LabelTypography>From Entity</LabelTypography>
          <UnderLineOnHoverNavLink
            style={{ color: Colors.APP_BLUE_COLOR }}
            to={""}
          >
            {entityMappingData?.from_entity.name}
          </UnderLineOnHoverNavLink>
        </Box>
        <Box>
          <LabelTypography>To Entity</LabelTypography>
          <UnderLineOnHoverNavLink
            style={{ color: Colors.APP_BLUE_COLOR }}
            to={""}
          >
            {entityMappingData?.to_entity.name}
          </UnderLineOnHoverNavLink>
        </Box>
        <Box>
          <LabelTypography>Mapping Feature</LabelTypography>
          <UnderLineOnHoverNavLink
            style={{ color: Colors.APP_BLUE_COLOR }}
            to={""}
          >
            {entityMappingData?.mapping_feature.name}
          </UnderLineOnHoverNavLink>
        </Box>
        <Box>
          <LabelTypography>Project multiple entities</LabelTypography>
          <SmallSizeLightColorTypography>
            Whether the Mapping Feature projects multiple entities, rather than
            a single entity
          </SmallSizeLightColorTypography>
          <SmallSizeLightColorTypography>
            If selected, the Mapping Feature must return an SQL array type,
            where each entry in the array is an entity to be mapped to{" "}
          </SmallSizeLightColorTypography>
          <SmallSizeLightColorTypography>
            If left unset the behavior will be inferred based on the type
            returned by the Mapping feature - and array results will be
            interpreted as an entity per item in the array
          </SmallSizeLightColorTypography>
          <CustomSwitch
            checked={entityMappingData?.project_multiple_entities}
          />
        </Box>
        <Box>
          <Typography fontSize={"23px"} fontWeight={600}>
            History
          </Typography>
        </Box>
      </FlexColumnBox>
    </FlexColumnBox>
  );
};

const getOptions = ({
  duplicateClickHandler,
  deleteClickHandler,
}: {
  duplicateClickHandler: () => void;
  deleteClickHandler: () => void;
}) => {
  return (
    <FlexColumnBox sx={{ width: "150px" }}>
      <Box
        onClick={duplicateClickHandler}
        sx={{
          textAlign: "right",
          padding: "10px",
          transition: "color 0.3s", // Smooth transition effect for color change
          color: "#add8e6", // Light blue color for text
          fontWeight: 500,
          "&:hover": {
            cursor: "pointer", // Change cursor to pointer on hover
            backgroundColor: Colors.SIDE_LINK_HOVER_COLOR,
          },
          marginBottom: "8px", // Add some space between each Box
          borderBottom: `1px solid ${Colors.LINK_TEXT_COLOR}`,
        }}
      >
        Duplicate
      </Box>
      <Box
        onClick={deleteClickHandler}
        sx={{
          textAlign: "right",
          padding: "10px",
          transition: "color 0.3s", // Smooth transition effect for color change
          color: Colors.APP_RED_COLOR, // Light blue color for text
          fontWeight: 500,
          "&:hover": {
            cursor: "pointer", // Change cursor to pointer on hover
            backgroundColor: Colors.SIDE_LINK_HOVER_COLOR,
          },
          marginBottom: "8px", // Add some space between each Box
        }}
      >
        Delete
      </Box>
    </FlexColumnBox>
  );
};
