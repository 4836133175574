import { BranchCreate, ProjectCreate } from "../Client/typescript-axios-client-generated";
import { ApiClient } from "../Utils/ApiClient";

export const createProject = async (body: ProjectCreate) => {
  return ApiClient.projectApiClient.createProjectProjectPost(body).then((data)=>{
    return data
  }).catch((err)=>{
    console.log(err)
  });
};
