import { ApiClient } from "../Utils/ApiClient";

export const getAllTeams = async () => {
  return ApiClient.teamApiClient
    .getDomainsTeamGet()
    .then((data) => {
      return data.data
    })
    .catch((err) => {
      return err;
    });
};
