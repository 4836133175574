import { useState } from "react";
import { InputTextField } from "../../../../../Components/Forms/InputTextField";
import { FlexColumnBox } from "../../../../../Styles";
import { GreenButton } from "../../../../../Styles/Button";
import { validateStringContainOnlyLowerCaseOrUnderScore } from "../../../../../Utils/CommonFunctions";
import { CircularProgress } from "@mui/material";
import { createProject } from "../../../../../Services/createProject";
import { DescriptionTypography } from "../../../../../Styles/CustomTypography";

export const CreateProject = () => {
  const [projectName, setProjectName] = useState<string>("");
  const [projectDescription, setProjectDescription] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [violationArrayProjectName, setViolationArrayProjectName] = useState<
    string[]
  >([]);
  const [isErrorOccured, setIsErrorOccured] = useState<boolean>(false);

  const handleCreateProject = () => {
    //validate input
    setViolationArrayProjectName([]);
    setIsErrorOccured(false)
    let res = validateStringContainOnlyLowerCaseOrUnderScore(projectName);
    if (!res) {
      setViolationArrayProjectName((prev) => {
        return [
          ...prev,
          "project name should contains only lowercase english letter or underscores",
        ];
      });
      return;
    }
    setIsLoading(true);
    createProject({
      name: projectName,
      description: projectDescription,
    })
      .then((data) => {
        setIsLoading(false)
        setProjectName('')
        setProjectDescription('')
      })
      .catch((error) => {
        setIsErrorOccured(true)
      });
  };

  return (
    <FlexColumnBox padding={"2rem"} width={'100%'}>
      <InputTextField
        title="Name"
        placeholder="Project Name"
        description="Name of project should contain underscopre and lowercase english letters"
        onChange={(event: any) => {
          setProjectName(event.target.value.toLowerCase());
        }}
        value={projectName}
        violationArray={violationArrayProjectName}
      />
      <InputTextField
        title="Description"
        placeholder="Description"
        onChange={(event: any) => {
          setProjectDescription(event.target.value);
        }}
        value={projectDescription}
        violationArray={[]}
      />
      <br />
      <GreenButton
        onClick={handleCreateProject}
        startIcon={isLoading && <CircularProgress size={20} color="inherit" />}
        disabled={isLoading}
      >
        Create Project
      </GreenButton>
      {isErrorOccured && (<DescriptionTypography color={"red"}>
        <span
          style={{
            display: "inline-block",
            verticalAlign: "middle",
            marginRight: "5px",
            backgroundColor: "red",
            width: "6px",
            height: "6px",
            borderRadius: "50%",
          }}
        ></span> Error occured while creating new project try again
      </DescriptionTypography>)}
    </FlexColumnBox>
  );
};
