import { JobType } from "../Client/typescript-axios-client-generated";
import { ApiClient } from "../Utils/ApiClient";

export const getSchedulesByType = (
  jobType: JobType,
  project_name: string,
  branch_name: string
) => {
  return ApiClient.scheduleClient
    .getAllSchedulesSchedulerFeatureGenerationScheduleGet(project_name, branch_name, jobType)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};
