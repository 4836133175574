import { TRowProps } from "../../Packages/Table/Types";
import { TEntityMapping } from "../../Types/EntityMapping";
import { BlueButton } from "../../Styles/Button";
import { EntityMappingGetSchema } from "../../Client/typescript-axios-client-generated";

export const mapToEntityMappingTableData = (
  entityMappings: EntityMappingGetSchema[]
): TRowProps[] => {
  return entityMappings.map((entityMapping) => {
    return {
      row_id: entityMapping.id,
      data: {
        name: {
          column_id: "name",
          text:
            entityMapping.from_entity.name +
            " to " +
            entityMapping.to_entity.name,
          link: "/data-layer/entity-mappings/" + entityMapping.id,
        },
        from: {
          column_id: "from",
          text: entityMapping.from_entity.name,
          link: "data-layer/entities/" + entityMapping.from_entity_id,
        },
        to: {
          column_id: "to",
          text: entityMapping.to_entity.name,
          link: "data-layer/entities/" + entityMapping.to_entity_id,
        },
        viaFeature: {
          column_id: "viaFeature",
          text: entityMapping.mapping_feature.name,
          link: "data-layer/features/" + entityMapping.mapping_feature_id,
        },
        updated: {
          column_id: "updated",
          text: "2 years ago",
          toolTip: "Apr 12, 2022 4:06 PM (IST)",
        },
        view: {
          column_id: "view",
          text: "View",
          onClickHandler: (row_id: string) => {},
          buttonElement: BlueButton,
        },
      },
    };
  });
};
