import { FeatureGenerationScheduleCreate } from "../Client/typescript-axios-client-generated";
import { ApiClient } from "../Utils/ApiClient";

export const createSchedule = async (
  projectName: string,
  branchName: string,
  schedule: FeatureGenerationScheduleCreate
) => {
  return ApiClient.scheduleClient
    .createSchedulerSchedulerFeatureGenerationSchedulePost(projectName, branchName, schedule)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};
