import { Alert, Box } from "@mui/material";
import { FlexColumnBox, FlexRowBox } from "../../../../../../../Styles";
import { Colors } from "../../../../../../../Constants";
import AddIcon from "@mui/icons-material/Add";
import {
  LabelTypography,
  MediumSizeDarkColorThinTypography,
  MediumSizeHeading,
  TableTypography,
} from "../../../../../../../Styles/CustomTypography";
import RichTextField from "../../../../../../../Components/Forms/RichTextInputField";
import { BlueButton, GreyButton } from "../../../../../../../Styles/Button";
import { GenericTable } from "../../../../../../../Packages/Table";
import { ApprovalText } from "./ApprovalText";
import ConfirmationModal from "../../../../../../DataLayer/Components/ConfirmationModal";
import { RequestReview, RequestReviewType } from "./RequestReview";
import { useState } from "react";
import { ChangeRequestGetSchemaWithCommits } from "../../../../../../../Client/typescript-axios-client-generated";

export type ChangeRequestDetailProps = {
  data?: ChangeRequestGetSchemaWithCommits;
};

export const ChangeRequestDetail = ({ data }: ChangeRequestDetailProps) => {
  const [openRequestReviewModal, setOpenRequestReviewModal] =
    useState<boolean>(false);
  const [requestReviewType, setRequestReviewType] = useState<RequestReviewType>(
    RequestReviewType.USER
  );

  const handleAddUserReviewer = () => {
    setOpenRequestReviewModal(true);
    setRequestReviewType(RequestReviewType.USER);
  };

  const handleAddGroupReviewer = () => {
    setOpenRequestReviewModal(true);
    setRequestReviewType(RequestReviewType.GROUP);
  };

  if (!data) {
    return (
      <Alert variant="filled" severity="warning">
        Failed to load change request data
      </Alert>
    );
  }

  return (
    <FlexColumnBox>
      <ConfirmationModal
        heading="Request reqview from a User"
        message=""
        isLoading={false}
        open={openRequestReviewModal}
        closeHandler={() => {
          setOpenRequestReviewModal(false);
        }}
        children={
          <RequestReview
            type={RequestReviewType.USER}
            closeHandler={() => {
              setOpenRequestReviewModal(false);
            }}
          />
        }
        hideButtons={true}
      />
      <FlexRowBox
        sx={{
          gap: "1.5rem",
        }}
      >
        <FlexColumnBox sx={{ gap: "1.5rem", flexGrow: 1 }}>
          <MediumSizeHeading>Change Request Details</MediumSizeHeading>
          <FlexRowBox sx={{ gap: "4rem" }}>
            <FlexColumnBox sx={{ gap: "1rem" }}>
              <LabelTypography>Created at</LabelTypography>
              <MediumSizeDarkColorThinTypography>
                {data.created_at}
              </MediumSizeDarkColorThinTypography>
            </FlexColumnBox>
            <FlexColumnBox sx={{ gap: "1rem" }}>
              <LabelTypography>Last Modified</LabelTypography>
              <MediumSizeDarkColorThinTypography>
                {data.updated_at}
              </MediumSizeDarkColorThinTypography>
            </FlexColumnBox>
          </FlexRowBox>
          <FlexColumnBox sx={{ gap: ".75rem" }}>
            <MediumSizeHeading>
              Adeyemi,OO C requested changes 2 days ago
            </MediumSizeHeading>
            <Box
              sx={{
                padding: "1.5rem",
                border: `1px solid ${Colors.SIDE_LINK_HOVER_COLOR}`,
              }}
            >
              <MediumSizeDarkColorThinTypography>
                changing the datatype of the feature to DATE as it does not
                function correctly with current output data type
              </MediumSizeDarkColorThinTypography>
            </Box>
          </FlexColumnBox>
          <FlexColumnBox
            sx={{
              borderRadius: ".25rem",
              padding: "1rem",
              backgroundColor: Colors.APP_VERY_LIGHT_BLUE_COLOR,
              gap: "1rem",
            }}
          >
            <RichTextField
              input={"{}"}
              setInput={function (e: string): void {
                throw new Error("Function not implemented.");
              }}
              editorBackgroundColors="white"
            />
            <BlueButton sx={{ width: "fit-content", alignSelf: "flex-end" }}>
              Post Comment
            </BlueButton>
          </FlexColumnBox>
        </FlexColumnBox>
        <FlexColumnBox sx={{ flex: "33.33%", gap: "4rem" }}>
          <FlexColumnBox sx={{ gap: "1rem" }}>
            <MediumSizeHeading>Review Requests</MediumSizeHeading>
            <FlexRowBox sx={{ justifyContent: "flex-end", gap: "1rem" }}>
              <GreyButton
                sx={{ padding: ".25rem, .5rem" }}
                onClick={handleAddUserReviewer}
              >
                {" "}
                <AddIcon /> User
              </GreyButton>
              <GreyButton
                sx={{ padding: ".25rem, .5rem" }}
                onClick={handleAddGroupReviewer}
              >
                {" "}
                <AddIcon /> Group
              </GreyButton>
            </FlexRowBox>
            <GenericTable
              rows={[
                {
                  row_id: "1",
                  data: {
                    info: {
                      column_id: "info",
                      text: "Features in the feature set will show here",
                      buttonElement: TableTypography,
                    },
                  },
                },
              ]}
              tableHeader={[
                {
                  column_id: "info",
                  label: "",
                  isSortable: "",
                  visible: true,
                },
              ]}
              selectAllRows={() => {}}
              updateSelectedRow={() => {}}
              totalRows={0}
              selectedRows={[]}
              hidePagination={true}
              hideHeader={true}
              hideCheckbox={true}
            />
          </FlexColumnBox>
          <FlexColumnBox sx={{ gap: "1rem" }}>
            <MediumSizeHeading>Approvals</MediumSizeHeading>
            <Box>
              <ApprovalText
                approvedBy={"Gibilaro,V,Vincenzo, QMKR"}
                approvedAt={"Jun 24, 2024 7:59PM(IST)"}
              />
              <ApprovalText
                approvedBy={"Gibilaro,V,Vincenzo, QMKR"}
                approvedAt={"Jun 24, 2024 7:59PM(IST)"}
              />
              <ApprovalText
                approvedBy={"Gibilaro,V,Vincenzo, QMKR"}
                approvedAt={"Jun 24, 2024 7:59PM(IST)"}
              />
            </Box>
          </FlexColumnBox>
        </FlexColumnBox>
      </FlexRowBox>
    </FlexColumnBox>
  );
};
