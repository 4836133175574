import { ApiClient } from "../Utils/ApiClient";

export const getAllBranchEntities = async (
  projectName: string,
  branchName: string
) => {
  return ApiClient.entityApiClient
    .getAllEntitiesEntityGet(projectName, branchName)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      return err;
    });
};
