import { SidebarProps } from "../../Components/Sidebar";
import GridOnIcon from '@mui/icons-material/GridOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ChecklistIcon from '@mui/icons-material/Checklist';
import ExploreIcon from "@mui/icons-material/Explore";
import PieChartIcon from '@mui/icons-material/PieChart';


export const SchedulesSidebarData: Omit<
  SidebarProps,
  "isToggled" | "toggle"
> = {
  data: [
    {
      title: "Governance",
      links: [
        {
          to: "/governance/change-requests",
          label: "All Schedules",
          icon: <CalendarTodayIcon />,
        },
        {
          to: "/governance/branches",
          label: "All Runs",
          icon: <ChecklistIcon />,
        },
        {
          to: "/schedules/feature-generation",
          label: "Feature Generation",
          icon: <ExploreIcon />,
        },
        {
          to: "/governance/commits",
          label: "Metrics Generation",
          icon: <PieChartIcon />,
        },
        {
          to: "/governance/commits",
          label: "Materialisation Jobs",
          icon: <GridOnIcon />,
        },
      ],
    },
  ],
};
