import { Box, FormGroup } from "@mui/material";
import { FlexColumnBox, FlexRowBox } from "../../../../Styles";
import {
  HeadingTypography,
  LabelTypography,
  SmallSizeLightColorTypography,
} from "../../../../Styles/CustomTypography";
import { CustomDropDown } from "../../../../Components/Forms/DropDown";
import { CustomSwitch } from "../../../../Components/CustomSwitch";
import { Colors } from "../../../../Constants";
import { BlueButton } from "../../../../Styles/Button";
import { useEffect, useMemo, useState } from "react";
import { useVersionControlContext } from "../../../../Hooks/useVersionControlContext";
import { createEntityMapping } from "../../../../Services/createEntityMapping";
import { useLocation, useNavigate } from "react-router-dom";
import { useToast } from "../../../../Hooks/useToast";
import { ToastType } from "../../../../Context/Providers/ToastProvider";
import { useGetEntities } from "../../../../Hooks/DataFetcher/Entity/useGetEntities";
import { useGetFeatures } from "../../../../Hooks/DataFetcher/Feature/useGetFeatures";

export const CreateEntityMapping = () => {
  const { entities, updateEntities } = useGetEntities();
  const [fromEntity, setFromEntity] = useState<string[]>([]);
  const [toEntity, setToEntity] = useState<string[]>([]);
  const [mappingFeature, setMappingFeature] = useState<string[]>([]);
  const [fromEntityViolationArray, setFromEntityViolationArray] = useState<
    string[]
  >([]);
  const [toEntityViolationArray, setToEntityViolationArray] = useState<
    string[]
  >([]);
  const [featureMappingViolationArray, setFeatureMappingViolationArray] =
    useState<string[]>([]);
  const [projectMultipleEntities, setProjectMultipleEntities] =
    useState<boolean>(false);
  const { features } = useGetFeatures();

  const toast = useToast();

  const navigate = useNavigate();

  const versionControlContext = useVersionControlContext();

  const location = useLocation();
  const { entityMappingData, duplicate } = location.state || {
    entityMappingData: undefined,
    duplicate: false,
  };
  console.log(duplicate);

  const entityDropDownData = useMemo(() => {
    return entities.map((entity) => {
      return {
        label: entity.name,
        itemId: entity.id,
      };
    });
  }, [entities]);

  const featureDropDownData = useMemo(() => {
    return features.map((feature) => {
      return {
        label: feature.name,
        itemId: feature.id,
      };
    });
  }, [features]);

  useEffect(() => {
    if (!entityMappingData) return;
    if (entities.length > 0 && features.length > 0) {
      setFromEntity([entityMappingData.fromEntityId]);
      setToEntity([entityMappingData.toEntityId]);
      setMappingFeature([entityMappingData.mappingFeatureId]);
    }
  }, [entities, features]);

  const handleSubmit = () => {
    /*
    call backend create entity mapping endpoint
    */
    setFromEntityViolationArray([]);
    setToEntityViolationArray([]);
    setFeatureMappingViolationArray([]);
    let isValidationFailed = false;
    if (fromEntity.length === 0) {
      setFromEntityViolationArray((prev) => [
        ...prev,
        "Need to select From entity field to create entity mapping",
      ]);
      isValidationFailed = true;
    }
    if (toEntity.length === 0) {
      setToEntityViolationArray((prev) => [
        ...prev,
        "Need to select To entity field to create entity mapping",
      ]);
      isValidationFailed = true;
    }
    if (mappingFeature.length === 0) {
      setFeatureMappingViolationArray((prev) => [
        ...prev,
        "Need to select mapping feature field to create entity mapping",
      ]);
      isValidationFailed = true;
    }
    if (isValidationFailed) {
      return;
    }
    if (fromEntity[0] === toEntity[0]) {
      setFromEntityViolationArray((prev) => [
        ...prev,
        "From Entity can't be equal to to Entity",
      ]);
      setToEntityViolationArray((prev) => [
        ...prev,
        "To Entity can't be equal to From Entity",
      ]);
      return;
    }
    if (versionControlContext === undefined) return;
    createEntityMapping(
      versionControlContext?.currentProjectName,
      versionControlContext?.currentBranchName,
      {
        from_entity_id: fromEntity[0],
        to_entity_id: toEntity[0],
        mapping_feature_id: mappingFeature[0],
        project_multiple_entities: projectMultipleEntities,
      }
    )
      .then((data) => {
        navigate("/data-layer/entity-mappings");
        toast.open("Entity Mapping create successfully", ToastType.SUCCESS);
      })
      .catch((err) => {
        console.log(err);
        toast.open(
          "Something went wrong while creating entity mapping",
          ToastType.ERROR
        );
      });
  };

  return (
    <FormGroup sx={{ flexGrow: 1 }}>
      <FlexRowBox
        sx={{
          justifyContent: "space-between",
          borderBottom: `1px solid ${Colors.SIDE_LINK_HOVER_COLOR}`,
          padding: "1rem",
        }}
      >
        <HeadingTypography>
          {entityMappingData
            ? entityMappingData.fromEntity.name +
              " to " +
              entityMappingData.toEntity.name
            : "Create Entity Mapping"}
        </HeadingTypography>
        <BlueButton onClick={handleSubmit}>
          {duplicate ? "Save Entity Mapping" : "Create Entity Mapping"}
        </BlueButton>
      </FlexRowBox>
      <FlexColumnBox sx={{ padding: "2rem", gap: "1rem" }}>
        <Box>
          <LabelTypography>From Entity</LabelTypography>
          <SmallSizeLightColorTypography>
            Select the source Entity that will be mapped from
          </SmallSizeLightColorTypography>
          <CustomDropDown
            data={entityDropDownData}
            multiple={false}
            selectedItems={fromEntity}
            updateSelectedItems={(selectedItems) => {
              if (selectedItems.length === 0) {
                setFromEntityViolationArray((prev) => [
                  ...prev,
                  "Need to select From entity field to create entity mapping",
                ]);
              } else {
                setFromEntityViolationArray([]);
              }
              setFromEntity(selectedItems);
            }}
            validationsErrors={fromEntityViolationArray}
          />
        </Box>
        <Box>
          <LabelTypography>To Entity</LabelTypography>
          <SmallSizeLightColorTypography>
            Select the destination Entity that will be the result of the mapping
          </SmallSizeLightColorTypography>
          <CustomDropDown
            data={entityDropDownData}
            multiple={false}
            selectedItems={toEntity}
            updateSelectedItems={(selectedItems) => {
              if (selectedItems.length === 0) {
                setToEntityViolationArray((prev) => [
                  ...prev,
                  "Need to select To entity field to create entity mapping",
                ]);
              } else {
                setToEntityViolationArray([]);
              }
              setToEntity(selectedItems);
            }}
            validationsErrors={toEntityViolationArray}
          />
        </Box>
        <Box>
          <LabelTypography>Mapping Feature</LabelTypography>
          <SmallSizeLightColorTypography>
            Select the Feature for mapping between Entites
          </SmallSizeLightColorTypography>
          <CustomDropDown
            data={featureDropDownData}
            multiple={false}
            selectedItems={mappingFeature}
            updateSelectedItems={(selectedItems) => {
              if (selectedItems.length === 0) {
                setFeatureMappingViolationArray((prev) => [
                  ...prev,
                  "Need to select mapping feature field to create entity mapping",
                ]);
              } else {
                setFeatureMappingViolationArray([]);
              }
              setMappingFeature(selectedItems);
            }}
            validationsErrors={featureMappingViolationArray}
          />
        </Box>
        <Box>
          <LabelTypography>Project multiple entities</LabelTypography>
          <SmallSizeLightColorTypography>
            Whether the Mapping Feature projects multiple entities, rather than
            a single entity
          </SmallSizeLightColorTypography>
          <SmallSizeLightColorTypography>
            If selected, the Mapping Feature must return an SQL array type,
            where each entry in the array is an entity to be mapped to{" "}
          </SmallSizeLightColorTypography>
          <SmallSizeLightColorTypography>
            If left unset the behavior will be inferred based on the type
            returned by the Mapping feature - and array results will be
            interpreted as an entity per item in the array
          </SmallSizeLightColorTypography>
          <CustomSwitch
            checked={projectMultipleEntities}
            onChange={() => {
              setProjectMultipleEntities((prev) => !prev);
            }}
          />
        </Box>
      </FlexColumnBox>
    </FormGroup>
  );
};
