import { useEffect, useReducer } from "react";
import { CreateSourceType, TCreateSource, TCreateSourceContextActions } from "../Type";
import { getSourcesTypes } from "../../../Services/getSourcesTypes";

const initialState: TCreateSource = {
    name: '',
    description: '{}',
    source_type: [],
    config_data: {},
    source_types_data: [],
    attribute_data: {},
};

const reducer = (state: TCreateSource, action: TCreateSourceContextActions): TCreateSource => {
    switch (action.type) {
        case 'UPDATE_NAME':
            return { ...state, name: action.payload };
        case 'UPDATE_DESCRIPTION':
            return { ...state, description: action.payload };
        case 'UPDATE_SOURCE_TYPE':
            return { ...state, source_type: action.payload };
        case 'UPDATE_CONFIG_DATA':
            return { ...state, config_data: action.payload };
        case 'SOURCE_TYPES_DATA':
            return { ...state, source_types_data: action.payload }
        case 'UPDATE_ATTRIBUTE_DATA':
            return { ...state, attribute_data: action.payload }
        default:
            return state;
    }
};

export const useCreateSourceContextProvider = (type: CreateSourceType) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        getSourcesTypes().then((data) => {
            dispatch({ type: 'SOURCE_TYPES_DATA', payload: data });
            const config_data: any = {}
            data.map((sourceType: any) => {
                const configFormat: any = {}
                sourceType[type === CreateSourceType.SOURCE ? 'source_config_format' : 'destination_config_format']?.map((field: any) => {
                    configFormat[field.key] = ''
                });
                config_data[sourceType.id] = configFormat;
            });
            dispatch({ type: 'UPDATE_CONFIG_DATA', payload: config_data })
        }).catch((err) => {

        })
    }, [])

    return { state, dispatch };
};