import { Typography } from "@mui/material";
import { useSelectedRow } from "../../../../../Hooks/useSelectedRows";
import { useVersionControlContext } from "../../../../../Hooks/useVersionControlContext";
import { GenericTable } from "../../../../../Packages/Table";
import { TRowProps, TTableHeader } from "../../../../../Packages/Table/Types";
import {
  DownWardAnimatedBox,
  FlexColumnBox,
  FlexRowBox,
} from "../../../../../Styles";
import { BlueButton, RedButton } from "../../../../../Styles/Button";
import { Colors } from "../../../../../Constants";
import { useNavigate } from "react-router-dom";
import { formattedReadableDate } from "../../../../../Utils/dateTimeUtils";
import "../../../../../Styles/CSS/branch.css";
export const BranchesTable = () => {
  const versionControlContext = useVersionControlContext();
  const branchesInTableFormat =
    versionControlContext?.branchesList.map((branch) => {
      return {
        row_id: branch.id,
        data: {
          name: {
            column_id: "name",
            text: branch.name,
          },
          created_at: {
            column_id: "created_at",
            text: formattedReadableDate(branch.created_at),
          },
          updated_at: {
            column_id: "updated_at",
            text: formattedReadableDate(branch.updated_at),
          },
          commits_behind: {
            column_id: "commits_behind",
            text: "",
            reactNode: (
              <CommitBar
                type={CommitBarType.BEHIND}
                value={branch.commits_behind ?? 0}
              />
            ),
          },
          commits_ahead: {
            column_id: "commits_ahead",
            text: "",
            reactNode: (
              <CommitBar
                type={CommitBarType.AHEAD}
                value={branch.commits_ahead ?? 0}
              />
            ),
          },
        },
      };
    }) ?? [];

  const { selectAllRows, selectedRows, updateSelectedRow, totalRows } =
    useSelectedRow(branchesInTableFormat);
  const header: TTableHeader[] = [
    { column_id: "name", label: "Name", isSortable: "asc", visible: true },
    {
      column_id: "created_at",
      label: "Created",
      isSortable: "asc",
      visible: true,
    },
    {
      column_id: "updated_at",
      label: "Last Updated",
      isSortable: "asc",
      visible: true,
    },
    {
      column_id: "commits_behind",
      label: "Commits Behind",
      isSortable: "asc",
      visible: true,
    },
    {
      column_id: "commits_ahead",
      label: "Commits Ahead",
      isSortable: "asc",
      visible: true,
    },
  ];
  const navigate = useNavigate();

  return (
    <FlexColumnBox sx={{ padding: "1rem", flexGrow: 1 }}>
      <FlexRowBox sx={{ justifyContent: "space-between" }}>
        <DownWardAnimatedBox flag={selectedRows.length > 0}>
          <FlexColumnBox>
            <FlexRowBox sx={{ gap: "5px" }}>
              <RedButton>Delete</RedButton>
            </FlexRowBox>
            <Typography sx={{ color: Colors.LINK_TEXT_COLOR }}>
              {selectedRows.length} row selected
            </Typography>
          </FlexColumnBox>
        </DownWardAnimatedBox>
        <FlexColumnBox>
          <BlueButton
            onClick={() => {
              navigate("/governance/branches/create");
            }}
          >
            Create Branch
          </BlueButton>
          <Typography></Typography>
        </FlexColumnBox>
      </FlexRowBox>
      <GenericTable
        rows={branchesInTableFormat}
        tableHeader={header}
        selectAllRows={selectAllRows}
        updateSelectedRow={updateSelectedRow}
        totalRows={totalRows}
        selectedRows={selectedRows}
      />
    </FlexColumnBox>
  );
};

enum CommitBarType {
  BEHIND,
  AHEAD,
}

type CommitBarProps = {
  type: CommitBarType;
  value: number;
};

const CommitBar = ({ type, value }: CommitBarProps) => {
  return (
    <div className="commit-bar">
      {type === CommitBarType.BEHIND && (
        <div className="commits-behind" style={{ width: `${2 * value}px` }}>
          {value}
        </div>
      )}
      {type === CommitBarType.AHEAD && (
        <div className="commits-ahead" style={{ width: `${2 * value}px` }}>
          {value}
        </div>
      )}
    </div>
  );
};
