import { useEffect, useMemo, useState } from "react";
import {
  DownWardAnimatedBox,
  FlexColumnBox,
  FlexRowBox,
} from "../../../../Styles";
import { getAllFeaturesByBranchId } from "../../../../Services/getAllFeatures";
import { useVersionControlContext } from "../../../../Hooks/useVersionControlContext";
import { convertFeatureIntoTableData } from "../../../../Utils/Mapper/Featuremapper";
import { GenericFilter } from "../../../../Components/GenericFilter/GenericFilter";
import { GenericTable } from "../../../../Packages/Table";
import { TRowProps, TTableHeader } from "../../../../Packages/Table/Types";
import { Typography } from "@mui/material";
import {
  BlueButton,
  GreenButton,
  GreyButton,
  RedButton,
} from "../../../../Styles/Button";
import { useSelectedRow } from "../../../../Hooks/useSelectedRows";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../../../Constants";
import { TFilter } from "../../../../Types";
import {
  BaseFeature,
  FilterType,
} from "../../../../Client/typescript-axios-client-generated";
import { useToast } from "../../../../Hooks/useToast";
import { ToastType } from "../../../../Context/Providers/ToastProvider";
import { useFilter } from "../../../../Hooks/useFilter";
import { filterDataHelper } from "../../../../Hooks/useFilterData";
import ConfirmationModal from "../ConfirmationModal";
import { FeatureDetailComponent } from "./Components/FeatureDetail/Components/Details/FeatureDetailPage";
import { usePermissionContext } from "../../../../Hooks/usePermissionContext";
import { FeatureTableHeader } from "../../../../Constants/TableHeaders/Feature";
import { CustomToolTip } from "../../../../Styles/ToolTip";

export const Features = () => {
  const [featureData, setFeatureData] = useState<BaseFeature[]>([]);
  const [filter, setFilter] = useState<TFilter>({});
  const [tableData, setTableData] = useState<TRowProps[]>([]);
  const versionControlContext = useVersionControlContext();
  const navigate = useNavigate();
  const { selectedRows, selectAllRows, updateSelectedRow, totalRows } =
    useSelectedRow(tableData);
  const [viewFeatureId, setViewFeatureId] = useState<string | undefined>(
    undefined
  );

  const toast = useToast();

  const permissionContext = usePermissionContext();

  const isCreateFeaturePermission = useMemo(() => {
    if (!permissionContext) return false;
    return permissionContext?.isPermission(["feature", "feature.create"]);
  }, []);

  const { filterComponentData } = useFilter(FilterType.Feature);

  useEffect(() => {
    if (
      !versionControlContext ||
      !versionControlContext.currentBranchName ||
      !versionControlContext.currentProjectName
    )
      return;
    getAllFeaturesByBranchId(
      versionControlContext.currentProjectName,
      versionControlContext.currentBranchName
    )
      .then((data) => {
        setFeatureData(data);
      })
      .catch((err) => {
        toast.open("Something went wrong while fetching data", ToastType.ERROR);
      });
  }, [versionControlContext?.currentBranch]);

  const viewClickHandler = (featureId: string) => {
    setViewFeatureId(featureId);
  };

  useEffect(() => {
    const filteredFeatureData = filterDataHelper(filter, featureData);
    const convertedTabelData = convertFeatureIntoTableData(
      filteredFeatureData,
      viewClickHandler
    );
    setTableData(convertedTabelData);
  }, [featureData, filter]);

  return (
    <FlexRowBox flexGrow={1}>
      <ConfirmationModal
        isLoading={false}
        open={viewFeatureId !== undefined}
        children={<FeatureDetailComponent featureIdProp={viewFeatureId} />}
        secondaryButtonText="View Feature"
        primaryButtonText="Close"
        secondaryCustomButton={BlueButton}
        primaryCustomButton={GreyButton}
        message=""
        heading={
          featureData.find((feature) => {
            return feature.id == viewFeatureId;
          })?.name
        }
        closeHandler={() => {
          setViewFeatureId(undefined);
        }}
        confirmHandler={() => {
          navigate("/data-layer/features/" + viewFeatureId);
        }}
      />
      <GenericFilter
        globalSearchEnabled={true}
        data={filterComponentData}
        onChangeFilter={setFilter}
      />
      <FlexColumnBox
        sx={{
          padding: "2rem",
          backgroundColor: Colors.APP_VERY_LIGHT_BLUE_COLOR,
          flexGrow: "1",
        }}
      >
        <FlexRowBox sx={{ justifyContent: "space-between" }}>
          <DownWardAnimatedBox flag={selectedRows.length > 0}>
            <FlexColumnBox>
              <FlexRowBox sx={{ gap: "5px" }}>
                <GreenButton>Add to project</GreenButton>
                <RedButton>Delete</RedButton>
              </FlexRowBox>
              <Typography sx={{ color: Colors.LINK_TEXT_COLOR }}>
                {selectedRows.length} row selected
              </Typography>
            </FlexColumnBox>
          </DownWardAnimatedBox>
          <FlexColumnBox>
            <CustomToolTip
              placement="top"
              title={
                isCreateFeaturePermission ? "" : "You dont have permission"
              }
            >
              <BlueButton
                className={isCreateFeaturePermission ? "" : "disabled-button"}
                onClick={() => {
                  if(!isCreateFeaturePermission) return;
                  navigate("/data-layer/features/create-feature");
                }}
                sx={{
                  cursor: isCreateFeaturePermission ? '' : 'not-allowed'
                }}
              >
                Create Feature
              </BlueButton>
            </CustomToolTip>
            <Typography></Typography>
          </FlexColumnBox>
        </FlexRowBox>
        <GenericTable
          rows={tableData}
          tableHeader={FeatureTableHeader}
          selectAllRows={selectAllRows}
          updateSelectedRow={updateSelectedRow}
          totalRows={totalRows}
          selectedRows={selectedRows}
        />
      </FlexColumnBox>
    </FlexRowBox>
  );
};
