import { SingleSelectInput } from "../../../../../../Components/Forms/SingleSelection";
import { CommonCreateAttributeProps } from "../Types";

export const AttributeTypes = ({
  state,
  dispatch,
}: CommonCreateAttributeProps) => {
  const options = state.attributeTypesData.map((attributeType) => {
    return {
      optionId: attributeType.id,
      title: attributeType.name,
      description: attributeType.description
    }
  })

  return (
    <SingleSelectInput
      label={""}
      data={options}
      onSelect={(id) => {dispatch({ type: 'SET_ATTRIBUTE_TYPE', payload: id })}}
      selectedOptionId={state.attributeType}
      showAsGrid={true}
      columnCount={2}
    />
  );
};
