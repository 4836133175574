import { Button } from "@mui/material";
import { Colors } from "../Constants";
import { FlexColumnBox, FlexRowBox, SidebarContainer } from "../Styles";
import { LabelTypography } from "../Styles/CustomTypography";
import { StyledNavLinkWithIcon } from "./StyledNavlinkWithIcon";
import withToggle, { WithToggleProps } from "./WithToggle";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import '../Styles/CSS/dropDown.css'

export type SidebarProps = {
  data: TSidebarItem[];
} & WithToggleProps;

type TSidebarItem = {
  title: string;
  links: TSidebarLink[];
};
type TSidebarLink = {
  to: string;
  label: string;
  icon: React.ReactElement;
};

const Sidebar = ({ isToggled, toggle, data }: SidebarProps) => {
  return (
    <FlexColumnBox
      sx={{
        minWidth: isToggled ? '15rem' : '',
        width: isToggled ? "15rem" : "content-fit",
        borderRight: `1px solid ${Colors.SIDE_LINK_HOVER_COLOR}`,
      }}
    >
      {data.map((sidebarSection) => {
        return (
          <>
            <SidebarContainer>
              {isToggled && <LabelTypography>{sidebarSection.title}</LabelTypography>}
            </SidebarContainer>
            {sidebarSection.links.map((link) => {
              return (
                <StyledNavLinkWithIcon
                  icon={link.icon}
                  text={link.label}
                  showText={isToggled}
                  link={link.to}
                />
              );
            })}
          </>
        );
      })}
      <FlexRowBox style={{ marginTop: "10px", marginLeft: "10px" }}>
        <Button onClick={toggle} className="collapse-sidebar">
          {isToggled ? <KeyboardArrowLeftIcon /> : <ChevronRightIcon />}
        </Button>
      </FlexRowBox>
    </FlexColumnBox>
  );
};

const GenericSidebar = withToggle(Sidebar); // Enhance SideBarComponent with withToggle

export default GenericSidebar;
