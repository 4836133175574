import { Box } from "@mui/material";
import {
  FlexColumnBox,
  FlexRowBox,
  UnderLineOnHoverNavLink,
} from "../../../../../../../../Styles";
import {
  HeadingTypography,
  LabelGithub,
  LabelTypography,
  MediumSizeHeading,
  SmallSizeLightColorTypography,
} from "../../../../../../../../Styles/CustomTypography";
import React, { useEffect, useState } from "react";
import { getFeatureById } from "../../../../../../../../Services/getFeatureById";
import { useVersionControlContext } from "../../../../../../../../Hooks/useVersionControlContext";
import { NavLink, useParams } from "react-router-dom";
import {
  BaseFeature,
  TransformationType,
} from "../../../../../../../../Client/typescript-axios-client-generated";
import { SingleSelectInput } from "../../../../../../../../Components/Forms/SingleSelection";
import { FeatureTypes } from "../../../../../../../../Constants/FormData/FeatureTypes";
import { WindowOptions } from "../../../../../../../../Constants/WindowOptions";
import SqlEditor from "../../../../../../../../Components/SqlEditor";
import "./index.css";
import { CustomSwitch } from "../../../../../../../../Components/CustomSwitch";
import { GenericTable } from "../../../../../../../../Packages/Table";
import { TRowProps } from "../../../../../../../../Packages/Table/Types";
import { FeatureUsedByFeatureSetsHeader } from "../../../../../../../../Constants/TableHeaders/FeatureUsedByFeatureSets";
import "../../../../../../../Governance/Components/ChangeRequest/Components/ViewChangeRequest/ViewChangeRequest.css";

type FeatureDetailComponentProps = {
  featureIdProp?: string;
};

export const FeatureDetailComponent = ({
  featureIdProp,
}: FeatureDetailComponentProps) => {
  const [feature, setFeature] = useState<BaseFeature | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [dataLoadFailed, setDataLoadFailed] = useState<boolean>(false);

  const versionControlContext = useVersionControlContext();

  let { featureId } = useParams();
  if (!featureId) {
    featureId = featureIdProp;
  }

  const featureSetsTableData: TRowProps[] =
    feature?.feature_sets?.map((featureSet) => {
      return {
        row_id: featureSet.id,
        data: {
          name: {
            column_id: "name",
            text: featureSet.name,
            reactNode: (
              <FlexRowBox>
                <UnderLineOnHoverNavLink
                  style={{ flexGrow: 1 }}
                  to={"/data-layer/feature-sets/" + featureSet.id}
                >
                  {featureSet.name}
                </UnderLineOnHoverNavLink>
              </FlexRowBox>
            ),
          },
        },
      };
    }) ?? [];

  useEffect(() => {
    if (
      !versionControlContext ||
      !versionControlContext.currentBranchName ||
      !versionControlContext.currentProjectName ||
      !featureId
    )
      return;
    getFeatureById(
      versionControlContext.currentProjectName,
      versionControlContext.currentBranchName,
      featureId
    )
      .then((data) => {
        setFeature(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [versionControlContext]);

  const getFeatureTypeRelatedDetails = () => {
    const getWindowOptions = () => {
      switch (feature?.event_window_type) {
        case "OPEN_WINDOW":
          return [WindowOptions[0]];
        case "ROW_WINDOW":
          return [WindowOptions[0]];
        case "HOUR_WINDOW":
          return [WindowOptions[0]];
        case "DAY_WINDOW":
          return [WindowOptions[0]];
        case "MONTH_WINDOW":
          return [WindowOptions[0]];
        default:
          return [];
      }
    };

    switch (feature?.transformation_type) {
      case TransformationType.EventFeature:
        return (
          <React.Fragment>
            <Box>
              <LabelTypography>Table</LabelTypography>
              <SmallSizeLightColorTypography>
                {feature.event_table}
              </SmallSizeLightColorTypography>
            </Box>
            <Box>
              <LabelTypography>Enity Restrictions</LabelTypography>
              <Box>
                {feature.entity_restrictions?.map((entity: any, index) => {
                  return (
                    <NavLink
                      style={{ paddingRight: "0.5rem" }}
                      to={"/data-layer/entities/" + entity.id}
                      className="link"
                    >
                      {entity.name +
                        (feature.entity_restrictions?.length == index + 1
                          ? ""
                          : ",")}
                    </NavLink>
                  );
                })}
              </Box>
            </Box>
            <Box>
              <SingleSelectInput
                label={"Window Type"}
                data={getWindowOptions()}
                onSelect={() => {}}
                selectedOptionId={""}
              />
            </Box>
            <Box>
              <SqlEditor
                data={feature.aggregation_expression ?? ""}
                readonly
                onChange={() => {}}
              />
            </Box>
            <Box>
              <LabelTypography>Additional Filter</LabelTypography>
              <SmallSizeLightColorTypography>
                Additional filters can be used to remove rows that are not
                relevant prior to aggregation. Any sql expresssion that is valid
                in a WHERE clause is valid here e.g.
              </SmallSizeLightColorTypography>
              <ul className="list">
                <li className="list-item">
                  <span className="highlight">age {"<"} 18</span>
                </li>
                <li className="list-item">
                  <span className="highlight">net_profit is not null</span>
                </li>
              </ul>
              <CustomSwitch
                checked={feature.event_additional_filter?.length ? true : false}
              />
              {feature.event_additional_filter && (
                <SqlEditor
                  data={feature.event_additional_filter ?? ""}
                  readonly
                  onChange={() => {}}
                />
              )}
            </Box>
            <Box>
              <LabelTypography>Post Aggregation Expression</LabelTypography>
              <CustomSwitch
                checked={
                  feature.event_post_aggregation_filter?.length ? true : false
                }
              />
            </Box>
          </React.Fragment>
        );
      case TransformationType.SqlFeature:
        return (
          <React.Fragment>
            <Box>
              <LabelTypography>Sources</LabelTypography>
              <Box>
                {feature.entity_restrictions?.map((entity: any, index) => {
                  return (
                    <NavLink
                      style={{ paddingRight: "0.5rem" }}
                      to={"/data-layer/entities/" + entity.id}
                      className="link"
                    >
                      {entity.name +
                        (feature.entity_restrictions?.length == index + 1
                          ? ""
                          : ",")}
                    </NavLink>
                  );
                })}
              </Box>
            </Box>
            <Box>
              <LabelTypography>SQL Expression</LabelTypography>
              <SqlEditor
                data={feature.aggregation_expression ?? ""}
                onChange={() => {}}
                readonly
              />
            </Box>
          </React.Fragment>
        );
    }
  };

  const getFeaturetype = () => {
    switch (feature?.transformation_type) {
      case TransformationType.CompositeFeature:
        return [FeatureTypes[1]];
      case TransformationType.EventFeature:
        return [FeatureTypes[0]];
      case TransformationType.SqlFeature:
        return [FeatureTypes[2]];
    }
    return [];
  };

  return (
    <FlexColumnBox sx={{ gap: "1rem", flexGrow: 1 }}>
      <Box>
        <SingleSelectInput
          label={""}
          data={getFeaturetype()}
          onSelect={() => {}}
          selectedOptionId={""}
        />
      </Box>
      <Box>
        <LabelTypography>Name</LabelTypography>
        <SmallSizeLightColorTypography>
          {feature?.name}
        </SmallSizeLightColorTypography>
      </Box>
      <Box>
        <LabelTypography>Description</LabelTypography>
        <SmallSizeLightColorTypography>
          {feature?.description}
        </SmallSizeLightColorTypography>
      </Box>
      {getFeatureTypeRelatedDetails()}
      <Box>
        <FlexRowBox sx={{ alignItems: "center", gap: ".5rem" }}>
          <HeadingTypography>Appears In Feature Sets</HeadingTypography>
          <LabelGithub>{feature?.feature_sets?.length}</LabelGithub>
        </FlexRowBox>
        <GenericTable
          rows={featureSetsTableData}
          tableHeader={FeatureUsedByFeatureSetsHeader}
          selectAllRows={() => {}}
          updateSelectedRow={() => {}}
          totalRows={0}
          selectedRows={[]}
          hidePagination
          hideCheckbox
          hideHeader
        />
      </Box>
    </FlexColumnBox>
  );
};
