import { Box, Typography } from "@mui/material";
import {
  DownWardAnimatedBox,
  FlexColumnBox,
  FlexRowBox,
} from "../../../../../Styles";
import {
  FeatureSetTableDescription,
  LabelTypography,
  SmallSizeLightColorTypography,
  TableTypography,
} from "../../../../../Styles/CustomTypography";
import { InputTextField } from "../../../../../Components/Forms/InputTextField";
import { CustomDropDown } from "../../../../../Components/Forms/DropDown";
import { TEntity } from "../../../../../Types";
import AddBoxIcon from "@mui/icons-material/AddBox";
import SearchIcon from "@mui/icons-material/Search";
import { CustomToolTip } from "../../../../../Styles/ToolTip";
import ConfirmationModal from "../../ConfirmationModal";
import { useMemo, useState } from "react";
import {
  BlueButton,
  GreyButton,
  RedButton,
} from "../../../../../Styles/Button";
import { AttachFeature } from "./AttachFeature";
import { GenericTable } from "../../../../../Packages/Table";
import { useSelectedRow } from "../../../../../Hooks/useSelectedRows";
import { Colors } from "../../../../../Constants/Colors";
import { isMatchingSubString } from "../../../../../Utils/CommonFunctions";
import RichTextField from "../../../../../Components/Forms/RichTextInputField";
import { BaseFeature } from "../../../../../Client/typescript-axios-client-generated";

type CreateFeatureFormProps = {
  name: string;
  description: string;
  selectedEntity: string[];
  entityData: TEntity[];
  selectedFeature: string[];
  featureData: BaseFeature[];
  updateName: (name: string) => void;
  updateDescription: (description: string) => void;
  updateSelectedEntity: (entity: string[]) => void;
  updateSelectedFeature: (features: string[]) => void;
  nameValidationArray: string[];
  entityValidationArray: string[];
};

export const CreateFeatureSetForm = ({
  name,
  description,
  selectedEntity,
  entityData,
  selectedFeature,
  featureData,
  updateName,
  updateDescription,
  updateSelectedEntity,
  updateSelectedFeature,
  nameValidationArray,
  entityValidationArray,
}: CreateFeatureFormProps) => {
  const [isAttachFeatureOpen, setIsAttachFeatureOpen] =
    useState<boolean>(false);
  const [currentSelectedFeatures, setCurrentSelectedFeatures] = useState<
    string[]
  >([]);
  const [searchFeatures, setSearchFeature] = useState<string>("");
  const [showSearchFeature, setShowSearchFeature] = useState<boolean>(false);

  const entityDropDownData = useMemo(() => {
    return entityData.map((entity) => {
      return {
        label: entity.name,
        itemId: entity.id,
      };
    });
  }, [entityData]);

  const handleAddFeature = () => {
    setIsAttachFeatureOpen(true);
  };

  const handleClose = () => {
    setIsAttachFeatureOpen(false);
    setCurrentSelectedFeatures([]);
  };

  const addFeatureHandler = () => {
    const newSelectedFeatures = [...selectedFeature];
    for (let feature_id of currentSelectedFeatures) {
      if (!newSelectedFeatures.includes(feature_id)) {
        newSelectedFeatures.push(feature_id);
      }
    }
    updateSelectedFeature(newSelectedFeatures);
    setCurrentSelectedFeatures([]);
    setIsAttachFeatureOpen(false);
  };

  const selectedFeatureTableData = useMemo(() => {
    let features: BaseFeature[] = [];
    selectedFeature.map((id) => {
      const findFeature = featureData.find(
        (feature) =>
          feature.id === id && isMatchingSubString(searchFeatures, feature.name)
      );
      if (findFeature !== undefined) {
        features.push(findFeature);
      }
    });
    return features.map((feature) => {
      return {
        row_id: feature.id ?? '',
        data: {
          name: {
            column_id: feature.id,
            text: feature.name ?? '',
          },
          description: {
            column_id: "description",
            text: feature.description ?? '',
            buttonElement: FeatureSetTableDescription,
          },
          quickLook: {
            column_id: "quickLook",
            text: "Quick Look",
            onClickHandler: () => {},
            buttonElement: GreyButton,
          },
        },
      };
    });
  }, [selectedFeature, featureData, searchFeatures]);
  const { selectAllRows, selectedRows, updateSelectedRow, totalRows } =
    useSelectedRow(selectedFeatureTableData);

  const handleRemove = () => {
    const newSelectedFeatures = [...selectedFeature];
    updateSelectedFeature(
      newSelectedFeatures.filter(
        (feature_id) => !selectedRows.includes(feature_id)
      )
    );
    selectAllRows();
  };

  const tableHead = [
    {
      column_id: "name",
      label: "",
      isSortable: "",
      visible: true,
    },
    {
      column_id: "description",
      label: "",
      isSortable: "",
      visible: true,
    },
    {
      column_id: "quickLook",
      label: "",
      isSortable: "",
      visible: true,
    },
    {
      column_id: "moreOptions",
      label: "",
      isSortable: "",
      visible: false,
    },
  ];

  return (
    <FlexColumnBox sx={{ gap: "1rem" }}>
      <ConfirmationModal
        isLoading={false}
        open={isAttachFeatureOpen}
        positionTop="35%"
        secondaryCustomButton={BlueButton}
        secondaryButtonText="Add"
        primaryButtonText="Close"
        primaryCustomButton={GreyButton}
        heading="Attach Feature"
        message=""
        closeHandler={handleClose}
        confirmHandler={addFeatureHandler}
        disabledSecondaryButton={currentSelectedFeatures.length < 1}
        children={
          <AttachFeature
            featuresData={featureData}
            selectedItems={currentSelectedFeatures}
            updateSelectedItems={setCurrentSelectedFeatures}
          />
        }
      />
      <Box>
        <InputTextField
          onChange={(event) => {
            updateName(event.target.value);
          }}
          value={name}
          violationArray={nameValidationArray}
          title="Name"
          description="Name must be unique, start with a lower case letter and may only contain underscores, letters and numbers"
        />
      </Box>
      <Box>
        <LabelTypography>Description</LabelTypography>
        <SmallSizeLightColorTypography>
          Name must be unique, start with a lower case letter and may only
          contain underscores, letters and numbers
        </SmallSizeLightColorTypography>
        <RichTextField input={description} setInput={updateDescription} />
      </Box>
      <Box>
        <LabelTypography>Entity</LabelTypography>
        <SmallSizeLightColorTypography>
          Select the Entity that the Feature Set will be generated for.
        </SmallSizeLightColorTypography>
        <CustomDropDown
          data={entityDropDownData}
          multiple={false}
          selectedItems={selectedEntity}
          updateSelectedItems={updateSelectedEntity}
          validationsErrors={entityValidationArray}
        />
      </Box>
      <Box sx={{ paddingTop: "1rem" }}>
        <Typography sx={{ fontWeight: "600", fontSize: "22px" }}>
          Features in feature set {selectedFeature.length}
        </Typography>
        <FlexRowBox sx={{ justifyContent: "space-between" }}>
          <DownWardAnimatedBox flag={selectedRows.length > 0}>
            <FlexColumnBox>
              <FlexRowBox sx={{ gap: "5px" }}>
                <RedButton onClick={handleRemove}>Remove</RedButton>
              </FlexRowBox>
              <Typography sx={{ color: Colors.LINK_TEXT_COLOR }}>
                {selectedRows.length} row selected
              </Typography>
            </FlexColumnBox>
          </DownWardAnimatedBox>
          <FlexColumnBox>
            <FlexRowBox sx={{ gap: 1, alignItems: "center" }}>
              {showSearchFeature && (
                <InputTextField
                  onChange={(event) => {
                    setSearchFeature(event.target.value);
                  }}
                  value={searchFeatures}
                  violationArray={[]}
                  placeholder="search"
                />
              )}
              <Box
                onClick={() => {
                  setShowSearchFeature((prev) => !prev);
                }}
              >
                <SearchIcon />
              </Box>
              <CustomToolTip title={"Add an existing feature"} placement="top">
                <Box onClick={handleAddFeature}>
                  <AddBoxIcon />
                </Box>
              </CustomToolTip>
            </FlexRowBox>
            <Typography></Typography>
          </FlexColumnBox>
        </FlexRowBox>
        <GenericTable
          rows={
            selectedFeatureTableData.length < 1
              ? [
                  {
                    row_id: "1",
                    data: {
                      info: {
                        column_id: "info",
                        text: "Features in the feature set will show here",
                        buttonElement: TableTypography,
                      },
                    },
                  },
                ]
              : selectedFeatureTableData
          }
          tableHeader={
            selectedFeatureTableData.length < 1
              ? [
                  {
                    column_id: "info",
                    label: "",
                    isSortable: "",
                    visible: true,
                  },
                ]
              : tableHead
          }
          selectAllRows={selectAllRows}
          updateSelectedRow={updateSelectedRow}
          totalRows={totalRows}
          selectedRows={selectedRows}
          hidePagination={true}
          hideHeader={true}
          hideCheckbox={selectedFeatureTableData.length < 1}
        />
      </Box>
    </FlexColumnBox>
  );
};
