import { useReducer } from "react";
import { UpdateMetaInformationFunctionType, UpdateMetaInformationType } from "../../../../../../Components/MetaInformation";

// Define the initial state type
interface State {
    labels: string[];
    containsPii: boolean;
    containsToken: boolean;
    containsUsageData: boolean;
    containsWebCookie: boolean;
    creator: string;
    domain: string[];
    mergeFilesCsv: boolean;
    modelDependency: string;
    owner: string[];
    postProcessWorkFlow: string;
    scheduleFailureEmailList: string[];
}

// Initial state
const initialState: State = {
    labels: [],
    containsPii: false,
    containsToken: false,
    containsUsageData: false,
    containsWebCookie: false,
    creator: '',
    domain: [],
    mergeFilesCsv: false,
    modelDependency: '',
    owner: [],
    postProcessWorkFlow: '',
    scheduleFailureEmailList: []
};

// Reducer function
const reducer = (state: State, action: UpdateMetaInformationFunctionType): State => {
    switch (action.type) {
        case UpdateMetaInformationType.LABEL:
            return { ...state, labels: action.payload };
        case UpdateMetaInformationType.CONTAINS_PII:
            return { ...state, containsPii: action.payload };
        case UpdateMetaInformationType.CONTAINS_TOKEN:
            return { ...state, containsToken: action.payload };
        case UpdateMetaInformationType.CONTAINS_USAGEDATA:
            return { ...state, containsUsageData: action.payload };
        case UpdateMetaInformationType.CONTAINS_WEB_TOKEN:
            return { ...state, containsWebCookie: action.payload };
        case UpdateMetaInformationType.CREATOR:
            return { ...state, creator: action.payload };
        case UpdateMetaInformationType.DOMAIN:
            return { ...state, domain: action.payload };
        case UpdateMetaInformationType.OWNER:
            return { ...state, owner: action.payload };
        case UpdateMetaInformationType.MERGE_FILES_CSV:
            return { ...state, mergeFilesCsv: action.payload };
        case UpdateMetaInformationType.MODEL_DEPENDENCIES:
            return { ...state, modelDependency: action.payload };
        case UpdateMetaInformationType.SCHEDULE_FAILURE_EMAIL_LIST:
            return { ...state, scheduleFailureEmailList: action.payload };
        default:
            return state;
    }
};

// Hook implementation
export const useMetaInformation = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    console.log(state);

    const updateMetaInformation = (action: UpdateMetaInformationFunctionType) => {
        dispatch(action);
    };

    return {
        state,
        updateMetaInformation
    };
};
