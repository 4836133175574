import { Box } from "@mui/material";
import { FlexColumnBox } from "../../../../../Styles";
import {
  DescriptionTypography,
  LabelTypography,
} from "../../../../../Styles/CustomTypography";
import { SingleSelectInput } from "../../../../../Components/Forms/SingleSelection";
import RichTextField from "../../../../../Components/Forms/RichTextInputField";
import { BaseEntity } from "../../../../../Client/typescript-axios-client-generated";

type QuickViewProps = {
  data: BaseEntity;
};

export const QuickView = ({ data }: QuickViewProps) => {
  return (
    <>
      {data && (
        <FlexColumnBox sx={{ padding: "1rem" }}>
          <Box>
            <LabelTypography>Name</LabelTypography>
            <DescriptionTypography>{data.name}</DescriptionTypography>
          </Box>
          <Box>
            <LabelTypography>Description</LabelTypography>
            <RichTextField
              input={data.description}
              setInput={() => {}}
              readonly={true}
            />
          </Box>
          <Box>
            <LabelTypography>Entity Type</LabelTypography>
            <SingleSelectInput
              label={""}
              data={[
                {
                  optionId: "1",
                  title:
                    data.entity_type === "BASE_ENTITY"
                      ? "Base Entity"
                      : "Composite Entity",
                  description:
                    data.entity_type === "BASE_ENTITY"
                      ? "An entity upon which tables can be keyed, and features aggregated."
                      : "An entity derived from multiple existing entities. Features can be aggregated from tables containing the deriving entities.",
                },
              ]}
              onSelect={() => {}}
              selectedOptionId={""}
            />
          </Box>
          {data.entity_type === "BASE_ENTITY" && (
            <Box>
              <LabelTypography>Output Column</LabelTypography>
              <DescriptionTypography>{data.output_column}</DescriptionTypography>
            </Box>
          )}
          {data.entity_type === "BASE_ENTITY" && (
            <Box>
              <LabelTypography>Type</LabelTypography>
              <DescriptionTypography>{data.data_type}</DescriptionTypography>
            </Box>
          )}
        </FlexColumnBox>
      )}
    </>
  );
};
