import { useEffect, useState } from "react"
import { EntityMappingGetSchema } from "../../../Client/typescript-axios-client-generated"
import { getEntityMappings } from "../../../Services/getEntityMappings";
import { useVersionControlContext } from "../../useVersionControlContext";

export const useGetEntityMappings = () => {
    const [entityMappings, setEntityMappings] = useState<EntityMappingGetSchema[]>([]);

    const versionControlContext = useVersionControlContext();

    const updateEntityMappings = (data: EntityMappingGetSchema[]) => {
        setEntityMappings(data);
    }

    useEffect(() => {
        if(versionControlContext === undefined) return;
        getEntityMappings(versionControlContext.currentProjectName, versionControlContext.currentBranchName);
    }, [versionControlContext?.currentBranch]);

    return {
        entityMappings,
        updateEntityMappings
    }

}