import { Box } from "@mui/material";
import { CustomDropDown } from "../../../../../Components/Forms/DropDown";
import { GreenButton, GreyButton } from "../../../../../Styles/Button";
import ConfirmationModal from "../../../../DataLayer/Components/ConfirmationModal";
import { useState } from "react";
import { useVersionControlContext } from "../../../../../Hooks/useVersionControlContext";
import { LabelTypography } from "../../../../../Styles/CustomTypography";
import { InputTextField } from "../../../../../Components/Forms/InputTextField";
import { createBranch } from "../../../../../Services/createBranch";
import { useToast } from "../../../../../Hooks/useToast";
import { ToastType } from "../../../../../Context/Providers/ToastProvider";

type TCreateBranchProps = {
  handleCancelAndCompletion: () => any;
}

export const CreateBranch = ({
  handleCancelAndCompletion
}: TCreateBranchProps) => {
  const versionControlContext = useVersionControlContext();
  const currProject = versionControlContext?.currentProject
    ? [versionControlContext.currentProject]
    : [];
  const currBranch = versionControlContext?.currentBranch
    ? [versionControlContext.currentBranch]
    : [];
  const [project, setProject] = useState<string[]>(currProject);
  const [fromBranch, setFromBranch] = useState<string[]>(currBranch);
  const [newBranchName, setNewBranchName] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast()


  const updateSelectedProject = (p: string[]) => {
    setProject(p);
  };

  const updateSelectedBranch = (b: string[]) => {
    setFromBranch(b);
  };

  const handleCreateBranch = () => {
    // Add logic to create the branch using selected project, fromBranch, and newBranchName
    setIsLoading(true)
    createBranch({
      name: newBranchName,
      project_id: project[0],
      from_branch_id: fromBranch[0]
    }).then((data)=>{
      setIsLoading(false)
      handleCancelAndCompletion()
      toast.open('Branch created successfully.', ToastType.SUCCESS)
    }).catch(()=>{
      console.log('error occured while creating new branch')
      setIsLoading(false)
      handleCancelAndCompletion()
    })
  };

  const createBranchContent = () => (
    <Box sx={{ padding: "1rem" }}>
      <LabelTypography>Select Project</LabelTypography>
      <CustomDropDown
        data={
          versionControlContext?.projectsList.map((project) => ({
            label: project.name,
            description: project.description || "", // Ensure description is always a string
            itemId: project.id,
          })) ?? []
        }
        multiple={false}
        selectedItems={project}
        updateSelectedItems={updateSelectedProject}
      />
      <LabelTypography>Select Branch</LabelTypography>
      <CustomDropDown
        data={
          versionControlContext?.branchesList.map((branch) => ({
            label: branch.name,
            itemId: branch.id,
          })) ?? []
        }
        multiple={false}
        selectedItems={fromBranch}
        updateSelectedItems={updateSelectedBranch}
      />
      <LabelTypography>New Branch Name</LabelTypography>
      <InputTextField
        onChange={(e) => {
          setNewBranchName(e.target.value);
        }}
        value={newBranchName}
        violationArray={[]}
      />
    </Box>
  );

  return (
    <ConfirmationModal
      isLoading={isLoading}
      open={true}
      heading="Create New Branch"
      message="Are you sure you wish to create a new branch?"
      primaryCustomButton={GreyButton}
      primaryButtonText="Cancel"
      secondaryCustomButton={GreenButton}
      secondaryButtonText="Create"
      closeHandler={handleCancelAndCompletion}
      confirmHandler={handleCreateBranch}
      disabledSecondaryButton={(project.length<1 || fromBranch.length<1 || newBranchName.length<1)}
    >
      {createBranchContent()}
    </ConfirmationModal>
  );
};
