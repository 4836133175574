import { AttributeCreate } from "../Client/configuration-client";
import { ApiClient } from "../Utils/ApiClient";

export const createAttribute = async (body: AttributeCreate) => {
  return ApiClient.attributeClient
    .createAttributeAttributesCreateAttributePost(body)
    .then((data) => {})
    .catch((err) => {
      console.log(err);
      throw err;
    });
};
