import { CreateSourceContextProvider } from ".";
import { NameAndDescription } from "./NameAndDescription";
import { FlexColumnBox, FlexRowBox } from "../../../Styles";
import { SourceType } from "./SourceType";
import { Box } from "@mui/material";
import { CreateSourceOrDestinationProps } from "../Type";
import { Header } from "./Header";
import { CreateSourceLabelAndAttribute } from "./LabelAndAttributes";
export const CreateSourceOrDestination = ({
  type,
}: CreateSourceOrDestinationProps) => {
  return (
    <CreateSourceContextProvider type={type}>
      <FlexColumnBox sx={{ width: "100%" }}>
        <Header type={type} />
        <FlexRowBox sx={{ padding: "2rem", gap: "2rem" }}>
          <Box sx={{ flexGrow: 1 }}>
            <FlexColumnBox sx={{ gap: "1rem" }}>
              <NameAndDescription />
              <SourceType type={type} />
            </FlexColumnBox>
          </Box>
          <CreateSourceLabelAndAttribute/>
        </FlexRowBox>
      </FlexColumnBox>
    </CreateSourceContextProvider>
  );
};
