import { Box } from "@mui/material";
import ClustersOptions from "../Constants/ClustersList";
import { FlexColumnBox } from "../Styles";
import { Colors } from "../Constants";

export const getClusters = (handleChooseCluster: (clusterId: string) => any) => {
    return (
      <FlexColumnBox>
        {ClustersOptions.map((cluster) => {
          return (
            <Box
              onClick={() => {
                handleChooseCluster(cluster.id);
              }}
              key={cluster.id} // Remember to add a unique key prop when mapping arrays in React
              sx={{
                padding: "10px",
                transition: "color 0.3s", // Smooth transition effect for color change
                color: Colors.APP_LIGHT_GREY, // Light blue color for text
                fontWeight: 500,
                "&:hover": {
                  cursor: "pointer", // Change cursor to pointer on hover
                  backgroundColor: Colors.APP_VERY_LIGHT_BLUE_COLOR,
                },
                marginBottom: "8px", // Add some space between each Box
              }}
            >
              {cluster.name}
            </Box>
          );
        })}
      </FlexColumnBox>
    );
  };