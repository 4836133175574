import { useMemo, useState } from "react";
import { FormControlLabel, FormControl, Button } from "@mui/material";
import { FlexColumnBox, FlexRowBox } from "../../Styles";
import { FilterType, TFilter, TFilterData } from "../../Types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { StyledThinBorderCheckBox } from "../../Styles/CheckBoxes";
import { InputTextField } from "../Forms/InputTextField";
import {
  AccordianWithoutShadow,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "../../Styles/Accordion";
import { CustomDropDown } from "../Forms/DropDown";
import {
  LabelTypography,
  MediumSizeDarkColorThinTypography,
} from "../../Styles/CustomTypography";
import "../../Styles/CSS/generic-filter.css"
import './generic-filter.css'

export type GenericFilterProps = {
  data: TFilterData[];
  globalSearchEnabled: boolean;
  onChangeFilter?: (filter: TFilter) => void
};

export enum SelectType {
  SINGLE,
  MULTIPLE,
}

export const GenericFilter = ({
  data,
  globalSearchEnabled,
  onChangeFilter
}: GenericFilterProps) => {
  const listOfIds = useMemo(() => {
    return data.map((val) => val.id);
  }, [data]);
  const [filter, setFilter] = useState<TFilter>({});
  const [expanded, setExpanded] = useState<string[]>([...listOfIds]); // State for expanded accordions

  const onChange = (key: string, value: string, type: SelectType) => {
    setFilter((prevFilter) => {
      const newFilter = { ...prevFilter };

      if (key in prevFilter) {
        if (type === SelectType.SINGLE) {
          if (value.length === 0) {
            delete newFilter.key;
          }
          newFilter[key] = value;
        } else {
          if (newFilter[key]?.includes(value)) {
            newFilter[key] = newFilter[key]?.filter(
              (val: string) => val !== value
            );
          } else {
            newFilter[key] = [...(newFilter[key] || []), value];
          }
        }
      } else {
        newFilter[key] = type === SelectType.SINGLE ? value : [value];
      }
      if(onChangeFilter){
        onChangeFilter(newFilter);
      }
      return newFilter;
    });
  };

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded((prevExpanded) =>
        isExpanded
          ? [...prevExpanded, panel]
          : prevExpanded.filter((id) => id !== panel)
      );
    };

  return (
    <FlexColumnBox sx={{ padding: "2rem" }}>
      {globalSearchEnabled && (
        <InputTextField
          value={"global" in filter ? filter.global : ""}
          placeholder="Search"
          onChange={(e) => {
            onChange("global", e.target.value, SelectType.SINGLE);
          }}
          violationArray={[]}
        />
      )}
      <button className="clear-filter-button" onClick={() => {
        setFilter({});
        if(onChangeFilter) onChangeFilter({});
      }} >Clear filters</button>
      {data.map((val, index) => {
        switch (val.type) {
          case FilterType.CHECKBOX:
            return (
              <CheckBoxFilter
                index={index}
                val={val}
                handleOnChange={onChange}
                filter={filter}
              />
            );
          case FilterType.DROPDOWN:
            return (
              <DropDownFilter
                index={index}
                val={val}
                expanded={expanded.includes(val.id)}
                handleAccordionChange={handleAccordionChange}
                handleOnChange={onChange}
                selectedItem={filter[val.id]}
              />
            );
          default:
            return null;
        }
      })}
    </FlexColumnBox>
  );
};

type DropDownFilterProps = {
  index: number | string;
  val: TFilterData;
  expanded: boolean;
  handleAccordionChange: any;
  handleOnChange: (key: string, value: string, type: SelectType) => void;
  selectedItem: string;
};

export const DropDownFilter = ({
  index,
  val,
  expanded,
  handleAccordionChange,
  handleOnChange,
  selectedItem,
}: DropDownFilterProps) => {
  const dropDownData = useMemo(() => {
    return val.data.map((item) => {
      return {
        itemId: item.id,
        label: item.label,
      };
    });
  }, [val]);

  return (
    <AccordianWithoutShadow
      key={index}
      expanded={expanded}
      onChange={handleAccordionChange(val.id)}
    >
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${val.id}-content`}
        id={`${val.id}-header`}
      >
        <LabelTypography>{val.id}</LabelTypography>
      </StyledAccordionSummary>
      <StyledAccordionDetails removeLeftPadding={true}>
        <FormControl fullWidth>
          <CustomDropDown
            data={dropDownData}
            multiple={false}
            selectedItems={selectedItem ? [selectedItem] : []}
            updateSelectedItems={(selectedItems) => {
              const newVal = selectedItems.length === 0 ? "" : selectedItems[0];
              handleOnChange(val.id, newVal, SelectType.SINGLE);
            }}
          />
        </FormControl>
      </StyledAccordionDetails>
    </AccordianWithoutShadow>
  );
};

type CheckBoxFilterProps = {
  index: string | number;
  val: TFilterData;
  handleOnChange: (key: string, value: string, type: SelectType) => void;
  filter: TFilter;
};

export const CheckBoxFilter = ({
  index,
  val,
  handleOnChange,
  filter,
}: CheckBoxFilterProps) => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const [showAll, setShowAll] = useState<boolean>(false);

  let buttonText = showAll
    ? `Hide ${val.data?.length - 5}`
    : `Show ${val.data?.length - 5} more`;

  return (
    <AccordianWithoutShadow
      key={index}
      expanded={expanded}
      onChange={() => {
        setExpanded((prev) => !prev);
      }}
      style={{ margin: '10px 0' }}
    >
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${val.id}-content`}
        id={`${val.id}-header`}
      >
        <h4>{val.label}</h4>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <FlexColumnBox>
          {val.data?.slice(0, showAll ? val.data.length : 5).map((item, idx) => (
            <FormControlLabel
              key={idx}
              control={
                <StyledThinBorderCheckBox
                  id={item.id}
                  onChange={(e: any) =>
                    handleOnChange(val.id, e.target.id, SelectType.MULTIPLE)
                  }
                  checked={filter[val.id]?.includes(item.id) ? true : false}
                  color="primary" // Change checkbox color to primary
                />
              }
              label={
                <FlexRowBox sx={{alignItems: 'center', gap: '5px'}} >
                  <MediumSizeDarkColorThinTypography
                  sx={{
                    fontWeight: filter[val.id]?.includes(item.id) ? "bold" : "",
                    maxWidth: '200px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {item.label?.toString()}
                </MediumSizeDarkColorThinTypography>
                   {item.count && (<div className="badge-light" >{item.count}</div>)}
                </FlexRowBox>
              }
            />
          ))}
        </FlexColumnBox>
        {val.data?.length >= 5 && (
          <button
          className="show-all-btn"
            onClick={() => {
              setShowAll((prev) => !prev);
            }}
          >
            {buttonText}
          </button>
        )}
      </StyledAccordionDetails>
    </AccordianWithoutShadow>
  );
};
