import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { SxProps, Theme } from "@mui/system";
import { CircularProgress, Divider, Typography } from "@mui/material";
import { FlexColumnBox, FlexRowBox } from "../../../../Styles";
import { Colors } from "../../../../Constants";

type ConfirmationModalProps = {
  closeHandler?: () => void;
  confirmHandler?: (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  heading?: string;
  message?: string;
  children?: React.ReactNode;
  isLoading: boolean;
  open: boolean;
  primaryCustomButton?: React.ElementType;
  secondaryCustomButton?: React.ElementType;
  disabledSecondaryButton?: boolean;
  positionTop?: string;
  hideButtons?: boolean;
};

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  closeHandler,
  confirmHandler,
  primaryButtonText = "Cancel",
  secondaryButtonText = "Confirm",
  heading = "Confirm",
  message = "Are you sure?",
  children,
  open,
  isLoading,
  primaryCustomButton,
  secondaryCustomButton,
  disabledSecondaryButton,
  positionTop,
  hideButtons,
}) => {
  const handleClose = () => {
    if (!isLoading) {
      closeHandler?.();
    }
  };

  const PrimaryButton: React.ElementType = primaryCustomButton || Button;
  const SecondaryButton: React.ElementType = secondaryCustomButton || Button;

  const style: SxProps<Theme> = {
    position: "absolute",
    top: positionTop || "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    padding: 2,
    boxShadow: 24,
    outline: 0,
    minWidth: 600,
    maxHeight: "70%",
    overflow: "auto",
  };
  const modalStyle: SxProps<Theme> = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,21,0.75)", // Very light blue background
    "&:focus": {
      outline: "none",
    },
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="confirmation-modal-title"
      aria-describedby="confirmation-modal-description"
      sx={modalStyle}
    >
      <FlexColumnBox sx={style}>
        <Typography
          id="confirmation-modal-title"
          variant="h6"
          sx={{
            fontWeight: 700,
            padding: "1rem 1rem 0.5rem 1rem",
            borderBottom: "1px solid #d8dbe0",
          }}
        >
          {heading}
        </Typography>
        <Divider sx={{ backgroundColor: Colors.LINK_TEXT_COLOR }} />
        {message && (
          <Typography
            id="confirmation-modal-description"
            sx={{
              padding: "1rem",
              borderBottom: "1px solid #d8dbe0",
            }}
          >
            {message}
          </Typography>
        )}
        {children}
        {!hideButtons && (
          <FlexRowBox
            sx={{ justifyContent: "flex-end", gap: "0.5rem", padding: "1rem" }}
          >
            <PrimaryButton
              variant="outlined"
              disabled={isLoading}
              onClick={handleClose}
            >
              {primaryButtonText}
            </PrimaryButton>
            <SecondaryButton
              variant="contained"
              color="primary"
              disabled={isLoading || disabledSecondaryButton}
              startIcon={
                isLoading && <CircularProgress size={20} color="inherit" />
              }
              onClick={confirmHandler}
            >
              {secondaryButtonText}
            </SecondaryButton>
          </FlexRowBox>
        )}
      </FlexColumnBox>
    </Modal>
  );
};

export default ConfirmationModal;
