import { Typography } from "@mui/material";
import { GenericFilter } from "../../../../Components/GenericFilter/GenericFilter";
import {
  DownWardAnimatedBox,
  FlexColumnBox,
  FlexRowBox,
} from "../../../../Styles";
import { useNavigate } from "react-router-dom";
import { GenericTable } from "../../../../Packages/Table";
import {
  BlueButton,
  GreenButton,
  GreyButton,
  RedButton,
} from "../../../../Styles/Button";
import { Colors } from "../../../../Constants";
import React, { useMemo, useState } from "react";
import { useSelectedRow } from "../../../../Hooks/useSelectedRows";
import { toTableRowDataArray } from "../../../../Utils/Mapper/EntitesMapper";
import ConfirmationModal from "../ConfirmationModal";
import { QuickView } from "./Components/QuickView";
import { useGetEntities } from "../../../../Hooks/DataFetcher/Entity/useGetEntities";
import { TFilter } from "../../../../Types";
import { useFilter } from "../../../../Hooks/useFilter";
import { filterDataHelper } from "../../../../Hooks/useFilterData";
import { EntityTableHeader } from "../../../../Constants/TableHeaders/EntityTableHeader";
import { usePermissionContext } from "../../../../Hooks/usePermissionContext";
import { CustomToolTip } from "../../../../Styles/ToolTip";

export const Entities = () => {
  const { entities } = useGetEntities();
  const [filter, setFilter] = useState<TFilter>({});
  const { filterComponentData } = useFilter("Entity");

  const [viewEntity, setViewEntity] = useState<boolean>(false);

  const permissionContext = usePermissionContext();

  const isCreateEntityPermission = permissionContext?.permissions.createEntity;

  const quickLookClickHandler = (rowId: string) => {
    const entity = entities.find((currEntity) => currEntity.id === rowId);
    // setQuickViewCurrentEntity(entity);
    updateViewEntity();
  };
  const viewClickHandler = (rowId: string) => {};
  const editClickHandler = (rowId: string) => {
    // navigate('/data-layer/entities/create-entity', { state: {entity: entityData[0]} })
  };
  const duplicateClickHandler = (rowId: string) => {};
  const deleteClickHandler = (rowId: string) => {};
  const updateViewEntity = () => {
    setViewEntity((prev) => !prev);
  };

  const entitiesTableData = useMemo(() => {
    const filteredEntites = filterDataHelper(filter, entities);
    return toTableRowDataArray(filteredEntites, {
      quickLookClickHandler: quickLookClickHandler,
      viewClickHandler: viewClickHandler,
      editClickHandler: editClickHandler,
      duplicateClickHandler: duplicateClickHandler,
      deleteClickHandler: deleteClickHandler,
    });
  }, [entities, filter]);

  const { selectAllRows, updateSelectedRow, totalRows, selectedRows } =
    useSelectedRow(entitiesTableData);

  const navigate = useNavigate();

  return (
    <React.Fragment>
      {viewEntity && (
        <ConfirmationModal
          closeHandler={updateViewEntity}
          isLoading={false}
          open={viewEntity}
          heading="Delete Entity Mapping"
          message="Are You Sure you wish to delete 1 Entity Mapping"
          primaryCustomButton={GreyButton}
          secondaryCustomButton={BlueButton}
          secondaryButtonText="View Entity"
          primaryButtonText="close"
          children={<QuickView data={entities[1]} />}
        />
      )}
      <FlexRowBox sx={{ overflow: "auto", flexGrow: "1" }}>
        <GenericFilter
          globalSearchEnabled={true}
          data={filterComponentData}
          onChangeFilter={setFilter}
        />
        <FlexColumnBox
          sx={{
            padding: "2rem",
            backgroundColor: Colors.APP_VERY_LIGHT_BLUE_COLOR,
            flexGrow: 1,
          }}
        >
          <FlexRowBox sx={{ justifyContent: "space-between" }}>
            <DownWardAnimatedBox flag={selectedRows.length > 0}>
              <FlexColumnBox>
                <FlexRowBox sx={{ gap: "5px" }}>
                  {selectedRows.length <= 1 && (
                    <GreenButton>Duplicate</GreenButton>
                  )}
                  <RedButton>Delete</RedButton>
                </FlexRowBox>
                <Typography sx={{ color: Colors.LINK_TEXT_COLOR }}>
                  {selectedRows.length} row selected
                </Typography>
              </FlexColumnBox>
            </DownWardAnimatedBox>
            <FlexColumnBox>
              <CustomToolTip placement="top" title={isCreateEntityPermission ? "" : "You Don't have permission."}>
              <BlueButton
                onClick={() => {
                  if(!isCreateEntityPermission) return;
                  navigate("/data-layer/entities/create-entity");
                }}
                className={isCreateEntityPermission ? '' : "disabled-button"}
                sx={{
                  cursor: isCreateEntityPermission ? '' : 'not-allowed'
                }}
              >
                Create Entity
              </BlueButton>
              </CustomToolTip>
              <Typography></Typography>
            </FlexColumnBox>
          </FlexRowBox>
          <GenericTable
            rows={entitiesTableData}
            tableHeader={EntityTableHeader}
            selectAllRows={selectAllRows}
            updateSelectedRow={updateSelectedRow}
            totalRows={totalRows}
            selectedRows={selectedRows}
          />
        </FlexColumnBox>
      </FlexRowBox>
    </React.Fragment>
  );
};
