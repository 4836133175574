import { TTableHeader } from "../../Packages/Table/Types";

export const EntityMappingTableHeader: TTableHeader[] = [
    {
      column_id: "name",
      label: "Name",
      isSortable: "",
      visible: true,
    },
    {
      column_id: "from",
      label: "From",
      isSortable: "",
      visible: true,
    },
    {
      column_id: "to",
      label: "To",
      isSortable: "",
      visible: true,
    },
    {
      column_id: "viaFeature",
      label: "Via Feature",
      isSortable: "",
      visible: true,
    },
    {
      column_id: "updated",
      label: "Updated",
      isSortable: "",
      visible: true,
    },
    {
      column_id: "view",
      label: "",
      isSortable: "",
      visible: false,
    },
  ];
