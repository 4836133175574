import { BaseFeature } from "../../Client/typescript-axios-client-generated";
import { TCol, TRowProps } from "../../Packages/Table/Types";
import { GreyButton } from "../../Styles/Button";

export const convertFeatureIntoTableData = (
  features: BaseFeature[],
  viewClickHandler: (featureId: string) => void
): TRowProps[] => {
  return features.map((feature) => {
    const rowData: Record<string, TCol | TCol[]> = {
      name: {
        column_id: "name",
        text: feature.name,
        link: '/data-layer/features/'+feature.id
      },
      usage: {
        column_id: "usage",
        text: "0",
      },
      runCount: {
        column_id: "runCount",
        text: "0",
      },
      view: {
        column_id: "view",
        text: "View",
        onClickHandler: viewClickHandler,
        buttonElement: GreyButton
      }
    };

    return {
      row_id: feature.id,
      data: rowData,
    };
  });
};
