import { TColors } from "../Types/Colors";

export const Colors : TColors = {
    APP_BLUE_COLOR: "#234afa",
    SIDEBAR_ICON_COLORS: "#768192",
    DARK_THEME: "#2B2B33",
    APP_LIGHT_GREY: "#333",
    TABLE_CHECKBOX_COLOR: "#fff",
    LINK_TEXT_COLOR: "#768192",
    APP_WHITE_COLOR: "#fff",
    TABLE_BORDER_COLOR: "#D7DEE4",
    APP_GREEN_BUTTON_COLOR: "#20bd9a",
    APP_ON_HOVER_GREEN_BUTTON_COLOR: "#1a9c7f",
    APP_RED_COLOR: "#fa666c",
    APP_DARK_RED_COLOR: "#f94148",
    APP_CANCEL_BUTTON_COLOR: "#f3f4f7",
    APP_CANCEL_BUTTON_BORDER_COLOR: "#d7dee4",
    BLUE_TEXT_COLOR: '#4f5d73',
    APP_LINES_COLOR: '#768192',
    SIDE_LINK_HOVER_COLOR: '#D7DEE4',
    APP_DARK_BLUE_COLOR: '#234afa',
    APP_LIGHT_BLUE_COLOR: '',
    APP_VERY_LIGHT_BLUE_COLOR: '#f7f8fa',
    APP_MEDIUM_BLUE_COLOR: '#234afa1a'
}
