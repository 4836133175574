import { Alert } from "@mui/material";

export const DataExplorer = () => {
  return (
    <>
      <Alert
        icon={false}
        sx={{ padding: "0 2px 0 5px", border: "1px solid white" }}
        variant="filled"
        severity="success"
      >
        Select a Table and we'll show its data for you to explore.
      </Alert>
    </>
  );
};
