export type TCreateSource = {
    name: string;
    description: string;
    source_type: string[];
    config_data: Record<string, any>;
    source_types_data: any[];
    attribute_data: Record<string, any>;
}

export type TCreateSourceContextActions = 
    | { type: 'UPDATE_NAME'; payload: string }
    | { type: 'UPDATE_DESCRIPTION'; payload: string }
    | { type: 'UPDATE_SOURCE_TYPE'; payload: string[] }
    | { type: 'UPDATE_CONFIG_DATA'; payload: Record<string, any> }
    | { type: 'SOURCE_TYPES_DATA'; payload: any[] }
    | { type: 'UPDATE_ATTRIBUTE_DATA'; payload: Record<string, any> };

export type TCreateSourceContext = {
    state: TCreateSource;
    dispatch: (e: TCreateSourceContextActions) => any;
}

export enum CreateSourceType {
    SOURCE,
    DESTINATION
  }
  
export type CreateSourceOrDestinationProps = {
    type: CreateSourceType;
}

export type SourceTypeProps = {
    type: CreateSourceType
}


  