import React, { useEffect, useState } from "react";
import { InputTextField } from "../../../../../Components/Forms/InputTextField";
import { SingleSelectInput } from "../../../../../Components/Forms/SingleSelection";
import { Colors } from "../../../../../Constants";
import { FlexColumnBox, FlexRowBox } from "../../../../../Styles";
import {
  HeadingTypography,
  LabelTypography,
  SmallSizeLightColorTypography,
} from "../../../../../Styles/CustomTypography";
import { Box, FormGroup } from "@mui/material";
import { BlueButton } from "../../../../../Styles/Button";
import { CustomDropDown } from "../../../../../Components/Forms/DropDown";
import { TEntity } from "../../../../../Types";
import { useToast } from "../../../../../Hooks/useToast";
import { createEntity } from "../../../../../Services/createEntities";
import { useVersionControlContext } from "../../../../../Hooks/useVersionControlContext";
import { EntityType } from "../../../../../Client/typescript-axios-client-generated";
import { ToastType } from "../../../../../Context/Providers/ToastProvider";
import { useLocation } from "react-router-dom";
import RichTextField from "../../../../../Components/Forms/RichTextInputField";
import { useGetEntities } from "../../../../../Hooks/DataFetcher/Entity/useGetEntities";
import { GenericLabelAndAttributesInput } from "../../../../../Packages/LabelsAndAttribute";
import { TFormData } from "../../../../../Packages/LabelsAndAttribute/Type";

export const CreateEntity = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState<string>("{}");
  const [entityType, setEntityType] = useState("1");
  const [outputColumn, setOutputColumn] = useState("");
  const [dataType, setDataType] = useState<string[]>([]);
  const [combinedEntities, setCombinedEntities] = useState<string[]>([]);
  const { entities } = useGetEntities();
  const [attributeData, setAttributeData] = useState<TFormData>({});

  console.log(attributeData)

  const location = useLocation();

  const dataFromNavigate = location.state;

  const versionControlContext = useVersionControlContext();

  const resetForm = () => {
    setName("");
    setEntityType("1");
    setOutputColumn("");
    setDataType([]);
    setCombinedEntities([]);
  };

  const setDataToForm = (entity: TEntity) => {
    setName(entity.name);
    setEntityType(entity.entityType === "BASE_ENTITY" ? "1" : "2");
    setCombinedEntities(entity.combineEntities.map((mapping) => mapping.id));
  };

  useEffect(() => {
    if (dataFromNavigate?.rowId) {
      setDataToForm(dataFromNavigate.entity);
    }
  }, []);

  const updateAttributeData = (attributeData: TFormData) => {
    setAttributeData(attributeData);
  }

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleEntityTypeChange = (selectedOptionId: string) => {
    setEntityType(selectedOptionId);
  };

  const handleOutputColumnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOutputColumn(e.target.value);
  };

  const handleDataTypeChange = (selectedItems: string[]) => {
    setDataType(selectedItems);
  };

  const handleCombinedEntitiesChange = (selectedItems: string[]) => {
    setCombinedEntities(selectedItems);
  };

  const handleSubmit = () => {
    // Handle form submission logic here
    if (versionControlContext === undefined) return;
    console.log({
      name,
      entityType,
      outputColumn,
      dataType,
    });
    createEntity(
      versionControlContext.currentProjectName,
      versionControlContext.currentBranchName,
      {
        name: name,
        description: description,
        entity_type: entityType == "1"
          ? EntityType.BaseEntity
          : EntityType.CompositeEntity,
        output_column: outputColumn,
        data_type: "Long",
        composite_mappings: entityType === "1" ? [] : combinedEntities,
        attribute_data: Object.keys(attributeData).map((key) => {
          return {
            attribute_id: key,
            value: attributeData[key]
          }

        })
      }
    )
      .then((data) => {
        toast.open(
          `${data.name} entity is created successfully.`,
          ToastType.SUCCESS
        );
        resetForm();
      })
      .catch((err) => {
        toast.open("Error occured while creating entity", ToastType.ERROR);
      });
  };
  const toast = useToast();

  return (
    <FormGroup
      sx={{
        borderLeft: `1px solid ${Colors.SIDE_LINK_HOVER_COLOR}`,
        flexGrow: 1,
      }}
    >
      <FlexRowBox
        sx={{
          justifyContent: "space-between",
          borderBottom: `1px solid ${Colors.SIDE_LINK_HOVER_COLOR}`,
          padding: "1rem",
        }}
      >
        <HeadingTypography>Create Entity</HeadingTypography>
        <BlueButton onClick={handleSubmit}>Create Entity</BlueButton>
      </FlexRowBox>
      <FlexRowBox sx={{ padding: "2rem", gap: "2rem" }}>
        <FlexColumnBox sx={{ gap: "30px", flex: 2 }}>
          <Box>
            <LabelTypography>Name</LabelTypography>
            <SmallSizeLightColorTypography>
              Name must be unique, start with a lower case letter and may only
              contain underscores, letters, and numbers.
            </SmallSizeLightColorTypography>
            <InputTextField
              onChange={handleNameChange}
              value={name}
              violationArray={[]}
            />
          </Box>
          <Box>
            <LabelTypography>Description</LabelTypography>
            <RichTextField input={description} setInput={setDescription} />
          </Box>
          <Box>
            <LabelTypography>Entity type</LabelTypography>
            <SingleSelectInput
              label={""}
              data={[
                {
                  optionId: "1",
                  title: "Base Entity",
                  description:
                    "An entity upon which tables can be keyed, and features aggregated.",
                },
                {
                  optionId: "2",
                  title: "Composite Entities",
                  description:
                    "An entity derived from multiple existing entities. Features can be aggregated from tables containing the deriving entities.",
                },
              ]}
              onSelect={handleEntityTypeChange}
              selectedOptionId={entityType}
              showAsGrid={true}
              columnCount={2}
            />
          </Box>
          {entityType === "1" && (
            <>
              <Box>
                <LabelTypography>Output column</LabelTypography>
                <SmallSizeLightColorTypography>
                  Specify the primary key column name to be used when producing
                  Features for this entity.
                </SmallSizeLightColorTypography>
                <InputTextField
                  onChange={handleOutputColumnChange}
                  value={outputColumn}
                  violationArray={[]}
                />
              </Box>
              <Box>
                <LabelTypography>Type (optional)</LabelTypography>
                <SmallSizeLightColorTypography>
                  Specify the data type the entity is encoded as. If set, tables
                  entity columns must be of this type.
                </SmallSizeLightColorTypography>
                <CustomDropDown
                  data={[
                    {
                      label: "Long",
                      itemId: "1",
                    },
                    {
                      label: "String",
                      itemId: "2",
                    },
                    {
                      label: "Integer",
                      itemId: "3",
                    },
                    {
                      label: "Binary",
                      itemId: "4",
                    },
                  ]}
                  multiple={false}
                  selectedItems={dataType}
                  updateSelectedItems={handleDataTypeChange}
                />
              </Box>
            </>
          )}
          {entityType === "2" && (
            <Box>
              <LabelTypography>Combined Entities</LabelTypography>
              <SmallSizeLightColorTypography>
                Specify the primary key column name to be used when producing
                Feature of this entity
              </SmallSizeLightColorTypography>
              <CustomDropDown
                data={entities.map((currEntity) => {
                  return {
                    label: currEntity.output_column ?? "",
                    itemId: currEntity.id,
                  };
                })}
                multiple
                selectedItems={combinedEntities}
                updateSelectedItems={handleCombinedEntitiesChange}
              />
            </Box>
          )}
        </FlexColumnBox>
        <GenericLabelAndAttributesInput
          entity_type_id={"7dfed51d-caa0-4cf1-8a1f-9d411415570f"}
          onChange={updateAttributeData}
        />
      </FlexRowBox>
    </FormGroup>
  );
};
