export type TFilter = Record<string, any>

export type TFilterData = {
    id: string;
    label?: string;
    type: FilterType;
    data: TFilterItem[];
}

export type TFilterItem = {
    id: string;
    label: string;
    count?: number;
}

export enum FilterType {
    CHECKBOX = 'CHECKBOX',
    DROPDOWN = 'DROPDOWN',
    SEARCH = 'SEARCH',
}