import { Box } from "@mui/material"
import { FlexColumnBox } from "../../../../../../../../Styles"
import { LabelTypography, SmallSizeLightColorTypography } from "../../../../../../../../Styles/CustomTypography"
import { useState } from "react"
import { BaseFeature } from "../../../../../../../../Client/typescript-axios-client-generated"

export const FeaturePreview = () => {

    const [loading, setLoading] = useState<boolean>(true);
    const [dataLoadFailed, setDataLoadFailed] = useState<boolean>(false);


    return (
        <FlexColumnBox>
            Feature Preview
        </FlexColumnBox>
    )
}