import React from "react";
import { MenuItemBaseStyle } from "../Styles";
import { Box } from "@mui/material";

type CommonDropDownMenuContentProps = {
  label: string;
  onClickHandler?: (e?: string) => void;
  element?: any;
  permission?: boolean;
};

export const CommonDropDownMenuContent = ({
  data,
}: {
  data: CommonDropDownMenuContentProps[];
}) => {
  return (
    <React.Fragment>
      {data.map((menuItem) => {
        const Element = menuItem.element ? menuItem.element : MenuItemBaseStyle;
        return (
          <Element
            onClick={menuItem.onClickHandler}
            style={{ paddingLeft: "2rem" }}
            showBorderBottom={false}
            isPermission={menuItem.permission}
          >
            {menuItem.label}
          </Element>
        );
      })}
    </React.Fragment>
  );
};
