import { ApiClient } from "../Utils/ApiClient";

export const getAllDomains = async () => {
  return ApiClient.domainApiClient
    .getDomainsDomainGet()
    .then((data) => {
      return data.data
    })
    .catch((err) => {
      return err;
    });
};
