import { Box } from "@mui/material";
import { FlexColumnBox } from "../../../../../../Styles";
import {
  DescriptionTypography,
  LabelTypography,
} from "../../../../../../Styles/CustomTypography";

export const JoinTable = () => {
  return (
    <FlexColumnBox>
      <Box>
        <LabelTypography>Source Tables</LabelTypography>
        <DescriptionTypography>
          Tables to Join. The first table can be either transactional or
          stateful; while subsequent tables must be stateful and have their
          primary keys matched in the preceding tables.
        </DescriptionTypography>
      </Box>
    </FlexColumnBox>
  );
};
