import GenericSidebar from "../../../Components/Sidebar";
import { ConfigurationSidebarData } from "../../../Constants/SidebarData/Configuration";

const SideBar = () => {
  return (
    <GenericSidebar data={ConfigurationSidebarData.data}/>
  )
};

export default SideBar;
