import { FlexColumnBox } from "../../../../../../../../Styles"
import { useState } from "react"

export const FeatureHistory = () => {

    const [loading, setLoading] = useState<boolean>(true);
    const [dataLoadFailed, setDataLoadFailed] = useState<boolean>(false);


    return (
        <FlexColumnBox>
            Feature History
        </FlexColumnBox>
    )
}