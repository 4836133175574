import { TTableHeader } from "../../Packages/Table/Types";

export const FeatureSetTableHeader: TTableHeader[] = [
    {
        column_id: "name",
        label: "Name",
        isSortable: "",
        visible: true
    },
    {
        column_id: "entity",
        label: "Entity",
        isSortable: "",
        visible: true
    },
    {
        column_id: "labels",
        label: "Labels",
        isSortable: "",
        visible: true
    },
    {
        column_id: "updated",
        label: "Updated",
        isSortable: "",
        visible: true
    },
    {
        column_id: "quickLook",
        label: "Quick Look",
        isSortable: "",
        visible: true
    },
    {
        column_id: "view",
        label: "View",
        isSortable: "",
        visible: false
    },
    {
        column_id: "moreOptions",
        label: "",
        isSortable: "",
        visible: false
    }
]