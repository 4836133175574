import React, { Suspense, useMemo, useState } from "react";
import { TViewTableData } from "../../../../../../Types/CreateTableForm";
import { Action } from "../../../../../../Reducers/CreateTableReducer";
import { Box } from "@mui/material";
import { CustomDropDown } from "../../../../../../Components/Forms/DropDown";
import { v4 as uuidv4 } from "uuid";
import {
  BlueButton,
  GreyButton,
  HollowButton,
  RedButton,
} from "../../../../../../Styles/Button";
import { sampleData } from "../../../../../../TestingData/DropDownData";
import { FlexRowBox } from "../../../../../../Styles";
import { SingleSelectInput } from "../../../../../../Components/Forms/SingleSelection";
import { tableTimeSemantics } from "../../../../../../Constants/FormsOptions/createTable";
import {
  DescriptionTypography,
  LabelTypography,
} from "../../../../../../Styles/CustomTypography";
import { GenericTable } from "../../../../../../Packages/Table";
import {
  TRow,
  TRowProps,
  TTableHeader,
} from "../../../../../../Packages/Table/Types";
import { InputTextField } from "../../../../../../Components/Forms/InputTextField";
import { CreateEntityMapping } from "./CreateEntityMapping";
import SqlEditor from "../../../../../../Components/SqlEditor";
import { TableGetSchema } from "../../../../../../Client/typescript-axios-client-generated";

const ConfirmationModal = React.lazy(
  () => import("../../../ConfirmationModal/index")
);

type ExternalTableProps = {
  disPatch: React.Dispatch<Action>;
  data: TViewTableData;
  sourcesTable: TableGetSchema[];
};

const ViewTable = ({ disPatch, data, sourcesTable }: ExternalTableProps) => {
  const [openEntityMappingModal, setOpenEntityMappingModal] =
    useState<boolean>(false);
  const [updateEntityMappingId, setUpdateEntityMappingId] =
    useState<string>("");

  const [selectedEntity, setSelectedEntity] = useState<string[]>([]);
  const [entityKeyColumn, setEntityKeyColumn] = useState<string>("");

  const sourcesTableDropDown = useMemo(() => {
    return sourcesTable.map((table) => {
      return {
        label: table.name,
        itemId: table.id ?? '',
      }
    })
  },[sourcesTable])
  console.log(sourcesTableDropDown)

  const updateTimeSemantics = (timeSemantics: string) => {
    disPatch({
      type: "SET_VIEW_TABLE_DATA",
      payload: {
        tableTimeSemantics: timeSemantics,
      },
    });
  };

  const confirmMappingHandler = () => {
    disPatch({
      type: "SET_VIEW_TABLE_DATA",
      payload: {
        entityMapping: [
          ...data.entityMapping,
          {
            id: uuidv4(),
            entity: selectedEntity[0],
            entityKeyColumn: entityKeyColumn,
          },
        ],
      },
    });
    setOpenEntityMappingModal(false);
    setSelectedEntity([]);
    setEntityKeyColumn("");
  };
  const updateMappingHandler = () => {
    const entityMapping = data.entityMapping.find(
      (currEntity) => currEntity.id === updateEntityMappingId
    );
    if (!entityMapping) {
      return;
    }
    entityMapping.entity = selectedEntity[0];
    entityMapping.entityKeyColumn = entityKeyColumn;
    setSelectedEntity([]);
    setEntityKeyColumn("");
    setUpdateEntityMappingId("");
    setOpenEntityMappingModal(false);
  };

  const rowsProps: TRowProps[] = [];

  for (let i = 0; i < data.entityMapping.length; i++) {
    const row_id = data.entityMapping[i].id;
    const currentEntity = data.entitiesData.find(
      (entity) => entity.id === data.entityMapping[i].entity
    );
    const row: TRow = {
      entity: { column_id: "entity", text: currentEntity?.name ?? "" },
      entityKeyColumn: {
        column_id: "entityKeyColumn",
        text: data.entityMapping[i].entityKeyColumn,
      },
      options: [
        {
          column_id: "options",
          text: "Edit",
          onClickHandler: (row_id) => {
            const currentEntity = data.entityMapping.find(
              (currMapping) => currMapping.id === row_id
            );
            if (!currentEntity) return;
            setSelectedEntity([currentEntity.entity]);
            setEntityKeyColumn(currentEntity.entityKeyColumn);
            setOpenEntityMappingModal(true);
            setUpdateEntityMappingId(row_id);
          },
        },
        {
          column_id: "remove",
          text: "Remove",
          onClickHandler: (row_id) => {
            const newEntityMappingArr = data.entityMapping.filter(
              (mapping) => mapping.id != row_id
            );
            disPatch({
              type: "SET_VIEW_TABLE_DATA",
              payload: {
                entityMapping: newEntityMappingArr,
              },
            });
          },
          buttonElement: RedButton,
        },
      ],
    };
    rowsProps.push({
      row_id: row_id,
      data: row,
    });
  }
  const tableHeader: TTableHeader[] = [
    { column_id: "entity", label: "Entity", isSortable: "asc", visible: true },
    {
      column_id: "entityKeyColumn",
      label: "Entity Key Column",
      isSortable: "asc",
      visible: true,
    },
    { column_id: "options", label: "Edit", isSortable: "asc", visible: true },
  ];

  return (
    <>
      <Box>
        <Suspense>
          <ConfirmationModal
            isLoading={false}
            open={openEntityMappingModal}
            children={
              <CreateEntityMapping
                entities={data.entitiesData}
                selectedEntity={selectedEntity}
                updateSelectedEntity={setSelectedEntity}
                entityKeyColumn={entityKeyColumn}
                updateEntityKeyColumn={setEntityKeyColumn}
              />
            }
            heading={updateEntityMappingId ? "Update" : "Create"}
            message=""
            primaryCustomButton={GreyButton}
            secondaryCustomButton={BlueButton}
            secondaryButtonText={updateEntityMappingId ? "Save" : "Create"}
            confirmHandler={
              updateEntityMappingId.length > 0
                ? updateMappingHandler
                : confirmMappingHandler
            }
            disabledSecondaryButton={
              selectedEntity.length < 1 || entityKeyColumn.length < 1
            }
            closeHandler={() => {
              setOpenEntityMappingModal(false);
              setUpdateEntityMappingId("");
              setEntityKeyColumn("");
              setSelectedEntity([]);
            }}
          />
        </Suspense>

        <LabelTypography>Sources</LabelTypography>
        <DescriptionTypography>
          Select the Tables that this view can use
        </DescriptionTypography>
        <FlexRowBox sx={{ gap: "1rem" }}>
          <Box sx={{ flexGrow: "1" }}>
            <CustomDropDown
              data={sourcesTableDropDown}
              multiple
              selectedItems={data.sources}
              updateSelectedItems={(selectedItems: string[]) =>
                disPatch({
                  type: "SET_VIEW_TABLE_DATA",
                  payload: {
                    sources: selectedItems,
                  },
                })
              }
            />
          </Box>
          <GreyButton>Browse</GreyButton>
        </FlexRowBox>
      </Box>
      <Box>
        <LabelTypography>SQL</LabelTypography>
        <DescriptionTypography>
          Enter a full SQL statement to create a view table. This can include
          joins, group bys, where clauses etc.
        </DescriptionTypography>
        <SqlEditor
          data={data.sql}
          onChange={(value) => {
            disPatch({
              type: "SET_VIEW_TABLE_DATA",
              payload: {
                sql: value,
              },
            });
          }}
        />
      </Box>
      <Box>
        <LabelTypography>Entity Id Columns</LabelTypography>
        <DescriptionTypography>
          Map columns in the table to entities
        </DescriptionTypography>
        {rowsProps.length > 0 && (
          <GenericTable
            rows={rowsProps}
            tableHeader={tableHeader}
            selectAllRows={() => {}}
            updateSelectedRow={() => {}}
            totalRows={2}
            selectedRows={[]}
          />
        )}
        <HollowButton
          sx={{ marginTop: "10px" }}
          onClick={() => {
            setOpenEntityMappingModal((prev) => !prev);
          }}
        >
          Add New Mapping
        </HollowButton>
      </Box>
      <Box>
        <LabelTypography>Table Time Semantics</LabelTypography>
        <SingleSelectInput
          label={""}
          data={tableTimeSemantics}
          onSelect={updateTimeSemantics}
          selectedOptionId={data.tableTimeSemantics}
        />
      </Box>
      {data.tableTimeSemantics === "event_fact_table" && (
        <Box>
          <InputTextField
            title="Timestamp Column"
            description="The column which describe when the event occured"
            placeholder="timestamp_column"
            onChange={(event: any) => {
              disPatch({
                type: "SET_VIEW_TABLE_DATA",
                payload: {
                  eventOrFactTable: {
                    ...data.eventOrFactTable,
                    timestampColumn: event.target.value,
                  },
                },
              });
            }}
            value={data.eventOrFactTable.timestampColumn}
            violationArray={[]}
          />
        </Box>
      )}
      {(data.tableTimeSemantics === "slowly_changing_dimension_type_2" ||
        data.tableTimeSemantics === "point_in_time") && (
        <Box>
          <LabelTypography>Primary Entity</LabelTypography>
          <DescriptionTypography>
            Select the entity that is the primary key for each row
          </DescriptionTypography>
          <CustomDropDown
            data={sampleData}
            multiple={false}
            selectedItems={
              data.tableTimeSemantics === "slowly_changing_dimension_type_2"
                ? data.slowlyChangingDimension.primaryEntity
                : data.pointInTime.primaryEntity
            }
            updateSelectedItems={(selectedItems: string[]) => {
              if (
                data.tableTimeSemantics === "slowly_changing_dimension_type_2"
              ) {
                disPatch({
                  type: "SET_VIEW_TABLE_DATA",
                  payload: {
                    slowlyChangingDimension: {
                      ...data.slowlyChangingDimension,
                      primaryEntity: selectedItems,
                    },
                  },
                });
              } else {
                disPatch({
                  type: "SET_VIEW_TABLE_DATA",
                  payload: {
                    pointInTime: {
                      ...data.pointInTime,
                      primaryEntity: selectedItems,
                    },
                  },
                });
              }
            }}
          />
        </Box>
      )}
      {data.tableTimeSemantics === "slowly_changing_dimension_type_2" && (
        <Box>
          <FlexRowBox style={{ minWidth: "100px", gap: "2rem" }}>
            <Box style={{ flexGrow: 1 }}>
              <InputTextField
                title="From Column"
                description="The column which describe when the row was valid from"
                placeholder="from_column"
                onChange={(event: any) => {
                  disPatch({
                    type: "SET_VIEW_TABLE_DATA",
                    payload: {
                      slowlyChangingDimension: {
                        ...data.slowlyChangingDimension,
                        fromColumn: event.target.value,
                      },
                    },
                  });
                }}
                value={data.slowlyChangingDimension.fromColumn}
                violationArray={[]}
              />
            </Box>
            <Box style={{ flexGrow: 1 }}>
              <InputTextField
                title="Valid To Column"
                description="The column which describes when the row is valid to"
                placeholder="to_column"
                onChange={(event: any) => {
                  disPatch({
                    type: "SET_VIEW_TABLE_DATA",
                    payload: {
                      slowlyChangingDimension: {
                        ...data.slowlyChangingDimension,
                        validToColumn: event.target.value,
                      },
                    },
                  });
                }}
                value={data.slowlyChangingDimension.validToColumn}
                violationArray={[]}
              />
            </Box>
          </FlexRowBox>
        </Box>
      )}

      {data.tableTimeSemantics === "point_in_time" && (
        <Box>
          <InputTextField
            title="Validity Column"
            description="The column describes when the row become valid"
            placeholder="generation_date_column"
            onChange={(event: any) => {
              disPatch({
                type: "SET_VIEW_TABLE_DATA",
                payload: {
                  pointInTime: {
                    ...data.pointInTime,
                    validityColumn: event.target.value,
                  },
                },
              });
            }}
            value={data.pointInTime.validityColumn}
            violationArray={[]}
          />
        </Box>
      )}
    </>
  );
};

export default ViewTable;
