import { useMemo } from "react";
import { CustomDropDown, TItemData } from "../../../../../Components/Forms/DropDown";
import { Box, Typography } from "@mui/material";
import { styled, Theme } from "@mui/system";
import { LabelTypography } from "../../../../../Styles/CustomTypography";
import { BaseFeature } from "../../../../../Client/typescript-axios-client-generated";

type AttachFeatureProps = {
  featuresData: BaseFeature[];
  selectedItems: string[];
  updateSelectedItems: (selectedFeatures: string[]) => void;
};

const Container = styled(Box)(({ theme }: { theme: Theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  maxWidth: "100%",
}));

const Title = styled(Typography)(({ theme }: { theme: Theme }) => ({
  marginBottom: theme.spacing(1),
  // color: theme.palette.text.primary,
}));

export const AttachFeature = ({
  featuresData,
  selectedItems,
  updateSelectedItems,
}: AttachFeatureProps) => {
  const dropDownData: TItemData[] = useMemo(() => {
    return featuresData.map((feature) => ({
      label: feature.name,
      itemId: feature.id,
    }));
  }, [featuresData]);

  return (
    <Container sx={{borderBottom: `1px solid white`}} >
      <LabelTypography sx={{padding: '0.5rem 0 0.5rem 0'}} >Features</LabelTypography>
      <CustomDropDown
        data={dropDownData}
        multiple
        selectedItems={selectedItems}
        updateSelectedItems={updateSelectedItems}
      />
    </Container>
  );
};
