import { useEffect, useState } from "react";
import { BaseFeature } from "../../../Client/typescript-axios-client-generated";
import { useVersionControlContext } from "../../useVersionControlContext";
import { getAllFeaturesByBranchId } from "../../../Services/getAllFeatures";

export const useGetFeatures = () => {
  const [features, setFeatures] = useState<BaseFeature[]>([]);

  const versionControlContext = useVersionControlContext();

  const updateFeatures = (data: BaseFeature[]) => {
    setFeatures(data);
  };

  useEffect(() => {
    if (versionControlContext === undefined) return;
    getAllFeaturesByBranchId(
      versionControlContext.currentProjectName,
      versionControlContext.currentBranchName
    ).then((data) => {
      updateFeatures(data);
    });
  }, [versionControlContext?.currentBranch]);

  return {
    features,
    updateFeatures,
  };
};
