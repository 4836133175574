import { FilterType } from "../Client/typescript-axios-client-generated";
import { ApiClient } from "../Utils/ApiClient";

export const getFilterByType = async (
  project_name: string,
  branch_name: string,
  filter_type: FilterType
) => {
  return ApiClient.filterClient
    .getFilterDataFilterGet(filter_type, project_name, branch_name)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};
