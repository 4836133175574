import { ApiClient } from "../Utils/ApiClient";

export const getAllProjects = async () => {
  return ApiClient.projectApiClient
    .getAllProjectProjectGet()
    .then((data) => {
      return data.data
    })
    .catch((err) => {
      throw err;
    });
};
