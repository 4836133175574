import { Reducer } from "react";
import {
  TCreateTableData,
  TEventStoreTableData,
  TExternalTableData,
  TTableMetaInformation,
  TViewTableData,
} from "../Types/CreateTableForm";
import { TDomain } from "../Types/Domain";
import { TTeam } from "../Types/Teams";
import { TableGetSchema } from "../Client/typescript-axios-client-generated";

export type Action =
  | { type: "SET_TABLE_NAME"; payload: string }
  | { type: "SET_TABLE_DESCRIPTION"; payload: string }
  | { type: "SET_TABLE_TYPE"; payload: string }
  | { type: "SET_EXTERNAL_TABLE_DATA"; payload: Partial<TExternalTableData> }
  | { type: "SET_VIEW_TABLE_DATA"; payload: Partial<TViewTableData> }
  | { type: "SET_COLUMN"; payload: string }
  | { type: "SET_META_INFORMATION"; payload: Partial<TTableMetaInformation> }
  | { type: "SET_DOMAINS_DATA"; payload: TDomain[] }
  | { type: "SET_OWNERS_DATA"; payload: TTeam[] }
  | {
      type: "SET_EVENT_STORE_TABLE_DATA";
      payload: Partial<TEventStoreTableData>;
    }
  | { type: "SET_SOURCES_TABLE_DATA"; payload: TableGetSchema[] };

export const createTableReducer: Reducer<TCreateTableData, Action> = (
  state,
  action
) => {
  switch (action.type) {
    case "SET_TABLE_NAME":
      return { ...state, table: { ...state.table, name: action.payload } };
    case "SET_TABLE_DESCRIPTION":
      return {
        ...state,
        table: { ...state.table, description: action.payload },
      };
    case "SET_TABLE_TYPE":
      return { ...state, table: { ...state.table, tableType: action.payload } };
    case "SET_EXTERNAL_TABLE_DATA":
      return {
        ...state,
        table: {
          ...state.table,
          externalTableData: {
            ...state.table.externalTableData,
            ...action.payload,
          },
        },
      };
    case "SET_VIEW_TABLE_DATA":
      return {
        ...state,
        table: {
          ...state.table,
          viewTableData: { ...state.table.viewTableData, ...action.payload },
        },
      };
    case "SET_COLUMN":
      return {
        ...state,
        modeling: { ...state.modeling, column: action.payload },
      };
    case "SET_META_INFORMATION":
      return {
        ...state,
        metaInformation: { ...state.metaInformation, ...action.payload },
      };
    case "SET_DOMAINS_DATA":
      return {
        ...state,
        domainsData: action.payload,
      };
    case "SET_OWNERS_DATA":
      return {
        ...state,
        ownersData: action.payload,
      };
    case "SET_EVENT_STORE_TABLE_DATA":
      return {
        ...state,
        table: {
          ...state.table,
          eventStoreTableData: {
            ...state.table.eventStoreTableData,
            ...action.payload,
          },
        },
      };
    case "SET_SOURCES_TABLE_DATA":
      return {
        ...state,
        sourcesTableData: action.payload
      }
    default:
      return state;
  }
};
