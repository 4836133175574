import React from "react";
import { CommonCreateAttributeProps } from "../Types";
import { StyledThinBorderCheckBox } from "../../../../../../Styles/CheckBoxes";
import { FormControlLabel } from "@mui/material";

export const AppliesTo = ({ state, dispatch }: CommonCreateAttributeProps) => {
  console.log(state);
  return (
    <React.Fragment>
      {state.entityTypesData.map((entityType) => {
        return (
          <FormControlLabel
            sx={{ display: "block" }}
            control={
              <StyledThinBorderCheckBox
                checked={state.appliesTo.includes(entityType.id)}
                onChange={() => {
                  let arr = state.appliesTo;
                  if (arr.includes(entityType.id)) {
                    arr = arr.filter((ele) => ele != entityType.id);
                  } else {
                    arr = [...arr, entityType.id];
                  }
                  dispatch({ type: "SET_APPLIES_TO", payload: arr });
                }}
              />
            }
            label={entityType.name}
          />
        );
      })}
    </React.Fragment>
  );
};
