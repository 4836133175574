import { Box, Checkbox, TableCell, TableHead, TableRow, TableSortLabel, Typography } from "@mui/material";
import { Data, EnhancedTableProps } from "../../../Types";
import { visuallyHidden } from '@mui/utils';
import { TableHeaderProps } from "../Types";
import { Colors } from "../../../Constants";
import { StyledThinBorderCheckBox } from "../../../Styles/CheckBoxes";

export const TableHeader = (props: TableHeaderProps) => {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, hideCheckbox } =
    props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow sx={{backgroundColor: Colors.APP_VERY_LIGHT_BLUE_COLOR}} >
        <TableCell>
          {!hideCheckbox && <StyledThinBorderCheckBox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />}
        </TableCell>
        {props.headerCells.map((headCell) => (
          <TableCell
            key={headCell.column_id}
            padding={'normal'}
            sortDirection={orderBy === headCell.column_id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.column_id}
              direction={orderBy === headCell.column_id ? order : 'asc'}
              onClick={createSortHandler(headCell.column_id)}
              sx={{fontSize: '.875rem', }}
            >
              <Typography sx={{fontWeight: 'bold', whiteSpace: 'nowrap'}} >{!headCell.visible ? '' : headCell.label}</Typography>
              {orderBy === headCell.column_id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
