import {BranchApi,ProjectApi,EntityApi,Configuration, DomainApi, TeamApi, EntityMappingApi, TableApi, FeatureSetApi, ChangeRequestApi, CommitsApi, FiltersApi, SchedulerApi} from '../Client/typescript-axios-client-generated'
import { Configuration as CConfiguration, FiltersApi as CFiltersApi, LabelAndAttributeApi, OrchestratorsApi, SourcesApi } from '../Client/configuration-client';
import { FeatureApi } from '../Client/typescript-axios-client-generated';
import { PermissionsApi, UsersApi } from '../Client/gate-keeper-client';

const backendBasePath = "http://18.232.85.232:8080"; // Replace this with your backend base URL
const configurationBackendPath = "http://44.211.91.226:8080";
const rbacServiceBackendPath = "http://physarum-gate-keeper-namespace.example.local";
const configuration = new Configuration({
    basePath: backendBasePath
});

const configurationServiceConfig = new CConfiguration({
    basePath: configurationBackendPath
});

const rbacServiceConfig = new Configuration({
    basePath: rbacServiceBackendPath
})

export const ApiClient = {
    branchApiClient: new BranchApi(configuration),
    projectApiClient: new ProjectApi(configuration),
    entityApiClient: new EntityApi(configuration),
    domainApiClient: new DomainApi(configuration),
    teamApiClient: new TeamApi(configuration),
    featureApiClient: new FeatureApi(configuration),
    entityMappingApiClient: new EntityMappingApi(configuration),
    tableClient: new TableApi(configuration),
    featureSetClient: new FeatureSetApi(configuration),
    changeRequestClient: new ChangeRequestApi(configuration),
    commitRequestClient: new CommitsApi(configuration),
    filterClient: new FiltersApi(configuration),
    scheduleClient: new SchedulerApi(configuration),
    sourceClient: new SourcesApi(configurationServiceConfig),
    attributeClient: new LabelAndAttributeApi(configurationServiceConfig),
    configurationFilterClient: new CFiltersApi(configurationServiceConfig),
    orchestratorClient: new OrchestratorsApi(configurationServiceConfig),
    permissionClient: new PermissionsApi(rbacServiceConfig),
    userClient: new UsersApi(rbacServiceConfig),
}