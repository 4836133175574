import { SourceCreate } from "../Client/configuration-client";
import { ApiClient } from "../Utils/ApiClient";

export const createSource = (body: SourceCreate) => {
  return ApiClient.sourceClient
    .createSourcesSourceCreateSourcePost(body)
    .then((data: any) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};
