import { ScheduleType } from "../../Client/typescript-axios-client-generated";
import { CreateScheduleState, ERetryType, ETarget } from "../../Pages/Schedules/Components/CreateSchedule/Type";

export const initialState: CreateScheduleState = {
  name: "",
  description: "{}",
  enabled: true,
  cluster: "",
  showAdditionalSparkProperty: false,
  standardOrStream: "STANDARD",
  destinations: [],
  target: ETarget.OFFICIAL,
  branch: [],
  featureSet: [],
  entityPopulation: [],
  includeMetadata: false,
  scheduleType: ScheduleType.Manual,
  utcTime: "",
  dataOffset: "",
  cron: "",
  dependentJob: [],
  retryType: ERetryType.DONT_RETRY,
  additionalSparkProperty: [],
  addDestinationOpened: false,
  selectedDestination: [],
  addJobOpened: false,
  selectedJobs: [],
  backOff: {
    hours: 0,
    minutes: 0
  },
  maxAttempts: 0,
  clusterData: [],
  featureSetData: [],
  domains: [],
  owner: [],
};
