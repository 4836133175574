import { Box } from "@mui/material";
import { CustomSwitch } from "../../../../../../Components/CustomSwitch";
import { InputTextField } from "../../../../../../Components/Forms/InputTextField";
import RichTextField from "../../../../../../Components/Forms/RichTextInputField";
import { FlexColumnBox, FlexRowBox } from "../../../../../../Styles";
import { CommonCreateAttributeProps } from "../Types";
import { AttributeTypes } from "./AttributeTypes";
import {
  LabelTypography,
  SmallSizeLightColorTypography,
} from "../../../../../../Styles/CustomTypography";
import { AppliesTo } from "./EntitySelection";
import { AllowedValues } from "./AllowedValues";
import { useState } from "react";
import { BlueButton, RedButton } from "../../../../../../Styles/Button";
import { GenericTable } from "../../../../../../Packages/Table";
import "./index.css";

export const CreateAttribute = ({
  state,
  dispatch,
}: CommonCreateAttributeProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [editIndex, setEditIndex] = useState<number | undefined>(undefined);

  const allowedValueDeleteHandler = (index: number) => {
    if (index < 0 || index >= state.allowedValues.length) return;
    const filteredRes = state.allowedValues.filter((_, idx) => {
      return index != idx;
    });
    dispatch({ type: "SET_ALLOWED_VALUES", payload: filteredRes });
  };

  const allowedValueEditHandler = (idx: number) => {
    setEditIndex(idx);
    setOpen(true);
  };

  const resetEditIndex = () => {
    setEditIndex(undefined);
  };

  const tableData = state.allowedValues.map((allowedValue, index) => {
    return {
      row_id: index.toString(),
      data: {
        name: {
          column_id: "name",
          text: "",
          reactNode: (
            <div style={{ textAlign: "left", padding: "0 2rem" }}>
              {allowedValue}
            </div>
          ),
        },
        editOrDelete: {
          column_id: "editOrDelete",
          text: "",
          reactNode: (
            <FlexRowBox sx={{ justifyContent: "flex-end", gap: "1rem" }}>
              <button
                className="delete-btn"
                onClick={() => {
                  allowedValueDeleteHandler(index);
                }}
              >
                Delete
              </button>
              <button
                className="edit-btn"
                onClick={() => {
                  allowedValueEditHandler(index);
                }}
              >
                Edit
              </button>
            </FlexRowBox>
          ),
        },
      },
    };
  });

  return (
    <FlexRowBox sx={{ gap: "2rem" }}>
      <FlexColumnBox sx={{ gap: "1rem" }}>
        <InputTextField
          onChange={(e) => {
            dispatch({ type: "SET_NAME", payload: e.target.value });
          }}
          value={state.name}
          violationArray={[]}
          title="Key"
          description="Key must be unique"
        />
        <AttributeTypes state={state} dispatch={dispatch} />
        <RichTextField
          input={state.description}
          setInput={(val) => {
            dispatch({ type: "SET_DESCRIPTION", payload: val });
          }}
        />
        <Box>
          <LabelTypography>Mandatory</LabelTypography>
          <SmallSizeLightColorTypography>
            Specify if this is mandatory
          </SmallSizeLightColorTypography>
          <CustomSwitch
            checked={state.mandatory}
            onChange={() => {
              dispatch({ type: "SET_MANDATORY", payload: !state.mandatory });
            }}
          />
        </Box>
        <FlexColumnBox sx={{ gap: "1rem" }}>
          <LabelTypography>Allowed Values</LabelTypography>
          <GenericTable
            rows={tableData}
            tableHeader={[
              {
                column_id: "name",
                label: "",
                isSortable: "",
                visible: false,
              },
              {
                column_id: "editOrDelete",
                label: "",
                isSortable: "",
                visible: false,
              },
            ]}
            selectAllRows={function (): void {
              throw new Error("Function not implemented.");
            }}
            updateSelectedRow={function (rowId: string): void {
              throw new Error("Function not implemented.");
            }}
            totalRows={0}
            selectedRows={[]}
            hideHeader
            hidePagination
            hideCheckbox
            padding="0"
          />
          <BlueButton
            sx={{ width: "fit-content" }}
            onClick={() => {
              setOpen((prev) => !prev);
            }}
          >
            Add Value
          </BlueButton>
        </FlexColumnBox>
        {open && (
          <AllowedValues
            state={state}
            dispatch={dispatch}
            open={open}
            closeHandler={() => {
              setOpen(false);
            }}
            editIndex={editIndex}
            resetEditIndex={resetEditIndex}
          />
        )}
      </FlexColumnBox>
      <Box sx={{ minWidth: "300px" }}>
        <LabelTypography>Applies To</LabelTypography>
        <AppliesTo state={state} dispatch={dispatch} />
      </Box>
    </FlexRowBox>
  );
};
