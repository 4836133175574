// CreateChangeRequestForm.js
import { useMemo, useState } from "react";
import { InputTextField } from "../../../../../Components/Forms/InputTextField";
import { FlexColumnBox, FlexRowBox } from "../../../../../Styles";
import {
  LabelTypography,
  SmallSizeLightColorTypography,
} from "../../../../../Styles/CustomTypography";
import RichTextField from "../../../../../Components/Forms/RichTextInputField";
import { Box } from "@mui/material";
import { CustomDropDown } from "../../../../../Components/Forms/DropDown";
import { useVersionControlContext } from "../../../../../Hooks/useVersionControlContext";
import { CreateFormHeader } from "../../../../../Components/CreateFormHeader";
import { Colors } from "../../../../../Constants";
import { createChangeRequest } from "../../../../../Services/createChangeRequest";
import { useToast } from "../../../../../Hooks/useToast";
import { ToastType } from "../../../../../Context/Providers/ToastProvider";
import { useNavigate } from "react-router-dom";

const initialContent = JSON.stringify({
  type: "doc",
  content: [
    {
      type: "paragraph",
      content: [
        {
          type: "text",
          text: "Initial content", // Non-empty initial text
        },
      ],
    },
  ],
});

export const CreateChangeRequestForm = () => {
  //form data
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>(initialContent);
  const [sourceBranch, setSourceBranch] = useState<string[]>([]);
  const [targetBranch, setTargetBranch] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  //form validation data[]
  const [nameValidationArray, setNameValidationArray] = useState<string[]>([]);
  const [sourceBranchValidationArray, setSourceBranchValidationArray] =
    useState<string[]>([]);
  const [targetBranchValidationArray, setTargetBranchValidationArray] =
    useState<string[]>([]);

  const versionControlContext = useVersionControlContext();

  const toast = useToast();

  const navigate = useNavigate();

  const branchesListDropDownData = useMemo(() => {
    if (!versionControlContext?.branchesList) return [];
    return versionControlContext.branchesList.map((branch) => {
      return {
        itemId: branch.id,
        label: branch.name,
      };
    });
  }, [versionControlContext?.branchesList]);

  const handleSubmit = () => {
    setNameValidationArray([]);
    setSourceBranchValidationArray([]);
    setTargetBranchValidationArray([]);
    let isValidationFailed = false;
    if (name.length === 0) {
      isValidationFailed = true;
      setNameValidationArray(["This field is required."]);
    }
    if (sourceBranch.length === 0) {
      isValidationFailed = true;
      setSourceBranchValidationArray(["This field is required."]);
    }
    if (targetBranch.length === 0) {
      isValidationFailed = true;
      setTargetBranchValidationArray(["This field is required."]);
    }
    if (isValidationFailed) {
      return;
    }
    //TODO - Call backend api to create change request
    if(!versionControlContext) return;
    setLoading(true);
    createChangeRequest({
      name: name,
      source_branch_id: sourceBranch[0],
      target_branch_id: targetBranch[0],
      project_id: versionControlContext.currentProject,
      description: description
    }).then((data) => {
      toast.open('Change request created successfully', ToastType.SUCCESS);
      navigate('/governance/change-requests');
    }).catch((err) => {
      toast.open('Something went wrong while creating change request', ToastType.ERROR);
    }).finally(() => {
      setLoading(false);
    })
  };

  return (
    <FlexColumnBox
      sx={{
        flexGrow: 1,
        borderLeft: `1px solid ${Colors.SIDE_LINK_HOVER_COLOR}`,
      }}
    >
      <CreateFormHeader
        heading={"Create Change Request"}
        submitButtonText={"Create Change Request"}
        submitHandler={handleSubmit}
        loading={loading}
      />
      <FlexColumnBox sx={{ padding: "2rem", gap: "1.5rem" }}>
        <InputTextField
          onChange={(e) => setName(e.target.value)}
          value={name}
          violationArray={nameValidationArray}
          title="Name"
        />
        <Box>
          <FlexRowBox
            sx={{ gap: ".5rem", alignItems: "center", marginBottom: ".5rem" }}
          >
            <LabelTypography>Description</LabelTypography>
            <SmallSizeLightColorTypography>
              Optional
            </SmallSizeLightColorTypography>
          </FlexRowBox>

          <RichTextField input={description} setInput={setDescription} />
        </Box>
        <FlexColumnBox sx={{ gap: ".5rem" }}>
          <LabelTypography>Source Branch</LabelTypography>
          <SmallSizeLightColorTypography>
            The Branch you have been working on, with changes you may wish to
            promote.
          </SmallSizeLightColorTypography>
          <CustomDropDown
            data={branchesListDropDownData}
            multiple={false}
            selectedItems={sourceBranch}
            updateSelectedItems={(selectedItems) => {
              if (selectedItems.length == 0) {
                setSourceBranchValidationArray(["This field is required."]);
              }else{
                setTargetBranchValidationArray([]);
              }
              setSourceBranch(selectedItems);
            }}
            validationsErrors={sourceBranchValidationArray}
          ></CustomDropDown>
        </FlexColumnBox>
        <FlexColumnBox sx={{ gap: ".5rem" }}>
          <LabelTypography>Target Branch</LabelTypography>
          <SmallSizeLightColorTypography>
            The Branch you would like your change to be integrated into.
          </SmallSizeLightColorTypography>
          <CustomDropDown
            data={branchesListDropDownData}
            multiple={false}
            selectedItems={targetBranch}
            updateSelectedItems={(selectedItems) => {
              if (selectedItems.length == 0) {
                setTargetBranchValidationArray(["This field is required."]);
              }else{
                setTargetBranchValidationArray([]);
              }
              setTargetBranch(selectedItems);
            }}
            validationsErrors={targetBranchValidationArray}
          ></CustomDropDown>
        </FlexColumnBox>
      </FlexColumnBox>
    </FlexColumnBox>
  );
};
