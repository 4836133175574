import { styled } from '@mui/system';
import { Colors } from '../Constants';
import { NavLink } from 'react-router-dom';

const StyledLinkContainer = styled(NavLink)({
    borderRadius: "0.25rem",
    textDecoration: 'none',
    color: Colors.LINK_TEXT_COLOR, // Example text color
    height: '50px', // Example padding
    padding: '0 20px 0 20px',
    transition: 'background-color 0.1s ease', // Example transition
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${Colors.SIDE_LINK_HOVER_COLOR}`,
    fontWeight: 500,
    whiteSpace:'nowrap',
    '&.active': {
        borderRight: `5px solid ${Colors.APP_BLUE_COLOR}`,
        color: Colors.APP_LIGHT_GREY,
        backgroundColor: Colors.APP_VERY_LIGHT_BLUE_COLOR
    },
    '&:hover': {
        backgroundColor: Colors.APP_VERY_LIGHT_BLUE_COLOR, // Example hover background color
        color: Colors.APP_LIGHT_GREY
    },
});

const StyledIcon = styled('div')({
    marginRight: '10px',
    width: '35px',
    textAlign: 'center'
});

export const StyledNavLinkWithIcon = ({ icon, text, showText, link, hideIcon }: { icon: JSX.Element, text: string, showText: boolean, link: string, hideIcon?: boolean }) => {
    return (
        <StyledLinkContainer to={link}>
            { !hideIcon && <StyledIcon>{icon}</StyledIcon>}
            {showText && text}
        </StyledLinkContainer>
    );
};
