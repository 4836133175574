import React from "react";
import { useCreateSourceContextProvider } from "../Hooks/useCreateSourceContextProvider";
import { CreateSourceContext } from "../Context/CreateSourceContext";
import { CreateSourceType } from "../Type";

type CreateSourceContextProviderProps = {
  type: CreateSourceType
  children?: React.ReactNode;
};

export const CreateSourceContextProvider = ({
  children,
  type
}: CreateSourceContextProviderProps) => {
  const { state, dispatch } = useCreateSourceContextProvider(type);

  return (
    <CreateSourceContext.Provider value={{ state, dispatch }}>
      {children}
    </CreateSourceContext.Provider>
  );
};
