import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Colors } from "../Constants";

export const UnderLineOnHoverNavLink = styled(NavLink)({
    textDecoration: 'none',
    color: Colors.APP_LIGHT_GREY,
    fontFamily: 'sans-serif',
    whiteSpace: 'nowrap', // Ensures the text stays on a single line
    '&:hover' : {
        textDecoration: 'underline'
    }
})
