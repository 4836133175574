import styled from "@emotion/styled";
import { Typography } from "@mui/material";

export const Label = styled(Typography)({
    display: 'inline-block',
    fontSize: '0.75rem',
    lineHeight: 1.5,
    fontFamily: 'Menlo, Consolas, "Droid Sans Mono", monospace',
    padding: '1px 4px',
    borderRadius: '6px',
    border: '1px solid',
    borderColor: 'hsla(210, 100%, 90%, 0.8)',
    backgroundColor: 'hsl(210, 100%, 96%)', // Fixed background color value
    whiteSpace: 'nowrap'
});
