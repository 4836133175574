import { useState } from "react";
import { Order, TCol, TRow, TRowProps } from "../Types";

export const useSorting = (data: TRowProps[]) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof TRow>('name');

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof TRow
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function descendingComparator(a: TRow, b: TRow, orderBy: keyof TRow) {
    const getColumnText = (row: TRow, columnId: string): string => {
      const column = row[columnId];
      if(!column) return '';
      if (Array.isArray(column)) {
        // If the column is an array of TCol, return the text of the first item
        return column[0].text;
      } else {
        // If the column is a single TCol, return its text
        return column.text;
      }
    };
  
    // Get the text value of the column for comparison
    const aText = getColumnText(a, orderBy);
    const bText = getColumnText(b, orderBy);
  
    // Perform comparison based on the text values using localeCompare
    return bText.localeCompare(aText);
  }
  
  

  function getComparator(
    order: Order,
    orderBy: keyof TRow
  ): (a: TRow, b: TRow) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array: readonly TRowProps[], comparator: (a: TRow, b: TRow) => number): TRowProps[] {
    const stabilizedThis = array.map((el, index) => [el, index] as [TRowProps, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0].data, b[0].data);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  
  const sortedArray = stableSort(data, getComparator(order, orderBy));

  return {
    order,
    orderBy,
    handleRequestSort,
    sortedArray,
  };
};
