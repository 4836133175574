import { Project } from "../../Client/typescript-axios-client-generated";
import { TProject } from "../../Context/VersionControlContext";
import { TRowProps } from "../../Packages/Table/Types";

export const convertToProject = (project: Project) : TProject => {
    return {
        name: project.name,
        id: project.id,
        description: project.description ?? ''
    }
}

export const convertToProjectArray = (projects: Project[]) : TProject[] => {
    return projects.map(project => convertToProject(project));
}

export const convertProjectToTRowProps = (project: TProject): TRowProps => {
    const row: TRowProps = {
      row_id: project.id,
      data: {
        name: {
          column_id: 'name',
          text: project.name,
        },
        description: {
          column_id: 'description',
          text: project.description ? project.description : '',
        }
      }
    };
    return row;
  }

  export const convertProjectToTRowPropsArray = (projects: TProject[]): TRowProps[] => {
    return projects.map(project => convertProjectToTRowProps(project))
  }