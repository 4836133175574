import { useEffect, useState } from "react";
import { FlexColumnBox, FlexRowBox } from "../../../../../../../Styles";
import { LabelTypography } from "../../../../../../../Styles/CustomTypography";
import { CustomDropDown } from "../../../../../../../Components/Forms/DropDown";
import { BlueButton, GreyButton } from "../../../../../../../Styles/Button";
import { CircularProgress } from "@mui/material";

export enum RequestReviewType {
  USER,
  GROUP
}

type RequestReviewProps = {
  type: RequestReviewType,
  closeHandler: () => void
}

export const RequestReview = ({
  type,
  closeHandler
}: RequestReviewProps) => {
  const [users, setUsers] = useState<string[]>([]); //Change this to array of object users
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = () => {
    // make request to backend to add reviewers
  }

  useEffect(() => {
    //Fetch list of users and set to users state
    
  });

  return (
    <FlexColumnBox sx={{ padding: "1rem", paddingBottom: 0 }}>
      <LabelTypography sx={{marginBottom: '16px'}} >Users</LabelTypography>
      <CustomDropDown
        data={[]}
        multiple
        selectedItems={[]}
        updateSelectedItems={() => {}}
      />
      <FlexRowBox
        sx={{
          justifyContent: "flex-end",
          gap: "0.5rem",
          padding: "1rem",
          paddingRight: 0,
          paddingBottom: 0,
        }}
      >
        <GreyButton variant="outlined">Close</GreyButton>
        <BlueButton
          variant="contained"
          color="primary"
          disabled={loading}
          startIcon={loading && <CircularProgress size={20} color="inherit" />}
          onClick={() => {}}
        >
          Make Request
        </BlueButton>
      </FlexRowBox>
    </FlexColumnBox>
  );
};
