/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AddToUserGroupResponse
 */
export interface AddToUserGroupResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AddToUserGroupResponse
     */
    'result': boolean;
}
/**
 * 
 * @export
 * @interface AddToUserGroups
 */
export interface AddToUserGroups {
    /**
     * 
     * @type {Array<string>}
     * @memberof AddToUserGroups
     */
    'user_groups': Array<string>;
}
/**
 * 
 * @export
 * @interface AssignRoleSchema
 */
export interface AssignRoleSchema {
    /**
     * 
     * @type {Array<string>}
     * @memberof AssignRoleSchema
     */
    'roles': Array<string>;
}
/**
 * 
 * @export
 * @interface AssignRoleSchemaResponse
 */
export interface AssignRoleSchemaResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AssignRoleSchemaResponse
     */
    'result': boolean;
}
/**
 * 
 * @export
 * @interface AssignRoleUserGroup
 */
export interface AssignRoleUserGroup {
    /**
     * 
     * @type {Array<string>}
     * @memberof AssignRoleUserGroup
     */
    'user_groups': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssignRoleUserGroup
     */
    'roles': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AssignRoleUserGroup
     */
    'edit': boolean;
}
/**
 * 
 * @export
 * @interface AssignRoleUserGroupResponse
 */
export interface AssignRoleUserGroupResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AssignRoleUserGroupResponse
     */
    'result': boolean;
}
/**
 * 
 * @export
 * @interface CreatePermissionByJsonSchema
 */
export interface CreatePermissionByJsonSchema {
    /**
     * 
     * @type {object}
     * @memberof CreatePermissionByJsonSchema
     */
    'json'?: object;
}
/**
 * 
 * @export
 * @interface GivePermissionSchema
 */
export interface GivePermissionSchema {
    /**
     * 
     * @type {string}
     * @memberof GivePermissionSchema
     */
    'role_id': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GivePermissionSchema
     */
    'permissions_id': Array<string>;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface ListRolesGetSchema
 */
export interface ListRolesGetSchema {
    /**
     * 
     * @type {string}
     * @memberof ListRolesGetSchema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ListRolesGetSchema
     */
    'icon_url': string;
    /**
     * 
     * @type {string}
     * @memberof ListRolesGetSchema
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ListRolesGetSchema
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ListRolesGetSchema
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof ListRolesGetSchema
     */
    'created_by'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListRolesGetSchema
     */
    'updated_by'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ListRolesGetSchema
     */
    'user_group_count'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListRolesGetSchema
     */
    'user_count'?: number | null;
}
/**
 * 
 * @export
 * @interface PermissionCreateSchema
 */
export interface PermissionCreateSchema {
    /**
     * 
     * @type {string}
     * @memberof PermissionCreateSchema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PermissionCreateSchema
     */
    'parent_id': string | null;
}
/**
 * 
 * @export
 * @interface PermissionListGetSchema
 */
export interface PermissionListGetSchema {
    /**
     * 
     * @type {string}
     * @memberof PermissionListGetSchema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PermissionListGetSchema
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PermissionListGetSchema
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof PermissionListGetSchema
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof PermissionListGetSchema
     */
    'created_by'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PermissionListGetSchema
     */
    'updated_by'?: string | null;
}
/**
 * 
 * @export
 * @interface RemoveUserFromGroup
 */
export interface RemoveUserFromGroup {
    /**
     * 
     * @type {string}
     * @memberof RemoveUserFromGroup
     */
    'user_id': string;
    /**
     * 
     * @type {string}
     * @memberof RemoveUserFromGroup
     */
    'group_id': string;
}
/**
 * 
 * @export
 * @interface RoleCreateSchema
 */
export interface RoleCreateSchema {
    /**
     * 
     * @type {string}
     * @memberof RoleCreateSchema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RoleCreateSchema
     */
    'icon_url': string;
}
/**
 * 
 * @export
 * @interface RoleGetSchema
 */
export interface RoleGetSchema {
    /**
     * 
     * @type {string}
     * @memberof RoleGetSchema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RoleGetSchema
     */
    'icon_url': string;
    /**
     * 
     * @type {string}
     * @memberof RoleGetSchema
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RoleGetSchema
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof RoleGetSchema
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof RoleGetSchema
     */
    'created_by'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleGetSchema
     */
    'updated_by'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RoleGetSchema
     */
    'user_group_count'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RoleGetSchema
     */
    'user_count'?: number | null;
    /**
     * 
     * @type {Array<PermissionListGetSchema>}
     * @memberof RoleGetSchema
     */
    'role_permissions': Array<PermissionListGetSchema>;
}
/**
 * 
 * @export
 * @interface UserCreateSchema
 */
export interface UserCreateSchema {
    /**
     * 
     * @type {string}
     * @memberof UserCreateSchema
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateSchema
     */
    'email': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserCreateSchema
     */
    'user_groups': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserCreateSchema
     */
    'roles': Array<string>;
}
/**
 * 
 * @export
 * @interface UserGetSchema
 */
export interface UserGetSchema {
    /**
     * 
     * @type {string}
     * @memberof UserGetSchema
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserGetSchema
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserGetSchema
     */
    'id': string;
    /**
     * 
     * @type {UserStatus}
     * @memberof UserGetSchema
     */
    'user_status': UserStatus;
    /**
     * 
     * @type {string}
     * @memberof UserGetSchema
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof UserGetSchema
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof UserGetSchema
     */
    'created_by'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserGetSchema
     */
    'updated_by'?: string | null;
    /**
     * 
     * @type {Array<ListRolesGetSchema>}
     * @memberof UserGetSchema
     */
    'roles': Array<ListRolesGetSchema>;
    /**
     * 
     * @type {Array<UserGroupGetSchema>}
     * @memberof UserGetSchema
     */
    'user_groups': Array<UserGroupGetSchema>;
}


/**
 * 
 * @export
 * @interface UserGroupCreateSchema
 */
export interface UserGroupCreateSchema {
    /**
     * 
     * @type {string}
     * @memberof UserGroupCreateSchema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserGroupCreateSchema
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserGroupCreateSchema
     */
    'users': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserGroupCreateSchema
     */
    'roles': Array<string>;
}
/**
 * 
 * @export
 * @interface UserGroupGetSchema
 */
export interface UserGroupGetSchema {
    /**
     * 
     * @type {string}
     * @memberof UserGroupGetSchema
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserGroupGetSchema
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserGroupGetSchema
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserGroupGetSchema
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof UserGroupGetSchema
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof UserGroupGetSchema
     */
    'created_by'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserGroupGetSchema
     */
    'updated_by'?: string | null;
    /**
     * 
     * @type {Array<UserListGetSchema>}
     * @memberof UserGroupGetSchema
     */
    'users': Array<UserListGetSchema>;
    /**
     * 
     * @type {Array<ListRolesGetSchema>}
     * @memberof UserGroupGetSchema
     */
    'roles': Array<ListRolesGetSchema>;
}
/**
 * 
 * @export
 * @interface UserListGetSchema
 */
export interface UserListGetSchema {
    /**
     * 
     * @type {string}
     * @memberof UserListGetSchema
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListGetSchema
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserListGetSchema
     */
    'id': string;
    /**
     * 
     * @type {UserStatus}
     * @memberof UserListGetSchema
     */
    'user_status': UserStatus;
    /**
     * 
     * @type {string}
     * @memberof UserListGetSchema
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof UserListGetSchema
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof UserListGetSchema
     */
    'created_by'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListGetSchema
     */
    'updated_by'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const UserStatus = {
    Pending: 'PENDING',
    Verified: 'VERIFIED',
    Rejected: 'REJECTED',
    Suspended: 'SUSPENDED',
    Locked: 'LOCKED',
    Deactivated: 'DEACTIVATED'
} as const;

export type UserStatus = typeof UserStatus[keyof typeof UserStatus];


/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}

/**
 * PermissionsApi - axios parameter creator
 * @export
 */
export const PermissionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Permission Using Json
         * @param {CreatePermissionByJsonSchema} createPermissionByJsonSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPermissionUsingJsonPermissionsCreatePermissionFromJsonPost: async (createPermissionByJsonSchema: CreatePermissionByJsonSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPermissionByJsonSchema' is not null or undefined
            assertParamExists('createPermissionUsingJsonPermissionsCreatePermissionFromJsonPost', 'createPermissionByJsonSchema', createPermissionByJsonSchema)
            const localVarPath = `/permissions/create-permission-from-json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPermissionByJsonSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Permissions
         * @param {PermissionCreateSchema} permissionCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPermissionsPermissionsPost: async (permissionCreateSchema: PermissionCreateSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'permissionCreateSchema' is not null or undefined
            assertParamExists('createPermissionsPermissionsPost', 'permissionCreateSchema', permissionCreateSchema)
            const localVarPath = `/permissions/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(permissionCreateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Full Permission Tree
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFullPermissionTreePermissionsGetFullPermissionTreeGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/permissions/get-full-permission-tree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Permission
         * @param {string} permissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissionPermissionsPermissionIdDelete: async (permissionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'permissionId' is not null or undefined
            assertParamExists('getPermissionPermissionsPermissionIdDelete', 'permissionId', permissionId)
            const localVarPath = `/permissions/{permission_id}`
                .replace(`{${"permission_id"}}`, encodeURIComponent(String(permissionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Permission
         * @param {string} permissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissionPermissionsPermissionIdGet: async (permissionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'permissionId' is not null or undefined
            assertParamExists('getPermissionPermissionsPermissionIdGet', 'permissionId', permissionId)
            const localVarPath = `/permissions/{permission_id}`
                .replace(`{${"permission_id"}}`, encodeURIComponent(String(permissionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PermissionsApi - functional programming interface
 * @export
 */
export const PermissionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PermissionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Permission Using Json
         * @param {CreatePermissionByJsonSchema} createPermissionByJsonSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPermissionUsingJsonPermissionsCreatePermissionFromJsonPost(createPermissionByJsonSchema: CreatePermissionByJsonSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPermissionUsingJsonPermissionsCreatePermissionFromJsonPost(createPermissionByJsonSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PermissionsApi.createPermissionUsingJsonPermissionsCreatePermissionFromJsonPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Permissions
         * @param {PermissionCreateSchema} permissionCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPermissionsPermissionsPost(permissionCreateSchema: PermissionCreateSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPermissionsPermissionsPost(permissionCreateSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PermissionsApi.createPermissionsPermissionsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Full Permission Tree
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFullPermissionTreePermissionsGetFullPermissionTreeGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFullPermissionTreePermissionsGetFullPermissionTreeGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PermissionsApi.getFullPermissionTreePermissionsGetFullPermissionTreeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Permission
         * @param {string} permissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPermissionPermissionsPermissionIdDelete(permissionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPermissionPermissionsPermissionIdDelete(permissionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PermissionsApi.getPermissionPermissionsPermissionIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Permission
         * @param {string} permissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPermissionPermissionsPermissionIdGet(permissionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPermissionPermissionsPermissionIdGet(permissionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PermissionsApi.getPermissionPermissionsPermissionIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PermissionsApi - factory interface
 * @export
 */
export const PermissionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PermissionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Permission Using Json
         * @param {CreatePermissionByJsonSchema} createPermissionByJsonSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPermissionUsingJsonPermissionsCreatePermissionFromJsonPost(createPermissionByJsonSchema: CreatePermissionByJsonSchema, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.createPermissionUsingJsonPermissionsCreatePermissionFromJsonPost(createPermissionByJsonSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Permissions
         * @param {PermissionCreateSchema} permissionCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPermissionsPermissionsPost(permissionCreateSchema: PermissionCreateSchema, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.createPermissionsPermissionsPost(permissionCreateSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Full Permission Tree
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFullPermissionTreePermissionsGetFullPermissionTreeGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getFullPermissionTreePermissionsGetFullPermissionTreeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Permission
         * @param {string} permissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissionPermissionsPermissionIdDelete(permissionId: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getPermissionPermissionsPermissionIdDelete(permissionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Permission
         * @param {string} permissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissionPermissionsPermissionIdGet(permissionId: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getPermissionPermissionsPermissionIdGet(permissionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PermissionsApi - object-oriented interface
 * @export
 * @class PermissionsApi
 * @extends {BaseAPI}
 */
export class PermissionsApi extends BaseAPI {
    /**
     * 
     * @summary Create Permission Using Json
     * @param {CreatePermissionByJsonSchema} createPermissionByJsonSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsApi
     */
    public createPermissionUsingJsonPermissionsCreatePermissionFromJsonPost(createPermissionByJsonSchema: CreatePermissionByJsonSchema, options?: RawAxiosRequestConfig) {
        return PermissionsApiFp(this.configuration).createPermissionUsingJsonPermissionsCreatePermissionFromJsonPost(createPermissionByJsonSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Permissions
     * @param {PermissionCreateSchema} permissionCreateSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsApi
     */
    public createPermissionsPermissionsPost(permissionCreateSchema: PermissionCreateSchema, options?: RawAxiosRequestConfig) {
        return PermissionsApiFp(this.configuration).createPermissionsPermissionsPost(permissionCreateSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Full Permission Tree
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsApi
     */
    public getFullPermissionTreePermissionsGetFullPermissionTreeGet(options?: RawAxiosRequestConfig) {
        return PermissionsApiFp(this.configuration).getFullPermissionTreePermissionsGetFullPermissionTreeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Permission
     * @param {string} permissionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsApi
     */
    public getPermissionPermissionsPermissionIdDelete(permissionId: string, options?: RawAxiosRequestConfig) {
        return PermissionsApiFp(this.configuration).getPermissionPermissionsPermissionIdDelete(permissionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Permission
     * @param {string} permissionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsApi
     */
    public getPermissionPermissionsPermissionIdGet(permissionId: string, options?: RawAxiosRequestConfig) {
        return PermissionsApiFp(this.configuration).getPermissionPermissionsPermissionIdGet(permissionId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RolesApi - axios parameter creator
 * @export
 */
export const RolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Role
         * @param {RoleCreateSchema} roleCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoleRolesPost: async (roleCreateSchema: RoleCreateSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleCreateSchema' is not null or undefined
            assertParamExists('createRoleRolesPost', 'roleCreateSchema', roleCreateSchema)
            const localVarPath = `/roles/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roleCreateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Role
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoleRolesRoleIdDelete: async (roleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('deleteRoleRolesRoleIdDelete', 'roleId', roleId)
            const localVarPath = `/roles/{role_id}`
                .replace(`{${"role_id"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Role
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleRolesGetRoleByIdRoleIdGet: async (roleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('getRoleRolesGetRoleByIdRoleIdGet', 'roleId', roleId)
            const localVarPath = `/roles/get-role-by-id/{role_id}`
                .replace(`{${"role_id"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Roles List
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolesListRolesGetRolesListGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roles/get-roles-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Give Permission To Role
         * @param {GivePermissionSchema} givePermissionSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        givePermissionToRoleRolesGivePermissionsToRolePost: async (givePermissionSchema: GivePermissionSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'givePermissionSchema' is not null or undefined
            assertParamExists('givePermissionToRoleRolesGivePermissionsToRolePost', 'givePermissionSchema', givePermissionSchema)
            const localVarPath = `/roles/give-permissions-to-role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(givePermissionSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesApi - functional programming interface
 * @export
 */
export const RolesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RolesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Role
         * @param {RoleCreateSchema} roleCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRoleRolesPost(roleCreateSchema: RoleCreateSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRoleRolesPost(roleCreateSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.createRoleRolesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Role
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRoleRolesRoleIdDelete(roleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRoleRolesRoleIdDelete(roleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.deleteRoleRolesRoleIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Role
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoleRolesGetRoleByIdRoleIdGet(roleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleGetSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoleRolesGetRoleByIdRoleIdGet(roleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.getRoleRolesGetRoleByIdRoleIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Roles List
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRolesListRolesGetRolesListGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleGetSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRolesListRolesGetRolesListGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.getRolesListRolesGetRolesListGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Give Permission To Role
         * @param {GivePermissionSchema} givePermissionSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async givePermissionToRoleRolesGivePermissionsToRolePost(givePermissionSchema: GivePermissionSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.givePermissionToRoleRolesGivePermissionsToRolePost(givePermissionSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolesApi.givePermissionToRoleRolesGivePermissionsToRolePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RolesApi - factory interface
 * @export
 */
export const RolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RolesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Role
         * @param {RoleCreateSchema} roleCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoleRolesPost(roleCreateSchema: RoleCreateSchema, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.createRoleRolesPost(roleCreateSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Role
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoleRolesRoleIdDelete(roleId: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.deleteRoleRolesRoleIdDelete(roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Role
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleRolesGetRoleByIdRoleIdGet(roleId: string, options?: RawAxiosRequestConfig): AxiosPromise<RoleGetSchema> {
            return localVarFp.getRoleRolesGetRoleByIdRoleIdGet(roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Roles List
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolesListRolesGetRolesListGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<RoleGetSchema>> {
            return localVarFp.getRolesListRolesGetRolesListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Give Permission To Role
         * @param {GivePermissionSchema} givePermissionSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        givePermissionToRoleRolesGivePermissionsToRolePost(givePermissionSchema: GivePermissionSchema, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.givePermissionToRoleRolesGivePermissionsToRolePost(givePermissionSchema, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RolesApi - object-oriented interface
 * @export
 * @class RolesApi
 * @extends {BaseAPI}
 */
export class RolesApi extends BaseAPI {
    /**
     * 
     * @summary Create Role
     * @param {RoleCreateSchema} roleCreateSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public createRoleRolesPost(roleCreateSchema: RoleCreateSchema, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).createRoleRolesPost(roleCreateSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Role
     * @param {string} roleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public deleteRoleRolesRoleIdDelete(roleId: string, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).deleteRoleRolesRoleIdDelete(roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Role
     * @param {string} roleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public getRoleRolesGetRoleByIdRoleIdGet(roleId: string, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).getRoleRolesGetRoleByIdRoleIdGet(roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Roles List
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public getRolesListRolesGetRolesListGet(options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).getRolesListRolesGetRolesListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Give Permission To Role
     * @param {GivePermissionSchema} givePermissionSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public givePermissionToRoleRolesGivePermissionsToRolePost(givePermissionSchema: GivePermissionSchema, options?: RawAxiosRequestConfig) {
        return RolesApiFp(this.configuration).givePermissionToRoleRolesGivePermissionsToRolePost(givePermissionSchema, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserGroupApi - axios parameter creator
 * @export
 */
export const UserGroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Assign Role
         * @param {AssignRoleUserGroup} assignRoleUserGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignRoleUserGroupsAssignRolePost: async (assignRoleUserGroup: AssignRoleUserGroup, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignRoleUserGroup' is not null or undefined
            assertParamExists('assignRoleUserGroupsAssignRolePost', 'assignRoleUserGroup', assignRoleUserGroup)
            const localVarPath = `/user-groups/assign-role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignRoleUserGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create User Group
         * @param {UserGroupCreateSchema} userGroupCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserGroupUserGroupsPost: async (userGroupCreateSchema: UserGroupCreateSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGroupCreateSchema' is not null or undefined
            assertParamExists('createUserGroupUserGroupsPost', 'userGroupCreateSchema', userGroupCreateSchema)
            const localVarPath = `/user-groups/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userGroupCreateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User Group
         * @param {number} [pageNumber] Page number, default is 1
         * @param {number} [pageSize] Number of items per page, default is 10
         * @param {string | null} [query] Search query string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGroupUserGroupsGet: async (pageNumber?: number, pageSize?: number, query?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user-groups/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNumber !== undefined) {
                localVarQueryParameter['page_number'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Users
         * @param {string} userGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersUserGroupsUserGroupIdGet: async (userGroupId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGroupId' is not null or undefined
            assertParamExists('getUsersUserGroupsUserGroupIdGet', 'userGroupId', userGroupId)
            const localVarPath = `/user-groups/{user_group_id}`
                .replace(`{${"user_group_id"}}`, encodeURIComponent(String(userGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserGroupApi - functional programming interface
 * @export
 */
export const UserGroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserGroupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Assign Role
         * @param {AssignRoleUserGroup} assignRoleUserGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignRoleUserGroupsAssignRolePost(assignRoleUserGroup: AssignRoleUserGroup, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignRoleUserGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignRoleUserGroupsAssignRolePost(assignRoleUserGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserGroupApi.assignRoleUserGroupsAssignRolePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create User Group
         * @param {UserGroupCreateSchema} userGroupCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserGroupUserGroupsPost(userGroupCreateSchema: UserGroupCreateSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserGroupUserGroupsPost(userGroupCreateSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserGroupApi.createUserGroupUserGroupsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get User Group
         * @param {number} [pageNumber] Page number, default is 1
         * @param {number} [pageSize] Number of items per page, default is 10
         * @param {string | null} [query] Search query string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserGroupUserGroupsGet(pageNumber?: number, pageSize?: number, query?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserGroupGetSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserGroupUserGroupsGet(pageNumber, pageSize, query, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserGroupApi.getUserGroupUserGroupsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Users
         * @param {string} userGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersUserGroupsUserGroupIdGet(userGroupId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroupGetSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersUserGroupsUserGroupIdGet(userGroupId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserGroupApi.getUsersUserGroupsUserGroupIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserGroupApi - factory interface
 * @export
 */
export const UserGroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserGroupApiFp(configuration)
    return {
        /**
         * 
         * @summary Assign Role
         * @param {AssignRoleUserGroup} assignRoleUserGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignRoleUserGroupsAssignRolePost(assignRoleUserGroup: AssignRoleUserGroup, options?: RawAxiosRequestConfig): AxiosPromise<AssignRoleUserGroupResponse> {
            return localVarFp.assignRoleUserGroupsAssignRolePost(assignRoleUserGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create User Group
         * @param {UserGroupCreateSchema} userGroupCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserGroupUserGroupsPost(userGroupCreateSchema: UserGroupCreateSchema, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.createUserGroupUserGroupsPost(userGroupCreateSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User Group
         * @param {number} [pageNumber] Page number, default is 1
         * @param {number} [pageSize] Number of items per page, default is 10
         * @param {string | null} [query] Search query string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGroupUserGroupsGet(pageNumber?: number, pageSize?: number, query?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<Array<UserGroupGetSchema>> {
            return localVarFp.getUserGroupUserGroupsGet(pageNumber, pageSize, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Users
         * @param {string} userGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersUserGroupsUserGroupIdGet(userGroupId: string, options?: RawAxiosRequestConfig): AxiosPromise<UserGroupGetSchema> {
            return localVarFp.getUsersUserGroupsUserGroupIdGet(userGroupId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserGroupApi - object-oriented interface
 * @export
 * @class UserGroupApi
 * @extends {BaseAPI}
 */
export class UserGroupApi extends BaseAPI {
    /**
     * 
     * @summary Assign Role
     * @param {AssignRoleUserGroup} assignRoleUserGroup 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApi
     */
    public assignRoleUserGroupsAssignRolePost(assignRoleUserGroup: AssignRoleUserGroup, options?: RawAxiosRequestConfig) {
        return UserGroupApiFp(this.configuration).assignRoleUserGroupsAssignRolePost(assignRoleUserGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create User Group
     * @param {UserGroupCreateSchema} userGroupCreateSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApi
     */
    public createUserGroupUserGroupsPost(userGroupCreateSchema: UserGroupCreateSchema, options?: RawAxiosRequestConfig) {
        return UserGroupApiFp(this.configuration).createUserGroupUserGroupsPost(userGroupCreateSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User Group
     * @param {number} [pageNumber] Page number, default is 1
     * @param {number} [pageSize] Number of items per page, default is 10
     * @param {string | null} [query] Search query string
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApi
     */
    public getUserGroupUserGroupsGet(pageNumber?: number, pageSize?: number, query?: string | null, options?: RawAxiosRequestConfig) {
        return UserGroupApiFp(this.configuration).getUserGroupUserGroupsGet(pageNumber, pageSize, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Users
     * @param {string} userGroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApi
     */
    public getUsersUserGroupsUserGroupIdGet(userGroupId: string, options?: RawAxiosRequestConfig) {
        return UserGroupApiFp(this.configuration).getUsersUserGroupsUserGroupIdGet(userGroupId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add To User Groups
         * @param {string} userId 
         * @param {AddToUserGroups} addToUserGroups 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToUserGroupsUsersUserIdPost: async (userId: string, addToUserGroups: AddToUserGroups, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('addToUserGroupsUsersUserIdPost', 'userId', userId)
            // verify required parameter 'addToUserGroups' is not null or undefined
            assertParamExists('addToUserGroupsUsersUserIdPost', 'addToUserGroups', addToUserGroups)
            const localVarPath = `/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addToUserGroups, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Assign Roles
         * @param {string} userId 
         * @param {AssignRoleSchema} assignRoleSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignRolesUsersAssignRolesUserIdPost: async (userId: string, assignRoleSchema: AssignRoleSchema, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('assignRolesUsersAssignRolesUserIdPost', 'userId', userId)
            // verify required parameter 'assignRoleSchema' is not null or undefined
            assertParamExists('assignRolesUsersAssignRolesUserIdPost', 'assignRoleSchema', assignRoleSchema)
            const localVarPath = `/users/assign-roles/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignRoleSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Users
         * @param {Array<UserCreateSchema>} userCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUsersUsersPost: async (userCreateSchema: Array<UserCreateSchema>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreateSchema' is not null or undefined
            assertParamExists('createUsersUsersPost', 'userCreateSchema', userCreateSchema)
            const localVarPath = `/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserUsersUserIdDelete: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUserUsersUserIdDelete', 'userId', userId)
            const localVarPath = `/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Permissions List
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPermissionsListUsersGetAllPermissionsListUserIdGet: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAllPermissionsListUsersGetAllPermissionsListUserIdGet', 'userId', userId)
            const localVarPath = `/users/get-all-permissions-list/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User By Id
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserByIdUsersUserIdGet: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserByIdUsersUserIdGet', 'userId', userId)
            const localVarPath = `/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersUsersGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove User From Group
         * @param {RemoveUserFromGroup} removeUserFromGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromGroupUsersRemoveUserFromGroupDelete: async (removeUserFromGroup: RemoveUserFromGroup, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'removeUserFromGroup' is not null or undefined
            assertParamExists('removeUserFromGroupUsersRemoveUserFromGroupDelete', 'removeUserFromGroup', removeUserFromGroup)
            const localVarPath = `/users/remove-user-from-group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeUserFromGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add To User Groups
         * @param {string} userId 
         * @param {AddToUserGroups} addToUserGroups 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addToUserGroupsUsersUserIdPost(userId: string, addToUserGroups: AddToUserGroups, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddToUserGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addToUserGroupsUsersUserIdPost(userId, addToUserGroups, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.addToUserGroupsUsersUserIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Assign Roles
         * @param {string} userId 
         * @param {AssignRoleSchema} assignRoleSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignRolesUsersAssignRolesUserIdPost(userId: string, assignRoleSchema: AssignRoleSchema, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignRoleSchemaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignRolesUsersAssignRolesUserIdPost(userId, assignRoleSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.assignRolesUsersAssignRolesUserIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Users
         * @param {Array<UserCreateSchema>} userCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUsersUsersPost(userCreateSchema: Array<UserCreateSchema>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUsersUsersPost(userCreateSchema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.createUsersUsersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserUsersUserIdDelete(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserUsersUserIdDelete(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.deleteUserUsersUserIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get All Permissions List
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPermissionsListUsersGetAllPermissionsListUserIdGet(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPermissionsListUsersGetAllPermissionsListUserIdGet(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getAllPermissionsListUsersGetAllPermissionsListUserIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get User By Id
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserByIdUsersUserIdGet(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGetSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserByIdUsersUserIdGet(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getUserByIdUsersUserIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersUsersGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserGetSchema>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersUsersGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getUsersUsersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Remove User From Group
         * @param {RemoveUserFromGroup} removeUserFromGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeUserFromGroupUsersRemoveUserFromGroupDelete(removeUserFromGroup: RemoveUserFromGroup, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeUserFromGroupUsersRemoveUserFromGroupDelete(removeUserFromGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.removeUserFromGroupUsersRemoveUserFromGroupDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Add To User Groups
         * @param {string} userId 
         * @param {AddToUserGroups} addToUserGroups 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToUserGroupsUsersUserIdPost(userId: string, addToUserGroups: AddToUserGroups, options?: RawAxiosRequestConfig): AxiosPromise<AddToUserGroupResponse> {
            return localVarFp.addToUserGroupsUsersUserIdPost(userId, addToUserGroups, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Assign Roles
         * @param {string} userId 
         * @param {AssignRoleSchema} assignRoleSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignRolesUsersAssignRolesUserIdPost(userId: string, assignRoleSchema: AssignRoleSchema, options?: RawAxiosRequestConfig): AxiosPromise<AssignRoleSchemaResponse> {
            return localVarFp.assignRolesUsersAssignRolesUserIdPost(userId, assignRoleSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Users
         * @param {Array<UserCreateSchema>} userCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUsersUsersPost(userCreateSchema: Array<UserCreateSchema>, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.createUsersUsersPost(userCreateSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserUsersUserIdDelete(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.deleteUserUsersUserIdDelete(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Permissions List
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPermissionsListUsersGetAllPermissionsListUserIdGet(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getAllPermissionsListUsersGetAllPermissionsListUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User By Id
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserByIdUsersUserIdGet(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<UserGetSchema> {
            return localVarFp.getUserByIdUsersUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersUsersGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<UserGetSchema>> {
            return localVarFp.getUsersUsersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove User From Group
         * @param {RemoveUserFromGroup} removeUserFromGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromGroupUsersRemoveUserFromGroupDelete(removeUserFromGroup: RemoveUserFromGroup, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.removeUserFromGroupUsersRemoveUserFromGroupDelete(removeUserFromGroup, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary Add To User Groups
     * @param {string} userId 
     * @param {AddToUserGroups} addToUserGroups 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public addToUserGroupsUsersUserIdPost(userId: string, addToUserGroups: AddToUserGroups, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).addToUserGroupsUsersUserIdPost(userId, addToUserGroups, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Assign Roles
     * @param {string} userId 
     * @param {AssignRoleSchema} assignRoleSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public assignRolesUsersAssignRolesUserIdPost(userId: string, assignRoleSchema: AssignRoleSchema, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).assignRolesUsersAssignRolesUserIdPost(userId, assignRoleSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Users
     * @param {Array<UserCreateSchema>} userCreateSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUsersUsersPost(userCreateSchema: Array<UserCreateSchema>, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).createUsersUsersPost(userCreateSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete User
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUserUsersUserIdDelete(userId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).deleteUserUsersUserIdDelete(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Permissions List
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getAllPermissionsListUsersGetAllPermissionsListUserIdGet(userId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getAllPermissionsListUsersGetAllPermissionsListUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User By Id
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserByIdUsersUserIdGet(userId: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserByIdUsersUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUsersUsersGet(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUsersUsersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove User From Group
     * @param {RemoveUserFromGroup} removeUserFromGroup 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public removeUserFromGroupUsersRemoveUserFromGroupDelete(removeUserFromGroup: RemoveUserFromGroup, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).removeUserFromGroupUsersRemoveUserFromGroupDelete(removeUserFromGroup, options).then((request) => request(this.axios, this.basePath));
    }
}



