import { Box, CircularProgress } from "@mui/material";
import { Colors } from "../../../../Constants";
import {
  FlexColumnBox,
  FlexRowBox,
  RightFloatTableMenuItemStyle,
} from "../../../../Styles";
import { BlueButton, GreenButton } from "../../../../Styles/Button";
import {
  HeadingTypography,
  LabelTypography,
  MediumSizeDarkColorThinTypography,
  SmallSizeLightColorTypography,
} from "../../../../Styles/CustomTypography";
import ThreeDotsMenu from "../../../../Components/MenuListModal";
import { CreateManualRun } from "./CreateManualRun";
import { GenericMetaInformation } from "../../../../Components/MetaInformation";
import { CustomSwitch } from "../../../../Components/CustomSwitch";
import { KeyValueInput } from "../../../../Components/Forms/KeyValueInput";
import { SingleSelectInput } from "../../../../Components/Forms/SingleSelection";
import { NavLink, useParams } from "react-router-dom";
import { TDestination } from "../CreateSchedule/Type";
import { CreationMode, FeatureGenerationDetailedGetSchema } from "../../../../Client/typescript-axios-client-generated";
import { useEffect, useState } from "react";
import { getFeatureGenerationById } from "../../../../Services/getFeatureGenerationById";
import { useVersionControlContext } from "../../../../Hooks/useVersionControlContext";
import { useToast } from "../../../../Hooks/useToast";
import { ToastType } from "../../../../Context/Providers/ToastProvider";
import { CreationModeOptions } from "../../../../Constants/FormData/FeatureTypes";

export const ViewFeatureGenerationSchedule = () => {
  const [schedule, setSchedule] = useState<
    FeatureGenerationDetailedGetSchema | undefined
  >(undefined);
  const [openManualRunModal, setOpenRunManualRunModal] = useState<boolean>(false);

  const closeHandler = () => {
    setOpenRunManualRunModal(false);
  }

  const { scheduleId } = useParams();
  const versionControlContext = useVersionControlContext();
  const toast = useToast();

  useEffect(() => {
    if (
      !scheduleId ||
      !versionControlContext?.currentProject ||
      !versionControlContext.currentBranch
    ) {
      return;
    }
    getFeatureGenerationById(
      scheduleId,
      versionControlContext.currentProjectName,
      versionControlContext.currentBranchName
    )
      .then((data) => {
        setSchedule(data);
      })
      .catch((err) => {
        toast.open("Failed to fetch data.", ToastType.ERROR);
      });
  }, [scheduleId, versionControlContext]);

  const selectedDestination: TDestination[] = [];
  if (!schedule) {
    return <></>;
  }
  return (
    <FlexColumnBox sx={{ flexGrow: 1 }}>
      { openManualRunModal && <CreateManualRun open={openManualRunModal} closeHandler={closeHandler} />}
      <FlexRowBox
        sx={{
          justifyContent: "space-between",
          borderBottom: `1px solid ${Colors.SIDE_LINK_HOVER_COLOR}`,
          padding: "1rem",
        }}
      >
        <HeadingTypography>{"account_details"}</HeadingTypography>
        <FlexRowBox sx={{ gap: "1rem" }}>
          <GreenButton
            disabled={false}
            startIcon={false ? <CircularProgress size={20} /> : ""}
            onClick={() => {}}
          >
            Add to Project
          </GreenButton>
          <BlueButton>Edit Schedule</BlueButton>
          <ThreeDotsMenu
            minWidth="175px"
            options={[
              <RightFloatTableMenuItemStyle
                showBorderBottom={false}
                onClick={() => {setOpenRunManualRunModal(true)}}
              >
                Create Manual Run
              </RightFloatTableMenuItemStyle>,
              <RightFloatTableMenuItemStyle
                showBorderBottom={true}
                onClick={() => {}}
              >
                Duplicate
              </RightFloatTableMenuItemStyle>,
              <RightFloatTableMenuItemStyle
                showBorderBottom={false}
                color={Colors.APP_RED_COLOR}
                onClick={() => {}}
              >
                Delete
              </RightFloatTableMenuItemStyle>,
            ]}
          />
        </FlexRowBox>
      </FlexRowBox>
      <FlexRowBox sx={{ padding: "2rem", gap: "1rem" }}>
        <FlexColumnBox sx={{ flexGrow: "1", width: "90%", gap: "1.5rem" }}>
          <Box>
            <LabelTypography>Name</LabelTypography>
            <MediumSizeDarkColorThinTypography>
              {schedule.name}
            </MediumSizeDarkColorThinTypography>
          </Box>
          <Box>
            <LabelTypography>Description</LabelTypography>
            <MediumSizeDarkColorThinTypography>
              {schedule.description}
            </MediumSizeDarkColorThinTypography>
          </Box>
          <Box>
            <LabelTypography>Enabled</LabelTypography>
            <CustomSwitch checked={schedule?.enabled} />
          </Box>
          <Box>
            <LabelTypography>Cluster</LabelTypography>
            <a href="">development</a>
          </Box>
          <Box>
            <LabelTypography>Additional Spark Property</LabelTypography>
            <SmallSizeLightColorTypography>
              Allow additional spark properties when running this job (it's
              usually better to use a Cluster Property Set).
            </SmallSizeLightColorTypography>
            <CustomSwitch
              checked={
                schedule.additional_spark_properties
                  ? schedule.additional_spark_properties.length > 0
                  : false
              }
            />
            {schedule.additional_spark_properties
              ? schedule.additional_spark_properties.length > 0
              : false && (
                  <KeyValueInput
                    pairs={schedule?.additional_spark_properties?.map((property) => {
                      return {
                        key: property.key ?? '',
                        value: property.value ?? ''
                      }
                    }) ?? []}
                    updatePair={() => {}}
                  />
                )}
          </Box>
          <Box>
            <SingleSelectInput
              label={"Standard or Stream"}
              data={schedule.creation_mode === CreationMode.Standard ? [CreationModeOptions[0]] : [CreationModeOptions[1]]}
              onSelect={() => {}}
              selectedOptionId={""}
            />
          </Box>
          <Box>
            <LabelTypography>Destinations</LabelTypography>
            <FlexColumnBox className="destination-view">
              {selectedDestination.map((dest, index) => {
                return (
                  <Box>
                    <Box className="content-view">
                      <NavLink to={""}>{dest.destination}</NavLink>
                      <LabelTypography>Table</LabelTypography>
                      <SmallSizeLightColorTypography>
                        {dest.tableName}
                      </SmallSizeLightColorTypography>
                      <LabelTypography>Mode</LabelTypography>
                      <SmallSizeLightColorTypography>
                        {dest.mode}
                      </SmallSizeLightColorTypography>
                      {dest.allowFieldAddition && (
                        <LabelTypography>Field Addition</LabelTypography>
                      )}
                      {dest.allowFieldRelaxation && (
                        <LabelTypography>Field Relaxation</LabelTypography>
                      )}
                      {dest.partitionOverwriteMode && (
                        <LabelTypography>
                          Partition Overwrite mode
                        </LabelTypography>
                      )}
                    </Box>
                    <FlexRowBox>
                      <Box className="edit-button" onClick={() => {}}>
                        Edit
                      </Box>
                      <Box className="delete-button" onClick={() => {}}>
                        Delete
                      </Box>
                    </FlexRowBox>
                  </Box>
                );
              })}
            </FlexColumnBox>
          </Box>
          <Box>
            <LabelTypography>Branch</LabelTypography>
            <MediumSizeDarkColorThinTypography>
              {schedule.branch}
            </MediumSizeDarkColorThinTypography>
          </Box>
          <Box>
            <LabelTypography>Feature Set</LabelTypography>
            <MediumSizeDarkColorThinTypography>
              {schedule.feature_set}
            </MediumSizeDarkColorThinTypography>
          </Box>
          <Box>
            <LabelTypography>Include MetaData</LabelTypography>
            <SmallSizeLightColorTypography>
              If switched on, we'll include the schedule run id and a valid at
              timestamp information in the result.
            </SmallSizeLightColorTypography>
            <CustomSwitch checked={true} />
          </Box>
        </FlexColumnBox>
        <FlexColumnBox>
          <GenericMetaInformation
            labels={schedule.labels}
            containPii={schedule.contains_pii ?? false}
            containToken={schedule.contains_token ?? false}
            containsUsageData={schedule.contains_usagedata ?? false}
            containsWebToken={schedule.contains_web_token ?? false}
            creator={schedule.creator}
            domain={[]}
            owner={[]}
            domainsData={[]}
            ownerData={[]}
            mergeFileCsv={schedule.merge_files_csv}
            modelDependencies={schedule.model_dependencies}
            scheduleFailureEmailList={schedule.schedule_failure_email_list ?? []}
            updateData={() => {}}
            readonly
          />
        </FlexColumnBox>
      </FlexRowBox>
    </FlexColumnBox>
  );
};
