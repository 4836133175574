import { TTableHeader } from "../../Packages/Table/Types";

export const OrchestratorTableHeader: TTableHeader[] = [
  {
    column_id: "name",
    label: "Name / Key",
    isSortable: "",
    visible: true,
  },
  {
    column_id: "type",
    label: "Type",
    isSortable: "",
    visible: true,
  },
  {
    column_id: "view",
    label: "",
    isSortable: "",
    visible: false,
  },
];
