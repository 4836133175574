import { ChangeRequestCreate } from "../Client/typescript-axios-client-generated";
import { ApiClient } from "../Utils/ApiClient";

export const createChangeRequest = (body: ChangeRequestCreate) => {
  return ApiClient.changeRequestClient
    .createChangeRequestChangeRequestPost(body)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};
