import { InputTextField } from "../../../../../../Components/Forms/InputTextField";
import { SingleSelectInput } from "../../../../../../Components/Forms/SingleSelection";
import { tableTypes } from "../../../../../../TestingData/TableTypes";
import {
  TCreateTableData,
  TTableTabData,
} from "../../../../../../Types/CreateTableForm";
import { Action } from "../../../../../../Reducers/CreateTableReducer";
import { FlexColumnBox } from "../../../../../../Styles";
import React, { Suspense } from "react";
import { Box } from "@mui/material";
import ViewTable from "./ViewTable";
import { JoinTable } from "./JoinTable";
import { EventStoreTable } from "./EventStoreTable";
import RichTextField from "../../../../../../Components/Forms/RichTextInputField";
import { LabelTypography } from "../../../../../../Styles/CustomTypography";

const ExternalTable = React.lazy(() => import("./ExternalTable"));

type TableTabProps = {
  createTableState: TCreateTableData;
  dispatch: React.Dispatch<Action>;
};

export const TableTab = ({ createTableState, dispatch }: TableTabProps) => {
  const data = createTableState.table;
  const updateName = (name: string) => {
    dispatch({
      type: "SET_TABLE_NAME",
      payload: name,
    });
  };

  const updateDescription = (description: string) => {
    dispatch({
      type: "SET_TABLE_DESCRIPTION",
      payload: description,
    });
  };

  const updateTableType = (tableType: string) => {
    dispatch({
      type: "SET_TABLE_TYPE",
      payload: tableType,
    });
  };

  return (
    <FlexColumnBox sx={{ gap: "1rem" }}>
      <InputTextField
        title="Name"
        description="Name must be unique, start with a lower case letter and may only contain underscores, letters and numbers."
        onChange={(event: any) => {
          updateName(event.target.value);
        }}
        value={data.name}
        violationArray={[]}
      />
      <Box>
        <LabelTypography>Description (Optional)</LabelTypography>
        <RichTextField input={data.description} setInput={updateDescription} />
      </Box>
      <SingleSelectInput
        label={"Table Type"}
        data={tableTypes}
        onSelect={(e: string) => {
          updateTableType(e);
        }}
        selectedOptionId={data.tableType}
      />
      {data.tableType === "EXTERNAL_TABLE" && (
        <Suspense fallback={<Box>Loading...</Box>}>
          <ExternalTable disPatch={dispatch} data={data.externalTableData} />
        </Suspense>
      )}
      {data.tableType === "VIEW_TABLE" && (
        <ViewTable
          data={data.viewTableData}
          disPatch={dispatch}
          sourcesTable={createTableState.sourcesTableData}
        />
      )}
      {data.tableType == "JOIN_TABLE" && <JoinTable />}
      {data.tableType == "EVENT_STORE_TABLE" && (
        <EventStoreTable data={data.eventStoreTableData} dispatch={dispatch} />
      )}
    </FlexColumnBox>
  );
};
