import { useEffect, useState } from "react"
import { BaseEntity } from "../../../Client/typescript-axios-client-generated"
import { useVersionControlContext } from "../../useVersionControlContext";
import { getAllBranchEntities } from "../../../Services/getAllEntities";

export const useGetEntities = () => {
    const [entities, setEntities] = useState<BaseEntity[]>([]);
    const versionControlContext = useVersionControlContext();

    const updateEntities = (data: BaseEntity[]) => {
        setEntities(data)
    }

    useEffect(() => {
        if(!versionControlContext?.currentBranch) return;
        getAllBranchEntities(versionControlContext.currentProjectName, versionControlContext.currentBranchName)
          .then((data) => {
            setEntities(data);
          })
          .catch((err) => {
            console.log(err);
          });
      }, [versionControlContext?.currentBranch]);

    return {
        entities,
        updateEntities
    }
}