import { Reducer } from "react";
import {
  TCompositeFeatureValidationError,
  TCreateFeatureData,
  TEventFeatureValidationError,
  TPreviewData,
  TSqlFeatureValidationError,
  TValidationErrors,
  TransformationType,
} from "../Types/CreateFeatureForm";
import { TFilter } from "../Types";

// Define the action types
export type Action =
  | { type: "SET_TRANSFORMATION_TYPE"; payload: TransformationType }
  | { type: "UPDATE_NAME"; payload: string }
  | { type: "UPDATE_DESCRIPTION"; payload: string }
  | {
      type: "UPDATE_EVENT_FEATURE";
      payload: Partial<TCreateFeatureData[TransformationType.EVENT_FEATURE]>;
    }
  | {
      type: "UPDATE_COMPOSITE_FEATURE";
      payload: Partial<
        TCreateFeatureData[TransformationType.COMPOSITE_FEATURE]
      >;
    }
  | {
      type: "UPDATE_SQL_FEATURE";
      payload: Partial<TCreateFeatureData[TransformationType.SQL_FEATURE]>;
    }
  | { type: "UPDATE_VALIDATION_ERRORS"; payload: Partial<TValidationErrors> }
  | {
      type: "UPDATE_EVENT_FEATURE_VALIDATION_ERROR";
      payload: Partial<TEventFeatureValidationError>;
    }
  | {
      type: "UPDATE_COMPOSITE_FEATURE_VALIDATION_ERROR";
      payload: Partial<TCompositeFeatureValidationError>;
    }
  | {
      type: "UPDATE_SQL_FEATURE_VALIDATION_ERROR";
      payload: Partial<TSqlFeatureValidationError>;
    }
  | {
      type: "UPDATE_EVENT_FEATURE_PREVIEW_FILTER";
      payload: Partial<TPreviewData>;
    }
  | {
      type: "UPDATE_COMPOSITE_FEATURE_PREVIEW_FILTER";
      payload: Partial<TPreviewData>;
    }
  | { type: "UPDATE_META_INFORMATION"; payload: TFilter };

// Define the initial state

// Define the reducer function
export const createFeatureReducer: Reducer<TCreateFeatureData, Action> = (
  state,
  action
) => {
  switch (action.type) {
    case "SET_TRANSFORMATION_TYPE":
      return {
        ...state,
        transformationType: action.payload,
      };
    case "UPDATE_NAME":
      return {
        ...state,
        name: action.payload,
      };
    case "UPDATE_DESCRIPTION":
      return {
        ...state,
        description: action.payload,
      };
    case "UPDATE_EVENT_FEATURE":
      return {
        ...state,
        [TransformationType.EVENT_FEATURE]: {
          ...state[TransformationType.EVENT_FEATURE],
          ...action.payload,
        },
      };
    case "UPDATE_COMPOSITE_FEATURE":
      return {
        ...state,
        [TransformationType.COMPOSITE_FEATURE]: {
          ...state[TransformationType.COMPOSITE_FEATURE],
          ...action.payload,
        },
      };
    case "UPDATE_SQL_FEATURE":
      return {
        ...state,
        [TransformationType.SQL_FEATURE]: {
          ...state[TransformationType.SQL_FEATURE],
          ...action.payload,
        },
      };
    case "UPDATE_VALIDATION_ERRORS":
      return {
        ...state,
        validationErrors: {
          ...state.validationErrors,
          ...action.payload,
        },
      };
    case "UPDATE_COMPOSITE_FEATURE_VALIDATION_ERROR":
      return {
        ...state,
        compositeFeatureValidationError: {
          ...state.compositeFeatureValidationError,
          ...action.payload,
        },
      };
    case "UPDATE_EVENT_FEATURE_VALIDATION_ERROR":
      return {
        ...state,
        eventFeatureValidationError: {
          ...state.eventFeatureValidationError,
          ...action.payload,
        },
      };
    case "UPDATE_SQL_FEATURE_VALIDATION_ERROR":
      return {
        ...state,
        sqlFeatureValidationError: {
          ...state.sqlFeatureValidationError,
          ...action.payload,
        },
      };
    case "UPDATE_COMPOSITE_FEATURE_PREVIEW_FILTER":
      return {
        ...state,
        compositeFeaturePreviewData: {
          ...state.compositeFeaturePreviewData,
          ...action.payload,
        },
      };
    case "UPDATE_EVENT_FEATURE_PREVIEW_FILTER":
      return {
        ...state,
        eventFeaturePreviewData: {
          ...state.eventFeaturePreviewData,
          ...action.payload,
        },
      };
    case "UPDATE_META_INFORMATION":
      return {
        ...state,
        attributeData: action.payload
      }
    default:
      return state;
  }
};
