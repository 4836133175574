import { TFilter } from "../Types";
import { filterData } from "../Utils/CommonFunctions";


export const filterDataHelper = (filter: TFilter, data: any[]) => {
    const res: any[] = []
    data.map((ele) => {
        if(filterData(filter, ele)) {
            res.push(ele);
        }
    });
    return res;
}