import { FilterType } from "../Client/configuration-client";
import { ApiClient } from "../Utils/ApiClient";

export const getConfigurationFilter = async (filterType: FilterType) => {
  return ApiClient.configurationFilterClient
    .getFilterFilterGet(filterType)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};
