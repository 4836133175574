import { Box, Typography } from "@mui/material";
import { LabelGithub } from "../Styles/CustomTypography";
import { StyledMenuItem } from "../Styles/MenuItem";
import { FlexRowBox } from "../Styles";
import { Colors } from "../Constants";
import DoneIcon from '@mui/icons-material/Done';

type BranchProps = {
  name: string;
  isDefault?: boolean;
  isSelected?: boolean;
};

export const Branch = ({ name, isDefault, isSelected }: BranchProps) => {
  return (
    <StyledMenuItem
      style={{ borderBottom: `0.2px solid ${Colors.SIDE_LINK_HOVER_COLOR}` }}
    >
    <FlexRowBox sx={{gap: '3px'}} >
      <DoneIcon style={{visibility : isSelected ? 'visible' : 'hidden'}}/>
    <Typography style={{fontWeight: isSelected ? 600 : 500,fontSize: "14px" }}>
        {name}
      </Typography>
    </FlexRowBox>
      {isDefault && <LabelGithub>default</LabelGithub>}
    </StyledMenuItem>
  );
};
