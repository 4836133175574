import React, { memo, useEffect, useState } from 'react';
import { useTimeout } from '../../Hooks/useTimeout';
import { IconButton, Paper, LinearProgress } from '@mui/material';
import { CrossIcon } from '../icons/CrossIcon';
import { ToastType } from '../../Context/Providers/ToastProvider';

interface ToastProps {
  children: React.ReactNode;
  type: ToastType;
  close: () => void;
}


const Toast: React.FC<ToastProps> = ({ children, close, type }) => {
  useTimeout(close, 5000);
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => (prev > 0 ? prev - 1 : 0));
    }, 50);

    return () => clearInterval(interval);
  }, []);

  return (
    <Paper
      style={{
        borderRadius: '.5rem',
        display: 'flex',
        alignItems: 'center',
        padding: '8px 16px',
        marginBottom: '8p x',
        color: 'white',
        position: 'relative',
      }}
    >
      <div style={{ flex: 1 }}>{children}</div>
      <IconButton size="small" onClick={close} style={{ color: 'black' }}>
        <CrossIcon />
      </IconButton>
      <LinearProgress
        variant="determinate"
        value={progress}
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: '4px',
          backgroundColor: 'rgba(255, 255, 255, 0.3)',
        }}
      />
    </Paper>
  );
};

export default memo(Toast)