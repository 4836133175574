import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { RouteHandler } from "./Components/RoutesHandler";
import { VersionControlContextProvider } from "./Context/Providers/VersionControlContextProvider";
import { ToastProvider } from "./Context/Providers/ToastProvider";
import { ThemeProvider } from "@emotion/react";
import theme from "./Components/Theme";
import { PermissionContextProvider } from "./Context/Providers/PermissionContextProvider";
import './Styles/CSS/permission.css';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <PermissionContextProvider>
        <ToastProvider>
          <VersionControlContextProvider>
            <Router basename="/frontend" >
              <RouteHandler />
            </Router>
          </VersionControlContextProvider>
        </ToastProvider>
      </PermissionContextProvider>
    </ThemeProvider>
  );
}

export default App;

