import { BaseFeatureCreate } from "../Client/typescript-axios-client-generated";
import { ApiClient } from "../Utils/ApiClient";

export const createFeature = async (projectName: string, branchName: string,body: BaseFeatureCreate) => {
  return ApiClient.featureApiClient
    .createFeatureFeaturePost(projectName, branchName, body)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};
