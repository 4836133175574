import { Outlet } from "react-router-dom";
import { FlexRowBox } from "../../Styles";
import { SideBar } from "./Components/GovernanceSidebar";

export const Governance = () => {
    return (
        <FlexRowBox>
            <SideBar/>
            <FlexRowBox sx={{flexGrow:1}} >
                <Outlet/>
            </FlexRowBox>
        </FlexRowBox>
    );
}