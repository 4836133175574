export const tableTypes = [
    {
        optionId: "EXTERNAL_TABLE",
        title: "External Table",
        description: "A physical table that exists in a Source"
    },
    {
        optionId: "VIEW_TABLE",
        title: "View Table",
        description: "A pre-defined SQL query over one or more other tables"
    },
    {
        optionId: "JOIN_TABLE",
        title: "Join Table",
        description: "A parametric join of two or more Tables"
    },
    {
        optionId: "PIVOT_TABLE",
        title: "Pivot Table",
        description: "Allows Features to be re-keyed to different Entities"
    },
    {
        optionId: "EVENT_STORE_TABLE",
        title: "Event Store Table",
        description: "A Table from an Event Store"
    }
]