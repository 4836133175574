import { FlexColumnBox } from "../../../../../Styles";
import { TCreateFeatureData } from "../../../../../Types/CreateFeatureForm";
import { Action } from "../../../../../Reducers/CreateFeatureReducer";
import { GenericLabelAndAttributesInput } from "../../../../../Packages/LabelsAndAttribute";

type MetaInformationProps = {
  createFeatureState: TCreateFeatureData;
  dispatch: React.Dispatch<Action>;
};

export const MetaInformation = ({
  createFeatureState,
  dispatch,
}: MetaInformationProps) => {
  console.log(createFeatureState.attributeData)

  const updateAttributeData = (e: any) => {
    dispatch({
      type: 'UPDATE_META_INFORMATION',
      payload: e
    })
  }

  return (
    <FlexColumnBox sx={{ gap: "1rem" }}>
      <GenericLabelAndAttributesInput
        entity_type_id={""}
        onChange={updateAttributeData}
      />
    </FlexColumnBox>
  );
};
