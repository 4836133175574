import GenericSidebar from "../../../Components/Sidebar";
import { DataLayerSidebarData } from "../../../Constants/SidebarData/DataLayer";

const SideBar = () => {
  return (
    <GenericSidebar data={DataLayerSidebarData.data}/>
  )
};

export default SideBar;
