import { useEffect, useState } from "react";
import { FlexColumnBox, FlexRowBox } from "../../../../../../Styles";
import { CreateFormHeader } from "../../../../../../Components/CreateFormHeader";
import { StyledNavLink } from "../../../../../../Components/Navbar/NavBar";
import { Colors } from "../../../../../../Constants";
import { Outlet, useParams } from "react-router-dom";
import { BaseFeature } from "../../../../../../Client/typescript-axios-client-generated";
import { useVersionControlContext } from "../../../../../../Hooks/useVersionControlContext";
import { getFeatureById } from "../../../../../../Services/getFeatureById";

export const FeatureDetail = () => {

    const [feature, setFeature] = useState<BaseFeature | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [dataLoadFailed, setDataLoadFailed] = useState<boolean>(false);

  const versionControlContext = useVersionControlContext();

  const { featureId } = useParams();

  useEffect(() => {
    if (
      !versionControlContext ||
      !versionControlContext.currentBranchName ||
      !versionControlContext.currentProjectName ||
      !featureId
    )
      return;
    getFeatureById(
      versionControlContext.currentProjectName,
      versionControlContext.currentBranchName,
      featureId
    )
      .then((data) => {
        setFeature(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [versionControlContext]);

    return (
        <FlexColumnBox sx={{flexGrow: 1}} >
            <CreateFormHeader heading={feature?.name ?? ""}/>
            <FlexRowBox sx={{padding: '.5rem 1rem', borderBottom: `1px solid ${Colors.SIDE_LINK_HOVER_COLOR}`}} >
                <StyledNavLink to={`/data-layer/features/${featureId}`}>Details</StyledNavLink>
                <StyledNavLink to={`/data-layer/features/${featureId}/lineage`}>Data Lineage</StyledNavLink>
                <StyledNavLink to={`/data-layer/features/${featureId}/history`}>History</StyledNavLink>
                <StyledNavLink to={`/data-layer/features/${featureId}/preview`}>Preview</StyledNavLink>
                <StyledNavLink to={`/data-layer/features/${featureId}/stats`}>Statistics</StyledNavLink>
            </FlexRowBox>
            <Outlet context={{feature: feature}} />
        </FlexColumnBox>
    )
}