import FolderIcon from "@mui/icons-material/Folder";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";
import { SidebarProps } from "../../Components/Sidebar";
import { ChangeRequestIcon } from "../../Components/icons/ChangeRequestIcon";
import { BranchIcon } from "../../Components/icons/BranchIcon";
import CommitIcon from "@mui/icons-material/Commit";

export const GovernanceLayerSidebarData: Omit<
  SidebarProps,
  "isToggled" | "toggle"
> = {
  data: [
    {
      title: "Governance",
      links: [
        {
          to: "/governance/change-requests",
          label: "Change Requests",
          icon: <ChangeRequestIcon />,
        },
        {
          to: "/governance/branches",
          label: "Branches",
          icon: <BranchIcon />,
        },
        {
          to: "/governance/projects",
          label: "Projects",
          icon: <FolderIcon />,
        },
        {
          to: "/governance/commits",
          label: "Change Log",
          icon: <CommitIcon />,
        },
      ],
    },
  ],
};
