import { useVersionControl } from "../../Hooks/useVersionControl";
import VersionControlContext, {
  TVersionControlContext,
} from "../VersionControlContext";

type VersionControlContextProviderProps = {
  children: React.ReactNode;
};

export const VersionControlContextProvider = ({
  children,
}: VersionControlContextProviderProps) => {
  const {
    currentBranch,
    currentProject,
    currentBranchName,
    currentProjectName,
    branchesList,
    projectsList,
    updateBranch,
    updateProject,
  } = useVersionControl();

  const value: TVersionControlContext = {
    currentProjectName: currentProjectName,
    currentBranchName: currentBranchName,
    currentBranch: currentBranch,
    currentProject: currentProject,
    branchesList: branchesList,
    projectsList: projectsList,

    updateCurrentProject: updateProject,
    updateCurrentBranch: updateBranch,
  };

  return (
    <VersionControlContext.Provider value={value}>
      {children}
    </VersionControlContext.Provider>
  );
};
