import { useOutletContext } from "react-router-dom";
import { GenericLabelAndAttributesInput } from "../../../../../../../../Packages/LabelsAndAttribute";
import { FlexRowBox } from "../../../../../../../../Styles";
import { FeatureDetailComponent } from "./FeatureDetailPage";
import { useEffect, useState } from "react";

export const FeatureDetailPage = () => {
  const [readonlyData, setReadonlyData] = useState<Record<string, any>>({});
  const outletContext: any = useOutletContext();
  
  useEffect(() => {
    outletContext?.feature?.attribute_data.map((attribute: any) => {
      setReadonlyData(prev => {return { ...prev, [attribute.attribute_id]: attribute.value }});
    });
  }, [outletContext, outletContext.feature])
  console.log(readonlyData)

  return (
    <FlexRowBox sx={{padding: '2rem', gap: '1rem'}} >
      <FeatureDetailComponent />
      <GenericLabelAndAttributesInput
        entity_type_id={""}
        onChange={() => {}}
        readonly
        readonlyData={readonlyData}
      />
    </FlexRowBox>
  );
};
