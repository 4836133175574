import { SidebarProps } from "../../Components/Sidebar";
import SourceIcon from '@mui/icons-material/Source';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';

export const ConfigurationSidebarData: Omit<SidebarProps, "isToggled" | "toggle"> = {
  data: [
    {
      title: "Configuration",
      links: [
        {
          to: "/configuration/users",
          label: "Users",
          icon: <PersonIcon />,
        },
        {
          to: "/configuration/user-groups",
          label: "User Groups",
          icon: <PeopleIcon />,
        },
        {
          to: "/configuration/sources",
          label: "Sources",
          icon: <SourceIcon />,
        },
        {
          to: "/configuration/destinations",
          label: "Destination",
          icon: <CreateNewFolderIcon />,
        },
        {
          to: "/configuration/labels-and-attributes",
          label: "Labels And Attributes",
          icon: <LocalOfferIcon/>
        },
        {
          to: "/configuration/orchestrators",
          label: "Orchestrator",
          icon: <LocalOfferIcon/>
        }
      ],
    },
    
  ],
};
