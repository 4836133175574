import React, { useState } from "react";
import {
  TCreateFeatureData,
  TransformationType,
} from "../../../../../Types/CreateFeatureForm";
import { Action } from "../../../../../Reducers/CreateFeatureReducer";
import { FlexColumnBox } from "../../../../../Styles";
import { GreenButton, GreyButton } from "../../../../../Styles/Button";
import { Colors } from "../../../../../Constants";
import {
  DescriptionTypography,
  LabelTypography,
  SmallSizeLightColorTypography,
} from "../../../../../Styles/CustomTypography";
import { InputTextField } from "../../../../../Components/Forms/InputTextField";
import { Alert, Box, styled } from "@mui/material";
import { CustomDropDown } from "../../../../../Components/Forms/DropDown";

type PreviewFilterProps = {
  createFeatureState: TCreateFeatureData;
  dispatch: React.Dispatch<Action>;
};

export const PreviewFilter = ({
  createFeatureState,
  dispatch,
}: PreviewFilterProps) => {
  const [previewFilterOpen, setPreviewFilterOpen] = useState<boolean>(false);

  const warningText =
    createFeatureState.transformationType ===
    TransformationType.COMPOSITE_FEATURE
      ? "To preview this Row Feature you must select an Entity and Attribute Dependency, and Enter an Aggregation Expression"
      : "To preview this Event Feature you must select a Table and Aggregation, and enter an Aggregation Expression";

  const previewData =
    createFeatureState.transformationType === TransformationType.EVENT_FEATURE
      ? createFeatureState.eventFeaturePreviewData
      : createFeatureState.compositeFeaturePreviewData;

  return (
    <FlexColumnBox sx={{ gap: "1rem" }}>
      <GreyButton
        onClick={() => {
          setPreviewFilterOpen((prev) => !prev);
        }}
      >
        Preview Filters
      </GreyButton>
      {previewFilterOpen && (
        <FlexColumnBox
          sx={{
            backgroundColor: 'white',
            padding: "1rem",
            gap: "1rem",
          }}
        >
          <Box>
            <LabelTypography>Data Preview Date (Optional)</LabelTypography>
            <SmallSizeLightColorTypography>
              Choose the date you would like to preview data for.
            </SmallSizeLightColorTypography>
            <InputTextField
              onChange={(event) => {
                console.log(event.target.value);
                dispatch({
                  type:
                    createFeatureState.transformationType ===
                    TransformationType.EVENT_FEATURE
                      ? "UPDATE_EVENT_FEATURE_PREVIEW_FILTER"
                      : "UPDATE_COMPOSITE_FEATURE_PREVIEW_FILTER",
                  payload: {
                    dataPreviewDate: event.target.value,
                  },
                });
              }}
              value={previewData.dataPreviewDate}
              violationArray={[]}
              type="date"
            />
          </Box>
          <Box>
            <LabelTypography>Data Preview Entity (Optional)</LabelTypography>
            <SmallSizeLightColorTypography>
              Choose the entity you would like to preview data for.
            </SmallSizeLightColorTypography>
            <CustomDropDown
              data={[]}
              multiple={false}
              selectedItems={[]}
              updateSelectedItems={() => {}}
            />
          </Box>
          <Box>
            <LabelTypography>
              Preview Entity Population (Optional)
            </LabelTypography>
            <SmallSizeLightColorTypography>
              Choose an optional entity population to sample for.
            </SmallSizeLightColorTypography>
            <CustomDropDown
              data={[]}
              multiple={false}
              selectedItems={[]}
              updateSelectedItems={() => {}}
            />
          </Box>
          <Box>
            <LabelTypography>Cluster (optional)</LabelTypography>
            <SmallSizeLightColorTypography>
              Choose the cluster you would like to preview data with.
            </SmallSizeLightColorTypography>
            <CustomDropDown
              data={[]}
              multiple={false}
              selectedItems={[]}
              updateSelectedItems={() => {}}
            />
          </Box>
        </FlexColumnBox>
      )}
      <GreenButton>Generate Preview</GreenButton>
      <Alert icon={false} variant="filled" severity="warning">
        {warningText}
      </Alert>
    </FlexColumnBox>
  );
};
