import { ApiClient } from "../Utils/ApiClient";

export const getChangeReqeustById = (change_request_id: string) => {
  return ApiClient.changeRequestClient
    .getChangeRequestByIdChangeRequestChangeRequestIdGet(change_request_id)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};
