import { TTableHeader } from "../../Packages/Table/Types";

export const ChangeRequestTableHeader: TTableHeader[] = [
    {
        column_id: "title",
        label: "Title",
        isSortable: "",
        visible: true
    },
    {
        column_id: "status",
        label: "Status",
        isSortable: "",
        visible: true
    },
    {
        column_id: "created_at",
        label: "Creation Date",
        isSortable: "",
        visible: true
    },
    {
        column_id: "created_by",
        label: "Author",
        isSortable: "",
        visible: true
    },
    {
        column_id: "source_branch",
        label: "Source Branch",
        isSortable: "",
        visible: true
    },
    {
        column_id: "target_branch",
        label: "Target Branch",
        isSortable: "",
        visible: true
    },
    {
        column_id: "view",
        label: "",
        isSortable: "",
        visible: false
    }
]