import { ApiClient } from "../Utils/ApiClient";

export const getTables = async (projectName: string, branchName: string) => {
  return ApiClient.tableClient
    .getTablesByBranchIdTableGet(projectName, branchName)
    .then((data) => {
        return data.data;
    })
    .catch((err) => {
        console.log(err);
        throw err;
    });
};
