import { Box, Divider, Menu, MenuItem } from "@mui/material";
import { StyledMenu } from "../Styles/MenuItem";
import { LabelTypography } from "../Styles/CustomTypography";
import AddIcon from '@mui/icons-material/Add';
import { FlexRowBox } from "../Styles";
import { InputTextField } from "./Forms/InputTextField";
import { Colors } from "../Constants";

type GithubMenuProps = {
    anchorEl: HTMLElement | null;
    title: string;
    minWidth?: string;
    children: React.ReactNode;
    handleClose: () => any;
    searchPlaceholder: string;
    search: string;
    handleUpdatedSeacrh: (e: string) => any;
    handlePlusClick?: () => void;
};

export const GithubMenu = ({
    anchorEl,
    title,
    minWidth = '15rem',
    children,
    handleClose,
    searchPlaceholder,
    search,
    handleUpdatedSeacrh,
    handlePlusClick
}: GithubMenuProps) => {
    return (
        <StyledMenu
            transitionDuration={0}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{ style: { minWidth: minWidth || 'auto', marginTop: '0.2rem', padding: '0.5rem 0' } }}
        >
            <FlexRowBox
                style={{
                    paddingBottom: 8,
                    paddingLeft: 8,
                    paddingRight: 8,
                    justifyContent: 'space-between',
                }}
            >
                <Box><LabelTypography sx={{ color: `${Colors.APP_LIGHT_GREY}` }} >{title}</LabelTypography></Box>
               {handlePlusClick && (<Box onClick={handlePlusClick} ><AddIcon sx={{color:Colors.APP_LIGHT_GREY}} /></Box>)}
            </FlexRowBox>
            <Box sx={{ padding: '.5rem', borderTop: `1px solid ${Colors.SIDE_LINK_HOVER_COLOR}` }} >
                <InputTextField
                 onChange={(e) => {handleUpdatedSeacrh(e.target.value)}}
                 value={search}
                 violationArray={[]}
                 placeholder={searchPlaceholder}
                 />
            </Box>
            <Divider />
            {children}
        </StyledMenu>
    );
};
