import { TextField } from "@mui/material";
import { FlexColumnBox } from "../../../Styles";
import { DescriptionTypography, LabelTypography, SmallSizeLightColorTypography } from "../../../Styles/CustomTypography";
import '../../../Styles/CSS/styled-text-field.css';

type InputTextFieldProps = {
    title?: string;
    description?: string;
    onChange: (e:any) => void;
    value: string | number;
    violationArray: string[];
    style?: Record<string, string | number>;
    placeholder?: string;
    onKeyDown?: (e:any) => void;
    type?: string;
}

export const InputTextField = ({
    value,
    onChange,
    title,
    description,
    violationArray,
    style,
    placeholder,
    onKeyDown,
    type
}:InputTextFieldProps) => {
    
    return (
        <FlexColumnBox>
            {title && (<LabelTypography color={'#fff'} fontWeight={'bold'} >{title}</LabelTypography>)}
            {description &&  (<SmallSizeLightColorTypography>{description}</SmallSizeLightColorTypography>)}
            <TextField className="styled-text-field" type={type} onKeyDown={onKeyDown} placeholder={placeholder ? placeholder : ''} InputProps={{style: {...style}}} value={value} onChange={onChange} fullWidth size="small" />
            {
                violationArray.map((violation)=>{
                    return <DescriptionTypography color={'red'} ><span style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '5px', backgroundColor: 'red', width: '6px', height: '6px', borderRadius: '50%' }}></span>{violation}</DescriptionTypography>
                })
            }
        </FlexColumnBox>            
    );
}