import { CustomDropDown } from "../../../Components/Forms/DropDown";
import { LabelTypography } from "../../../Styles/CustomTypography";
import { useCreateSource } from "../Hooks/useSourceContext";
import { InputTextField } from "../../../Components/Forms/InputTextField";
import { FlexColumnBox } from "../../../Styles";
import { CreateSourceType, SourceTypeProps } from "../Type";

export const SourceType = ({ type }: SourceTypeProps) => {
  const createSourceContext = useCreateSource();
  const selectedSourceType = createSourceContext.state.source_types_data.find(
    (source_type) => source_type.id === createSourceContext.state.source_type[0]
  );

  const properties: Array<any> = selectedSourceType
    ? selectedSourceType[type === CreateSourceType.SOURCE ? 'source_config_format' : 'destination_config_format']
    : [];

  return (
    <FlexColumnBox sx={{ gap: "1rem" }}>
      <LabelTypography>Source Type</LabelTypography>
      <CustomDropDown
        data={createSourceContext.state.source_types_data.map((sourceType) => {
          return {
            itemId: sourceType.id,
            label: sourceType.name,
            description: sourceType.description,
          };
        })}
        multiple={false}
        selectedItems={createSourceContext.state.source_type}
        updateSelectedItems={(selectedItems) => {
          createSourceContext.dispatch({
            type: "UPDATE_SOURCE_TYPE",
            payload: selectedItems,
          });
        }}
      />

      {selectedSourceType &&
        properties.map((property) => {
          return (
            <InputTextField
              key={property.key} // Add a unique key for each property
              onChange={(e) => {
                const newConfigData = {
                  ...createSourceContext.state.config_data,
                  [selectedSourceType.id]: {
                    ...createSourceContext.state.config_data[
                      selectedSourceType.id
                    ],
                    [property.key]: e.target.value,
                  },
                };
                createSourceContext.dispatch({
                  type: "UPDATE_CONFIG_DATA",
                  payload: newConfigData,
                });
              }}
              value={
                createSourceContext.state.config_data[selectedSourceType.id]?.[
                  property.key
                ] || ""
              }
              violationArray={[]}
              title={property.label}
              description={property.description}
              placeholder={property.placeholder}
            />
          );
        })}
    </FlexColumnBox>
  );
};
