import { TTableHeader } from "../../Packages/Table/Types";

export const SourceTableHeader: TTableHeader[] = [
    {
        column_id: "name",
        label: "Name",
        isSortable: "",
        visible: true
    },
    {
        column_id: "type",
        label: "Type",
        isSortable: "",
        visible: true
    },
    {
        column_id: "labels",
        label: "Labels",
        isSortable: "",
        visible: false
    },
    {
        column_id: "view",
        label: "",
        isSortable: "",
        visible: false
    }
]