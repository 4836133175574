import { ChangeRequestGetSchema } from "../../Client/typescript-axios-client-generated";
import { TRowProps } from "../../Packages/Table/Types";
import { BlueButton } from "../../Styles/Button";
import {
  ClosedChangeRequestStatus,
  MergedChnageRequestStatus,
  OpenChangeRequestStatus,
} from "../../Styles/CustomTypography";

export const mapToChangeRequestTableData = (
  changeRequests: ChangeRequestGetSchema[],
  onViewClick: (row_id: string) => void
): TRowProps[] => {
  return changeRequests.map((changeRequest) => {
    const status = getStatus(changeRequest);
    return {
      row_id: changeRequest.id,
      data: {
        title: {
          column_id: "title",
          text: changeRequest.name,
          link: "/governance/change-requests/" + changeRequest.id,
        },
        status: {
          column_id: "status",
          text: status,
          buttonElement: getStatusBadge(status),
        },
        created_by: {
          column_id: "created_by",
          text: changeRequest.created_by ?? "",
        },
        created_at: {
          column_id: "created_at",
          text: changeRequest.created_at,
        },
        source_branch: {
          column_id: "source_branch",
          text: changeRequest.source_branch.name,
        },
        target_branch: {
          column_id: "target_branch",
          text: changeRequest.target_branch.name,
        },
        view: {
          column_id: "view",
          text: "View",
          buttonElement: BlueButton,
          onClickHandler: onViewClick
        },
      },
    };
  });
};

export const getStatus = (changeRequest: ChangeRequestGetSchema): string => {
  if (changeRequest.merged_at) return "merged";
  if (changeRequest.closed_at) return "closed";
  return "open";
};

export const getStatusBadge = (state: string) => {
  switch (state) {
    case "merged":
      return MergedChnageRequestStatus;
    case "open":
      return OpenChangeRequestStatus;
    case "closed":
      return ClosedChangeRequestStatus;
  }
};
