import { useEffect, useState } from "react";
import { TFormData } from "../Type";
import { GetAttributeResponse } from "../../../Client/configuration-client";
import { getAttributes } from "../../../Services/getAttributes";

export const useGenericAttributes = (entity_type_id: string, updateData: (e: TFormData) => void, readonlyData?: TFormData) => {
  const [attributesData, setAttributesData] = useState<GetAttributeResponse[]>(
    []
  );
  const [formData, setFormData] = useState<TFormData>(readonlyData ?? {});

  const updateFormData = (key: string, value: any) => {
    setFormData((prev) => {
      const updateFormData = { ...prev, [key]: value };
      updateData(updateFormData);
      return updateFormData;
    });
  };

  useEffect(() => {
    getAttributes()
      .then((data) => {
        setAttributesData(data);
      })
      .catch((err) => {});
  }, []);

  return {
    formData,
    updateFormData,
    attributesData,
  };
};
