import { TFilter } from "../Types";
const jsonpath = require('jsonpath')

export const isMatchingSubString = (a: string, b: string): boolean => {
  // Iterate through each character of the second string
  for (let i = 0; i <= b.length - a.length; i++) {
    // Check if the substring starting from index i of length a is equal to string a
    if (b.substring(i, i + a.length) === a) {
      return true; // If substring matches, return true
    }
  }
  return false; // If no match found, return false
};

//validate whether string is contains only lowercase english letter or underscorese
export const validateStringContainOnlyLowerCaseOrUnderScore = (
  input: string
) => {
  const regex = /^[a-z_]+$/;
  return regex.test(input);
};

export function convertStringToEnum<T extends Record<string, string>>(
  enumDef: T,
  value: string
): T[keyof T] | null {
  // Get all enum values
  const enumValues = Object.values(enumDef);

  // Find the corresponding enum value
  const enumValue = enumValues.find((enumValue) => enumValue === value);

  return enumValue !== undefined ? (enumValue as T[keyof T]) : null;
}

export const filterData = (filter: TFilter, data: any): boolean => {
  for (const [key, value] of Object.entries(filter)) {
    if (!value) continue;
    const fieldValue = jsonpath.query(data, key)
    if (Array.isArray(value)) {
      if (value.length == 0) continue;
      if (Array.isArray(value)) {
        if (Array.isArray(fieldValue)) {
          const set1 = new Set(value);
          const set2 = new Set(fieldValue);
          const combinedSet = new Set([
            ...Array.from(set1),
            ...Array.from(set2),
          ]);
          if (set1.size + set2.size == combinedSet.size) return false;
        } else {
          const found = value.includes(fieldValue?.toString());
          if (!found) return false;
        }
      }
    }
  }
  return true;
};
