import { useContext } from "react";
import { PermissionContext } from "../Context/PermissionContext";

export const usePermissionContext = () => {
  const context = useContext(PermissionContext);
  if (!context) {
    console.log("You can't use this version control context here...");
  }
  return context;
};
