import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { NavLink } from "react-router-dom";
import { Colors } from "../Constants";

export const FlexColumnBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

export const FlexRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
});

export const FlexRowBoxWithBorder = styled(FlexRowBox)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "left",
});

export const FlexRowBoxSidebarLinks = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "left",
  gap: "1rem",
  border: "1px solid black",
});

export const DownWardAnimatedBox = styled(Box)(
  ({ flag }: { flag: boolean }) => ({
    opacity: flag ? 1 : 0, // Set initial opacity
    transition: "opacity 0.5s ease, transform 0.5s ease", // Define the transition
    transform: flag ? "translateY(0)" : "translateY(-100%)", // Apply the translateY transformation
    gap: "5px",
  })
);

export const DropDownItemBox = styled(Box)(
  ({ isSelected }: { isSelected: boolean }) => ({
    padding: "10px",
    cursor: "pointer",
    backgroundColor: isSelected ? Colors.APP_MEDIUM_BLUE_COLOR : "",
    "&:hover": {
      backgroundColor: `${Colors.APP_MEDIUM_BLUE_COLOR}`,
    },
  })
);

export const DropDownSelectedItem = styled(Box)({
  backgroundColor: "rgb(204, 204, 204)",
  padding: "5px 10px",
  borderRadius: "1px",
  margin: "5px",
  display: "inline-block",
});

export const SidebarContainer = styled(Box)({
  color: "#768192", // Example text color
  height: "50px", // Example padding
  padding: "0 20px 0 20px",
  display: "flex",
  alignItems: "center",
  borderBottom: `1px solid ${Colors.SIDE_LINK_HOVER_COLOR}`,
  fontWeight: 600,
  borderRight: "",
  cursor: "default",
});

interface MenuItemBaseStyleProps {
  isPermission?: boolean;
}

export const MenuItemBaseStyle = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isPermission'
})<MenuItemBaseStyleProps>(({ isPermission }) => ({
  padding: "10px",
  transition: "color 0.3s", // Smooth transition effect for color change
  opacity: isPermission ? 1 : '.4',
  color: Colors.APP_LIGHT_GREY, // Change color based on isPermission
  fontWeight: 500,
  cursor: isPermission ? 'pointer' : 'not-allowed',
  "&:hover": {
    backgroundColor: Colors.APP_VERY_LIGHT_BLUE_COLOR,
  },
  marginBottom: "8px", // Add some space between each Box
}));


export const RightFloatTableMenuItemStyle = styled(Box)(
  ({ showBorderBottom, color }: { showBorderBottom?: boolean, color?: string }) => ({
    textAlign: "right",
    padding: "10px",
    transition: "color 0.3s", // Smooth transition effect for color change
    color: color ? color : Colors.APP_LIGHT_GREY, // Light blue color for text
    "&:hover": {
      cursor: "pointer", // Change cursor to pointer on hover
      backgroundColor: Colors.SIDE_LINK_HOVER_COLOR,
    },
    marginBottom: "8px", // Add some space between each Box
    borderBottom: showBorderBottom ? `1px solid ${Colors.LINK_TEXT_COLOR}` : undefined,
  })
);