import {
  Box,
  Paper,
  Table,
  TableContainer,
  TablePagination,
} from "@mui/material";
import { TGenericTableProps } from "./Types";
import { TableHeader } from "./Components/TableHeader";
import { Rows } from "./Components/Rows";
import { usePagination } from "./Hooks/usePagination";
import { useSorting } from "./Hooks/useSorting";
import { Colors } from "../../Constants";
import { useEffect, useRef, useState } from "react";

export const GenericTable = ({
  rows,
  tableHeader,
  selectAllRows,
  updateSelectedRow,
  totalRows,
  selectedRows,
  hideCheckbox,
  hidePagination,
  hideHeader,
  padding
}: TGenericTableProps) => {
  const { order, orderBy, sortedArray, handleRequestSort } = useSorting(rows);
  const {
    paginatedData,
    pageNumber,
    pageSize,
    updatePageNumber,
    updatePageSize,
  } = usePagination(sortedArray, hidePagination ? true : false);

  const parentRef = useRef<HTMLDivElement>(null);
  const [remainingWidth, setRemainingWidth] = useState<number>(0);


  useEffect(() => {
    const handleResize = () => {
      if (parentRef.current) {
        const parentWidth = parentRef.current.offsetWidth;
        setRemainingWidth(parentWidth);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize(); // Initial calculation

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box ref={parentRef} sx={{ flexGrow: 1, overflow: 'auto', display: 'flex' }}>
      <Paper sx={{ flexGrow: 1, border: ".1px solid " + Colors.TABLE_BORDER_COLOR }}>
        <TableContainer sx={{ overflow: 'auto', maxWidth: remainingWidth }}>
          <Table aria-labelledby="tableTitle">
            {!hideHeader && <TableHeader
              numSelected={selectedRows.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={selectAllRows}
              order={order}
              orderBy={orderBy}
              rowCount={totalRows}
              headerCells={tableHeader}
              hideCheckbox={hideCheckbox}
            />}
            <Rows
              rows={paginatedData}
              tableHeader={tableHeader}
              updateSelect={updateSelectedRow}
              selectedRows={selectedRows}
              hideCheckbox={hideCheckbox}
              padding={padding}
            />
          </Table>
        </TableContainer>
        {!hidePagination && <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={hidePagination ? 10000 : pageSize}
          page={pageNumber}
          onPageChange={updatePageNumber}
          onRowsPerPageChange={updatePageSize}
          sx={{ backgroundColor: Colors.APP_VERY_LIGHT_BLUE_COLOR, color: Colors.APP_LIGHT_GREY }}
        />}
      </Paper>
    </Box>
  );
};
