import { TTableHeader } from "../../Packages/Table/Types";

export const CommitTableHeader : TTableHeader[] = [
    {
        column_id: "id",
        label: "ID",
        isSortable: "",
        visible: true
    },
    {
        column_id: "author",
        label: "Author",
        isSortable: "",
        visible: true
    },
    {
        column_id: "description",
        label: "Description",
        isSortable: "",
        visible: true
    },
    {
        column_id: "created_at",
        label: "Created",
        isSortable: "",
        visible: true
    },
    {
        column_id: "show_changes",
        label: "",
        isSortable: "",
        visible: false
    },
    {
        column_id: "moreOptions",
        label: "",
        isSortable: "",
        visible: false
    }
]