import { useMemo, useState } from "react";
import {
  ChangeEntrySchema,
  ChangeRequestGetSchemaWithCommits,
  ChangeSchema,
} from "../../../../../../../Client/typescript-axios-client-generated";
import {
  TRowProps,
  TTableHeader,
} from "../../../../../../../Packages/Table/Types";
import { GenericTable } from "../../../../../../../Packages/Table";
import { ChangeRequestDiffTableHeader } from "../../../../../../../Constants/TableHeaders/ChangeRequestDiffTable";
import { BlueButton } from "../../../../../../../Styles/Button";
import { Box, Typography } from "@mui/material";
import { DiffTableProps } from "../../../../../../../Packages/Diff";
import { Colors } from "../../../../../../../Constants";
import diffViewer from "../../../../../../../Components/DiffView";
import DiffViewer from "../../../../../../../Components/DiffView/data/DiffViewer";

type ChangesProps = {
  data: ChangeRequestGetSchemaWithCommits | undefined;
};

export const buildAttributeText = (data: any) => {
  return <Typography>OWNER = {data.owner} </Typography>;
};

export const EntityDiff = (data: any): DiffTableProps => {
  return {
    data: {
      name: {
        element: <Typography>Name</Typography>,
        from: undefined,
        to: undefined,
      },
    },
  };
};

export const Changes = ({ data }: ChangesProps) => {
  const [currentlyOpenedId, setCurrentlyOpenedId] = useState<string>("");

  const changes = data?.changes ?? [];

  const seprateChanges = () => {};

  const changesTableData: TRowProps[] = useMemo(() => {
    return changes.map((change) => {
      return {
        row_id: change.id,
        accordianElement: <ShowChanges data={change} />,
        currentlyOpenedElementId: currentlyOpenedId,
        data: {
          type: {
            column_id: "type",
            text: change.operation_type,
          },
          change_type: {
            column_id: "change_type",
            text: change.item_type,
          },
          item_name: {
            column_id: "item_name",
            text: change.item_name,
          },
          view: {
            column_id: "view",
            text: currentlyOpenedId === change.id ? "Hide" : "View",
            buttonElement: BlueButton,
            onClickHandler: (rowId) => {
              if (currentlyOpenedId == rowId) {
                setCurrentlyOpenedId("");
              } else setCurrentlyOpenedId(rowId);
            },
          },
        },
      };
    });
  }, [changes, currentlyOpenedId]);

  return (
    <GenericTable
      rows={changesTableData}
      tableHeader={ChangeRequestDiffTableHeader}
      selectAllRows={function (): void {
        throw new Error("Function not implemented.");
      }}
      updateSelectedRow={function (rowId: string): void {
        throw new Error("Function not implemented.");
      }}
      totalRows={0}
      selectedRows={[]}
      hideCheckbox={true}
    />
  );
};

type ShowChangesProps = {
  data: ChangeSchema;
};

export const ShowChanges = ({ data }: ShowChangesProps) => {
  const rowData: TRowProps[] = useMemo(() => {
    return data.change.map((fieldChange) => {
      let fieldValue = (
        <Typography
          sx={{
            whiteSpace: "nowrap",
            color: fieldChange ? Colors.APP_RED_COLOR : "#333",
            fontWeight: fieldChange ? 540 : 400,
          }}
        >
          {fieldChange.field_name}
        </Typography>
      );

      return {
        row_id: "",
        data: {
          field_name: {
            column_id: "field_name",
            text: fieldChange.field_name,
            reactNode: fieldValue,
          },
          from_value: {
            column_id: "from_value",
            text: "",
            reactNode: (
              <DiffViewer
                oldText={fieldChange.from_value?.toString() ?? ""}
                newText={fieldChange.to_value?.toString() ?? ""}
              />
            ),
          },
          to_value: {
            column_id: "to_value",
            text: fieldChange.to_value?.toString() ?? "-",
            reactNode: <></>,
          },
        },
      };
    });
  }, [data]);

  const header: TTableHeader[] = [
    {
      column_id: "field_name",
      label: "",
      isSortable: "",
      visible: false,
    },
    {
      column_id: "from_value",
      label: "",
      isSortable: "",
      visible: false,
    },
    {
      column_id: "to_value",
      label: "",
      isSortable: "",
      visible: false,
    },
  ];

  return (
    <GenericTable
      rows={rowData}
      tableHeader={header}
      selectAllRows={() => {}}
      updateSelectedRow={() => {}}
      totalRows={0}
      selectedRows={[]}
      hideCheckbox={true}
      hidePagination={true}
    />
  );
};
