import { TCreateTableData } from "../../Types/CreateTableForm";

export const defaultCreateTableData: TCreateTableData = {
    table: {
        name: "",
        description: "{}",
        tableType: "EXTERNAL_TABLE",
        externalTableData: {
            source: [],
            sourceBrowseOpen: false,
            entityMapping: [],
            tableTimeSemantics: "NONE",
            eventOrFactTable: {
                timestampColumn: ""
            },
            slowlyChangingDimension: {
                primaryEntity: [],
                fromColumn: "",
                validToColumn: ""
            },
            pointInTime: {
                primaryEntity: [],
                validityColumn: ""
            },
            entitiesData: [],
            source_table: ""
        },
        viewTableData: {
            sources: [],
            sourceBrowseOpen: false,
            sql: "",
            entityMapping: [],
            tableTimeSemantics: "NONE",
            eventOrFactTable: {
                timestampColumn: ""
            },
            slowlyChangingDimension: {
                primaryEntity: [],
                fromColumn: "",
                validToColumn: ""
            },
            pointInTime: {
                primaryEntity: [],
                validityColumn: ""
            },
            entitiesData: []
        },
        eventStoreTableData: {
            eventStore: [],
            sourceTopic: "",
            entity: []
        }
    },
    modeling: {
        column: ""
    },
    metaInformation: {},
    domainsData: [],
    ownersData: [],
    sourcesTableData: []
};