import { useEffect, useMemo, useState } from "react";
import {
  DownWardAnimatedBox,
  FlexColumnBox,
  FlexRowBox,
} from "../../../../Styles";
import { useVersionControlContext } from "../../../../Hooks/useVersionControlContext";
import { GenericFilter } from "../../../../Components/GenericFilter/GenericFilter";
import { GenericTable } from "../../../../Packages/Table";
import { Typography } from "@mui/material";
import { BlueButton, GreyButton, RedButton } from "../../../../Styles/Button";
import { useSelectedRow } from "../../../../Hooks/useSelectedRows";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../../../Constants";
import {
  FeatureSet as FeatureSetGetSchem,
  FilterType,
} from "../../../../Client/typescript-axios-client-generated";
import { getAllFeatureSetsByBranchId } from "../../../../Services/getAllFeatureSetsByBranchId";
import { convertToFeatureSetTableData } from "../../../../Utils/Mapper/FeatureSetMapping";
import { FeatureSetTableHeader } from "../../../../Constants/TableHeaders/FeatureSet";
import ConfirmationModal from "../ConfirmationModal";
import { deleteFeatureSetById } from "../../../../Services/deleteFeatureSetById";
import { useToast } from "../../../../Hooks/useToast";
import { ToastType } from "../../../../Context/Providers/ToastProvider";
import { FeatureSetQuickView } from "./Components/QuickView";
import { TFilter } from "../../../../Types";
import { filterData } from "../../../../Utils/CommonFunctions";
import { useFilter } from "../../../../Hooks/useFilter";
import { CustomToolTip } from "../../../../Styles/ToolTip";
import { usePermissionContext } from "../../../../Hooks/usePermissionContext";

export const FeatureSet = () => {
  const [featureSetsData, setFeatureSetsData] = useState<FeatureSetGetSchem[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<TFilter>({});
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] =
    useState<boolean>(false);
  const [selectedFeatureSetsForDeletion, setSelectedFeatureSetsForDeletion] =
    useState<string[]>([]);
  const [openQlickViewModal, setOpenQuickViewModal] = useState<boolean>(false);
  const [quickViewFeatureSet, setQuickViewFeatureSet] = useState<
    FeatureSetGetSchem | undefined
  >(undefined);

  const toast = useToast();
  const usePermission = usePermissionContext();

  const isCreateFeatureSetPermission = usePermission?.permissions.createFeatureset;

  const navigate = useNavigate();

  const handleEdit = (rowId: string) => {
    // Edit logic here
    console.log(featureSetsData);
    navigate("/data-layer/feature-sets/create", {
      state: { edit: true, featureSetId: rowId },
    });
  };

  const handleDuplicate = (rowId: string) => {
    // Duplicate logic here
    navigate("/data-layer/feature-sets/create", {
      state: { duplicate: true, featureSetId: rowId },
    });
  };

  const handleDelete = (featureSetId: string) => {
    // Delete logic here
    setSelectedFeatureSetsForDeletion([featureSetId]);
    setOpenDeleteConfirmationModal(true);
  };

  const quickViewHandler = (rowId: string) => {
    const qvFeatureSet = featureSetsData.find(
      (featureSet) => featureSet.id === rowId
    );
    if (qvFeatureSet) {
      setOpenQuickViewModal(true);
      setQuickViewFeatureSet(qvFeatureSet);
    }
  };

  const quickViewModalCloseHandler = () => {
    setOpenQuickViewModal(false);
  };

  const handleDeleteFeatureSetModalCloseHandler = () => {
    setOpenDeleteConfirmationModal(false);
  };

  const handleDeleteConfirmation = () => {
    if (!versionControlContext) return;
    if (selectedFeatureSetsForDeletion.length < 1) return;
    if (selectedFeatureSetsForDeletion.length > 1)
      handleMutipleFeatureSetDeletion();
    setIsLoading(true);
    deleteFeatureSetById(
      versionControlContext.currentProjectName,
      versionControlContext.currentBranchName,
      selectedFeatureSetsForDeletion[0]
    )
      .then(() => {
        toast.open(`Feature set is deleted successfully`, ToastType.SUCCESS);
        navigate("/data-layer/feature-sets");
        setOpenDeleteConfirmationModal(false);
      })
      .catch((err) => {
        toast.open("Failed to delete Feature set", ToastType.ERROR);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleMutipleFeatureSetDeletion = () => {
    //
  };

  const convertedTabelData = convertToFeatureSetTableData(
    featureSetsData.filter((featureSet) => {
      return filterData(filter, featureSet);
    }),
    handleEdit,
    handleDuplicate,
    handleDelete,
    quickViewHandler
  );

  const versionControlContext = useVersionControlContext();
  const { selectedRows, selectAllRows, updateSelectedRow, totalRows } =
    useSelectedRow(convertedTabelData);

  useEffect(() => {
    if (!versionControlContext?.currentBranch) return;
    getAllFeatureSetsByBranchId(
      versionControlContext.currentProjectName,
      versionControlContext.currentBranchName
    )
      .then((data) => {
        setFeatureSetsData(data);
      })
      .catch((err) => {});
  }, [versionControlContext?.currentBranch]);

  const { filterComponentData } = useFilter(FilterType.FeatureSet);

  return (
    <FlexRowBox flexGrow={1}>
      <ConfirmationModal
        heading="Delete Feature Set"
        message="Are you sure you want to delete 1 Feature Set"
        isLoading={isLoading}
        open={openDeleteConfirmationModal}
        secondaryButtonText="Delete"
        secondaryCustomButton={RedButton}
        primaryCustomButton={GreyButton}
        positionTop="30%"
        confirmHandler={handleDeleteConfirmation}
        closeHandler={handleDeleteFeatureSetModalCloseHandler}
      />
      <ConfirmationModal
        heading={quickViewFeatureSet?.name}
        message=""
        isLoading={isLoading}
        open={openQlickViewModal}
        secondaryButtonText="View Feature Set"
        primaryButtonText="Close"
        secondaryCustomButton={BlueButton}
        primaryCustomButton={GreyButton}
        confirmHandler={handleDeleteConfirmation}
        closeHandler={quickViewModalCloseHandler}
        children={<FeatureSetQuickView data={quickViewFeatureSet} />}
      />
      <GenericFilter
        globalSearchEnabled={true}
        data={filterComponentData}
        onChangeFilter={setFilter}
      />
      <FlexColumnBox
        sx={{
          padding: "2rem",
          backgroundColor: Colors.APP_VERY_LIGHT_BLUE_COLOR,
          flexGrow: 1,
        }}
      >
        <FlexRowBox sx={{ justifyContent: "space-between" }}>
          <DownWardAnimatedBox flag={selectedRows.length > 0}>
            <FlexColumnBox>
              <FlexRowBox sx={{ gap: "5px" }}>
                <RedButton>Delete</RedButton>
              </FlexRowBox>
              <Typography sx={{ color: Colors.LINK_TEXT_COLOR }}>
                {selectedRows.length} row selected
              </Typography>
            </FlexColumnBox>
          </DownWardAnimatedBox>
          <FlexColumnBox>
            <CustomToolTip
              placement="top"
              title={
                isCreateFeatureSetPermission ? "" : "You dont have permission"
              }
            >
              <BlueButton
                className={ isCreateFeatureSetPermission ? '' : "disabled-button"}
                onClick={() => {
                  if (!isCreateFeatureSetPermission) return;
                  navigate("/data-layer/feature-sets/create");
                }}
                sx={{
                  cursor: isCreateFeatureSetPermission ? "" : "not-allowed",
                }}
              >
                Create Feature Set
              </BlueButton>
            </CustomToolTip>
            <Typography></Typography>
          </FlexColumnBox>
        </FlexRowBox>
        <GenericTable
          rows={convertedTabelData}
          tableHeader={FeatureSetTableHeader}
          selectAllRows={selectAllRows}
          updateSelectedRow={updateSelectedRow}
          totalRows={totalRows}
          selectedRows={selectedRows}
        />
      </FlexColumnBox>
    </FlexRowBox>
  );
};
