import { useEffect, useState } from "react";
import { FilterType, TFilterData } from "../Types";
import { getFilterByType } from "../Services/getFilterByType";
import { useVersionControlContext } from "./useVersionControlContext";
import { FilterType as CFilterType } from "../Client/typescript-axios-client-generated";
import { FilterType as ConfigFilterType } from "../Client/configuration-client";
import { getConfigurationFilter } from "../Services/getConfigurationFilter";

export const useFilter = (filterType: CFilterType | ConfigFilterType) => {
  const [filterData, setFilterData] = useState<TFilterData[]>([]);
  const versionControlContext = useVersionControlContext();

  // Type guard to check if the filterType is of type CFilterType
  const isCFilterType = (val: any): val is CFilterType => {
    return Object.values(CFilterType).includes(val);
  };

  useEffect(() => {
    let promise: Promise<any>;

    // Use the type guard to check the type of filterType
    if (isCFilterType(filterType)) {
      if (
        !versionControlContext ||
        !versionControlContext.currentProjectName ||
        !versionControlContext.currentBranchName
      )
        return;
      promise = getFilterByType(
        versionControlContext.currentProjectName,
        versionControlContext.currentBranchName,
        filterType
      );
    } else {
      promise = getConfigurationFilter(filterType);
    }

    promise
      .then((data) => {
        const filterData: TFilterData[] = Object.keys(data).map((key) => {
          return {
            label: data[key].label,
            id: data[key].expression,
            type: FilterType.CHECKBOX,
            data: data[key].data,
          };
        });
        setFilterData(filterData);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [
    filterType,
    versionControlContext?.currentBranchName,
    versionControlContext?.currentProjectName,
  ]);

  return {
    filterComponentData: filterData,
  };
};
