import { TTableHeader } from "../../Packages/Table/Types";

export const FeatureTableHeader: TTableHeader[] = [
  {
    column_id: "name",
    label: "Name",
    isSortable: "true",
    visible: true,
  },
  {
    column_id: "labels",
    label: "Labels",
    isSortable: "true",
    visible: true,
  },
  {
    column_id: "usage",
    label: "Usage",
    isSortable: "true",
    visible: true,
  },
  {
    column_id: "runCount",
    label: "Run Count",
    isSortable: "true",
    visible: true,
  },
  {
    column_id: "view",
    label: "View",
    isSortable: "",
    visible: false,
  },
];
