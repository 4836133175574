import { Box } from "@mui/material";
import { GetAttributeResponse } from "../../Client/configuration-client";
import { useGenericAttributes } from "./Hooks/useGenericAttributes";
import {
  LabelTypography,
  SmallSizeLightColorTypography,
} from "../../Styles/CustomTypography";
import { CustomDropDown } from "../../Components/Forms/DropDown";
import { FlexColumnBox } from "../../Styles";
import { CustomSwitch } from "../../Components/CustomSwitch";
import { InputTextField } from "../../Components/Forms/InputTextField";
import { MultipleInputTextField } from "../../Components/Forms/MultipleInputTextField";

type GenericLabelAndAttributesInputProps = {
  entity_type_id: string;
  onChange: (e: any) => void;
  readonly?: boolean;
  readonlyData?: Record<string, any>;
};

export const GenericLabelAndAttributesInput = ({
  entity_type_id,
  onChange,
  readonly,
  readonlyData
}: GenericLabelAndAttributesInputProps) => {
  const { formData, updateFormData, attributesData } = useGenericAttributes(entity_type_id, onChange, readonlyData);

  const getAttributes = (attribute: GetAttributeResponse) => {
    switch (attribute.type) {
      case "Choice":
        return (
          <Box>
            <LabelTypography>{attribute.name}</LabelTypography>
            <CustomDropDown
              data={[]}
              multiple={false}
              selectedItems={[]}
              updateSelectedItems={function (selectedItems: string[]): void {
                throw new Error("Function not implemented.");
              }}
            />
          </Box>
        );
      case "Toggle":
        return (
          <Box>
            <LabelTypography>{attribute.name}</LabelTypography>
            <SmallSizeLightColorTypography>
              {attribute.description}
            </SmallSizeLightColorTypography>
            <CustomSwitch
              checked={formData?.[attribute.id] ?? false}
              onChange={() => {
                if(readonly) return;
                updateFormData(attribute.id, !formData?.[attribute.id]);
              }}
            />
          </Box>
        );
      case "Free Text":
        return (
          <Box>
            <LabelTypography>{attribute.name}</LabelTypography>
            <SmallSizeLightColorTypography>
              {attribute.description}
            </SmallSizeLightColorTypography>
            <InputTextField
              onChange={(e) => {
                if(readonly) return;
                updateFormData(attribute.id, e.target.value);
              }}
              value={formData?.[attribute.id] ?? ""}
              violationArray={[]}
            />
          </Box>
        );
      case "Number":
        return (
          <Box>
            <LabelTypography>{attribute.name}</LabelTypography>
            <SmallSizeLightColorTypography>
              {attribute.description}
            </SmallSizeLightColorTypography>
            <InputTextField
              onChange={(e) => {
                if(readonly) return;
                updateFormData(attribute.id, e.target.value);
              }}
              value={formData?.[attribute.id] ?? ""}
              violationArray={[]}
              type="number"
            />
          </Box>
        );
      case "Multi Value String Field":
        return (
          <Box>
            <LabelTypography>{attribute.name}</LabelTypography>
            <MultipleInputTextField
              enteredValues={formData?.[attribute.id] ?? []}
              onChange={(e) => {
                if(readonly) return;
                updateFormData(attribute.id, e);
              }}
            />
          </Box>
        );
    }
  };

  return (
    <FlexColumnBox sx={{ gap: "1rem" }}>
      {attributesData.map((attribute) => getAttributes(attribute))}
    </FlexColumnBox>
  );
};
