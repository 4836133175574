import { Box, Typography } from "@mui/material";
import { GenericFilter } from "../../../../Components/GenericFilter/GenericFilter";
import { GenericTable } from "../../../../Packages/Table";
import {
  DownWardAnimatedBox,
  FlexColumnBox,
  FlexRowBox,
} from "../../../../Styles";
import { BlueButton, GreenButton, RedButton } from "../../../../Styles/Button";
import { useNavigate } from "react-router-dom";
import { useSelectedRow } from "../../../../Hooks/useSelectedRows";
import { Colors } from "../../../../Constants";
import { usePermissionContext } from "../../../../Hooks/usePermissionContext";
import { useMemo } from "react";
import { CustomToolTip } from "../../../../Styles/ToolTip";
import { PERMISSION_DENIED_TOOLTIP_MESSAGE } from "../../../../Constants/messages";

export const Destination = () => {
  const navigate = useNavigate();
  const { selectAllRows, selectedRows, updateSelectedRow, totalRows } =
    useSelectedRow([]);

  const usePermission = usePermissionContext();

  const isCreateDestinationPermission = useMemo(() => {
    if (!usePermission) return;
    return usePermission.isPermission([
      "configurations",
      "configurations.destinations",
      "configurations.destinations.create",
    ]);
  }, []);

  return (
    <FlexRowBox sx={{ flexGrow: 1 }}>
      <GenericFilter data={[]} globalSearchEnabled={true} />
      <Box sx={{ flexGrow: "1", padding: "2rem" }}>
        <FlexRowBox sx={{ justifyContent: "space-between" }}>
          <DownWardAnimatedBox flag={selectedRows.length > 0}>
            <FlexColumnBox>
              <FlexRowBox sx={{ gap: "5px" }}>
                <GreenButton>Add to project</GreenButton>
                <RedButton>Delete</RedButton>
              </FlexRowBox>
              <Typography sx={{ color: Colors.LINK_TEXT_COLOR }}>
                {selectedRows.length} row selected
              </Typography>
            </FlexColumnBox>
          </DownWardAnimatedBox>
          <FlexColumnBox>
            <CustomToolTip placement="top" title={isCreateDestinationPermission ? '' : PERMISSION_DENIED_TOOLTIP_MESSAGE}>
            <BlueButton
              className={isCreateDestinationPermission ? '' : "disabled-button"}
              onClick={() => {
                if(!isCreateDestinationPermission) return;
                navigate("/configuration/destinations/create");
              }}
              sx={{ height: "40px" }}
            >
              Create Destination
            </BlueButton>
            </CustomToolTip>
            <Typography></Typography>
          </FlexColumnBox>
        </FlexRowBox>

        <GenericTable
          rows={[]}
          tableHeader={[]}
          selectAllRows={selectAllRows}
          updateSelectedRow={updateSelectedRow}
          totalRows={totalRows}
          selectedRows={selectedRows}
        />
      </Box>
    </FlexRowBox>
  );
};
