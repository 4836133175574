import { Box, Button, Typography } from "@mui/material";
import {
  FlexColumnBox,
  FlexRowBox,
  UnderLineOnHoverNavLink,
} from "../../../../../../../Styles";
import "../ViewChangeRequest.css";
import { NavLink } from "react-router-dom";
import { ChangeRequestGetSchemaWithCommits } from "../../../../../../../Client/typescript-axios-client-generated";
import { Colors } from "../../../../../../../Constants";

type ChangeRequestDataProps = {
  data?: ChangeRequestGetSchemaWithCommits;
}

export const ChangeRequestData = ({
  data
}: ChangeRequestDataProps) => {

  const getChangeRequestStatus = (merged_at: string, closed_at: string): string => {
    if(merged_at) return "Merged";
    if(closed_at) return "Closed";
    return "Open";
  }

  const getBadgeColor = (state: string) => {
    switch(state){
      case "Merged":
        return "#f9b115";
      case "Open":
        return Colors.APP_GREEN_BUTTON_COLOR;
      case "Closed":
        return 'grey';
    }
  }

  const status = getChangeRequestStatus(data?.merged_at ?? '', data?.closed_at ?? '');
  const badgeColor = getBadgeColor(status);

  const getDetailText = () => {
    switch(status){
      case "Open":
        return `wants to merge 5 changes from ${data?.source_branch.name} to ${data?.target_branch.name}`;
      case "Merged":
        return `successfully merged 1 change from ${data?.source_branch.name} to ${data?.target_branch.name}`;
      case "Closed":
        return `proposed a merge 8 changes from ${data?.source_branch.name} to ${data?.target_branch.name}`;
    }
  }

  return (
    <FlexColumnBox>
      <FlexRowBox sx={{ alignItems: "center", gap: '4px' }}>
        <Typography sx={{backgroundColor: badgeColor}} className="font-lg capitalize badge badge-warning badge-pill">
          {status}
        </Typography>
        <NavLink className="link" to="">
          {data?.created_by}
        </NavLink>
        <Typography className="common-font">
          {getDetailText()}
        </Typography>
      </FlexRowBox>
    </FlexColumnBox>
  );
};
