import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { Colors } from "../Constants";

export const TabTypography = styled(Typography)({
    fontWeight: 'bold',
    fontSize: '13px',
});

export const DescriptionTypography = styled(Typography)({
    fontWeight: 400,
    fontSize: '80%',
});

export const LabelTypography = styled(Typography)({
    fontSize: '0.9rem',
    fontWeight: '700',  
    color: Colors.APP_LIGHT_GREY,
})

export const LabelGithub = styled(Typography)({
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    borderWidth: '1px',
    borderRadius: '999px',
    borderStyle: 'solid',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: 1,
    whiteSpace: 'nowrap',
    borderColor: 'var(--borderColor-default, var(--color-border-default, #d0d7de))',
    height: '20px',
    padding: '0px 7px',
    color: `${Colors.APP_LIGHT_GREY}`
});

export const TableTypography = styled(Typography)({
    fontWeight: 400,
    fontSize: '.875rem',
});

export const FeatureSetTableDescription = styled(Typography)({
    maxWidth: '50px'
});

export const HeadingTypography = styled(Typography)({
    fontSize: '1.8rem',
    marginBottom: 0,
    fontWeight: 600,
    color: Colors.APP_LIGHT_GREY
});

export const LightColorTypography = styled(Typography)({
    color: Colors.SIDEBAR_ICON_COLORS,
    fontFamily: 'sans-serif'
});

export const DarkColorTypography = styled(Typography)({
    color: Colors.APP_LIGHT_GREY,
    fontFamily: 'sans-serif'
});

export const MediumSizeDarkColorTypogrphy = styled(Typography)({
    fontWeight: 500,
    
});

export const SmallSizeLightColorTypography = styled(LightColorTypography)({
    fontSize: '80%',
    fontWeight: 400,
});

export const SmallThinDarkColorTypography = styled(DarkColorTypography)({
    fontSize: '85%', 
});

export const MediumSizeDarkColorThinTypography = styled(DarkColorTypography)({
    fontFamily: 'sans-serif'
});
export const MediumSizeLightColorBoldTypograpgy = styled(DarkColorTypography)({
    fontWeight: 500,
})

export const MediumSizeHeading = styled(DarkColorTypography)({
    fontWeight:700,
    fontsize: '1.125rem',
});

export const ChangeRequestStatus = styled(Typography)(
    ({  }: { }) => ({
        fontSize: '1rem !important',
        color: '#4f5d73',
        paddingRight: '.6rem',
        paddingLeft: '.6rem',
        borderRadius: '.5rem',
        display: 'inline-block',
        padding: '.25rem .4rem',
        fontWeight: '700 !important',
        lineHeight: 1,
        whiteSpace: 'nowrap',
        verticalAlign: 'baseline'
    })
  );

export const MergedChnageRequestStatus = styled(ChangeRequestStatus)({
    backgroundColor: '#f9b115'
})

export const OpenChangeRequestStatus = styled(ChangeRequestStatus)({
    backgroundColor: Colors.APP_GREEN_BUTTON_COLOR,
    color: 'white',
    height: 'fit-content'
})

export const ClosedChangeRequestStatus = styled(ChangeRequestStatus)({
    backgroundColor: 'grey',
    color: 'white'
})
