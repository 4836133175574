import { Box } from "@mui/material";
import { CreateFormHeader } from "../../../../../Components/CreateFormHeader";
import { SingleSelectInput } from "../../../../../Components/Forms/SingleSelection";
import { FlexColumnBox } from "../../../../../Styles";
import { CreateAttribute } from "./CreateAttribute";
import { useCreateAttributes } from "./Hooks/useCreateAttributes";
import { createAttribute } from "../../../../../Services/createAttribute";
import { useToast } from "../../../../../Hooks/useToast";
import { ToastType } from "../../../../../Context/Providers/ToastProvider";

export const CreateLabelsOrAttributes = () => {
  const { state, dispatch } = useCreateAttributes();
  const toast = useToast();

  const handleSubmit = () => {
    createAttribute({
        name: state.name,
        type_id: state.attributeType,
        attribute_entity_mapping: state.appliesTo,
        mandatory: state.mandatory
    })
      .then((data) => {
        toast.open("Attribute created successfully.", ToastType.SUCCESS);
      })
      .catch((err) => {
        toast.open(
          "Something went wrong while creating attribute",
          ToastType.ERROR
        );
      });
  };

  return (
    <FlexColumnBox sx={{ flexGrow: 1, gap: "1rem" }}>
      <CreateFormHeader
        heading={"Create Label or Attribute"}
        submitButtonText="Create Label or Attribute"
        submitHandler={handleSubmit}
      />
      <FlexColumnBox sx={{ padding: "2rem", gap: "2rem" }}>
        <SingleSelectInput
          label={""}
          data={[
            {
              optionId: "",
              title: "Attribute",
              description: "",
            },
          ]}
          showAsGrid
          columnCount={2}
          onSelect={function (id: string): void {
            throw new Error("Function not implemented.");
          }}
          selectedOptionId={""}
        />
        <CreateAttribute state={state} dispatch={dispatch} />
      </FlexColumnBox>
    </FlexColumnBox>
  );
};
