import { TFilter } from "./Filter";

export type TCreateFeatureData = {
    transformationType: TransformationType | undefined;
    name: string;
    description: string;
    [TransformationType.EVENT_FEATURE]: TEventFeature;
    [TransformationType.COMPOSITE_FEATURE]: TCompositeFeature;
    [TransformationType.SQL_FEATURE]: TSqlFeature;
    validationErrors: TValidationErrors;
    eventFeatureValidationError: TEventFeatureValidationError;
    compositeFeatureValidationError: TCompositeFeatureValidationError;
    sqlFeatureValidationError: TSqlFeatureValidationError;
    eventFeaturePreviewData: TPreviewData;
    compositeFeaturePreviewData: TPreviewData;
    attributeData: TFilter;
}

export type TValidationErrors = {
    name: string[];
    labels: string[];
    creator: string[];
    domain: string[];
    owner: string[];
}

export type TPreviewData = {
    dataPreviewDate: string;
    dataPreviewEntity: string[];
    previewEntityPopulation: string[];
    cluster: string[];
}

export type TEventFeatureValidationError = {
    table: string[];
    aggregation: string[];
}

export type TCompositeFeatureValidationError = {
    entity: string[];
    feature_dependencies: string[];
}

export type TSqlFeatureValidationError = {
    sources: string[];
}

export type TSqlFeature = {
    sources: string[];
}

export type TEventFeature = {
    table: string[];
    entityRestriction: string[];
    aggregation: string[];
    aggregationExpression: string;
    additionalFilter: boolean;
    additionalFilterValue: string;
    postAggregationExpresion: boolean;
    postAggregationExpressionValue: string;
    windowType: string;
    windowSize: number;
}

export type TCompositeFeature = {
    entity: string[];
    featureDependencies: string[];
    aggregationExpression: string;
}

export enum TransformationType {
    EVENT_FEATURE = 'EVENT_FEATURE',
    COMPOSITE_FEATURE = 'COMPOSITE_FEATURE',
    SQL_FEATURE = 'SQL_FEATURE'
}
