import { Box } from "@mui/material";
import { LabelGithub, LabelTypography } from "../../../Styles/CustomTypography";
import { CrossIcon } from "../../icons/CrossIcon";
import { InputTextField } from "../InputTextField";
import { useState } from "react";

type MultipleInputTextFieldProps = {
  title?: string;
  enteredValues: string[];
  onChange: (e: string[]) => void;
  readonly?: boolean;
};

export const MultipleInputTextField = ({
  title,
  enteredValues,
  onChange,
  readonly,
}: MultipleInputTextFieldProps) => {
  const [currenInput, setCurrentInput] = useState<string>("");

  const handleCurrentLabelInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCurrentInput(e.target.value);
  };

  const handleLabelsChange = (e: any) => {
    if (e.key === "Enter" && currenInput.trim() !== "") {
      const updatedValues = [...enteredValues, currenInput.trim()];
      onChange(updatedValues);
      setCurrentInput("");
    }
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "0.5rem",
          marginBottom: "0.5rem",
        }}
      >
        {enteredValues.map((label, index) => (
          <LabelGithub>
            {label}
            {!readonly && <p
              onClick={() => {
                const prev = enteredValues;
                let newArr = [];
                for (let i = 0; i < index; i++) {
                  newArr.push(prev[i]);
                }
                for (let i = index + 1; i < prev.length; i++) {
                  newArr.push(prev[i]);
                }
                onChange(newArr);
              }}
            >
              <CrossIcon />
            </p>}
          </LabelGithub>
        ))}
      </Box>
      {!readonly && (
        <InputTextField
          onChange={handleCurrentLabelInputChange}
          onKeyDown={handleLabelsChange}
          value={currenInput}
          violationArray={[]}
          placeholder="Add a new value and press enter."
          title={title}
        />
      )}
    </Box>
  );
};
