import { TDomain } from "../../Types/Domain";
import { Doamin } from "../../Client/typescript-axios-client-generated";

export const fromDomainToTDomain = (domain: Doamin): TDomain => {
    return {
        id: domain.id,
        name: domain.name
    }
}

export const fromDomainToTDomainArray = (domains: Doamin[]): TDomain[] => {
    return domains.map(domain => fromDomainToTDomain(domain))
}