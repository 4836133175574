import { Box } from "@mui/material";
import { FlexColumnBox } from "../../../../../../Styles";
import {
  DescriptionTypography,
  LabelTypography,
} from "../../../../../../Styles/CustomTypography";
import { CustomDropDown } from "../../../../../../Components/Forms/DropDown";
import { InputTextField } from "../../../../../../Components/Forms/InputTextField";
import { TEntity } from "../../../../../../Types";
import { useState } from "react";

type CreateEntityMappingProps = {
  selectedEntity: string[];
  updateSelectedEntity: (selectedEntity: string[]) => any;
  entityKeyColumn: string;
  updateEntityKeyColumn: (entityKeyColumn: string) => void;
  entities: TEntity[];
  isAlreadySelected?: boolean;
}

export const CreateEntityMapping = ({
  selectedEntity,
  updateSelectedEntity,
  updateEntityKeyColumn,
  entityKeyColumn,
  entities,
  isAlreadySelected
}: CreateEntityMappingProps) => {


  return (
    <FlexColumnBox sx={{padding: '2rem', gap: '1rem'}} >
      <Box>
        <LabelTypography>Entity</LabelTypography>
        <DescriptionTypography>
          select the entity that column contains keys for
        </DescriptionTypography>
        <CustomDropDown
          data={entities.map((entity) => {
            return {
              label: entity.outputColumn,
              itemId: entity.id,
            }
          })}
          multiple={false}
          selectedItems={selectedEntity}
          updateSelectedItems={(selectedItems) => {
            updateSelectedEntity(selectedItems);
          }}
        />
      </Box>
      <Box>
        <LabelTypography>Entity Key Column</LabelTypography>
        <DescriptionTypography>
          The name of the column that contains the entity key
        </DescriptionTypography>
        <InputTextField
          onChange={(event) => {
            updateEntityKeyColumn(event.target.value)
          }}
          placeholder="my_pk_column"
          value={entityKeyColumn}
          violationArray={[]}
        />
      </Box>
    </FlexColumnBox>
  );
};
