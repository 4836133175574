import { useEffect, useMemo, useState } from "react";
import { GenericTable } from "../../../../Packages/Table";
import {
  DownWardAnimatedBox,
  FlexColumnBox,
  FlexRowBox,
  RightFloatTableMenuItemStyle,
} from "../../../../Styles";
import { OrchestratorGetSchema } from "../../../../Client/configuration-client";
import { getOrchestrators } from "../../../../Services/getOrchestrators";
import { OrchestratorTableHeader } from "../../../../Constants/TableHeaders/OrchestratorTableHeader";
import { TRowProps } from "../../../../Packages/Table/Types";
import { BlueButton, GreyButton, RedButton } from "../../../../Styles/Button";
import ThreeDotsMenu from "../../../../Components/MenuListModal";
import { Colors } from "../../../../Constants";
import { useSelectedRow } from "../../../../Hooks/useSelectedRows";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../../../DataLayer/Components/ConfirmationModal";
import { deleteOrchestrator } from "../../../../Services/deleteOrchestrator";
import { useToast } from "../../../../Hooks/useToast";
import { ToastType } from "../../../../Context/Providers/ToastProvider";
import { usePermissionContext } from "../../../../Hooks/usePermissionContext";
import { CustomToolTip } from "../../../../Styles/ToolTip";
import { PERMISSION_DENIED_TOOLTIP_MESSAGE } from "../../../../Constants/messages";

export const Orchestrators = () => {
  const [orchestrators, setOrchestrators] = useState<OrchestratorGetSchema[]>(
    []
  );
  const [deleteOrchestratorId, setDeleteOrchestratorId] = useState<
    string | undefined
  >(undefined);

  const navigate = useNavigate();

  const toast = useToast();

  const permissionContext = usePermissionContext();

  const isCreateOrchestratorPermission = useMemo(() => {
    if (!permissionContext) return false;
    return permissionContext.isPermission([
      "configurations",
      "configurations.orchestrator",
      "configurations.orchestrator.create",
    ]);
  }, []);

  const fetchData = () => {
    getOrchestrators()
      .then((data) => {
        setOrchestrators(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const deleteHandler = () => {
    if (!deleteOrchestratorId) return;
    deleteOrchestrator(deleteOrchestratorId)
      .then((data) => {
        toast.open("Orchestrator Deleted Successfully.", ToastType.SUCCESS);
        fetchData();
      })
      .catch((err) => {
        toast.open(
          "Something went wrong while deleting orchestrator.",
          ToastType.ERROR
        );
      })
      .finally(() => {
        setDeleteOrchestratorId(undefined);
      });
  };

  const tableData: TRowProps[] = orchestrators.map((orchestrator) => {
    return {
      row_id: orchestrator.id,
      data: {
        name: {
          column_id: "name",
          text: orchestrator.name,
        },
        type: {
          column_id: "type",
          text: orchestrator.orchestrator_type.name,
        },
        view: {
          column_id: "view",
          text: "",
          reactNode: (
            <FlexRowBox sx={{ justifyContent: "flex-end" }}>
              <BlueButton>View</BlueButton>
              <ThreeDotsMenu
                minWidth="175px"
                options={[
                  <RightFloatTableMenuItemStyle
                    showBorderBottom={false}
                    onClick={() => {
                      navigate("/configuration/orchestrators/create", {
                        state: {
                          orchestratorId: orchestrator.id,
                          isEdit: true,
                        },
                      });
                    }}
                  >
                    Edit
                  </RightFloatTableMenuItemStyle>,
                  <RightFloatTableMenuItemStyle
                    showBorderBottom={true}
                    onClick={() => {
                      navigate("/configuration/orchestrators/create", {
                        state: {
                          orchestratorId: orchestrator.id,
                          isDuplicate: true,
                        },
                      });
                    }}
                  >
                    Duplicate
                  </RightFloatTableMenuItemStyle>,
                  <RightFloatTableMenuItemStyle
                    showBorderBottom={false}
                    color={Colors.APP_RED_COLOR}
                    onClick={() => {
                      setDeleteOrchestratorId(orchestrator.id);
                    }}
                  >
                    Delete
                  </RightFloatTableMenuItemStyle>,
                ]}
              />
            </FlexRowBox>
          ),
        },
      },
    };
  });
  const { selectAllRows, selectedRows, updateSelectedRow, totalRows } =
    useSelectedRow(tableData);

  return (
    <FlexColumnBox
      sx={{
        flexGrow: 1,
        padding: "2rem",
        backgroundColor: Colors.APP_VERY_LIGHT_BLUE_COLOR,
      }}
    >
      <ConfirmationModal
        isLoading={false}
        open={deleteOrchestratorId !== undefined}
        message="Are you sure you want to delete this orchestrator."
        secondaryCustomButton={RedButton}
        secondaryButtonText="Delete"
        primaryCustomButton={GreyButton}
        closeHandler={() => {
          setDeleteOrchestratorId(undefined);
        }}
        confirmHandler={deleteHandler}
      />
      <FlexRowBox sx={{ justifyContent: "space-between" }}>
        <DownWardAnimatedBox flag={selectedRows.length > 0}>
          <FlexColumnBox>
            <FlexRowBox sx={{ gap: "5px" }}>
              <RedButton>Delete</RedButton>
            </FlexRowBox>
            <Typography sx={{ color: Colors.LINK_TEXT_COLOR }}>
              {selectedRows.length} row selected
            </Typography>
          </FlexColumnBox>
        </DownWardAnimatedBox>
        <FlexColumnBox>
          <CustomToolTip
            placement="top"
            title={
              isCreateOrchestratorPermission
                ? ""
                : PERMISSION_DENIED_TOOLTIP_MESSAGE
            }
          >
            <BlueButton
              onClick={() => {
                if (!isCreateOrchestratorPermission) return;
                navigate("/configuration/orchestrators/create");
              }}
              sx={{
                height: "40px",
                cursor: isCreateOrchestratorPermission ? "" : "not-allowed",
              }}
              className={
                isCreateOrchestratorPermission ? "" : "disabled-button"
              }
            >
              Create Orchestrator
            </BlueButton>
          </CustomToolTip>
          <Typography></Typography>
        </FlexColumnBox>
      </FlexRowBox>
      <GenericTable
        rows={tableData}
        tableHeader={OrchestratorTableHeader}
        selectAllRows={selectAllRows}
        updateSelectedRow={updateSelectedRow}
        totalRows={totalRows}
        selectedRows={selectedRows}
      />
    </FlexColumnBox>
  );
};
