import { Colors } from "../../../../../Constants"
import { FlexColumnBox } from "../../../../../Styles"
import { GreenButton, GreyButton } from "../../../../../Styles/Button"

export const Preview = () => {
    return (
        <FlexColumnBox sx={{gap: '.875rem', flexGrow: 1, backgroundColor: Colors.APP_VERY_LIGHT_BLUE_COLOR, padding: '2rem', width: '50%'}} >
            <GreyButton>Preview Filter</GreyButton>
            <GreenButton>Generate Preview</GreenButton>
        </FlexColumnBox>
    )
}