import React, { useState } from 'react';

// Define a generic interface for props
export interface WithToggleProps {
  isToggled: boolean;
  toggle: () => void;
}

// Define the Higher-Order Component (HOC) that adds toggle functionality
const withToggle = <P extends object>(WrappedComponent: React.ComponentType<P & WithToggleProps>) => {
  // Return a new component
  const WithToggle: React.FC<Omit<P, keyof WithToggleProps>> = (props) => {
    const [isToggled, setIsToggled] = useState<boolean>(true);

    // Function to toggle the state
    const toggle = () => {
      setIsToggled(prevIsToggled => !prevIsToggled);
    };

    // Render the wrapped component with the toggle functionality
    return <WrappedComponent {...props as P} isToggled={isToggled} toggle={toggle} />;
  };

  return WithToggle;
};

export default withToggle;