import { ApiClient } from "../Utils/ApiClient";

export const getFeatureById = (
  projectName: string,
  branchName: string,
  featureId: string
) => {
  return ApiClient.featureApiClient
    .getFeatureByIdFeatureFeatureIdGet(featureId, projectName, branchName)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};
