import { useState } from "react"
import { TRowProps } from "../Types";

export const usePagination = (data: TRowProps[], hidePagination: boolean) => {
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(hidePagination ? 1000 : 25);

    const updatePageNumber = (event: unknown,pageNum: number) => {
            setPageNumber(pageNum);
    }

    const updatePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPageSize(parseInt(event.target.value, 10));
        setPageNumber(0);
    }
    const paginatedData = data.slice((pageNumber)*pageSize, (pageNumber+1)*pageSize);

    return {
        paginatedData,
        pageNumber,
        pageSize,
        updatePageNumber,
        updatePageSize,
    }

}