import * as React from "react";
import { styled } from "@mui/system";
import { Tabs } from "@mui/base/Tabs";
import { TabsList as BaseTabsList } from "@mui/base/TabsList";
import { TabPanel as BaseTabPanel } from "@mui/base/TabPanel";
import { buttonClasses } from "@mui/base/Button";
import { Tab as BaseTab, tabClasses } from "@mui/base/Tab";
import { TabTypography } from "../../Styles/CustomTypography";
import { Colors } from "../../Constants";

export type CustomTabProps = {
  data: TabData[];
};

type TabData = {
  tabText: string;
  tabPanel: React.ReactNode;
};

export default function CustomTab({ data }: CustomTabProps) {
  const arr_data = data.map(
    (val) => [val.tabText, val.tabPanel] as [string, React.ReactNode]
  );

  return (
    <Tabs defaultValue={0}>
      <TabsList>
        {arr_data.map((val, index) => {
          return (
            <Tab
              first={index == 0 ? true : false}
              last={index == arr_data.length - 1 ? true : false}
              value={index}
            >
              <TabTypography>{val[0]}</TabTypography>
            </Tab>
          );
        })}
      </TabsList>
      {arr_data.map((val, index) => {
        return <TabPanel value={index}>{val[1]}</TabPanel>;
      })}
    </Tabs>
  );
}

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#80BFFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Tab = styled(BaseTab)(
  ({ first, last }: { first?: boolean; last?: boolean }) => `
font-family: 'IBM Plex Sans', sans-serif;
color: #fff;
cursor: pointer;
background-color: transparent;
padding: 5px 5px;
border: 0.3px solid ${Colors.APP_BLUE_COLOR};
border-left-width: ${first ? "0.3px" : 0};
display: flex;
justify-content: center;
border-top-left-radius: ${first ? "4px" : 0};
border-bottom-left-radius: ${first ? "4px" : 0};
border-top-right-radius: ${last ? "4px" : 0};
border-bottom-right-radius: ${last ? "4px" : 0};
color: ${Colors.APP_BLUE_COLOR};
transition: background-color 0.5s ease, color 0.5s ease; /* Corrected transition syntax */
&:hover {
  background-color: ${Colors.APP_BLUE_COLOR};
  color: #fff;
}

&:focus {
  color: #fff;
  outline: 1px solid ${Colors.APP_BLUE_COLOR};
}

&.${tabClasses.selected} {
  color: #fff;
  background-color: ${Colors.APP_BLUE_COLOR}
}

&.${buttonClasses.disabled} {
  opacity: 0.5;
  cursor: not-allowed;
}
`
);

const TabPanel = styled(BaseTabPanel)(
  ({ theme }) => `
  `
);

const TabsList = styled(BaseTabsList)(
  ({ theme }) => `
  min-width: 400px;
  border-radius: 6px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: left;
  align-content: space-between;
  `
);
