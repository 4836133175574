import {
  Action,
  CreateScheduleState,
} from "../Pages/Schedules/Components/CreateSchedule/Type";

export function reducer(
  state: CreateScheduleState,
  action: Action
): CreateScheduleState {
  switch (action.type) {
    case "SET_NAME":
      return { ...state, name: action.payload };
    case "SET_DESCRIPTION":
      return { ...state, description: action.payload };
    case "SET_ENABLED":
      return { ...state, enabled: action.payload };
    case "SET_CLUSTER":
      return { ...state, cluster: action.payload };
    case "SET_SHOW_ADDITIONAL_SPARK_PROPERTY":
      return { ...state, showAdditionalSparkProperty: action.payload };
    case "SET_STANDARD_OR_STREAM":
      return { ...state, standardOrStream: action.payload };
    case "SET_DESTINATIONS":
      return { ...state, destinations: action.payload };
    case "SET_TARGET":
      return { ...state, target: action.payload };
    case "SET_BRANCH":
      return { ...state, branch: action.payload };
    case "SET_FEATURE_SET":
      return { ...state, featureSet: action.payload };
    case "SET_ENTITY_POPULATION":
      return { ...state, entityPopulation: action.payload };
    case "SET_INCLUDE_METADATA":
      return { ...state, includeMetadata: action.payload };
    case "SET_SCHEDULE_TYPE":
      return { ...state, scheduleType: action.payload };
    case "SET_UTC_TIME":
      return { ...state, utcTime: action.payload };
    case "SET_DATA_OFFSET":
      return { ...state, dataOffset: action.payload };
    case "SET_CRON":
      return { ...state, cron: action.payload };
    case "SET_DEPENDENT_JOB":
      return { ...state, dependentJob: action.payload };
    case "SET_RETRY_TYPE":
      return { ...state, retryType: action.payload };
    case "SET_ADDITIONAL_SPARK_PROPERTY":
      return { ...state, additionalSparkProperty: action.payload };
    case "SET_ADD_DESTINATION_OPENED":
      return { ...state, addDestinationOpened: action.payload };
    case "SET_SELECTED_DESTINATION":
      return { ...state, selectedDestination: action.payload };
    case "SET_BACKOFF":
      return { ...state, backOff: action.payload };
    case "SET_MAX_ATTEMPTS":
      return { ...state, maxAttempts: action.payload };
    case "SET_CLUSTERS_DATA":
      return { ...state, clusterData: action.payload };
    case "SET_DOMAINS_DATA":
      return { ...state, domains: action.payload };
    case "SET_FEATURE_SET_DATA":
      return { ...state, featureSetData: action.payload };
    case "SET_TEAMS_DATA":
      return { ...state, owner: action.payload };
    case "RESET_STATE":
      return action.payload
    default:
      return state;
  }
}
