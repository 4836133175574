import { useContext } from "react";
import VersionControlContext from "../Context/VersionControlContext";

export const useVersionControlContext = () => {
  const context = useContext(VersionControlContext);
  if (!context) {
    console.log("You can't use this version control context here...");
  }
  return context;
};
