import { useEffect, useState } from "react";
import {
  DownWardAnimatedBox,
  FlexColumnBox,
  FlexRowBox,
  RightFloatTableMenuItemStyle,
} from "../../../../Styles";
import { useVersionControlContext } from "../../../../Hooks/useVersionControlContext";
import { GenericTable } from "../../../../Packages/Table";
import { TRowProps, TTableHeader } from "../../../../Packages/Table/Types";
import { Typography } from "@mui/material";
import { BlueButton, GreenButton, RedButton } from "../../../../Styles/Button";
import { Colors } from "../../../../Constants";
import {
  FeatureGenerationScheduleGetSchema,
  JobType,
} from "../../../../Client/typescript-axios-client-generated";
import { getSchedulesByType } from "../../../../Services/getSchedulesByType";
import { useNavigate } from "react-router-dom";
import ThreeDotsMenu from "../../../../Components/MenuListModal";

export const FeatureGeneration = () => {
  const [schedules, setSchedules] = useState<FeatureGenerationScheduleGetSchema[]>([]);

  const versionControlContext = useVersionControlContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (
      !versionControlContext ||
      !versionControlContext.currentBranchName ||
      !versionControlContext.currentProjectName
    )
      return;
    getSchedulesByType(
      JobType.FeatureGenerationSchedule,
      versionControlContext.currentProjectName,
      versionControlContext.currentBranchName
    ).then((data) => {
      setSchedules(data);
    });
  }, [versionControlContext?.currentBranch]);

  const tableData: TRowProps[] = schedules.map((schedule) => {
    return {
      row_id: schedule.id,
      data: {
        name: {
          column_id: "name",
          text: schedule.name,
          link: '/schedules/feature-generation/'+schedule.id
        },
        feature_set: {
          column_id: "feature_set",
          text: schedule.feature_set ?? "",
        },
        target: {
          column_id: "target",
          text: schedule.branch,
        },
        destinations: {
          column_id: "destinations",
          text:
            []
              ?.map((d: any) => {
                return d.destination;
              })
              .join(", ") ?? "",
        },
        quick_look: {
          column_id: "quick_look",
          text: schedule.schedule_type,
        },
        labels: schedule.labels.map((label) => {
          return {
            column_id: "labels",
            text: label,
          };
        }),
        view: {
          column_id: "view",
          text: "View Details", // This could be a button or link to view more details
          reactNode: (
            <FlexRowBox>
              <BlueButton>View Detail</BlueButton>
              <ThreeDotsMenu
                minWidth="175px"
                options={[
                  <RightFloatTableMenuItemStyle
                    showBorderBottom={false}
                    onClick={() => {}}
                  >
                    Edit
                  </RightFloatTableMenuItemStyle>,
                  <RightFloatTableMenuItemStyle
                    showBorderBottom={true}
                    onClick={() => {}}
                  >
                    Duplicate
                  </RightFloatTableMenuItemStyle>,
                  <RightFloatTableMenuItemStyle
                    showBorderBottom={false}
                    color={Colors.APP_RED_COLOR}
                    onClick={() => {}}
                  >
                    Delete
                  </RightFloatTableMenuItemStyle>,
                ]}
              />
            </FlexRowBox>
          ),
        },
      },
    };
  });

  const tableHeader: TTableHeader[] = [
    {
      column_id: "name",
      label: "Name",
      isSortable: "true",
      visible: true,
    },
    {
      column_id: "feature_set",
      label: "Feature Set",
      isSortable: "true",
      visible: true,
    },
    {
      column_id: "target",
      label: "Target",
      isSortable: "true",
      visible: true,
    },
    {
      column_id: "destinations",
      label: "Destinations",
      isSortable: "true",
      visible: true,
    },
    {
      column_id: "quick_look",
      label: "Quick Look",
      isSortable: "true",
      visible: true,
    },
    {
      column_id: "labels",
      label: "Labels",
      isSortable: "true",
      visible: true,
    },
    {
      column_id: "view",
      label: "View Details",
      isSortable: "true",
      visible: false,
    },
  ];

  return (
    <FlexRowBox flexGrow={1}>
      {/* <GenericFilter globalSearchEnabled={true} data={featureFilterData} onChangeFilter={setFilter} /> */}
      <FlexColumnBox
        sx={{
          padding: "2rem",
          backgroundColor: Colors.APP_VERY_LIGHT_BLUE_COLOR,
          flexGrow: "1",
        }}
      >
        <FlexRowBox sx={{ justifyContent: "space-between" }}>
          <DownWardAnimatedBox flag={true}>
            <FlexColumnBox>
              <FlexRowBox sx={{ gap: "5px" }}>
                <GreenButton>Add to project</GreenButton>
                <RedButton>Delete</RedButton>
              </FlexRowBox>
              <Typography sx={{ color: Colors.LINK_TEXT_COLOR }}>
                {0} row selected
              </Typography>
            </FlexColumnBox>
          </DownWardAnimatedBox>
          <FlexColumnBox>
            <BlueButton
              onClick={() => {
                navigate("/schedules/feature-generation/create-schedule");
              }}
            >
              Create Feature Generation
            </BlueButton>
            <Typography></Typography>
          </FlexColumnBox>
        </FlexRowBox>
        <GenericTable
          rows={tableData}
          tableHeader={tableHeader}
          selectAllRows={() => {}}
          updateSelectedRow={() => {}}
          totalRows={schedules.length}
          selectedRows={[]}
        />
      </FlexColumnBox>
    </FlexRowBox>
  );
};
