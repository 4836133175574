import React, { useState, useMemo, ReactNode } from 'react';
import { createPortal } from 'react-dom';

import { ToastContext, ToastContextProps } from '../ToastContext';
import  Toast  from '../../Components/Toast/Toast';
import { SmallSizeLightColorTypography } from '../../Styles/CustomTypography';
import { FlexRowBox } from '../../Styles';
import { Box, Typography } from '@mui/material';

// Create a random ID
function generateUEID(): string {
    let first: number = (Math.random() * 46656) | 0;
    let second: number = (Math.random() * 46656) | 0;
    let firstStr: string = ('000' + first.toString(36)).slice(-3);
    let secondStr: string = ('000' + second.toString(36)).slice(-3);
  
    return firstStr + secondStr;
  }
  

interface ToastProviderProps {
  children: ReactNode;
}

export enum ToastType {
  WARNING,
  ERROR,
  SUCCESS,
}

interface Toast {
  id: string;
  content: string;
  type: ToastType;
}

const getBackgroundColor = (type: ToastType) => {
  switch (type) {
    case ToastType.SUCCESS:
      return '#4caf50';
    case ToastType.ERROR:
      return '#f44336';
    case ToastType.WARNING:
      return '#ff9800';
    default:
      return '#4caf50';
  }
};

const getToastText = (type: ToastType) => {
  switch (type) {
    case ToastType.SUCCESS:
      return "Success!";
    case ToastType.ERROR:
      return "Error!";
    case ToastType.WARNING:
      return "Warning!";
  }
}

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
    const [toasts, setToasts] = useState<Toast[]>([]);
    const open = (content: string, type: ToastType) =>
      setToasts(currentToasts => [
        ...currentToasts,
        { id: generateUEID(), content,  type: type},
      ]);
    const close = (id: string) =>
      setToasts(currentToasts =>
        currentToasts.filter((toast) => toast.id !== id)
      );
    const contextValue = useMemo(() => ({ open }), []);
    console.log(toasts)
  
    return (
      <ToastContext.Provider value={contextValue}>
        {children}
  
        {createPortal(
          <div style={{ position: 'fixed', top: 16, right: 16, zIndex: 1300 }}>
            {toasts.map((toast) => (
              <Toast type={toast.type} key={toast.id} close={() => close(toast.id)}>
                <FlexRowBox sx={{maxWidth: "250px"}} >
                <Box sx={{border: `3.5px solid ${getBackgroundColor(toast.type)}`, borderRadius: '10px'}} ></Box>
                <Box sx={{padding: '0 20px'}} >
                  <Typography fontWeight={'bold'} sx={{color: getBackgroundColor(toast.type)}} >{getToastText(toast.type)}</Typography>
                  <SmallSizeLightColorTypography>{toast.content}</SmallSizeLightColorTypography>
                </Box> 
                </FlexRowBox>
              </Toast>
            ))}
          </div>,
          document.body
        )}
      </ToastContext.Provider>
    );
  };