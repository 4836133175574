import { ApiClient } from "../Utils/ApiClient";

export const getFeatureSetById = async (
  projectName: string,
  branchName: string,
  featureSetId: string
) => {
  return ApiClient.featureSetClient
    .getFeatureSetByIdFeatureSetFeatureSetIdGet(
      featureSetId,
      projectName,
      branchName,
    )
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};
